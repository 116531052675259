import { styled, css } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Input, Typography } from '@mui/material';
import { COLORS } from '@utils/colors';
import { FontFamilyNames } from 'app/types/fonts';

export const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    padding: '0',
  },
}));

export const Wrapper = styled(Box)`
  max-width: 1077px;
  margin: 0 auto;
`;

export const FirstLevel = styled(Stack)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',

  [theme.breakpoints.down('sm')]: {
    padding: '0px 20.5px',
    gridTemplateColumns: '1fr',
    marginBottom: '10px',
    gap: '6px',
  },
}));

export const MinimumSizeMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: '12px 0',
  color: COLORS.coralPink,
  fontSize: '16px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    textAlign: 'left',
    margin: '12px 20px',
  },
}));

export const SecondLevel = styled(Stack)(({ theme }) => ({
  display: 'grid',
  width: 'max-content',
  margin: '26px auto',
  gridTemplateColumns: 'repeat(3, 270px)',

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0px 20px',
    width: '100%',
    marginTop: '0',
    marginBottom: '0',
    gridTemplateColumns: '1fr',

    '>div': {
      marginBottom: '10px',
    },
  },
}));

export const ThirdLevel = styled(Stack)(({ theme }) => ({
  display: 'grid',
  width: 'max-content',
  margin: '26px auto',
  gridTemplateColumns: 'repeat(3, 210px)',

  [theme.breakpoints.down('sm')]: {
    padding: '0px 20.5px',
    width: '100%',
    margin: '0',
    gridTemplateColumns: '1fr',
  },
}));

export const StyledInput = styled(Input)<{
  position: 'left' | 'center' | 'right';
}>(({ position }) => {
  let border = null;

  if (position === 'left') {
    border = css`
      border-radius: 25px 0 0 25px;
    `;
  } else if (position === 'right') {
    border = css`
      border-radius: 0px 25px 25px 0;
    `;
  } else {
    border = css`
      border-radius: 0;
      border-left: none;
      border-right: none;
    `;
  }

  return css`
    width: 100%;
    border: none;

    ::before,
    ::after {
      display: none;
    }
    height: 50px;

    .MuiInputBase-input {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background: ${COLORS.white};
      padding: 11px 30px 11px 17px;
      border: 1px solid ${COLORS.grey[25]};
      ${border};

      :-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
      }
      ::placeholder {
        color: ${COLORS.darkBlue};
        opacity: 1;
      }
    }

    @media (max-width: 600px) {
      .MuiInputBase-input {
        height: 46px;
        border-radius: 25px;
        border: 1px solid ${COLORS.grey[25]};
        font-size: 14px;
        line-height: 130%; /* 18.2px */
        letter-spacing: -0.14px;
      }

      margin-bottom: 10px;
    }
  `;
});

export const ButtonWrapper = styled(Stack)`
  margin-top: 26px;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;

  > button {
    width: 100%;
    max-width: 248px;
    height: 46px;
    font-family: ${FontFamilyNames.MoretRegular};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 16px;
    margin-bottom: 0;

    button {
      width: 100%;
      max-width: unset;
      height: 46px;
      font-size: 16px;
    }
  }
`;

export const StyledErrorMessage = styled(Typography)`
  color: ${COLORS.error.main};
`;

export const StyledTypography = styled(Typography)`
  max-width: 470px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: -0.32px;
  color: ${COLORS.grey[50]};
  text-align: center;
  margin: 0px 0px 15px 0px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 24px;
    font-size: 14px;
    letter-spacing: -0.14px;
  }
`;
