import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

export const Container = styled(Toolbar)`
  cursor: pointer;
  padding: 0;
  width: 200px; // 207
  height: 22px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    display: flex;
    justify-content: center;

    > .desktop-version {
      display: none;
    }
  }
`;
