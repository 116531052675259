import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@components/Typography';
import { ReactComponent as NoMoveDesktop } from '@assets/svg/no-move-desktop.svg';

export const NoMoveDesktopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '60px',
}));

export const NoMoveMobileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24',
}));

export const NoMoveMobileTitle = styled(Typography)(({ theme }) => ({
  paddingRight: '50px',
  fontSize: '16px',
  lineHeight: '130%',
  paddingTop: '24px',
}));

export const MoveDetailsSummaryContainer = styled(Box)(({ theme }) => ({
  alignSelf: 'self-start',
  maxWidth: '860px',
  width: '100%',
}));

export const StyledNoMoveDesktop = styled(NoMoveDesktop)(({ theme }) => ({
  width: '100%',
}));

export const LottieContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const LottieContent = styled(Box)(({ theme }) => ({
  width: '300px',
  height: '298.96px',
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%,-20%)',

  [theme.breakpoints.down(1100)]: {
    transform: 'translate(-50%, -18%)',
  },
  [theme.breakpoints.down(980)]: {
    transform: 'translate(-50%, -16%)',
  },
  [theme.breakpoints.down(950)]: {
    transform: 'translate(-50%, -13%)',
  },
  [theme.breakpoints.down(915)]: {
    transform: 'translate(-50%, -10%)',
  },
  [theme.breakpoints.down(810)]: {
    transform: 'translate(-50%, -8%)',
  },
}));
