import Modal from '@ui/Modal';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@components/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from "@components/Button";

import { FontFamilyNames } from 'app/types/fonts';

import { COLORS } from '@utils/colors';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: 670,
    height: 500,
    maxWidth: 670,
    borderRadius: 20,
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },
  },
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  padding: '90px 0 67px 0',
  borderRadius: 20,
  maxWidth: '296px',
  margin: "0 auto",

  "> form": {
    maxWidth: 296,
    margin: '0px auto',
  },

  '> img': {
    height: '200px'
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '256px',

  },
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  fontWeight: 400,
  lineHeight: 'normal',

  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
    marginBottom: 40,
  },
}));


export const SubTitle = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  textAlign: 'center',
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',
  margin: "10px auto 40px auto",
  maxWidth: '256px',

  [theme.breakpoints.down('sm')]: {
    margin: "11px auto 69px auto",
    fontSize: '14px',
    letterSpacing: '-0.14px',
    maxWidth: '244px',
  },
}));

export const FieldWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: 25,

  '> .MuiTypography-body1': {
    position: 'static !important',
    // top: 'calc(100% - 23px)',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: 300,
  maxHeight: 35,
  // marginBottom: 25,


  '.MuiOutlinedInput-root': {
    borderBottom: `1px solid ${COLORS.darkBlue}`,
    borderRadius: 0,
    color: COLORS.darkBlue,
  },
  '.MuiInputBase-input': {
    color: COLORS.darkBlue,
    fontSize: 16,
    padding: '0px 0px 14px 0',
    letterSpacing: '-0.02em',
  },
  '&::placeholder': {
    color: COLORS.darkBlue,
    opacity: 0.5,
  },
  'input:-webkit-autofill': {
    boxShadow: '0 0 0 1000px white inset !important',
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: 30,
    maxHeight: 31,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      width: '100%',
    },

    '& .MuiInputBase-input': {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
}));

export const ForgotPassword = styled(Box)(() => ({
  width: '100%',
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '123.4%',
  letterSpacing: '-0.28px',
  textDecorationLine: 'underline',
  textAlign: 'left'
}))



export const StyledLeftLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: -33,

  '> img': {
    width: '172px',
  },

  [theme.breakpoints.down('sm')]: {
    left: 'calc(50% - 70px)',
    height: '200px',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },
}));

export const ButtonAndMessageWrapper = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
}));

export const StyledLoginButton = styled(Button)(({ theme }) => ({
  marginTop: 30,
  height: 48,
  width: 195,
  fontSize: '20px',
  padding: '4px 30px',
  fontFamily: FontFamilyNames.MoretRegular,

  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    marginTop: 58,
    padding: '9px 36px',
    width: 167,
    height: 36,
  },
}));

export const MainErrorMessage = styled(Typography)(({ theme }) => ({
  color: COLORS.error.main,
  position: 'absolute',
  top: '100%'
}));

