import {
  IMoveRequestFormValues,
  PackingServicesEnum,
} from '../../types/move.types';

export const payloadCollector = (data: IMoveRequestFormValues): any => {
  return {
    name: data.name,
    email: data.email,
    phone: data.phone ? `+${data.phone}` : '',

    pickupDate: data.date,
    houseId: data?.currentHouseSize?.id,
    newBuildingType: data?.toPlace?.value.toLowerCase(),
    currentBuildingType: data?.fromPlace?.value.toLowerCase(),
    isPackingIncluded:
      data?.packingServices?.value ===
      PackingServicesEnum.PACKING_SERVICES_INCLUDED,

    origin: {
      address: data?.from?.address,
      placeId: data?.from?.placeId,
    },
    destination: {
      address: data?.to?.address,
      placeId: data?.to?.placeId,
    },
  };
};
