import { MyProfile } from './components';
import PasswordForm from './components/PasswordForm';
import { StyledAccountContent, StyledAccount } from './styled';

const Account = () => {
  return (
    <StyledAccount>
      <StyledAccountContent>
        <MyProfile />
        <PasswordForm />
      </StyledAccountContent>
    </StyledAccount>
  );
};

export default Account;
