import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { logout } from '@modules/auth';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { setPopUpName } from '@app/store/features/app/app.slice';
import {
  PAGE_ROUTES_PRIVATE,
  PAGE_ROUTES_PUBLIC,
} from '@app/types/enums/pages';
import { PopUpEnum } from '@app/store/features/app/app.types';

import { Container, StyledLink, StyledButton } from './styled';
import { LINKS } from '@constants/links';
import { isDebugMode } from '@utils/constants';

const MainMenuModal = () => {
  let time: any;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const auth = useAppSelector(state => state.auth);

  const handleClose = () => {
    time = setTimeout(() => {
      dispatch(setPopUpName({ name: PopUpEnum.Empty }));
    }, 200);
  };

  useEffect(() => {
    return () => {
      clearTimeout(time);
    };
  }, [time]);

  return (
    <Container>
      {isDebugMode && (
        <StyledButton
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.HOME);
            handleClose();
          }}>
          Get Started
        </StyledButton>
      )}
      <StyledLink
        onClick={() => {
          navigate(PAGE_ROUTES_PUBLIC.HOW_IT_WORKS);
          handleClose();
        }}>
        How Moo Works
      </StyledLink>
      {/*{isDebugMode && (*/}
      {/*  <StyledLink*/}
      {/*    onClick={() => {*/}
      {/*      handleClose();*/}
      {/*      window.location.href = LINKS.MOVINGTIPS;*/}
      {/*    }}>*/}
      {/*    Moo'ving Tips*/}
      {/*  </StyledLink>*/}
      {/*)}*/}
      <StyledLink
        onClick={() => {
          handleClose();
          navigate(PAGE_ROUTES_PUBLIC.TRUCKER_LANDING);
        }}>
        Mooving Partners
      </StyledLink>
      <StyledLink
        onClick={() => {
          handleClose();
          navigate(PAGE_ROUTES_PUBLIC.AFFILIATE);
        }}>
        Referral Partners
      </StyledLink>

      {auth.userInfo ? (
        <>
          <StyledLink
            onClick={() => {
              handleClose();
              setTimeout(() => {
                navigate(PAGE_ROUTES_PRIVATE.PROFILE);
              }, 500);
            }}>
            My Profile
          </StyledLink>
          <StyledLink
            onClick={() => {
              dispatch(logout());
              handleClose();
              setTimeout(() => {
                navigate(PAGE_ROUTES_PUBLIC.HOME);
              }, 500);
            }}>
            Log out
          </StyledLink>
        </>
      ) : (
        <>
          {isDebugMode && (
            <StyledLink
              onClick={() => {
                dispatch(setPopUpName({ name: PopUpEnum.LoginModal }));
                handleClose();
              }}>
              Login
            </StyledLink>
          )}
        </>
      )}
      <StyledLink
        onClick={() => {
          dispatch(setPopUpName({ name: PopUpEnum.ContactUsModal }));
          handleClose();
        }}>
        Contact us
      </StyledLink>
      <StyledLink
        onClick={() => {
          navigate(PAGE_ROUTES_PUBLIC.FAQ);
          handleClose();
        }}>
        FAQ
      </StyledLink>
    </Container>
  );
};

export default memo(MainMenuModal);
