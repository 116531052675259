import * as yup from 'yup';

const requiredFieldMessage = 'This field is required';
const emailFieldMessage = 'This field must be a valid email';

export const getErrorMessageByKey = (key: string) => {
  switch (key) {
    case 'EMAIL_ALREADY_EXIST':
      return 'Email has already been taken';
    case 'USERNAME_ALREADY_EXIST':
      return 'Username has already been taken';
    default:
      return 'Something went wrong';
  }
};

const googleReviewRegex1 =
  /^https:\/\/www\.google\.com\/maps\/place\/[a-zA-Z0-9+\-]+$/;
const googleReviewRegex2 =
  /^https:\/\/www\.google\.com\/search\?q=[a-zA-Z0-9+\-]+$/;
const googleReviewRegex3 =
  /^https:\/\/www\.google\.com\/search\?q=[a-zA-Z0-9+\-]+&tbm=rls$/;
const googleReviewRegex4 = /^https:\/\/g\.page\/r\/[A-Za-z0-9]+\/review$/;

const charMessage = 'The field must contain at least 1 character';

export const getFormValidationSchema = () => {
  return yup.object().shape({
    isPhoneNumberForTextsActive: yup
      .boolean()
      .nullable()
      .required('Checkbox state must be defined'),

    phoneNumberForTexts: yup
      .string()
      .test(
        'phone-number-conditional-requirement',
        'Agreeing to receive text messages is required when a phone number is provided',
        function (value) {
          const { isPhoneNumberForTextsActive } = this.parent;

          if (value && value.trim().length > 0) {
            return isPhoneNumberForTextsActive === true;
          }
          return true;
        },
      )
      .test(
        'phone-number-format',
        'Phone number must contain only numbers',
        function (value) {
          // Apply the regex match only if there's a value to check
          return value && value.trim().length > 0
            ? /^[0-9]+$/.test(value)
            : true;
        },
      )
      .nullable()
      // .notRequired(),
      .test(
        'conditional-required',
        'If you would like to receive text message notifications, please enter a valid cell phone number',
        (value, context) => {
          const { isPhoneNumberForTextsActive } = context.parent;
          if (isPhoneNumberForTextsActive) {
            // If consent is checked, phone number must not be empty and must only contain numbers
            return !!value && /^[0-9]+$/.test(value);
          }
          // If consent is not checked, there are no restrictions on the phone number field
          return true;
        },
      ),
    contactName: yup
      .string()
      .trim('The field cannot include trailing spaces')
      .strict(true)
      .min(1, charMessage)
      .max(60, 'The field cannot exceed 60 char')
      .required(requiredFieldMessage),

    // TODO: maybe can need registerState in future
    // isRegisterStateActive: yup.boolean().required(),
    // registerState: yup.number().when('isRegisterStateActive', {
    //   is: false,
    //   then: () =>
    //     yup
    //       .number()
    //       .typeError('State Registration must contain only numbers')
    //       .positive('State Registration must be positive')
    //       .required('State Registration is required'),
    //   otherwise: () => yup.string().optional(),
    // }),

    companyName: yup
      .string()
      .trim('The field cannot include trailing spaces')
      .strict(true)
      .min(1, charMessage)
      .max(60, 'The field cannot exceed 60 char')
      .required(requiredFieldMessage),
    companyAddress: yup
      .string()
      .trim('The field cannot include trailing spaces')
      .strict(true)
      .min(1, charMessage)
      .max(60, 'The field cannot exceed 60 char')
      .required(requiredFieldMessage),
    companyWebsite: yup
      .string()
      .trim('The field cannot include trailing spaces')
      .strict(true)
      .min(1, charMessage)
      .max(60, 'The field cannot exceed  60 char')
      .required(requiredFieldMessage),
    isDotActive: yup.boolean().required(),
    dot: yup.number().when('isDotActive', {
      is: false,
      then: () => yup.string().required(),
      otherwise: () => yup.string().optional(),
    }),
    isGooglePlaceId: yup.boolean().required(),
    googlePlaceId: yup
      .mixed<{
        address: string;
        placeId: string;
      }>()
      .test(
        'google-place-id-validation',
        'Google Place ID validation error',
        function (
          this: yup.TestContext,
          value?: { address: string; placeId: string },
        ) {
          const isGooglePlaceId = this.parent.isGooglePlaceId;

          if (isGooglePlaceId) return true;

          return (
            !!value &&
            typeof value.address === 'string' &&
            typeof value.placeId === 'string'
          );
        },
      ),
    email: yup.string().email(emailFieldMessage).required(requiredFieldMessage),
    phone: yup.string().required(requiredFieldMessage),
  });
};
