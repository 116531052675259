import { styled } from '@mui/material/styles';
import Modal from '@ui/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '100%',
    maxWidth: '670px',
    maxHeight: '500px',
    borderRadius: 20,
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },

    '.MuiDialog-container': {
      '.MuiPaper-root': {
        'h2': {
          zIndex: '1000',
          position: 'relative'
        }
      }
    }
  },
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  width: 670,
  height: 500,
  padding: '77px 0 0 0',
  borderRadius: 20,

  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    zIndex: 0,
    width: '100%',
    height: '100%',
    padding: '90px 45px 0 45px',
    'overflow-x': 'hidden'
  },
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 40,
  lineHeight: 'normal',
  marginBottom: 18,

  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
    textAlign: 'left',
  },
}));

export const StyledLeftLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, 18%)',

  [theme.breakpoints.down('sm')]: {
    top: 'unset',
    bottom: '0',
    left: '0',
    height: '218px',
    transform: 'unset',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  width: '100%',
  maxWidth: 484,
  marginTop: 18,
  margin: '0 auto',
  textAlign: 'center',
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    letterSpacing: '-0.14px',
    textAlign: 'left',
  },
}));

export const Buttons = styled(Box)(({ theme }) => ({
  width: 'max-content',
  margin: '40px auto',
  display: 'flex',
  flexDirection: 'unset',

  button: {
    width: '162px',
    height: '52px',
    marginLeft: '19px',

    ':last-child': {
      background: 'none'
    }
  },

  [theme.breakpoints.down('sm')]: {
    margin: '40px 0 0 0',
    display: 'flex',
    flexDirection: 'column',

    button: {
      width: '200px',
      height: '36px',
      marginLeft: '0',

      ':first-child': {
        marginBottom: '20px',
      }
    }
  },
}));

