
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';

import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { useQuoteBoardLogic } from './usePriceModalLogic';
import {
  StyledModal,
  StyledIconButton,
  StyledModalTitle,
  StyledDialogContent,
  Content,
  Footer,
} from './styled';


const PriceModal = () => {
  const {
    matches,
    handleClose,
  } = useQuoteBoardLogic()

  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type='closed'
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>
      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Each Quote Includes Two Prices:"
          textAlign="center"
        />
        <Content>
          <p>1.  All-Inclusive amount for the estimated size of your home.</p>
          <p>2. Overage amount is the "Dollar Per Cubic Foot Rate" for items exceeding this maximum. (Ex. if a Mover confirms your inventory to be 50 Cubic Feet over what was estimated for your household and charges $4.00 per cubic foot for the overage amount, you would be charged 50 X $4 = $200 additional.</p>
        </Content>
        <Footer>
          <p>We haven't conducted a detailed inventory of your move to save time and provide you with quick prices. Based on the information you provided, we estimated the total cubic feet of your move, which the moving companies are using to generate their bids.</p>
        </Footer>
      </StyledDialogContent>
    </StyledModal >
  );
};

export default PriceModal;
