import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { COLORS } from '@utils/colors';
import Button from '@components/Button';
import { FontFamilyNames } from '@app/types/fonts';
import PlainImage from '@ui/Image/PlainImage';

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  ...theme.mixins.toolbar,
  maxWidth: 1440,
  margin: '0 auto',
  background: COLORS.beige,
  display: 'flex',
  justifyContent: 'space-between',
  height: '110px',
  padding: '0 69px 0 70px',
  alignItems: 'flex-start',

  '&.isCheckout': {
    background: 'transparent',
  },

  [theme.breakpoints.down('lg')]: {
    padding: '0 35px',
    gap: '20px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0',
    height: 'auto',
    minHeight: '49px',
  },
}));

export const StyledMenuItem = styled(Typography)<{ disabled?: boolean }>(
  ({ disabled }) => ({
    cursor: 'pointer',

    ...(disabled && {
      opacity: 0.5,
    }),
  }),
);

export const LeftSide = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  paddingTop: 'calc(34px - 8px)',

  '&.MuiStack-root': {
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      gap: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      gap: '30px',
    },
  },
}));

export const StyledGetMyQuotesButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: FontFamilyNames.PlainLight,
  lineHeight: '130%',
  letterSpacing: '-0.28px',
  display: 'flex',
  width: '161px',
  height: '34px',
  padding: '8px 30px',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const LeftAndCenterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: '34px',

  [theme.breakpoints.up('xl')]: {
    gap: 'calc(261px + 110px)',
  },
  [theme.breakpoints.between(1100, 'xl')]: {
    gap: 'calc(200px + 80px)',
  },
  [theme.breakpoints.down(1100)]: {
    gap: 'calc(150px + 60px)',
  },
  [theme.breakpoints.down('md')]: {
    gap: 'calc(110px + 50px)',
  },
}));

export const MobileLogo = styled(PlainImage)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    maxWidth: '107px',
    height: '11px',
    marginTop: '20px',
    marginLeft: '134px',
  },
}));

export const RightSide = styled(Stack)(({ theme }) => ({
  '> div': {
    alignItems: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    '> .MuiStack-root': {
      display: 'none',
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.28px',
  width: '161px',
  height: '34px',

  [theme.breakpoints.down('sm')]: {},
}));
