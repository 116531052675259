import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { COLORS } from '@utils/colors';

export const StyledTopPanelBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  background: COLORS.amour,
  padding: '12px 70px',

  [theme.breakpoints.down('sm')]: {
    padding: '12px 22px',
    flexDirection: 'row',
  },
}));

export const StyledTopPanelText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: 140,
    fontSize: '10px',
  },
}));

export const StyledTopPanelLinksContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '30px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '3px',
  },
}));

export const StyledTopPanelLink = styled(MuiLink)(({ theme }) => ({
  cursor: 'pointer',
  color: COLORS.darkBlue,

  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
}));
