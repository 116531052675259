import React, { FC, ReactNode } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import CloseButton from '@modals/PhoneVerificationModal/CloseButton';
import { StyledDialog, StyledDialogContent } from './styled';
import { SxProps } from '@mui/material/styles';

export interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: ReactNode;
  content?: ReactNode;
  closeIcon?: ReactNode;
  children?: ReactNode;
  styles?: {
    dialog?: SxProps;
    dialogTitle?: SxProps;
    box?: SxProps;
    dialogContent?: SxProps;
  };
}

const SuccessModal: FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  title,
  content = 'Default content',
  closeIcon = <CloseButton onClose={onClose} />,
  children,
  styles = {},
}) => {
  const {
    dialog: dialogStyle = {},
    dialogTitle: dialogTitleStyle = {},
    box: boxStyle = {},
    dialogContent: dialogContentStyle = {},
  } = styles;

  console.log('dsfsad2 dialogStyle', { dialogStyle });

  return (
    <StyledDialog open={isOpen} onClose={onClose} sx={dialogStyle}>
      <DialogTitle sx={{ padding: 0, ...dialogTitleStyle }}>
        {title}
        {closeIcon}
      </DialogTitle>
      <Box sx={{ padding: '20px', height: '100%', ...boxStyle }}>
        {children ? (
          children
        ) : (
          <StyledDialogContent sx={dialogContentStyle}>
            {content}
          </StyledDialogContent>
        )}
      </Box>
    </StyledDialog>
  );
};

export default SuccessModal;
