import { Suspense } from 'react';

import { useRoutes } from 'react-router-dom';
import { ROUTES } from 'app/routes/helper';

const Routes = () => {
  const element = useRoutes(ROUTES);

  return <Suspense fallback={<div>Loading...</div>}>{element}</Suspense>;
};

export default Routes;
