import { useAppDispatch } from '@app/store/hooks';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { ReactComponent as MobileBurgerMenuIcon } from '@assets/svg/mobile-burger-menu-icon.svg';
import Center from './components/Center';
import LeftPart from './components/NavbarSections/LeftPart';
import RightPart from './components/NavbarSections/RightPart';
import { LeftAndCenterContainer, MobileLogo, StyledToolbar } from './styled';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import appLogoMobile from '@assets/svg/app-logo-mobile.svg';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

const Header = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <StyledToolbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        {!matches ? (
          <LeftAndCenterContainer>
            <LeftPart />
          </LeftAndCenterContainer>
        ) : (
          <MobileLogo
            src={appLogoMobile}
            objectFit="cover"
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.HOME);
            }}
          />
        )}

        {!matches && <Center />}

        <RightPart />
      </Box>
      <BurgerMenuButton
        type="closed"
        onClick={() => {
          dispatch(setPopUpName({ name: PopUpEnum.MainMenuModal }));
        }}>
        <MobileBurgerMenuIcon />
      </BurgerMenuButton>
    </StyledToolbar>
  );
};

export default Header;
