import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { FontFamilyNames } from '@app/types/fonts';
import Typography from '@components/Typography';
import { COLORS } from '@utils/colors';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.down('sm')]: {
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',

  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

export const SubTitle = styled('span')(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '12px',
  marginBottom: 25,
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  color: COLORS.grey[50],
  display: 'block',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const StyledSelect = styled(Box)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',

  border: 'none',
  width: '100%',
  height: '33px',
  borderRadius: '0',
  background: 'none',
  paddingBottom: '12px',
  borderBottom: '1px solid #000',

  whiteSpace: 'pre',
  overflow: 'scroll',
  paddingRight: '30px',
}))

export const InputWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',

  input: {
    cursor: 'pointer',
  }
}))


export const SubSection = styled(Box)(({ theme }) => ({
  background: '#FFF',
  padding: '0px 20px',
  border: '1px solid #282750',
  borderRadius: '20px',
  maxHeight: '200px',
  height: 'auto',
  width: '100%',
  marginTop: '25px',
  overflow: 'scroll'
}))

export const EmptyTypography = styled(Typography)(({ theme }) => ({
  padding: '16px 0',
  maxHeight: 54,
}))

export const SvgWrapper = styled(Box)(({ theme }) => ({
  background: COLORS.beige,
  position: 'absolute',
  right: '0',
  top: '0px',
  padding: '5px',
}))

