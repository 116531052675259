import queryString from 'query-string';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@app/api/baseQueryWithReauth';
import {
  PartnerInfoAfterLockInRate,
  IMovesResponse,
} from '../types/moves-response.model';
import { MoveNavbarEnum } from '../types/move-navbar.enum';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  keepUnusedDataFor: 0,
  baseQuery: baseQueryWithReAuth(),
  endpoints: builder => ({
    getMoveBidPartnerInfo: builder.query<
      PartnerInfoAfterLockInRate,
      { moveId: number; bidId: number }
    >({
      query: ({ moveId, bidId }) => ({
        url: `moves/${moveId}/bids/${bidId}`,
        method: 'GET',
      }),
    }),
    deleteMove: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `moves/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteBid: builder.mutation<void, { moveId: number; bidId: number }>({
      query: ({ moveId, bidId }) => ({
        url: `moves/${moveId}/bids/${bidId}`,
        method: 'DELETE',
      }),
    }),
    acceptBid: builder.mutation<void, { moveId: number; bidId: number }>({
      query: ({ moveId, bidId }) => ({
        url: `moves/${moveId}/bids/${bidId}`,
        method: 'PATCH',
      }),
    }),
    getMoves: builder.query<IMovesResponse, { status: MoveNavbarEnum[] }>({
      query: payload => {
        return `moves?${queryString.stringify(payload, { arrayFormat: 'bracket' })}`;
      },
    }),
    setProfileInformation: builder.mutation<
      void,
      { fullName: string; phone?: string }
    >({
      // This query is here because, auth api works without token checking
      query: body => ({
        url: '/profile',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetMoveBidPartnerInfoQuery,
  useAcceptBidMutation,
  useDeleteBidMutation,
  useDeleteMoveMutation,
  useLazyGetMovesQuery,
  useGetMovesQuery,
  useSetProfileInformationMutation,
} = profileApi;
