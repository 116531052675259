import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import PlacesAutocomplete from 'react-places-autocomplete';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StandardTextFieldProps } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { ReactComponent as LocationIcon } from '@assets/svg/location.svg';
import { useLocalLocation } from './useLocalLocation';
import {
  StyledStack,
  StyledDropdownBox,
  SuggestionsList,
  StyledLocationInput,
  StyledDropdownBoxItem,
} from './styled';
import { isBoolean } from 'lodash';
import {
  NO_SPECIFIC_AREAS,
  SPECIFIC_AREAS,
} from '@modules/home/constants/google.constants';

interface ILocationField {
  zIndex: 'up' | '';
  borderType: 'first' | 'second';
  name: 'from' | 'to';
  types?: 'regions' | 'cities' | 'address';
  error?: any;
  disabled?: boolean;
  placeholder?: string;
  locationValue?: string;
  startInputAdornment?: string;
  variant?: StandardTextFieldProps['variant'];
}

const LocationField = (props: ILocationField) => {
  const {
    watch,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const noDropOffAddressOrStorageNeeded = watch(
    'noDropOffAddressOrStorageNeeded',
  );

  const {
    name,
    error: customError,
    types,
    zIndex,
    disabled,
    borderType,
    placeholder,
    locationValue,
    ...restProps
  } = props;

  const location = watch(props.name);

  const { address, setAddress, handleSelect, makeTextWithDots } =
    useLocalLocation({ name, locationValue });

  useEffect(() => {
    if (name === 'to' && isBoolean(noDropOffAddressOrStorageNeeded)) {
      setValue(name, { address: '', placeId: '' });
      setAddress('');
    }
  }, [noDropOffAddressOrStorageNeeded, name]);

  const handleBlur = () => {
    // Validate based on the form state
    if (!location?.placeId) {
      setError(name, {
        message: 'Please enter applicable Address or Zip Code',
      });
    } else {
      clearErrors(name);
    }
  };

  const errorMessage =
    errors[name]?.message ||
    // If not, use the custom error passed through props, if any
    customError ||
    // If there's no custom error, default to an empty string
    '';

  return (
    <Box sx={{ position: 'relative' }}>
      <PlacesAutocomplete
        value={address}
        searchOptions={{
          types:
            noDropOffAddressOrStorageNeeded && name === 'to'
              ? ['(cities)']
              : undefined,
          componentRestrictions: { country: 'us' },
        }}
        onSelect={handleSelect}
        onChange={value => {
          setValue(name, { address: '', placeId: '' });
          setAddress(value);
        }}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          const { value, ...inputProps } = getInputProps({
            onBlur: handleBlur,
            name,
          });

          // Filter suggestions based on specific areas only when needed
          const filteredSuggestions =
            noDropOffAddressOrStorageNeeded && name === 'to'
              ? suggestions
              : suggestions.filter(suggestion => {
                  return suggestion.types.some(
                    type => !NO_SPECIFIC_AREAS.includes(type),
                  );
                });

          return (
            <StyledStack zIndex={zIndex} direction="column">
              <StyledLocationInput
                {...inputProps}
                placeholder={placeholder || ''}
                disabled={disabled}
                borderType={borderType}
                {...restProps}
                zIndex={zIndex}
                value={value}
                error={(errors[name]?.message as string) || ''}
              />
              <LocationIcon />

              {!!filteredSuggestions.length && (
                <StyledDropdownBox zIndex={zIndex}>
                  <SuggestionsList>
                    {filteredSuggestions.map(suggestion => {
                      return (
                        <StyledDropdownBoxItem
                          direction="row"
                          spacing="10px"
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.placeId}
                          sx={{ cursor: 'pointer' }}>
                          <Typography variant="body2">
                            {suggestion.description}
                          </Typography>
                        </StyledDropdownBoxItem>
                      );
                    })}
                  </SuggestionsList>
                </StyledDropdownBox>
              )}

              <FormHelperText>{errorMessage}</FormHelperText>
            </StyledStack>
          );
        }}
      </PlacesAutocomplete>
    </Box>
  );
};

export default memo(LocationField);
