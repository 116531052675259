import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';
import { ReactComponent as GreenStar } from '@assets/svg/greenStar.svg';
import movingPartner from '@assets/svg/homeIcons/partners/moving-partner.svg';
import referralPartner from '@assets/svg/homeIcons/partners/referral-partner.svg';
import Button from '@components/Button';

import {
  PartnerInfoWrapper,
  ItemWrapper,
  ImgWrapper,
  Info,
  StyledTypography,
  SubTitle,
  Description,
} from './styled';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import Box from '@mui/material/Box';

const Partner = () => {
  const navigate = useNavigate();

  return (
    <PartnerInfoWrapper>
      <ItemWrapper>
        <ImgWrapper>
          <img src={movingPartner} alt="mooving partner" />
        </ImgWrapper>

        <Info className="section-1">
          <Box>
            <Box>
              <SubTitle>Moving Partner</SubTitle>
              <Description>
                Get access to thousands of moving jobs per year!
              </Description>
            </Box>

            <Box>
              <StyledTypography className="first">
                <GreenStar />
                Make More Money
              </StyledTypography>
              <StyledTypography className="partner">
                <GreenStar />
                Spend Less on Advertising
              </StyledTypography>
            </Box>
          </Box>

          <Button
            fullWidth
            endIcon={<ArrowRightIcon />}
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.TRUCKER_LANDING);
            }}>
            Become Our Moving Partner
          </Button>
        </Info>
      </ItemWrapper>
      <ItemWrapper>
        <ImgWrapper>
          <img src={referralPartner} alt="referral partner" />
        </ImgWrapper>

        <Info className="section-2">
          <Box>
            <SubTitle>Referral Partner</SubTitle>
            <Description>{`Get paid for referring your friends & clients\n to Moo Moves You!`}</Description>
          </Box>
          <Button
            fullWidth
            endIcon={<ArrowRightIcon />}
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.AFFILIATE);
              window.scrollTo({ top: 0 });
            }}>
            Become Our Referral Partner
          </Button>
        </Info>
      </ItemWrapper>
    </PartnerInfoWrapper>
  );
};

export default Partner;
