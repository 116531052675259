import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled(Box)`
  cursor: pointer;
  padding: 0;
  width: 207px;
  height: 22px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    display: flex;
    justify-content: center;

    > .desktop-version {
      display: none;
    }
  }
`;
export const MobileLogo = styled('img')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    maxWidth: '107px',
    height: '11px',
    margin: '20px 0',
  },
}));
