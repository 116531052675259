import { useState, useRef, useEffect, useCallback } from 'react';
import { useVerifyPhoneMutation } from '@modules/home/api/requests.api';
import { processError } from '@modules/home/components/Content/helpers/processError.helper';
import { PAGE_ROUTES_PRIVATE } from '@app/types/enums/pages';
import { IMoveRequestFormValues } from '@modules/home/types/move.types';
import { IUserResponse } from '@modules/auth/types';
import { PHONE_NUMBER_FORMAT } from '@utils/constants';
import { useLazyCheckEmailAvailabilityQuery } from '@modules/auth/api/auth.api';
import { useNavigate } from 'react-router-dom';

interface UsePhoneVerification {
  phone: string;
  formData: IMoveRequestFormValues;
  navigate: (path: string) => void;
  userInfo: IUserResponse | null;
  createMoveSubmit: (formData: IMoveRequestFormValues) => Promise<void>;
}

const RESEND_TIMOUT = 15000;

export const usePhoneVerification = ({
  formData,
  createMoveSubmit,
  userInfo,
  phone,
}: UsePhoneVerification) => {
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verificationErrorMessage, setVerificationErrorMessage] =
    useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
  const [isPhoneVerificationLoading, setIsPhoneVerificationLoading] =
    useState<boolean>(false);
  const resendTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const [verifyPhone] = useVerifyPhoneMutation();
  const [triggerEmailCheck] = useLazyCheckEmailAvailabilityQuery();

  const handleModalClose = useCallback(() => {
    setIsVerifyModalOpen(false);
    setIsVerified(false);
    setVerificationErrorMessage('');
    setOtp('');
    setIsResendDisabled(false);
  }, []);

  const submitMoveWithOtp = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await createMoveSubmit({ ...formData, code: otp });
      setIsVerified(true);
      navigate(PAGE_ROUTES_PRIVATE.PROFILE);
      setVerificationErrorMessage('');
    } catch (error) {
      const errorMessage = processError(error);
      setIsVerified(false);
      setIsSubmitting(false);
      setVerificationErrorMessage(errorMessage);
    }
  }, [otp, formData, createMoveSubmit]);

  const handleOtpChange = useCallback((newOtp: string) => {
    setOtp(newOtp);
  }, []);

  const resendOtp = useCallback(async () => {
    if (isResendDisabled) return;
    try {
      await verifyPhone({ phone: `${PHONE_NUMBER_FORMAT}${phone}` }).unwrap();
      setVerificationErrorMessage('');
      setIsResendDisabled(true);
      resendTimeoutRef.current = setTimeout(
        () => setIsResendDisabled(false),
        RESEND_TIMOUT,
      );
    } catch (error) {
      const errorMessage = processError(error);
      setVerificationErrorMessage(errorMessage);
      throw error;
    }
  }, [phone, isResendDisabled, verifyPhone]);

  const checkEmailAvailability = async (email: string) => {
    try {
      await triggerEmailCheck({ email }).unwrap();
      return true; // Email is available (no error occurred)
    } catch (error) {
      return false; // Email already exists (error occurred)
    }
  };

  const handleGetMyQuotesClick = useCallback(async () => {
    try {
      if (userInfo) {
        await createMoveSubmit(formData);
        navigate(PAGE_ROUTES_PRIVATE.PROFILE);
      } else {
        setIsPhoneVerificationLoading(true);
        const emailAvailable = await checkEmailAvailability(formData.email);
        if (!emailAvailable) {
          setVerificationErrorMessage('Email already exists');
          setIsPhoneVerificationLoading(false);
          return;
        }

        try {
          await verifyPhone({ phone: `+1${phone}` }).unwrap();
          setIsPhoneVerificationLoading(false);
          setIsVerifyModalOpen(true);
          setVerificationErrorMessage('');
        } catch (error) {
          const errorMessage = processError(error);
          setVerificationErrorMessage(errorMessage);
          setIsPhoneVerificationLoading(false);
        }
      }
    } catch (error) {
      console.error('Creat eMove Submit Error:', error);
    }
  }, [userInfo, phone, formData, verifyPhone, createMoveSubmit, navigate]);

  useEffect(() => {
    return () => {
      if (resendTimeoutRef.current) {
        clearTimeout(resendTimeoutRef.current);
      }
    };
  }, []);

  return {
    isSubmitting,
    isVerifyModalOpen,
    isVerified,
    verificationErrorMessage,
    otp,
    isResendDisabled,
    isPhoneVerificationLoading,
    handleModalClose,
    submitMoveWithOtp,
    handleOtpChange,
    resendOtp,
    handleGetMyQuotesClick,
  };
};
