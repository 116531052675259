import mooLogo from '@assets/svg/moo-login.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import { useAppDispatch } from '@app/store/hooks';
import DialogTitle from '@mui/material/DialogTitle';
import { EMAIL_REGEX, PASSWORD_REGEX } from '@utils/regex';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { StyledErrorMessage } from '@components/ErrorMessage/styled';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { useLoginLogic } from './useLoginLogic';
import {
  StyledModal,
  FieldWrapper,
  StyledLeftLogo,
  StyledTextField,
  ForgotPassword,
  StyledIconButton,
  StyledModalTitle,
  StyledLoginButton,
  MainErrorMessage,
  StyledDialogContent,
  ButtonAndMessageWrapper,
  MainSuccessMessage
} from './styled';

const LoginPopup = () => {
  const dispatch = useAppDispatch()
  const {
    errors,
    message,
    matches,
    isLoading,
    showPassword,
    resetSuccessMessage,
    passwordInputProps,
    register,
    handleClose,
    handleSubmit,
    handleLogin,
    clearMessages,
  } = useLoginLogic()

  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type='opened'
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>

      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Log In"
          textAlign="center"
        />
        <form onSubmit={handleSubmit(handleLogin, clearMessages)}>
          <Stack alignItems="center">
            <FieldWrapper>
              <StyledTextField
                placeholder='Your Email Address'
                variant="outlined"
                type="text"
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Email is a required field'
                  },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Please enter a valid email address.'
                  },
                })}
              />
              {errors && errors.email && (<StyledErrorMessage>{errors.email?.message}</StyledErrorMessage>)}
            </FieldWrapper>

            <FieldWrapper>
              <StyledTextField
                placeholder='Your Password'
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                InputProps={passwordInputProps}
                {...register('password', {
                  minLength: {
                    value: 6,
                    message: "Password must have at least 6 characters"
                  },
                  required: {
                    value: true,
                    message: 'Password is a required field'
                  },
                  // pattern: {
                  //   value: PASSWORD_REGEX,
                  //   message: 'Please enter a valid password.'
                  // },
                })}
              />
              {errors && errors.password && (
                <StyledErrorMessage>{errors?.password?.message}</StyledErrorMessage>
              )}
            </FieldWrapper>
            <ForgotPassword
              onClick={() => {
                dispatch(setPopUpName({ name: PopUpEnum.Empty }))
                dispatch(setPopUpName({ name: PopUpEnum.ForgotPasswordModal }))
              }}
            >
              <p>Forgot password?</p>
            </ForgotPassword>

            <ButtonAndMessageWrapper>
              <StyledLoginButton
                type="submit"
                disabled={!!isLoading}
              >
                {isLoading ? 'Loading ... ' : 'Log In'}
              </StyledLoginButton>
              {resetSuccessMessage && <MainSuccessMessage>Password Successfully Updated</MainSuccessMessage>}
              {message && (<MainErrorMessage> {message}</MainErrorMessage>)}
            </ButtonAndMessageWrapper>
          </Stack>
        </form>
        <StyledLeftLogo src={mooLogo} />
      </StyledDialogContent>
    </StyledModal >
  );
};

export default LoginPopup;
