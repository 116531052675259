import Modal from '@ui/Modal';
import { styled } from '@mui/material/styles';

import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import { FontFamilyNames } from '@app/types/fonts';
import { IconButton } from '@mui/material';
import { COLORS } from '@utils/colors';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '100%',
    maxWidth: '670px',
    minHeight: 608,
    borderRadius: 20,
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },
  },
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  padding: '41px 38px',
  borderRadius: 20,

  [theme.breakpoints.down('sm')]: {
    padding: '59px 0 0 0',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  fontWeight: 400,
  marginBottom: 20,
  lineHeight: 'normal',

  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
    marginBottom: 17,
  },
}));

export const StyledSubTitle = styled(Typography)(({ theme }) => ({
  color: '#282750',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  whiteSpace: 'pre-wrap',

  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
  },
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  '> div': {
    position: 'relative',
  },

  button: {
    width: '100%',
    fontSize: '24px',
    maxWidth: '362px',
    height: '52px',
    display: 'flex',
    margin: '0 auto',
  },

  [theme.breakpoints.down('sm')]: {
    button: {
      fontSize: '20px',
      maxWidth: '335',
      height: '36px',
      marginTop: '47px',
      marginBottom: '70px',
    },
  },
}));

export const BackgroundWrapper = styled(Box)(({ theme }) => ({
  maxWidth: 599,
  height: 258,
  padding: '21px 40px 0px 34px',
  background: '#FEF6EB',
  borderRadius: '19px',
  fontSize: '12px',
  overflow: 'scroll',
  margin: '31px 0px 44px ',

  '> p': {
    whiteSpace: 'pre-wrap',
    marginBottom: '20px'
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: '0',
    margin: '17px 0px 0px',
    padding: '37px 20px',
    height: '100%',

    'p': {

      fontSize: '12px',
      letterSpacing: '-0.12px',
    }
  },
}));

export const ScrollBar = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '21px 16px',
  background: 'white',
  width: '10px',
  height: '62px',
  borderRadius: '19px',
}));

