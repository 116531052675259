import { useState } from 'react';
import { useTheme } from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useMediaQuery } from '@mui/material';
import { useAppDispatch } from '@app/store/hooks';
import { errorHandler } from '@utils/errorHendler';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { useResetPasswordMutation } from '@modules/auth/api/auth.api';
import { IForgotPasswordValues } from './types';

export const useForgotPasswordLogic = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [resetPassword] = useResetPasswordMutation();

  const clearMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordValues>();

  const handleLogin: SubmitHandler<IForgotPasswordValues> = async ({ email }) => {
    try {
      setErrorMessage('')
      setIsLoading(true)

      await resetPassword({
        email, redirectUri: window.location.origin
      }).unwrap();

      reset()
      setIsLoading(false)
      setSuccessMessage('Please check your email.')

    } catch (error) {
      const errorMessage = errorHandler(error)
      setErrorMessage(errorMessage)
      setSuccessMessage('')
      setIsLoading(false)
    }
  };

  const handleClose = () => {
    reset();
    clearMessages();
    dispatch(setPopUpName({ name: PopUpEnum.Empty }))
  };

  return {
    errors,
    matches,
    isLoading,
    errorMessage,
    successMessage,
    register,
    handleClose,
    handleLogin,
    handleSubmit,
    clearMessages,
  }
}
