import {styled, Theme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@components/Typography';

import {COLORS} from '@utils/colors';
import {FontFamilyNames} from '@app/types/fonts';

export const ImageBox = styled(Box)(() => ({
    width: '100%',
    position: 'relative',
}));

export const DownBackground = styled(Box)(({theme}) => ({
    paddingBottom: 62,
    background: 'linear-gradient(180deg, #FEF6EB 0%, #FFF 43.32%)',

    [theme.breakpoints.down('sm')]: {
        paddingBottom: 38,
    },
}));

export const StyledTruckerLanding = styled(Box)(({theme}) => ({
    background: COLORS.beige,

    [theme.breakpoints.down('sm')]: {},
}));

export const PromoAndJobBox = styled(Box)(({theme}) => ({
    margin: '0 20px 110px 20px',

    [theme.breakpoints.down('sm')]: {
        marginBottom: 60,
    },
}));

export const StyledJobContent = styled(Box)(({theme}: { theme: Theme }) => ({
    gridGap: '34px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 410,

    '.MuiStack-root > div:first-child': {
        marginBottom: 46
    },

    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gridGap: '24px',

        '.MuiStack-root > div:first-child': {
            marginBottom: 40
        },
    },
}));

export const JobSectionContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
    gap: 80,

    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 40,
    }
}));

export const StyledTypography = styled(Typography)(({theme}) => ({
    fontFamily: FontFamilyNames.MoretRegular,
    fontSize: '40.194px',
    lineHeight: 'normal',
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        textAlign: 'left'
    }
}));
