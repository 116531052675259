import { useNavigate } from 'react-router-dom';

import { logout } from '@modules/auth';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import { StyledMenuItem, StyledToolbar } from './styled';

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const email = useAppSelector(({ auth }) => auth.userInfo?.email);

  return (
    <StyledToolbar>
      <StyledMenuItem cursor="unset">{email}</StyledMenuItem>

      <StyledMenuItem
        cursor="pointer"
        onClick={() => {
          dispatch(setPopUpName({ name: PopUpEnum.ContactUsModal }));
        }}>
        Contact Us
      </StyledMenuItem>

      <StyledMenuItem
        cursor="pointer"
        onClick={() => {
          dispatch(logout());
          navigate(PAGE_ROUTES_PUBLIC.HOME);
        }}>
        Log out
      </StyledMenuItem>
    </StyledToolbar>
  );
};

export default Header;
