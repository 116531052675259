import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const StyledAddNewMoveAction = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '135%',
  letterSpacing: '-0.4px',
  cursor: 'pointer',
  paddingBottom: '13px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    letterSpacing: '-0.14px',
    lineHeight: '130%',
  },
}));
