import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { FontFamilyNames } from '@app/types/fonts';
import { COLORS } from '@utils/colors';

export const MovesContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    overflow: 'hidden',
  },
}));

export const StyledTextLikeFooter = styled(Stack)(({ theme }) => ({
  color: COLORS.grey[50],
  fontFamily: FontFamilyNames.PlainLight,
  fontStyle: 'normal',
  fontWeight: '300',
  lineHeight: '130%',
  fontSize: '16px',
  letterSpacing: '-0.32px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    letterSpacing: '-0.14px',
  },
}));
