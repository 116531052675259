import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

export const LeadContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'unset',
  gap: '60px',
  display: 'flex',
  alignItems: 'self-start',
  flexWrap: 'wrap',

  [theme.breakpoints.down('xl')]: {
    gap: '40px',
  },

  [theme.breakpoints.down('lg')]: {
    gap: '30px',
  },

  [theme.breakpoints.down('l')]: {
    gap: '30px',
  },

  [theme.breakpoints.down('md')]: {
    gap: '30px',
    justifyContent: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    gap: '16px',
  },

  [theme.breakpoints.down(1441)]: {
    gap: '10px',
  },
}));
