import { Components } from '@mui/material/styles';

export const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      color: '#fff',
      // '&.MuiFormLabel-filled, &.Mui-focused': {},
    },
    standard: {},
    filled: {},
    outlined: {},
    error: {},
    disabled: {},
    animated: {},
    asterisk: {},
    required: {},
    formControl: {},
    shrink: {},
    sizeSmall: {},
  },
};

export const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: '100%',
      // height: '48px',
      color: '#fff',

      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(243, 70, 77, 0.7)',
        },
      },

      '.Mui-disabled': {
        opacity: 1,
        // '-webkit-text-fill-color': 'rgba(255, 255, 255, 0.8)',
      },
    },
  },
};

export const MuiFormHelperText: Components['MuiFormHelperText'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      margin: '0px',
      color: '#ec3e32',
    },
  },
};

export const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      color: '#fff',
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '4px',

      '&.Mui-focused, &:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(255, 255, 255, 0.2)',
        },

        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d32f2f',
        },
      },
    },
    input: {
      // '&::placeholder': {
      //   color: 'rgba(255, 255, 255, 0.4)',
      // },
    },
    inputMultiline: {},
    inputAdornedStart: {},
    inputAdornedEnd: {},
    inputSizeSmall: {},
    sizeSmall: {},
    adornedStart: {},
    adornedEnd: {},
    focused: {},
    disabled: {},
    error: {},
    multiline: {},
    notchedOutline: {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    colorSecondary: {},
  },
};

export const MuiInput: Components['MuiInput'] = {
  defaultProps: {
  },
  styleOverrides: {
    root: {
    },
    input: {
    },
  },
};
