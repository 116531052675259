import { memo, useEffect } from "react";
import queryString from 'query-string'

import LoginModal from "@modals/LoginModal";
import MainMenuModal from '@modals/MainMenuModal';
import TruckerMenuModal from '@modals/TruckerMenuModal';
import ContactUsModal from "@modals/ContactUsModal";
import ForgotPasswordModal from "@modals/ForgotPasswordModal";
import FullPackingServicesModal from "@modals/FullPackingServicesModal";
import { PopUpEnum } from "@app/store/features/app/app.types";
import { setPopUpName, setLoginPopUpStatus } from "@app/store/features/app/app.slice";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import ResetPasswordModal from "@modals/ResetPasswordModal";

import { logout } from "@modules/auth";
import QuoteBoardModal from "@modals/Profile/QuoteBoardModal";
import ConnectModal from "@modals/Profile/ConnectModal";
import PriceModal from "@modals/Profile/PriceModal";
import AdditionalServices from "@modals/Profile/AdditionalServices";
import CreateNewMove from "@modals/Profile/CreateNewMove";
import DeleteMove from "@modals/Profile/DeleteMove";
import AgreementPopup from "@modals/AgreementModal";


const ModalsController = () => {
  const dispatch = useAppDispatch()
  const modal = useAppSelector((state) => state.app.modal)
  const isOpenLogin = useAppSelector(({ app }) => app.isOpenLogin);
  const auth = useAppSelector(({ auth }) => auth.userInfo)
  const params = queryString.parse(window.location.search)

  useEffect(() => {
    if (isOpenLogin) {
      dispatch(setPopUpName({ name: PopUpEnum.LoginModal }))
      dispatch(setLoginPopUpStatus(false))
    }
  }, [isOpenLogin])

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    if (params.get('token')) {
      dispatch(setPopUpName({ name: PopUpEnum.ResetPasswordModal }))
    }
  }, [window.location.search])

  useEffect(() => {
    if (params && params?.userId) {
      if (auth != null && auth?.id != +params?.userId) {
        dispatch(logout())
        dispatch(setPopUpName({ name: PopUpEnum.LoginModal }))
        return
      }
      if (auth == null) {
        dispatch(setPopUpName({ name: PopUpEnum.LoginModal }))
        return
      }
    }
  }, [params])

  const modals = {
    [PopUpEnum.ContactUsModal]: <ContactUsModal />,
    [PopUpEnum.LoginModal]: <LoginModal />,
    [PopUpEnum.ForgotPasswordModal]: <ForgotPasswordModal />,
    [PopUpEnum.ResetPasswordModal]: <ResetPasswordModal />,
    [PopUpEnum.FullPackingServicesModal]: <FullPackingServicesModal />,
    [PopUpEnum.MainMenuModal]: <MainMenuModal />,
    [PopUpEnum.TruckerMenuModal]: <TruckerMenuModal />,
    [PopUpEnum.QuoteBoardModal]: <QuoteBoardModal />,
    [PopUpEnum.ConnectModal]: <ConnectModal />,
    [PopUpEnum.PriceModal]: <PriceModal />,
    [PopUpEnum.AdditionalServices]: <AdditionalServices />,
    [PopUpEnum.CreateNewMove]: <CreateNewMove />,
    [PopUpEnum.DeleteMove]: <DeleteMove />,
    [PopUpEnum.AgreementPopup]: <AgreementPopup />,
    [PopUpEnum.Empty]: null
  }

  const Component = modals[modal] || null

  return (
    <div>
      {modal === PopUpEnum.Empty ? null : Component}
    </div>
  );
}

export default memo(ModalsController);