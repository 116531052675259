import { Outlet } from 'react-router-dom';
import Header from '../Header';
import TopPanel from '../TopPanel';
import { Main, StyledOutletContainer } from './styled';

const Layout = () => {
  return (
    <Main>
      <StyledOutletContainer>
        <TopPanel />
        <Header />
        <Outlet />
      </StyledOutletContainer>
    </Main>
  );
};

export default Layout;
