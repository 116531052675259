import useAgreement from './useAgreement';
import Button from '@components/Button';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import { COLORS } from '@utils/colors';
import { DialogTitle, Stack } from '@mui/material';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import {
  ScrollBar,
  StyledModal,
  StyledContent,
  StyledSubTitle,
  StyledModalTitle,
  StyledIconButton,
  BackgroundWrapper,
  StyledDialogContent,
} from './styled';

const agreementMessage = [
  {
    title: '',
    desc: `This Service Agreement (the "Agreement") is made and entered into on this date (the "Effective Date") between Moo Moves You, LLC, a Florida limited liability corporation, with its principal place of business at 3500 NW 2nd Ave, Suite 623, Boca Raton, FL 33431, hereinafter referred to as "Provider," and yourself and your company who have elected at your own discretion to enroll in the services offered by Moo Moves You, LLC, you are hereinafter referred to as the "Subscriber."`,
  },
  {
    title: `PURPOSE AND SCOPE OF SERVICES`,
    desc: `1.1 Your Company engages the Provider to act as an intermediary between Your Company and potential customers seeking moving quotes. The Provider shall utilize its marketing channels, resources, and network to introduce potential customers in need of moving quotes to Your Company.`,
  },
  {
    title: `REFERRAL PROCESS`,
    desc: `2.1 The Provider shall use ethical and legal means to generate clients who seek moving quotes to connect with their Subscribers. It is understood and agreed that any unethical or illegal practices are strictly prohibited.`,
  },
  {
    title: '',
    desc: `2.2 The Subscriber understands and agrees that it is their responsibility to create, edit, and/or update their Service Area(s) so that the Provider can instantly connect them with potential customers looking for moving quotes accurately.`,
  },
  {
    title: '',
    desc: `2.3 The Subscriber understands and agrees that the Provider is simply making a connection and providing potential customer contact and potential job information that was provided to them by the potential customer. Provider does not guarantee the accuracy of this information.`,
  },
  {
    title: '',
    desc: `2.4 The Subscriber understands and agrees that connections are based on the Service Area(s) that they create matched with the origin / pick-up address that the potential customer requests. As connections are made, Subscribers contact information is shared with the potential customer via their User Account and potential customer's information is shared with Subscriber to the contact information provided at time of signup and available in the My Leads tab of their Moving Partner Account. It is the responsibility of the Subscriber to contact the potential customer.`,
  },
  {
    title: `FEES AND PAYMENT`,
    desc: `3.1 The Subscriber agrees to pay $9.99 per Local Lead and $19.99 per Long Distance Lead. These payments will be made through the credit card on file at the time the connection is made and the Potential Customer (lead) information is made available to the Subscriber. It is the responsibility of the Subscriber to keep an active credit card on file. The Subscribers authorizes Moo Moves You, LLC to auto-charge their credit card on file instantly as each connection is made. Connections will only be made based on the Service Area(s) created by the Subscriber. For the purpose of this Agreement, a Local Lead shall be defined as any job that has a pickup (origin) address and a dropoff (destination) address that are in the same state, and a Long Distance Lead shall be defined as any job that has a pickup (origin) address and a dropoff (destination) address that are in different states. The Subscriber will be charged for all leads they are connected with where contact and job info is made available that match the Service Area(s) and job specifications they provided to the Provider in their Moving Partner Portal, whether or not the Subscriber calls and/or makes contact with the potential customer. The Provider has the right to change the fees charged to the Subscriber and must provide written notice prior to any changes taking place.`,
  },
  {
    title: `CONFIDENTIALITY`,
    desc: `4.1 Both parties agree to treat all non-public information shared during the course of this Agreement and the length of their business relationship as confidential and shall not disclose or use such information for any purpose other than the fulfillment of this Agreement.`,
  },

  {
    title: `INDEMNIFICATION`,
    desc: `5.1 Subscriber agrees to indemnify, defend, and hold harmless the Provider and its officers, directors, employees, and agents from and against any and all claims, demands, actions, suits, losses, liabilities, damages, costs, and expenses (including reasonable attorneys' fees) arising out of or relating to any disputes or claims between Subscriber and potential customers introduced by the Provider.`,
  },
  {
    title: '',
    desc: `5.2 The indemnification provided under this clause shall cover claims arising from any act or omission of Subscriber, including but not limited to claims related to the provision of moving services, pricing disputes, property damage, or any other matter related to the services provided by Subscriber to the potential customers introduced by the Provider.`,
  },
  {
    title: `TERM AND TERMINATION`,
    desc: `6.1 This Agreement shall commence on the Effective Date and shall remain in effect until terminated by either party to the other party.`,
  },
  {
    title: '',
    desc: `6.2 Either party may terminate this Agreement immediately for a material breach of its terms by the other party.`,
  },

  {
    title: `INDEPENDENT CONTRACTORS`,
    desc: `7.1 The parties agree that they are independent contractors and nothing in this Agreement shall be construed to create a partnership, joint venture, or agency relationship between the parties.`,
  },

  {
    title: `FEDERAL AND STATE LICENSING REQUIREMENTS`,
    desc: `8.1 Subscriber agrees that it is their responsibility to have and maintain all Federal & State licensing and insurance requirements for Household Goods Moving Carriers. When requesting leads for interstate moving jobs, Subscriber agrees to have all required licenses and registrations such as an active Federal DOT #, be registered for Household Goods, and have active insurance policies that meet the minimum requirements, to name a few. When requesting leads for in-state jobs, Subscriber agrees that they are legally registered and meet all of the requirements necessary with the state and local authorities that govern moving companies, the services they provide, household goods carriers and/or carriers-for-hire in all in-state service areas that they create.`,
  },
  {
    title: '',
    desc: `8.2 Subscriber agrees that it is their responsibility to notify Provider immediately if any Federal or State Licensing, Insurance or Other requirement are canceled, voided, lapse or are discontinued for any reason AND it is the Subscribers responsibility to delete or deactivate any and all Service Areas in which they are no longer legally allowed to operate in.`,
  },
  {
    title: '',
    desc: `8.3 Subscriber agrees to indemnify and hold harmless Moo Moves You, LLC from any claims, damages, losses, liabilities or suits including attorney fees arising out of or in connection with information provided by the Subscriber being incomplete, inaccurate, if coverages, licenses or requirements lapse, or if the company is not properly licensed or registered to carry out and perform the services represented in this agreement or the services they offer the potential customer or lead connected to them by Moo Moves You, LLC under the terms of this agreement or through our platform.`,
  },
  {
    title: '',
    desc: `8.4 Provider has the right to suspend or remove Subscriber from the Moo Moves You platform at any time without notice for reasons including but not limited to unprofessional conduct or lapses of any coverages or licenses described above.`,
  },

  {
    title: `GOVERNING LAW AND DISPUTE RESOLUTION`,
    desc: `9.1 This Agreement shall be governed by and construed in accordance with the laws of Florida.`,
  },
  {
    title: '',
    desc: `9.2 Any disputes arising out of or in connection with this Agreement shall be resolved amicably through good-faith negotiations between the parties. If resolution is not reached, the dispute shall be submitted to arbitration in accordance with the rules of the Arbitration Association of Palm Beach County, Florida. The decision of the arbitrator shall be final and binding on both parties.`,
  },
  {
    title: ``,
    desc: `IN WITNESS WHEREOF, the parties agree that the Subscriber electronically accepts this Agreement by clicking the “I Agree” button below, this agreement will be in force and effect.`,
  },
];

const AgreementPopup = () => {
  const {
    matches,
    elemRef,
    isDisable,
    containerRef,
    containerMobileRef,
    handleScroll,
    handleAgreement,
    handleClose,
  } = useAgreement();

  return (
    <StyledModal open={true} onClose={handleClose}>
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type="opened"
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}>
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}>
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>
      <StyledDialogContent ref={containerMobileRef} onScroll={handleScroll}>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="SERVICE AGREEMENT"
          textAlign="center"
        />
        <StyledSubTitle
          component="h3"
          variant="h1"
          label={`BETWEEN MOO MOVES YOU, LLC &\n SUBSCRIBING MOVING COMPANY`}
          textAlign="center"
        />

        <StyledContent>
          <div>
            <BackgroundWrapper ref={containerRef} onScroll={handleScroll}>
              {agreementMessage.map((item, index) => {
                return (
                  <p key={index}>
                    {item.title ? <span>{item.title}</span> : null}
                    <br />
                    {item.desc}
                  </p>
                );
              })}
            </BackgroundWrapper>
            {!matches ? <ScrollBar ref={elemRef} /> : null}
          </div>
          <Button
            disabled={isDisable}
            endIcon={<ArrowRightIcon />}
            onClick={handleAgreement}>
            I Agree
          </Button>
        </StyledContent>
      </StyledDialogContent>
    </StyledModal>
  );
};

export default AgreementPopup;
