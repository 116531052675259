import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@app/store/hooks';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { setPopUpName } from '@app/store/features/app/app.slice';
import {
  StyledTopPanelBox,
  StyledTopPanelText,
  StyledTopPanelLinksContainer,
  StyledTopPanelLink,
} from './styled';

const TopPanel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <StyledTopPanelBox>
      <StyledTopPanelText>
        We only work with trusted interstate movers that are authorized by the
        US Department of Transportation.
      </StyledTopPanelText>
      <StyledTopPanelLinksContainer>
        <StyledTopPanelLink
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.TRUCKER_LANDING);
            dispatch(setPopUpName({ name: PopUpEnum.Empty }));
          }}>
          Moving Partner
        </StyledTopPanelLink>
        <StyledTopPanelLink
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.AFFILIATE);
          }}>
          Referral Partner
        </StyledTopPanelLink>
      </StyledTopPanelLinksContainer>
    </StyledTopPanelBox>
  );
};

export default TopPanel;
