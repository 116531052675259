import { useNavigate } from 'react-router-dom';

import PlainImage from '@ui/Image/PlainImage';
import appLogo from '@assets/svg/app-logo.svg';
import { PAGE_ROUTES_PUBLIC } from 'app/types/enums/pages';
import { Container, MobileLogo } from './styled';

const Center = () => {
  const navigate = useNavigate();

  const handleLogoImageClick = () => {
    navigate(PAGE_ROUTES_PUBLIC.HOME);
  };

  return (
    <Container>
      <MobileLogo onClick={handleLogoImageClick} src={appLogo} alt="Moo've" />
      <PlainImage className='desktop-version' onClick={handleLogoImageClick} objectFit="contain" src={appLogo} />
    </Container>
  );
};

export default Center;
