import PlainImage from '@components/Image/PlainImage';

import image1 from '@assets/svg/tracker-checked-home-icon.svg';
import image2 from '@assets/svg/tracker-loudspeaker-icon.svg';
import image3 from '@assets/svg/tracker-moo-suit.svg';
import image4 from '@assets/svg/tracker-pen-with-board.svg';
import image5 from '@assets/svg/tracking-promo-profit-icon.svg';
import image6 from '@assets/svg/shopping-moo.svg';
import mooMovingTrack from '@assets/svg/moo-moving-track.svg';
import {
  StyledRow1,
  StyledRow2,
  StyledTitle,
  StyledCardContainer,
  StyledMovingTrackBox,
  StyledPromotionSection,
  StyledMoveTrackerContainer,
} from './styled';
import PromotionCard from '@modules/truckerLanding/components/PromotionSection/PromotionCard';
import Box from '@mui/material/Box';

const PromotionSection = () => {
  return (
    <StyledPromotionSection direction="column">
      <StyledTitle align="center" variant="h3" label="Why Work With Moo?" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <StyledCardContainer>
          <StyledRow1>
            <PromotionCard
              id={1}
              imageSrc={image1}
              title="Instant Connections"
              description="We connect you to our users in real-time, within seconds of them requesting a quote!"
            />

            <StyledMoveTrackerContainer>
              <PromotionCard
                id={2}
                imageSrc={image2}
                title="Create Your Own Service Areas!"
                description="Only connect with customers who have jobs in your areas!"
              />
              <StyledMovingTrackBox>
                <PlainImage src={mooMovingTrack} />
              </StyledMovingTrackBox>

              <PromotionCard
                id={3}
                imageSrc={image3}
                title="Choose Your Job Preferences"
                description="Local Jobs, Long Distance Jobs or Both!"
              />
            </StyledMoveTrackerContainer>
          </StyledRow1>

          <StyledRow2>
            <PromotionCard
              id={4}
              imageSrc={image4}
              title="We are NOT a Broker"
              description="The price you quote is the money you make. We do not take a % of the job."
            />

            <PromotionCard
              id={5}
              imageSrc={image5}
              title="Decreased Competition"
              description="We only connect our clients to a maximum of 3 other Moving Partners"
            />

            <PromotionCard
              id={6}
              imageSrc={image6}
              title="Verified Cell Phone #’s"
              description="All of our leads come with verified cell phone numbers!"
            />
          </StyledRow2>
        </StyledCardContainer>
      </Box>
    </StyledPromotionSection>
  );
};

export default PromotionSection;
