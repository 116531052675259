export enum PAGE_ROUTES_PRIVATE {
  PROFILE = '/profile',
}

export enum PAGE_ROUTES_PUBLIC {
  // ABOUT = '/about',
  HOME = '/',
  FAQ = '/faq',
  WHY_JOIN = '/why-join',
  TRUCKER_FAQ = '/trucker-faq',
  HOW_IT_WORKS = '/how-it-works',
  PRIVACY_POLICY = '/privacy-policy',
  COOKIES_POLICY = '/cookies-policy',
  TRUCKER_LANDING = '/trucker-landing',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  TRUCKER_BECOME_PARTNER = '/trucker-become-partner',
  AFFILIATE = '/affiliate',
  AFFILIATE_BECOME_PARTNER = '/affiliate-become-partner',
}

export enum RouterQueryParams {
  action = 'check',
}

export enum ALL_ROUTES {
  '/' = '/',
  '/about' = '/about',
  '/faq' = '/faq',
  '/how-it-works' = '/how-it-works',
}

export type AllRoutesType = keyof typeof ALL_ROUTES;
