import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';

export const LayoutContainer = styled(Stack)`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    gap: 27px;
    background: ${COLORS.white};
    grid-template-columns: 1fr;
    grid-template-rows: 52px max-content;
  }
`;
export const Container = styled(Box)(() => ({
  background: 'white',
}));

export const ProfileHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '103px',
  background: COLORS.beige,
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    '&.profile-header': {
      height: 50,

      img: {
        marginTop: '24px',
        marginLeft: '20px',
      },
    },
  },
}));
