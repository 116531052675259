import Button from '@components/Button';
import homeEstimate from '@assets/svg/homeEstimate.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';
import {
  EstimateBox,
  EstimateTitle,
  EstimateTextBox,
  EstimateImageBox,
  EstimateContentBox,
  EstimateDescription,
} from './styled';

const Estimate = () => {
  return (
    <EstimateBox>
      <EstimateContentBox>
        <EstimateImageBox>
          <img src={homeEstimate} alt="homeEstimate" />
        </EstimateImageBox>

        <EstimateTextBox>
          <EstimateTitle>
            How do our Instant <br /> Connections Work?
          </EstimateTitle>
          <EstimateDescription className="first">
            After you enter your moving details, your will be instantly
            connected to several of our Moving Partners that can service your
            job. You instantly receive their contact information.
          </EstimateDescription>
          <EstimateDescription>
            The Moving Companies will be reaching out to you shortly after
            requesting your quote. Once you choose which moving company you want
            to work with, you book your move directly!
          </EstimateDescription>
          <a href="#top">
            <Button endIcon={<ArrowRightIcon />}>Get Started</Button>
          </a>
        </EstimateTextBox>
      </EstimateContentBox>
    </EstimateBox>
  );
};

export default Estimate;
