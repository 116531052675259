import { ILead } from '../../../../../../types/moves-response.model';
import Lead from '../Lead';
import { LeadContainer } from './styled';

interface IRenderBidsProps {
  moveId: number;
  leads: ILead[];
}

const RenderLeads = ({ moveId, leads }: IRenderBidsProps) => {
  return (
    <LeadContainer>
      {leads?.map(
        (
          {
            id: leadId,
            partner: {
              rating,
              companyName,
              companyWebsite,
              contactName,
              dot,
              email,
              phone,
              googlePlaceId,
            },
          },
          _,
        ) => (
          <Lead
            dot={dot}
            googlePlaceId={googlePlaceId}
            phone={phone}
            email={email}
            key={leadId}
            rating={rating}
            contactName={contactName}
            companyName={companyName}
            companyWebsite={companyWebsite}
          />
        ),
      )}
    </LeadContainer>
  );
};

export default RenderLeads;
