import MuiPhoneNumber from 'react-phone-input-2';
import { styled } from '@mui/material/styles';

import { COLORS } from '@utils/colors';

export const StyledPhoneNumber = styled(MuiPhoneNumber)(({ theme }) => ({
  width: '100%',
  height: '50px',
  background: COLORS.white,
  border: `1px solid ${COLORS.grey[25]}`,
  borderRadius: '0 25px 25px 0',
  input: {
    width: '100%',
    height: '100%',
    border: 'none',
    background: 'transparent',
    color: COLORS.darkBlue,
    padding: '14px 30px 11px 17px',
    borderRadius: '0 25px 25px 0',
    fontSize: '14px',

    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
    },

    ':-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0px 1000px white inset',
    },
  },
  '.form-control::placeholder': {
    color: COLORS.darkBlue,
  },
  '.form-control:focus-visible': {
    outlineWidth: 0,
  },
  '.special-label': {
    display: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: '25px',
    height: '46px',
    input: {
      borderRadius: '25px',
    },
  },
}));
