import { styled } from '@mui/material/styles';

export const StyledLeftLogo = styled('img')({
  position: 'absolute',
  bottom: 0,
  left: 0,
});

export const StyledRightLogo = styled('img')({
  position: 'absolute',
  bottom: 0,
  right: 0,
});
