import { FC, ReactNode } from 'react';

import MuiTypography, { TypographyProps } from '@mui/material/Typography';

interface ITypography extends TypographyProps {
  label?: ReactNode;
  component?: any;
  localColor?: string;
  underline?: boolean;
}

const Typography: FC<ITypography> = ({
  label,
  children,
  underline,
  localColor,
  component = 'div',
  ...restProps
}) => (
  <MuiTypography
    sx={underline ? { borderBottom: '1px solid' } : {}}
    color={localColor}
    component={component as any}
    {...restProps}
  >
    {label || children}
  </MuiTypography>
);

export default Typography;
