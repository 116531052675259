import PhoneField from '@ui/PhoneField';
import { NAME_REGEX } from '@utils/regex';
import { ErrorMessage } from '@modules/profile/components/Account/styled';
import { useUpdateProfileLogic } from './useUpdateProfileLogic';
import {
  Container,
  StyledBox,
  PhoneNumber,
  StyledButton,
  StyledTextField,
  StyledTypography,
  LocalErrorMessage,
  FieldsContainer,
} from './styled';
import { IProfileInfoFormValues } from '@modules/profile/components/Account/types';

const ProfileForm = ({
  setIsMyProfileFormOpen,
  isSuccessModalOpen,
  setIsSuccessModalOpen,
}: {
  isSuccessModalOpen: boolean;
  setIsSuccessModalOpen: (isSuccessModalOpen: boolean) => void;
  setIsMyProfileFormOpen: (isMyProfileFormOpen: boolean) => void;
}) => {
  const {
    error,
    control,
    errors,
    isLoading,
    email,
    isDirty,
    register,
    handleRequest,
    handleSubmit,
  } = useUpdateProfileLogic({
    setIsMyProfileFormOpen,
    setIsSuccessModalOpen,
  });

  console.log('sdfs3 isSuccessModalOpen', isSuccessModalOpen);
  return (
    <Container>
      <form onSubmit={handleSubmit(handleRequest)}>
        <FieldsContainer>
          <StyledBox>
            <StyledTypography>Name</StyledTypography>
            <StyledTextField
              placeholder="Your Name"
              {...register('fullName', {
                required: {
                  value: true,
                  message: 'Name is a required field',
                },
                pattern: {
                  value: NAME_REGEX,
                  message: 'Please enter a valid name address.',
                },
              })}
            />
            {errors?.fullName && (
              <ErrorMessage>{errors?.fullName?.message}</ErrorMessage>
            )}
          </StyledBox>

          <StyledBox>
            <StyledTypography>Email Address</StyledTypography>
            <StyledTextField disabled value={email} placeholder="Your email" />
          </StyledBox>

          <StyledBox>
            <StyledTypography>Phone Number</StyledTypography>
            <PhoneField<IProfileInfoFormValues>
              name="phone"
              control={control}
              placeholder="Your phone number">
              <PhoneNumber />
            </PhoneField>
            {errors?.phone && (
              <ErrorMessage>{errors?.phone?.message}</ErrorMessage>
            )}
          </StyledBox>
        </FieldsContainer>

        {error && <LocalErrorMessage>{error}</LocalErrorMessage>}

        <StyledButton type="submit" disabled={isLoading || !isDirty}>
          {isLoading ? 'Loading ...' : 'Save Changes'}
        </StyledButton>
      </form>
    </Container>
  );
};

export default ProfileForm;
