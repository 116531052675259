import { useNavigate } from 'react-router-dom';

import mooInMoveCar from '@assets/svg/mooInMoveCar.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';

import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import Button from '@components/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { useCreateNewMoveLogic } from './useCreateNewMoveLogic';
import {
  StyledModal,
  StyledLeftLogo,
  StyledIconButton,
  StyledModalTitle,
  StyledDialogContent,
  Description,
  Buttons,
} from './styled';

const CreateNewMove = () => {
  const navigate = useNavigate()
  const {
    matches,
    handleClose,
  } = useCreateNewMoveLogic()

  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type='closed'
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>

      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Do You Want to Create a New Moo’ve?"
          textAlign="center"
        />
        <Description alignItems="center">
          Your current Moo’ve will be stay active and you will keep recieving quotes unless you deactivate it.
        </Description>
        <Buttons>
          <Button
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.HOME)
              handleClose()
            }}
          >
            Create New
          </Button>
          <Button
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </Buttons>
        <StyledLeftLogo src={mooInMoveCar} />
      </StyledDialogContent>
    </StyledModal >
  );
};

export default CreateNewMove;
