import { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


import { useMediaQuery } from '@mui/material';
import { useAppDispatch } from '@app/store/hooks';
import { errorHandler } from '@utils/errorHendler';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { useSetNewPasswordForResetFormMutation } from '@modules/auth/api/auth.api';
import { IResetPasswordValues } from './types';

export const useResetPasswordLogic = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [setNewPassword] = useSetNewPasswordForResetFormMutation();

  const clearMessages = () => {
    setErrorMessage('');
  };

  const {
    watch,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IResetPasswordValues>();

  const handleLogin: SubmitHandler<IResetPasswordValues> = async ({ newPassword, confirmPassword }) => {
    try {
      const params = new URLSearchParams(document.location.search);
      if (!params?.get('token')) {

        handleClose()
        return
      }

      setErrorMessage('')
      setIsLoading(true)

      await setNewPassword({
        token: params?.get('token') || '',
        newPassword,
        newPasswordConfirmation: confirmPassword
      }).unwrap();

      reset()
      setIsLoading(false)
      dispatch(setPopUpName({ name: PopUpEnum.Empty }))
      dispatch(setPopUpName({ name: PopUpEnum.LoginModal }))
    } catch (error) {
      const errorMessage = errorHandler(error)
      setErrorMessage(errorMessage)
      setIsLoading(false)
    }
  };

  const handleClose = () => {
    reset();
    clearMessages();
    dispatch(setPopUpName({ name: PopUpEnum.Empty }))
  };

  const passwordInputProps = useMemo(
    () => ({
      endAdornment: showPassword ? (
        <VisibilityIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowPassword(!showPassword)}
        />
      ) : (
        <VisibilityOffIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowPassword(!showPassword)}
        />
      ),
    }),
    [showPassword],
  );


  return {
    errors,
    matches,
    isLoading,
    errorMessage,
    showPassword,
    passwordInputProps,
    watch,
    register,
    handleClose,
    handleLogin,
    handleSubmit,
    clearMessages,
  }
}
