import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ContinueButton,
  StyledBoldTypography,
  StyledCodeBox,
  StyledInnerBox,
  StyledOuterBox,
  StyledRequestBox,
  StyledResend,
  StyledLeftLogo,
  StyledTitleBox,
  StyledTypography,
  Title,
} from '@modals/PhoneVerificationModal/styles';
import Box from '@mui/material/Box';
import OtpInputController from '@modals/PhoneVerificationModal/components/OtpInputController';
import { IPhoneVerificationFormValues } from '@modals/PhoneVerificationModal/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneVerificationSchema } from '@modals/PhoneVerificationModal/helpers';
import { formatPhoneNumber } from '@utils/helpers';
import Typography from '@mui/material/Typography';
import { COLORS } from '@utils/colors';
import deliveryCow from '@assets/svg/delivery-cow.svg';
import deliveryCowMobile from '@assets/svg/delivery-cow-mobile.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface IPhoneVerificationFormProps {
  phoneNumber: string;
  otp: string;
  isResendDisabled: boolean;
  errorMessage?: string;
  onOtpChange: (otp: string) => void;
  onResendOtp: () => void;
  isSubmitting: boolean;
  onContinueClick: (isValid: boolean) => void;
}

const NUM_INPUTS = 6;

const PhoneVerificationForm: React.FC<IPhoneVerificationFormProps> = ({
  phoneNumber,
  otp,
  onOtpChange,
  onContinueClick,
  errorMessage,
  onResendOtp,
  isSubmitting,
  isResendDisabled,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [enableResendButton, setEnableResendButton] = useState(false);

  const methods = useForm<IPhoneVerificationFormValues>({
    resolver: yupResolver(PhoneVerificationSchema),
  });

  useEffect(() => {
    // Enable the "Request Again" button after the first successful submission
    if (isSubmitting) {
      setEnableResendButton(true);
    }
  }, [isSubmitting]);

  const handleContinue = () => {
    if (!errorMessage) {
      onContinueClick(true);
    } else {
      // Handle invalid OTP case
      onContinueClick(false);
    }
  };

  const handleResendClick = async () => {
    setEnableResendButton(false);

    try {
      onResendOtp();
    } catch {
      // If the resend request fails, enable the button again
      setEnableResendButton(true);
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledOuterBox>
        <StyledInnerBox>
          <StyledTitleBox>
            <Title>Phone Verification</Title>
            <Box>
              <StyledTypography className="phoneText">
                Enter the 6 digits code that was sent to your phone number{' '}
                <StyledBoldTypography>
                  {formatPhoneNumber(phoneNumber)}.
                </StyledBoldTypography>
              </StyledTypography>
            </Box>
          </StyledTitleBox>

          <StyledCodeBox>
            <Box>
              <OtpInputController
                otp={otp}
                numInputs={NUM_INPUTS}
                error={!!errorMessage}
                onOtpChange={onOtpChange}
              />
              {errorMessage && (
                <Typography style={{ color: COLORS.coralPink, marginTop: 10 }}>
                  {errorMessage}
                </Typography>
              )}
            </Box>

            <StyledRequestBox>
              <StyledTypography>Don’t receive code? </StyledTypography>
              <StyledResend
                onClick={handleResendClick}
                disabled={
                  !enableResendButton || isResendDisabled || isSubmitting
                }>
                Request Again
              </StyledResend>
            </StyledRequestBox>
          </StyledCodeBox>
        </StyledInnerBox>

        <ContinueButton
          disabled={
            otp.length !== NUM_INPUTS || isResendDisabled || isSubmitting
          }
          variant="contained"
          onClick={handleContinue}>
          Continue
        </ContinueButton>
      </StyledOuterBox>
      <StyledLeftLogo src={matches ? deliveryCowMobile : deliveryCow} />
    </FormProvider>
  );
};

export default PhoneVerificationForm;
