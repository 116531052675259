import { styled, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LazyImage from '@components/Image/LazyImage';
import Button from '@components/Button';

import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';
import Stack from '@mui/material/Stack';

export const ApplyNowContent = styled(Box)(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  width: '100vw',
  maxHeight: '637px',
  fontSize: '64px',
  left: '0',
  top: 'calc(63% - 50px)',
  transform: 'translateY(-50%)',

  [theme.breakpoints.down('lg')]: {
    '.MuiTypography-root': {
      '&.subTitle': {
        whiteSpace: 'pre-wrap',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    top: '81px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    maxWidth: '335px',
    zIndex: 1,

    '.MuiTypography-root': {
      textAlign: 'center',

      '&.title': {
        fontSize: '40px',
        lineHeight: 'normal',
      },

      '&.subTitle': {
        whiteSpace: 'pre-wrap',
        fontSize: '16px',
        lineHeight: '135%',
        letterSpacing: '-0.32px',
        width: '218px',
        margin: '0 auto',
        marginTop: '16px',
      },
    },
  },
}));

export const StyledStackWrapper = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    width: '100%',
    maxWidth: 1440,
    margin: '0 auto',
    padding: '0px 70px',
    display: 'flex',
    flexDirection: 'column',
    gap: 34,

    '.title': {
      fontFamily: FontFamilyNames.MoretRegular,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    '.subTitle': {
      fontSize: 20,
      lineHeight: '135%',
      letterSpacing: '-0.4px',
      margin: '20px 0 0 0',
    },
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      gap: 32,
    },
  }),
);

export const StyledTitleAndSubTitleStack = styled(Stack)(
  ({ theme }: { theme: Theme }) => ({
    maxWidth: '576px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '335px',
      padding: '0 20px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '201px',
    },
  }),
);

export const ApplyNowButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  ...theme.typography.h5,
  padding: '12px 30px',
  fontFamily: FontFamilyNames.MoretRegular,
  lineHeight: '35px',
  cursor: 'pointer',
  maxHeight: 52,
  maxWidth: 308,

  '&.footerApplyNow': {
    marginTop: '26px',
  },
  [theme.breakpoints.down('l')]: {
    padding: theme.spacing(2, 8),
    lineHeight: '25px',
  },
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'center',
    marginTop: '0px',
    fontSize: '16px',
    padding: '0',
    width: '335px',
    height: '42px',
    minWidth: '100%',
    lineHeight: '123.4%',
  },
}));

export const ImagePlaceholder = styled(Box)(() => ({
  background: COLORS.beige,
  height: '721px',
}));

export const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  marginTop: -120,

  img: {
    marginTop: 20,
  },

  [theme.breakpoints.down('md')]: {
    marginTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    height: 220,
    marginTop: 0,
    border: '1px solid red',
  },
}));

export const Image = styled(LazyImage)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    minHeight: 520,
    marginTop: '265px',
  },
}));
