import { CSSProperties } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledImageBox = styled(Box)(() => ({
  position: 'relative',
  overflow: 'hidden',

  '.source': {
    opacity: '0',
    transition: 'opacity 1s',
  },
  '.loaded': {
    opacity: '1',
  },
}));

export const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'objectFit' && prop !== 'position' && prop !== 'objectPosition',
})<{
  objectFit?: CSSProperties['objectFit'];
  position: CSSProperties['position'];
  objectPosition?: CSSProperties['objectPosition'];
}>(({ objectFit, position, objectPosition }) => ({
  position,
  width: '100%',
  height: '100%',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  objectFit,
  objectPosition,
}));
