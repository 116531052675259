import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledCheckbox = styled(Box)(() => ({
  border: '1px solid #A09D95',
  borderRadius: '4px',
  width: '16px',
  height: '16px',
}));

export const Wrapper = styled(Box)(() => ({
  cursor: 'pointer',
  width: 'max-content',
  color: 'rgb(160, 157, 149)',
  gap: '12px',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '14px',
}));
