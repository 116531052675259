import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Box, Typography, Link } from '@mui/material';
import { FontFamilyNames } from '@app/types/fonts';
import { COLORS } from '@utils/colors';

export const BidCardContainer = styled(Stack)(({ theme }) => ({
  height: 'min-content',
  padding: '24px 24px 32px 24px',
  borderRadius: '30px',
  display: 'flex',
  gap: '16px',
  maxWidth: '393px',
  width: '100%',
  background: '#EAF9EB',

  [theme.breakpoints.down(1441)]: {
    maxWidth: '315px',
  },
}));

export const InfoSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
});

export const ContactInfoSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
});

export const TitleText = styled(Typography)(({ theme }) => ({
  color: COLORS.grey[50],
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: 16,
  fontWeight: 300,
  lineHeight: '130%',

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const BidContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',

  // [theme.breakpoints.down('sm')]: {
  //   gap: '27px',
  // },
}));

export const ContentText = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: 16,
  lineHeight: '130%',

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: COLORS.darkBlue,
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: 16,
  lineHeight: '130%',
  textDecorationColor: COLORS.darkBlue,

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const CompanyName = styled(Box)(({ theme }) => ({
  fontFamily: FontFamilyNames.MoretRegular,
  fontWeight: 400,
  color: COLORS.darkBlue,
  fontSize: 40,

  [theme.breakpoints.down('sm')]: {
    fontSize: 26,
  },
}));
