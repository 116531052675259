import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IUseLocalLocation {
  name: string;
  locationValue: string | undefined;
}

export const useLocalLocation = ({
  name,
  locationValue,
}: IUseLocalLocation) => {
  const [address, setAddress] = useState(locationValue || '');
  const { setValue, clearErrors } = useFormContext();

  // TODO: optimize here
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  // @ts-ignore
  const matchesL = useMediaQuery(theme.breakpoints.down('l'));
  // @ts-ignore
  const matchesVsm = useMediaQuery(theme.breakpoints.down('vsm'));
  // @ts-ignore
  const matchesEsm = useMediaQuery(theme.breakpoints.down('esm'));

  const makeTextWithDots = (valueWithDots: string | undefined) => {
    // optimize here
    if (matchesEsm) {
      if (
        valueWithDots &&
        !valueWithDots.includes('...') &&
        valueWithDots.length > 40
      ) {
        valueWithDots = valueWithDots.substring(0, 30) + '...';
      }
    } else if (matchesVsm) {
      if (
        valueWithDots &&
        !valueWithDots.includes('...') &&
        valueWithDots.length > 45
      ) {
        valueWithDots = valueWithDots.substring(0, 35) + '...';
      }
    } else if (matchesSm) {
      if (
        valueWithDots &&
        !valueWithDots.includes('...') &&
        valueWithDots.length > 50
      ) {
        valueWithDots = valueWithDots.substring(0, 45) + '...';
      }
    } else if (matchesMd) {
      if (
        valueWithDots &&
        !valueWithDots.includes('...') &&
        valueWithDots.length > 20
      ) {
        valueWithDots = valueWithDots.substring(0, 18) + '...';
      }
    } else if (matchesL) {
      if (
        valueWithDots &&
        !valueWithDots.includes('...') &&
        valueWithDots.length > 23
      ) {
        valueWithDots = valueWithDots.substring(0, 20) + '...';
      }
    } else if (matchesLg) {
      if (
        valueWithDots &&
        !valueWithDots.includes('...') &&
        valueWithDots.length > 26
      ) {
        valueWithDots = valueWithDots.substring(0, 23) + '...';
      }
    } else {
      if (
        valueWithDots &&
        !valueWithDots.includes('...') &&
        valueWithDots.length > 29
      ) {
        valueWithDots = valueWithDots.substring(0, 27) + '...';
      }
    }

    return valueWithDots;
  };

  const handleSelect = async (value: string) => {
    try {
      const result = await geocodeByAddress(value);
      const { place_id } = result[0];

      clearErrors(name);
      setValue(name, { address: value, placeId: place_id });
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    locationValue && setAddress(locationValue);
  }, [locationValue]);

  return {
    address,
    setAddress,
    handleSelect,
    makeTextWithDots,
  };
};
