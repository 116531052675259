import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import { styled } from '@mui/material/styles';

import { Box } from '@mui/material';
import { FontFamilyNames } from '@app/types/fonts';
import { COLORS } from '@utils/colors';
import Typography from '@mui/material/Typography';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1262px',
  margin: '0 auto',
  marginBottom: '110px',

  [theme.breakpoints.down('xl')]: {
    padding: '0 20px',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '100px',
    padding: '0px',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.MoretRegular,
  textAlign: 'center',
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '-0.26px',
  color: COLORS.darkBlue,
  marginBottom: 60,

  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
  },
}));

export const StyledSwiper = styled(Swiper)<SwiperProps>(({ theme }) => ({
  '.swiper-wrapper': {
    alignItems: 'center',
  },

  [theme.breakpoints.down('sm')]: {},
}));

export const StyledSwiperSlideItem = styled(SwiperSlide)(({ theme }) => ({
  minHeight: '261px !important',
  borderRadius: '20px',
  border: `0.837px solid ${COLORS.grey[50]}`,
  padding: '35px 56px 31px 56px',

  display: 'flex !important',
  flexDirection: 'column',
  justifyContent: 'space-between !important',
  alignItems: 'center !important',

  '&.swiper-slide': {
    width: '370px!important',
    padding: '29px 0 26px 0',

    [theme.breakpoints.down('sm')]: {
      width: '310px!important',
      padding: '26px 24px 23px 24px',
    },
  },
  '&.swiper-slide-active': {
    width: '442px!important',
    height: '312px',
    background: COLORS.white,
    minHeight: '312px !important',

    [theme.breakpoints.down('sm')]: {
      width: '310px!important',
      height: '275px',
      minHeight: '275px !important',
    },

    h4: {
      color: COLORS.darkBlue,
      fontSize: '16px',
      letterSpacing: '-0.32px',
    },
    p: {
      color: COLORS.darkBlue,
      fontSize: '16px',
      letterSpacing: '-0.32px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    minHeight: '275px !important',
    justifyContent: 'flex-start!important',
  },
}));

export const Avatar = styled('img')<{ isActive: boolean }>(
  ({ isActive, theme }) => ({
    width: isActive ? 76 : 63,
    height: isActive ? 76 : 63,
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    [theme.breakpoints.down('sm')]: {
      width: isActive ? 67 : 54,
      height: isActive ? 67 : 54,
      marginBottom: 4,
    },
  }),
);

export const Name = styled('h4')(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '130%',
  letterSpacing: '-0.26px',
  color: COLORS.grey[50],

  [theme.breakpoints.down('sm')]: {
    minHeight: '14px',
    marginBottom: '13px',
  },
}));

export const Quote = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '13px',
  fontStyle: 'normal',
  lineHeight: '130%',
  letterSpacing: '-0.26px',
  color: COLORS.grey[50],
  textAlign: 'center',
  whiteSpace: 'pre-wrap',

  [theme.breakpoints.down('sm')]: {
    marginBottom: '36px',
  },
}));

export const StarsWrapper = styled(Box)(() => ({
  '.special-size-for-svg': {
    width: '18px',
    height: '18px',
  },
}));

export const PaginationBox = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  justifyContent: 'center',
  marginTop: '32px',
}));

export const NavigationButton = styled(Box)(({ theme }) => ({
  cursor: 'pointer',

  svg: {
    width: '36px !important',
    height: '18px !important',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',

    svg: {
      width: '32px !important',
      height: '16px !important',
    },
  },
}));

export const SlideInfo = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));
