import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { FontFamilyNames } from '@app/types/fonts';
import { COLORS } from '@utils/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ViewContainer = styled(Stack)(({ theme }) => ({
  maxWidth: 860,
  gap: '24px',

  [theme.breakpoints.down('sm')]: {
    gap: '16px',
  },
}));

export const Title = styled(Stack)(({ theme }) => ({
  color: COLORS.darkBlue,
  textAlign: 'left',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: 40,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'capitalize',

  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',
  },
}));

export const DetailsContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const ToggleDetailsButton = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  gap: '4px',
}));

export const AddNewMoveAndViewDetailsSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledExpandIcon = styled('svg', {
  shouldForwardProp: prop => prop !== 'expanded',
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  transform: expanded
    ? 'rotate(0deg) translate(0, 0)'
    : 'rotate(180deg) translate(0, -4px)',

  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  transformOrigin: 'center',
}));

export const InformationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('l')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '16px',
  },
}));

export const InformationGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '6px',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const InformationLabel = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: '16px',
  fontWeight: 300,
  lineHeight: '130%',
  fontStyle: 'normal',
  letterSpacing: '-0.32px',
  color: theme.palette.grey[50],

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    letterSpacing: '-0.14px',
  },
}));

export const InformationValue = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 300,
  lineHeight: '130%',
  fontStyle: 'normal',
  letterSpacing: '-0.32px',
  color: theme.palette.text.primary,

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    letterSpacing: '-0.14px',
  },
}));

export const InformationLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',

  [theme.breakpoints.down('sm')]: {
    gap: 16,
  },
}));

export const InformationRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',

  [theme.breakpoints.down('sm')]: {
    gap: '16px',
  },
}));
