import {
  memo,
  useRef,
  useState,
  ReactNode,
  useEffect,
  CSSProperties,
  ImgHTMLAttributes,
} from 'react';

import { StyledImage, StyledImageBox } from './styled';

interface IProgressiveImgProps extends ImgHTMLAttributes<HTMLImageElement> {
  customClass?: string;
  alt?: string;
  imagePlaceholder?: ReactNode;
  objectFit?: CSSProperties['objectFit'];
  objectPosition?: CSSProperties['objectPosition'];
}

const LazyImage = ({
  src,
  alt = '',
  objectFit = 'cover',
  objectPosition = 'center',
  imagePlaceholder,
  customClass = '',
  ...restProps
}: IProgressiveImgProps) => {
  const [loaded, setLoaded] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, []);

  const showPlaceholder = !loaded && imagePlaceholder;

  return (
    <StyledImageBox sx={{ display: showPlaceholder ? 'unset' : 'flex' }}>
      {showPlaceholder && imagePlaceholder}
      <StyledImage
        position={showPlaceholder ? 'absolute' : 'initial'}
        alt={alt}
        src={src}
        ref={imgRef}
        objectFit={objectFit}
        objectPosition={objectPosition}
        onLoad={() => setLoaded(true)}
        className={`image ${customClass}`}
        {...restProps}
      />
    </StyledImageBox>
  );
};

export default memo(LazyImage);
