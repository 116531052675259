import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

/** TODO: this section need mobile drawer in for header */
export const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: `-${DRAWER_WIDTH}px`,
  // ...(open && {
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   marginLeft: 0,
  // }),
}));

export const StyledOutletContainer = styled(Box)`
  &.home {
    max-width: 1436px;
  }
`;
