import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { geocodeByAddress } from 'react-places-autocomplete';


interface IUseLocalLocation {
  name: string
  locationValue: string | undefined
}

export const useLocalLocation = ({ name, locationValue }: IUseLocalLocation) => {
  const [address, setAddress] = useState(locationValue || '');
  const { setValue, clearErrors, watch, setError } = useFormContext()
  const isGooglePlaceId = watch('isGooglePlaceId')

  const makeTextWithDots = (valueWithDots: string | undefined) => {
    return valueWithDots
  }

  const handleSelect = async (value: string) => {
    try {
      const result = await geocodeByAddress(value);
      const { place_id } = result[0]

      clearErrors(name)
      setValue(name, { address: value, placeId: place_id })
    } catch (e) {

      throw e;
    }
  };


  useEffect(() => {
    locationValue && setAddress(locationValue || "")
  }, [locationValue])

  useEffect(() => {
    setAddress('')
    setError('googlePlaceId', { message: '' });
  }, [isGooglePlaceId])

  return {
    address,
    setAddress,
    handleSelect,
    makeTextWithDots
  }
}
