import mooLogo from '@assets/svg/moo-login.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';

import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import { PASSWORD_REGEX } from '@utils/regex';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { StyledErrorMessage } from '@components/ErrorMessage/styled';
import { useResetPasswordLogic } from './useResetPasswordLogic';
import {
  StyledModal,
  FieldWrapper,
  StyledLeftLogo,
  SubTitle,
  StyledTextField,
  StyledIconButton,
  StyledModalTitle,
  StyledLoginButton,
  MainErrorMessage,
  StyledDialogContent,
  ButtonAndMessageWrapper
} from './styled';

const ResetPasswordModal = () => {
  const {
    watch,
    errors,
    matches,
    isLoading,
    errorMessage,
    passwordInputProps,
    showPassword,
    register,
    handleClose,
    handleSubmit,
    handleLogin,
    clearMessages,
  } = useResetPasswordLogic()


  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type='opened'
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>

      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Reset Password"
          textAlign="center"
        />
        <SubTitle>
          Enter a new password below.
        </SubTitle>
        <form onSubmit={handleSubmit(handleLogin, clearMessages)}>
          <Stack alignItems="center">
            <FieldWrapper>
              <StyledTextField
                placeholder='New Password'
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                InputProps={passwordInputProps}
                {...register('newPassword', {
                  minLength: {
                    value: 6,
                    message: "Password must have at least 6 characters"
                  },
                  required: {
                    value: true,
                    message: 'Password is a required field'
                  },
                  pattern: {
                    value: PASSWORD_REGEX,
                    message: 'Password should contain minimum six characters, at least one uppercase letter, one lowercase letter, and one special character'
                  },
                })}
              />
              {errors && errors.newPassword && (
                <StyledErrorMessage>{errors?.newPassword?.message}</StyledErrorMessage>
              )}
            </FieldWrapper>

            <FieldWrapper>
              <StyledTextField
                placeholder='Confirm New Password'
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                InputProps={passwordInputProps}
                {...register('confirmPassword', {
                  validate: value => {
                    return value === watch('newPassword') || "The passwords do not match"
                  },
                  required: {
                    value: true,
                    message: 'Password is a required field'
                  },
                })}
              />
              {errors && errors.confirmPassword && (<StyledErrorMessage>{errors?.confirmPassword?.message}</StyledErrorMessage>)}
            </FieldWrapper>

            <ButtonAndMessageWrapper>
              <StyledLoginButton
                type="submit"
                disabled={!!isLoading}
              >
                {isLoading ? 'Loading ... ' : 'Reset Password'}
              </StyledLoginButton>
              {errorMessage && (<MainErrorMessage> {errorMessage}</MainErrorMessage>)}
            </ButtonAndMessageWrapper>
          </Stack>
        </form>
        <StyledLeftLogo src={mooLogo} />
      </StyledDialogContent>
    </StyledModal >
  );
};

export default ResetPasswordModal;
