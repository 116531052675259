import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { PartnerRegistrationFormValues } from '@modules/truckerBecomePartner/types';
import {
  StyledGoogleReview,
  Description,
  CustomErrorMessage,
} from '../../styled';
import LocationField from './LocationField';
import CustomCheckbox from '@components/CustomCheckbox';

interface IGoogleReviewProps {
  icon: string;
  placeholder: string;
  fieldName: keyof PartnerRegistrationFormValues;
}

const GoogleReviewField = ({ icon, placeholder }: IGoogleReviewProps) => {
  const {
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<PartnerRegistrationFormValues>();

  const isGooglePlaceId = watch('isGooglePlaceId');
  const selectedPlace = watch('googlePlaceId');

  const handleSelect = (address: string, placeId: string) => {
    setValue('googlePlaceId', { address, placeId });
    trigger('googlePlaceId');
  };

  const handleCheckboxChange = () => {
    const newValue = !isGooglePlaceId;
    setValue('isGooglePlaceId', newValue);

    if (newValue) {
      setValue('googlePlaceId', { address: '', placeId: '' });
    } else {
      setValue('googlePlaceId', { address: '', placeId: '' });
    }
  };

  return (
    <StyledGoogleReview>
      <div className="wrapper">
        <img src={icon} className="google-logo" alt="google logo" />
        <LocationField
          disabled={isGooglePlaceId}
          name="from"
          placeholder={placeholder}
          locationValue={selectedPlace?.address}
          onSelect={handleSelect}
        />
        {errors['googlePlaceId'] && (
          <CustomErrorMessage>
            <ErrorMessage name={'googlePlaceId'} errors={errors} />
          </CustomErrorMessage>
        )}
        <Description>
          <p>
            Please type in your business name as it appears in your Google
            Business Profile and select your business listing. Please make sure
            the address listed is correct.
          </p>
        </Description>
      </div>
      <CustomCheckbox
        checked={isGooglePlaceId}
        onCheck={handleCheckboxChange}
        onLabelClick={handleCheckboxChange}
        label="I don’t have a Business Profile"
        alignment="center"
        containerStyles={{
          gap: '12px',
        }}
      />
    </StyledGoogleReview>
  );
};

export default GoogleReviewField;
