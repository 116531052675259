import { useState, useMemo, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { setUser } from '@modules/auth';
import { useMediaQuery } from '@mui/material';
import { errorHandler } from '@utils/errorHendler';
import { setToken } from '@modules/auth/store/auth.slice';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { setLoginPopUpStatus, setPopUpName } from '@app/store/features/app/app.slice';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import { useLazyGetProfileQuery, useLoginMutation } from '@modules/auth/api/auth.api';
import { ILoginFormValues } from './types';
import { deletePlusFromPhone } from '@utils/helpers';

export const useLoginLogic = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const modal = useAppSelector(({ app }) => app.modal)
  const [searchParams, setSearchParams] = useSearchParams();

  const params = new URLSearchParams(document.location.search);
  const [message, setMessage] = useState('');
  const [resetSuccessMessage, setResetSuccessMessage] = useState(params.get('token'));
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [loginRequest] = useLoginMutation();
  const [getProfile] = useLazyGetProfileQuery();

  const clearMessages = () => {
    setMessage('');
  };

  const handleLogin: SubmitHandler<ILoginFormValues> = async (data) => {
    try {
      setIsLoading(true)
      setResetSuccessMessage('')
      const { accessToken, refreshToken } = await loginRequest({ ...data, role: 'user' }).unwrap();

      dispatch(setToken({ accessToken, refreshToken, rememberMe: true }));

      const { phone, ...rest } = await getProfile().unwrap();

      const response = {
        ...rest,
        phone: deletePlusFromPhone(phone)
      }

      dispatch(setUser(response));
      dispatch(setPopUpName({ name: PopUpEnum.Empty }))
      navigate(PAGE_ROUTES_PRIVATE.PROFILE)
      setMessage('')
      setIsLoading(false)
    } catch (error) {
      const errorMessage = errorHandler(error)
      setMessage(errorMessage)
      setIsLoading(false)
    }
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormValues>();


  const handleClose = () => {
    searchParams.delete('userId');
    setSearchParams(searchParams)
    dispatch(setLoginPopUpStatus(false))
    reset();
    clearMessages();
    setShowPassword(false)
    dispatch(setPopUpName({ name: PopUpEnum.Empty }))
  };

  const passwordInputProps = useMemo(
    () => ({
      endAdornment: showPassword ? (
        <VisibilityIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowPassword(!showPassword)}
        />
      ) : (
        <VisibilityOffIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowPassword(!showPassword)}
        />
      ),
    }),
    [showPassword],
  );

  useEffect(() => {
    setShowPassword(false)
  }, [])

  useEffect(() => {
    let time: any

    if (modal === PopUpEnum.LoginModal && params.get('token')) {
      time = setTimeout(() => {
        navigate(PAGE_ROUTES_PUBLIC.HOME)
      }, 500)
    }

    return () => {
      clearInterval(time)
    }
  }, [modal])

  return {
    params,
    errors,
    message,
    matches,
    isLoading,
    showPassword,
    resetSuccessMessage,
    passwordInputProps,
    register,
    handleClose,
    handleLogin,
    handleSubmit,
    clearMessages,
  }
}
