
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';

import { useAppSelector } from '@app/store/hooks';
import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { useQuoteBoardLogic } from './useConnectModalLogic';
import {
  StyledModal,
  StyledIconButton,
  StyledModalTitle,
  StyledDialogContent,
  Content,
} from './styled';
import { formatPhoneNumber } from '@utils/helpers';

const Svg = ({ className }: { className: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="198" height="130" viewBox="0 0 198 130" fill="none" className={className}>
    <path d="M186.033 128.507C185.827 80.3392 146.72 41.3535 98.5042 41.3535C50.2884 41.3535 11.1815 80.3392 10.9756 128.507H186.033Z" fill="#FEE5E9" />
    <path d="M27.86 102.235C27.6844 102.755 27.7026 103.294 27.6602 103.791C28.6958 101.907 27.8903 101.011 30.6884 98.6551C35.4004 94.6942 40.0275 95.1 40.0275 95.1L40.0033 92.5684C30.5128 96.4748 29.6164 96.9836 27.86 102.235Z" fill="#282750" />
    <path d="M25.7343 106.347C23.6933 107.546 20.877 106.783 19.4355 111.204C22.7303 108.37 23.1058 110.259 25.6374 108.86C27.8662 107.631 27.3696 106.002 27.5876 103.488C27.0243 104.512 26.6367 105.82 25.7343 106.347Z" fill="#FFB49E" />
    <path d="M57.6279 17.0013C60.9469 16.3412 62.2914 13.4159 63.4967 14.282C64.708 15.1481 64.5747 17.2315 61.5889 18.8183" fill="#282750" />
    <path d="M84.6405 64.6583H79.8741C79.4077 64.6583 79.0322 64.2828 79.0322 63.8165C79.0322 63.3501 79.4077 62.9746 79.8741 62.9746H84.6405C85.1069 62.9746 85.4824 63.3501 85.4824 63.8165C85.4824 64.2828 85.1008 64.6583 84.6405 64.6583Z" fill="#282750" />
    <path d="M55.8171 19.4715C61.3104 14.2992 57.1435 11.8161 55.4113 8.8181C53.6792 5.82013 53.2613 1.86523 53.2613 1.86523C53.2613 1.86523 52.5769 6.74072 53.9214 9.45403C55.5082 12.664 56.8528 14.4385 52.165 15.7952" fill="#FFB49E" />
    <path d="M57.6287 16.9885C55.3636 13.6877 48.4168 11.1621 41.2459 17.0066C34.614 22.4151 36.643 27.6721 36.643 27.6721C37.0306 31.3484 38.3085 32.5597 38.3085 32.5597L56.2721 17.7819L57.6287 16.9885Z" fill="#282750" />
    <path d="M42.1053 56.9925C42.1053 56.9925 42.1113 41.7119 39.5858 35.9522C38.9377 34.4744 38.429 32.9057 38.1504 31.325C39.5313 27.4791 40.8637 21.8708 46.6113 18.7335C54.3515 14.5182 61.8616 16.7894 65.6772 23.2335C70.0197 30.5679 69.9591 30.6043 69.9591 30.6043L83.3925 61.9648C83.3925 61.9648 81.1576 69.1418 78.0627 69.0873L42.1053 56.9925Z" fill="white" stroke="#282750" stroke-miterlimit="10" />
    <path d="M69.5414 63.6951L78.2204 64.7005L80.0131 63.3559C81.5394 63.3862 79.0199 58.7711 76.2763 56.5121C75.0529 55.5067 67.4156 51.3095 69.5414 63.6951Z" fill="#FFB49E" />
    <path d="M60.6143 25.1461L60.6204 25.134C54.9697 23.6259 50.4091 25.2611 49.3432 26.4482C48.2772 27.6353 49.3008 31.6023 50.6272 32.959C52.65 35.0303 54.8243 36.4112 57.3257 35.3028C60.8142 33.7524 63.5517 30.4455 63.4851 28.8284C63.4851 28.8163 63.4791 28.8042 63.4791 28.7921C63.5215 28.6891 63.5699 28.5862 63.5881 28.4771C63.8667 27.0842 62.5524 25.6669 60.6143 25.1461Z" fill="#282750" />
    <path d="M54.4184 25.084L50.6754 32.5577L49.8457 30.7347L52.8618 25.302L54.4184 25.084Z" fill="white" />
    <path d="M58.8942 25.5198L54.7334 35.0042H56.3687L59.1546 33.0177L61.868 26.2465C61.874 26.2465 60.4931 25.2472 58.8942 25.5198Z" fill="white" />
    <path d="M74.0413 27.4231C73.6355 25.7212 71.013 25.3941 69.3536 25.3093C67.6941 25.2246 65.5501 25.9513 65.5137 27.738C65.2472 29.3672 66.5857 33.3039 67.8213 34.5273C69.0568 35.7508 70.9282 34.4365 72.0548 32.9405C73.1691 31.4446 74.4471 29.1249 74.0413 27.4231Z" fill="#282750" />
    <path d="M71.238 26.4801C71.3046 26.5407 68.8275 32.5609 68.8275 32.5609L66.417 31.9371L69.1303 26.25H71.244" fill="white" />
    <path d="M73.1814 26.8789L71.2373 31.7726L73.1814 30.9004L73.8779 28.0236L73.1814 26.8789Z" fill="white" />
    <path d="M56.0841 25.133C57.3257 25.133 58.5915 25.2783 59.8452 25.563L60.4569 25.7265C62.0861 26.1626 63.2005 27.3194 63.0006 28.3611C62.9945 28.3853 62.9764 28.4398 62.9582 28.4822L62.9219 28.5731L62.8734 28.7003L62.8855 28.8396C62.8855 28.8577 62.8916 28.8699 62.8916 28.888C62.8916 30.1962 60.4387 33.2548 57.0895 34.7447C56.5747 34.9748 56.072 35.0838 55.5572 35.0838C54.1945 35.0838 52.7712 34.2723 51.0693 32.528C49.858 31.2925 49.0525 27.6767 49.8035 26.847C50.4334 26.1384 52.8075 25.133 56.0841 25.133ZM56.0841 24.5273C52.644 24.5273 50.1063 25.5993 49.3432 26.4473C48.2772 27.6343 49.3008 31.6013 50.6272 32.958C52.1534 34.5206 53.7705 35.6955 55.5511 35.6955C56.1265 35.6955 56.714 35.5744 57.3257 35.3019C60.8142 33.7514 63.5518 30.4445 63.4851 28.8275C63.4851 28.8153 63.4791 28.8032 63.4791 28.7911C63.5215 28.6882 63.5699 28.5852 63.5881 28.4762C63.8606 27.0832 62.5464 25.666 60.6083 25.1451L60.6143 25.133C59.0033 24.6969 57.465 24.5273 56.0841 24.5273Z" fill="#282750" />
    <path d="M69.0882 25.9103C69.167 25.9103 69.2457 25.9103 69.3244 25.9164C72.4496 26.0799 73.2975 26.9036 73.455 27.5638C73.7881 28.9568 72.6979 31.0644 71.5653 32.5786C70.7477 33.6687 69.6999 34.4076 68.961 34.4076C68.6885 34.4076 68.4523 34.3047 68.2464 34.0987C67.1562 33.0207 65.8783 29.2778 66.1145 27.8424L66.1205 27.8V27.7576C66.1448 26.4009 67.9072 25.9103 69.0882 25.9103ZM69.0882 25.3047C67.4711 25.3047 65.5452 26.0436 65.5088 27.7394C65.2424 29.3686 66.5808 33.3053 67.8164 34.5287C68.1555 34.8679 68.5492 35.0133 68.961 35.0133C70.033 35.0133 71.2383 34.0261 72.0499 32.9419C73.1703 31.446 74.4482 29.1263 74.0425 27.4245C73.6367 25.7226 71.0142 25.3955 69.3547 25.3107C69.2699 25.3047 69.1791 25.3047 69.0882 25.3047Z" fill="#282750" />
    <path d="M69.96 48.2224C62.7467 51.6262 57.8833 52.3348 52.8564 43.1289" stroke="#282750" stroke-miterlimit="10" />
    <path d="M58.8815 32.2646C57.4219 34.1422 56.362 38.0607 58.6998 41.6159C61.0376 45.171 61.5161 51.1852 67.4636 49.4288C73.4111 47.6724 76.8754 46.1098 80.4366 46.7942C84.0039 47.4786 81.1452 41.0345 81.5934 37.2067C82.0416 33.3851 82.302 26.7835 76.8936 28.8124C71.4851 30.8414 71.8909 31.9255 67.0033 31.4833C62.1157 31.0412 60.65 29.9874 58.8815 32.2646Z" fill="#FFB49E" stroke="#282750" stroke-miterlimit="10" />
    <path d="M81.4354 37.4118C81.8836 33.5902 82.144 26.9886 76.7355 29.0175C71.3271 31.0464 71.7329 32.1305 66.8453 31.6884C61.9577 31.2463 60.8978 30.1077 59.1232 32.3849C58.9234 32.6454 58.7295 32.9482 58.5479 33.2813C60.2497 31.2826 61.3702 32.3546 66.1003 32.7847C70.9879 33.2268 70.5821 32.1427 75.9906 30.1137C81.399 28.0848 81.1386 34.6803 80.6904 38.508C80.3815 41.1426 81.6958 44.5161 81.3445 46.4481C83.2644 45.685 81.0357 40.7853 81.4354 37.4118Z" fill="white" />
    <path d="M66.6826 36.6199C68.6873 37.6616 70.7344 37.0196 70.3407 38.2915C69.9471 39.5633 68.5056 40.2719 65.9922 39.5815C63.4787 38.891 62.6066 36.7289 63.079 35.8749C63.5453 35.0209 64.1328 34.5061 66.6826 36.6199Z" fill="#282750" />
    <path d="M76.5064 35.1966C75.4828 36.4927 73.9081 36.6744 74.5743 37.4133C75.2345 38.1461 76.4276 38.1946 77.8994 36.9772C79.3711 35.7599 79.2802 34.0459 78.6988 33.6159C78.1174 33.1859 77.5602 33.0163 76.5064 35.1966Z" fill="#282750" />
    <path d="M50.596 41.519C52.2736 41.2099 53.5028 40.25 53.3416 39.3751C53.1804 38.5001 51.6897 38.0414 50.0122 38.3505C48.3346 38.6596 47.1053 39.6195 47.2665 40.4944C47.4278 41.3694 48.9184 41.8281 50.596 41.519Z" fill="#FFB49E" />
    <path d="M51.5187 44.8673C51.4884 44.5342 51.5974 44.1829 51.7852 43.8922C51.9729 43.6015 52.2334 43.3653 52.518 43.1775C52.8027 42.9958 53.1116 42.8686 53.4386 42.7899C53.7596 42.7112 54.0927 42.6748 54.4258 42.7354C53.8686 43.1048 53.3356 43.3713 52.8451 43.6863C52.5967 43.8377 52.3727 44.0133 52.1486 44.195C51.9305 44.3888 51.7307 44.6008 51.5187 44.8673Z" fill="#282750" />
    <path d="M61.5283 25.7793C65.5801 27.6205 67.4576 26.1911 67.4576 26.1911" stroke="#282750" stroke-miterlimit="10" />
    <path d="M62.334 30.1835C64.0177 27.8275 65.1321 28.4695 66.2283 30.1108" stroke="#282750" stroke-miterlimit="10" />
    <path d="M55.2901 19.4043C55.5506 20.2946 55.0479 21.1667 54.1152 21.4514C52.6313 21.8996 50.5721 22.6627 49.7969 23.6136C50.3541 20.3128 55.2901 19.4043 55.2901 19.4043Z" fill="#282750" />
    <path d="M62.8799 20.1133C63.0192 20.7008 63.431 21.1853 63.9882 21.4336C64.715 21.7546 65.7264 22.2755 66.2958 22.8993C65.6235 20.5433 62.8799 20.1133 62.8799 20.1133Z" fill="white" />
    <path d="M62.5703 20.5488C62.7096 21.1363 63.1215 21.6208 63.6787 21.8691C64.4054 22.1901 65.4169 22.711 65.9862 23.3348C65.32 20.9788 62.5703 20.5488 62.5703 20.5488Z" fill="#282750" />
    <path d="M46.6113 15.7531C48.2466 14.463 51.4686 13.9785 51.4686 13.9785" stroke="white" stroke-miterlimit="10" />
    <path d="M50.5729 25.6133C48.5561 26.552 46.6846 27.6785 46.8118 28.6355C46.939 29.5924 47.7748 29.1866 48.3562 29.5137C48.9376 29.8407 49.4464 30.1012 49.4464 30.1012L49.192 27.2788" fill="#282750" />
    <path d="M40.5789 21.9488C33.3958 15.6863 38.1684 12.755 40.2094 8.87883C42.2565 5.00267 42.5896 0 42.5896 0C42.5896 0 43.6859 6.12313 42.1233 9.60561C40.276 13.7361 38.665 16.0376 44.6488 17.5275C44.6488 17.5275 45.4241 20.4044 40.5789 21.9488Z" fill="#FFB49E" />
    <path d="M46.9508 19.7326C40.4703 19.666 40.2765 15.0388 36.7334 16.9102C34.8983 17.8793 36.8546 22.8153 42.0087 23.9176" fill="white" />
    <path d="M45.6425 19.3208C39.1621 19.2542 40.2825 15.0389 36.7395 16.9103C34.9044 17.8794 37.5147 23.0153 42.6627 24.1176" stroke="#282750" stroke-miterlimit="10" />
    <path d="M45.413 20.9454C40.483 21.7085 37.9453 19.9219 37.9453 19.9219C37.9453 19.9219 40.3376 21.9629 42.4574 22.2052C44.5711 22.4414 45.413 20.9454 45.413 20.9454Z" fill="#282750" />
    <path d="M50.8145 26.2491C50.8145 26.2491 52.7404 24.9227 56.4531 25.0862C60.1657 25.2498 62.3945 25.922 62.879 27.7511C62.9456 28.6293 62.5762 29.3258 62.5762 29.3258C62.5762 29.3258 62.9335 27.1576 61.0802 26.4913C59.2209 25.8191 56.1078 24.7289 50.8145 26.2491Z" fill="white" />
    <path d="M66.4159 27.0481C67.2032 26.3334 69.3533 25.7338 71.6911 26.3334C74.0289 26.933 73.5868 27.9505 73.4717 28.6591C73.3566 29.3677 73.1628 28.2655 73.1628 28.2655C73.1628 28.2655 73.0356 26.9451 70.6191 26.5939C68.2025 26.2487 66.3008 27.1026 66.3008 27.1026" fill="white" />
    <path d="M36.7387 28.4349C36.7085 26.6664 38.162 24.1953 38.162 24.1953" stroke="white" stroke-miterlimit="10" />
    <path d="M32.833 122.759C32.833 122.759 33.1964 126.399 33.9232 128.028C36.4003 126.665 37.0181 126.562 37.0181 126.562L38.0477 128.028C38.0477 128.028 44.3404 126.375 45.8848 123.74C42.0692 119.349 32.833 118.525 32.833 118.525" fill="#FFB49E" />
    <path d="M32.833 122.759C32.833 122.759 33.1964 126.399 33.9232 128.028C36.4003 126.665 37.0181 126.562 37.0181 126.562L38.0477 128.028C38.0477 128.028 44.3404 126.375 45.8848 123.74C42.0692 119.349 32.833 118.525 32.833 118.525" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M83.8709 123.052C83.8709 123.052 82.0418 126.692 81.3211 128.321C78.844 126.958 78.2262 126.855 78.2262 126.855L77.1966 128.321C77.1966 128.321 72.3635 126.668 70.8252 124.033C74.6408 119.642 83.877 118.818 83.877 118.818" fill="#FFB49E" />
    <path d="M83.8709 123.052C83.8709 123.052 82.0418 126.692 81.3211 128.321C78.844 126.958 78.2262 126.855 78.2262 126.855L77.1966 128.321C77.1966 128.321 72.3635 126.668 70.8252 124.033C74.6408 119.642 83.877 118.818 83.877 118.818" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M57.3007 60.1836C54.9387 59.4568 52.8431 58.4575 50.9838 57.3188C49.1426 52.2677 51.2745 50.372 52.825 51.22C54.4905 52.1284 55.084 51.5591 55.0598 50.5477C55.0356 49.5362 57.2765 49.4454 58.4091 52.4736C59.5416 55.4958 61.0739 53.8303 62.267 55.2475C63.2664 56.4346 65.4467 59.4629 63.9023 61.3464C61.6008 61.189 59.366 60.8195 57.3007 60.1836Z" fill="#282750" />
    <path d="M84.1255 123.329C84.1255 123.329 87.0871 81.7506 85.8213 62.7817C81.5696 56.2891 69.172 59.2568 64.672 61.3402C61.353 60.9526 41.0456 69.1713 41.0456 69.1713C41.0456 69.1713 39.3558 90.6052 30.4648 122.287C42.6808 124.915 49.3854 123.329 49.3854 123.329C49.3854 123.329 56.6834 116.133 59.5482 107.394C60.9412 107.781 65.2716 107.878 65.2716 107.878C65.2716 107.878 66.9734 115.255 69.7594 123.329C77.9539 125.806 84.1255 123.329 84.1255 123.329Z" fill="#A2B7FA" />
    <path d="M85.8286 62.7823C81.9706 56.8954 71.4201 58.785 66.1328 60.7413C71.6745 58.9546 81.1105 57.6706 84.7081 63.1699C85.9497 81.8361 83.1031 122.403 83.0123 123.686C83.733 123.493 84.1327 123.329 84.1327 123.329C84.1327 123.329 87.0883 81.7513 85.8286 62.7823Z" fill="white" />
    <path d="M54.7637 63.9895C58.761 62.6268 62.3222 61.5851 63.5517 61.7304C63.8303 61.6032 64.1391 61.47 64.4722 61.3367C62.9642 61.3004 59.0153 62.5056 54.7637 63.9895Z" fill="white" />
    <path d="M59.0336 106.293C56.4657 114.13 49.7308 122.216 48.4893 123.493C49.0828 123.409 49.3856 123.336 49.3856 123.336C49.3856 123.336 56.7624 115.935 59.7422 107.45C59.3728 107.395 59.2698 106.36 59.0336 106.293Z" fill="white" />
    <path d="M84.1255 123.329C84.1255 123.329 87.0871 81.7506 85.8213 62.7817C81.5696 56.2891 69.172 59.2568 64.672 61.3402C61.353 60.9526 41.0456 69.1713 41.0456 69.1713C41.0456 69.1713 39.3558 90.6052 30.4648 122.287C42.6808 124.915 49.3854 123.329 49.3854 123.329C49.3854 123.329 56.6834 116.133 59.5482 107.394C60.9412 107.781 65.2716 107.878 65.2716 107.878C65.2716 107.878 66.9734 115.255 69.7594 123.329C77.9539 125.806 84.1255 123.329 84.1255 123.329Z" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M70.6738 105.674C71.552 113.184 74.1503 124.431 74.1503 124.431" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" stroke-dasharray="5 5" />
    <path d="M45.1574 68.2207C45.1574 85.0214 40.1548 107.909 35.3096 123.165" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" stroke-dasharray="5 5" />
    <path d="M40.2579 61.444C40.27 59.2999 41.4026 57.3316 43.1166 56.0416C45.3454 54.37 49.1792 52.5046 54.4907 53.5826C63.3574 55.3874 68.0755 65.5866 67.5849 69.7656C67.1004 73.9143 64.7989 74.3019 63.7693 72.703C62.7397 71.1041 50.6024 58.5792 41.015 62.7522C40.4699 63.0126 40.2519 62.3888 40.2579 61.444Z" fill="#A2B7FA" />
    <path d="M54.4912 53.5825C49.4098 52.5469 45.679 54.2124 43.4199 55.8234C45.7032 54.5697 48.9495 53.6067 53.0982 54.4547C61.9649 56.2595 67.6157 64.89 66.1924 70.6376C65.8593 71.9701 65.6715 72.9573 65.2294 73.569C66.2166 73.5326 67.2704 72.4243 67.5854 69.7655C68.0699 65.5865 63.3579 55.3874 54.4912 53.5825Z" fill="white" />
    <path d="M40.2579 61.444C40.27 59.2999 41.4026 57.3316 43.1166 56.0416C45.3454 54.37 49.1792 52.5046 54.4907 53.5826C63.3574 55.3874 68.0755 65.5866 67.5849 69.7656C67.1004 73.9143 64.7989 74.3019 63.7693 72.703C62.7397 71.1041 50.6024 58.5792 41.015 62.7522C40.4699 63.0126 40.2519 62.3888 40.2579 61.444Z" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M64.411 71.181C65.1703 71.181 65.7858 69.9363 65.7858 68.401C65.7858 66.8657 65.1703 65.6211 64.411 65.6211C63.6517 65.6211 63.0361 66.8657 63.0361 68.401C63.0361 69.9363 63.6517 71.181 64.411 71.181Z" fill="#282750" />
    <path d="M43.4736 88.734C49.9056 89.4668 54.23 84.7246 54.4359 79.5645" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M64.7451 107.85C73.5089 104.616 76.6038 100.219 76.6038 100.219" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M46.0973 78.1773C45.4129 77.5777 44.7951 77.0387 44.2319 76.5481C39.7804 72.684 38.4903 66.1733 41.4338 61.0677C45.5522 53.927 53.7951 56.5556 58.8341 64.0777C65.5508 74.1073 77.6941 71.4969 82.297 67.4391C86.9 63.3752 90.9033 73.968 86.5184 78.6921C82.1335 83.4161 66.9438 87.1591 56.254 83.8643C52.0872 82.5682 48.7076 80.4787 46.0973 78.1773Z" fill="white" stroke="#282750" stroke-miterlimit="10" />
    <path d="M55.9814 83.7676C53.6193 83.0408 51.5238 82.0415 49.6644 80.9028C47.8233 75.8517 49.9552 73.956 51.5056 74.8039C53.1712 75.7124 53.7647 75.1431 53.7405 74.1317C53.7163 73.1202 55.9572 73.0294 57.0897 76.0576C58.2223 79.0798 59.7546 77.4143 60.9477 78.8315C61.947 80.0186 64.1274 83.0468 62.583 84.9304C60.2876 84.7729 58.0527 84.4095 55.9814 83.7676Z" fill="#282750" />
    <path d="M68.916 66.6275L86.3285 60.25C86.3285 60.25 104.395 62.9088 107.968 72.7385C102.366 75.9 89.3264 85.3663 89.3264 85.3663C89.3264 85.3663 87.6003 70.2977 68.916 66.6275Z" fill="white" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M92.6581 79.0015C92.6581 78.9954 92.6581 78.9894 92.6581 78.9894C92.6581 78.9833 92.6399 78.953 92.6157 78.9228C92.4704 78.6623 92.3068 78.408 92.1251 78.1778C91.7375 77.6993 91.2833 77.2875 90.8533 76.8454C90.5807 76.5607 90.2961 76.2821 89.9751 75.9551C89.9509 76.0217 89.9266 76.0883 89.9024 76.1489C89.8842 76.1368 89.8237 76.3669 89.6844 76.7424C89.6783 76.7545 89.6723 76.7727 89.6662 76.7848H89.6723C89.5572 77.1845 89.7025 78.1172 89.7025 78.1172C89.7813 78.4322 89.854 78.6744 89.9327 78.8864L89.9448 78.9228L89.9509 78.9288C90.1265 79.3891 90.3264 79.6495 90.6958 80.019C91.3741 80.6791 92.325 80.667 92.8095 79.9705C92.9064 79.8373 92.967 79.5769 92.9004 79.4497C92.8216 79.2922 92.7429 79.1469 92.6581 79.0015ZM91.035 79.8857C90.9926 79.8615 90.9502 79.8373 90.9078 79.8131C90.9381 79.8373 90.9865 79.8615 91.035 79.8857Z" fill="#FFB49E" />
    <path d="M95.0561 77.5781C94.6685 76.1003 92.8455 74.8587 91.0225 75.1555C91.5797 76.5425 92.0521 77.9052 93.2876 78.8197C93.7479 79.1589 94.1355 79.2376 94.4807 78.9953C94.9834 78.638 95.1893 78.0929 95.0561 77.5781Z" fill="#FFB49E" />
    <path d="M80.2793 69.0929L92.1864 63.3574L94.07 63.8238C94.07 63.8238 83.2954 69.3231 83.1683 69.2504C83.0411 69.1777 80.2793 69.0929 80.2793 69.0929Z" fill="#FFB49E" />
    <path d="M84.0051 69.5823L83.0724 72.986L86.8153 78.4974L83.5508 80.7444C83.5508 80.7444 78.0697 74.8393 77.4883 70.2182C79.7534 69.0917 81.9701 69.0372 84.0051 69.5823Z" fill="#FFB49E" stroke="#282750" stroke-miterlimit="10" />
    <path d="M73.373 66.4779C73.373 66.4779 86.0856 61.4692 86.3764 61.5358C86.6731 61.6024 88.4053 62.0082 88.4053 62.0082L75.4201 67.023L73.373 66.4779Z" fill="#FFB49E" />
    <path d="M80.8896 66.0595L82.2524 66.4774L90.6891 62.9767L89.1204 62.4922L80.8896 66.0595Z" fill="#FFB49E" />
    <path d="M87.3955 60.0323L88.522 63.0121L91.9742 61.4495L95.275 62.4307C95.275 62.4367 93.0462 56.0774 87.3955 60.0323Z" fill="#FFB49E" stroke="#282750" stroke-miterlimit="10" />
    <path d="M84.7293 60.8128C84.7293 60.8128 83.9661 57.1426 79.6357 53.1816C81.6526 58.1237 80.4836 62.3148 80.4836 62.3148L84.7293 60.8128Z" fill="#A2B7FA" />
    <path d="M83.6958 61.1762L84.7254 60.8128C84.7254 60.8128 83.9622 57.1426 79.6318 53.1816C79.9952 54.078 80.2557 54.9441 80.4374 55.7738C82.5511 58.1843 83.405 60.2678 83.6958 61.1762Z" fill="white" />
    <path d="M84.7293 60.8128C84.7293 60.8128 83.9661 57.1426 79.6357 53.1816C81.6526 58.1237 80.4836 62.3148 80.4836 62.3148L84.7293 60.8128Z" stroke="#282750" stroke-miterlimit="10" />
    <path d="M69.5581 38.1062C69.1826 38.8814 69.0554 39.8262 69.2674 40.7953C69.7337 42.8969 71.6476 44.3081 73.5493 43.9023C75.4813 43.4904 76.6018 41.3828 76.0385 39.2448C75.7902 38.3121 75.2633 37.5369 74.5971 37.0039" stroke="white" stroke-miterlimit="10" />
    <path d="M110.102 72.418C107.498 72.6724 101.647 73.7565 98.5889 70.0681C99.1158 69.2081 100.254 68.0937 100.254 68.0937L101.375 65.344C102.411 64.2842 103.695 64.0782 105.669 63.1819C134.819 49.9787 135.909 51.0446 138.38 55.7748C140.851 60.5049 135.382 68.0331 128.042 71.5883C120.707 75.1495 110.102 72.418 110.102 72.418Z" fill="white" stroke="#282750" stroke-miterlimit="10" />
    <path d="M100.769 68.1297L99.1824 69.2623C98.9159 67.7845 100.012 66.0948 101.375 65.3438C102.671 67.4575 102.883 70.6008 102.877 72.5025C101.266 72.1634 99.7335 71.4548 98.5889 70.0739C99.1158 69.2078 100.769 68.1297 100.769 68.1297Z" fill="#FFB49E" stroke="#282750" stroke-miterlimit="10" />
    <path d="M110.096 61.3398C109.163 63.9865 107.122 65.9307 106.038 65.2281C104.954 64.5256 105.668 64.5377 104.59 64.8768C103.512 65.216 103.403 64.4953 103.403 64.4953C103.403 64.4953 106.958 62.8055 106.995 62.7934C107.031 62.7752 107.582 62.4845 107.582 62.4845" fill="#282750" />
    <path d="M99.4668 68.9728C99.376 69.0394 99.6667 59.4883 99.6667 59.4883L101.157 59.6397L100.551 68.2823L99.4668 68.9728Z" fill="#FFCF47" stroke="#282750" stroke-miterlimit="10" />
    <path d="M99.5391 71.0918C99.6057 71.2129 100.29 73.0904 100.332 73.0359C100.375 72.9814 100.672 71.867 100.672 71.867" fill="#282750" />
    <path d="M157.704 42.719C156.766 35.8993 155.312 31.7991 153.653 26.2089C150.261 14.7864 140.195 11.7278 132.322 15.5858C124.448 19.4438 126.32 29.8852 126.32 29.8852C126.32 29.8852 122.928 32.108 116.108 32.114L124.733 47.7277C124.733 47.7277 126.411 51.725 121.753 58.4114C117.096 65.0977 136.882 69.8218 143.326 60.4585C149.764 51.089 158.776 50.5379 158.365 49.3993C157.941 48.2546 158.207 45.8804 157.704 42.719Z" fill="white" />
    <path d="M133.406 124.069C133.406 124.069 132.94 126.546 132.019 128.115C129.675 126.455 129.058 126.279 129.058 126.279L127.192 128.375C127.192 128.375 123.153 125.413 121.893 122.567L117.205 109.412L133.497 114.263L133.406 124.069Z" fill="#FFB49E" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M122.044 122.846C121.996 122.749 121.941 122.658 121.899 122.561L117.211 109.406L133.503 114.258L133.412 123.506C128.064 123.494 124.412 123.179 122.044 122.846Z" fill="white" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M162.616 123.34C162.616 123.34 162.35 126.653 161.568 128.373C160.121 127.38 158.14 126.277 158.14 126.277L156.408 128.373C156.408 128.373 151.599 126.568 149.819 123.982L134.956 111.996L160.66 113.783L162.616 123.34Z" fill="#FFB49E" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M150.025 124.23C149.959 124.146 149.886 124.067 149.826 123.976L134.963 111.99L160.782 113.698L162.544 122.777C156.7 123.752 152.66 124.115 150.025 124.23Z" fill="white" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M144.477 26.9815C140.31 30.6094 136.149 26.8543 136.149 26.8543C136.149 26.8543 137.882 22.5784 140.28 22.9176C142.642 23.4324 144.483 26.3032 144.483 26.3032V26.9815H144.477Z" fill="white" />
    <path d="M144.404 39.1641C143.987 43.6216 140.219 47.843 136.149 47.4918" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M145.834 39.8527C145.01 38.9806 142.963 39.4893 142.963 39.4893" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M150.408 35.5705C150.794 34.721 149.663 33.3758 147.882 32.5659C146.101 31.756 144.343 31.7881 143.957 32.6376C143.571 33.4871 144.702 34.8323 146.483 35.6423C148.264 36.4522 150.021 36.42 150.408 35.5705Z" fill="#FFB49E" />
    <path d="M144.993 26.4452C144.993 26.4452 143.309 23.4352 141.498 22.5691C139.687 21.703 137.458 22.9022 136.156 26.8571C138.094 23.6956 140.402 21.1095 143.757 26.0031C144.078 26.4755 144.563 26.845 144.981 26.4574L144.993 26.4452Z" fill="#282750" />
    <path d="M139.523 27.6186C140.393 27.6186 141.098 26.5611 141.098 25.2566C141.098 23.9521 140.393 22.8945 139.523 22.8945C138.653 22.8945 137.948 23.9521 137.948 25.2566C137.948 26.5611 138.653 27.6186 139.523 27.6186Z" fill="#282750" />
    <path d="M138.373 25.1011C138.604 25.1011 138.791 24.8625 138.791 24.5681C138.791 24.2738 138.604 24.0352 138.373 24.0352C138.142 24.0352 137.955 24.2738 137.955 24.5681C137.955 24.8625 138.142 25.1011 138.373 25.1011Z" fill="white" />
    <path d="M128.12 28.2191C131.287 29.9755 133.274 26.6747 133.274 26.6747C133.274 26.6747 131.517 24.0522 130.082 24.7063C128.695 25.4695 128.011 27.7588 128.011 27.7588L128.12 28.2191Z" fill="white" />
    <path d="M127.961 27.9334C127.961 27.9334 128.433 25.7046 129.324 24.7961C130.214 23.8876 131.952 24.5902 133.272 27.2793C131.492 24.9839 129.784 23.5364 128.579 28.3634L127.961 27.9334Z" fill="#282750" />
    <path d="M130.247 27.9471C130.802 27.8831 131.166 27.0904 131.061 26.1766C130.955 25.2629 130.42 24.574 129.865 24.638C129.31 24.702 128.946 25.4947 129.051 26.4085C129.157 27.3222 129.692 28.0111 130.247 27.9471Z" fill="#282750" />
    <path d="M129.324 24.7965C130.214 23.8881 131.728 24.2696 133.273 27.2797C131.492 24.9843 129.784 23.5368 128.579 28.3638" fill="#282750" />
    <path d="M131.39 16.3485C127.314 16.7846 127.853 14.465 125.563 15.2523C124.17 15.7308 123.437 19.401 128.392 19.401" fill="#282750" />
    <path d="M138.384 16.9762C137.125 16.3463 136.168 17.2487 137.082 18.1148C137.997 18.9809 140.395 18.5448 141.497 20.7918C141.298 18.5933 138.384 16.9762 138.384 16.9762Z" fill="#282750" />
    <path d="M131.281 18.443C132.244 17.9585 132.976 18.655 132.28 19.3151C131.583 19.9753 129.742 19.6482 128.9 21.3622C129.052 19.6785 131.281 18.443 131.281 18.443Z" fill="#282750" />
    <path d="M143.884 24.791C144.986 26.0932 146.028 25.2574 146.028 25.2574C146.028 25.2574 145.604 26.1295 144.968 26.4566C144.332 26.7836 143.884 25.2937 143.884 25.2937" fill="#282750" />
    <path d="M145.362 25.0103C144.538 25.5856 143.957 25.5069 143.957 25.5069L143.091 24.0352C143.097 24.0352 143.908 25.5129 145.362 25.0103Z" fill="#282750" />
    <path d="M128.578 28.1403C128.306 28.6672 127.434 27.7467 127.434 27.7467C127.434 27.7467 127.7 27.8072 128.118 27.3651C128.536 26.923 128.354 27.4741 128.354 27.4741" fill="#282750" />
    <path d="M113.535 70.4563C112.208 68.5061 111.47 65.8776 112.711 62.7827L125.702 49.6765C125.702 49.6765 118.871 65.4658 128.591 60.56C137.107 56.2599 155.325 36.4733 159.304 48.6347C163.925 68.7484 160.654 68.3729 155.161 86.7241C152.442 95.821 159.667 105.414 161.163 115.662C149.541 118.624 124.915 120.307 119.773 115.541C116.714 110.841 106.37 93.3862 113.535 70.4563Z" fill="#FFCF47" />
    <path d="M159.304 48.6347C155.325 36.4733 137.107 56.2599 128.591 60.56C118.877 65.4658 125.702 49.6765 125.702 49.6765L124.927 50.9847C124.927 50.9847 118.095 66.774 127.816 61.8682C136.332 57.5681 154.55 37.7815 158.529 49.9429C163.15 70.0566 157.772 69.348 153.974 88.1231C152.26 96.578 158.91 106.141 160.237 115.995C160.545 115.922 160.854 115.735 161.157 115.662C159.661 105.414 152.436 95.821 155.155 86.7241C160.654 68.3668 163.925 68.7484 159.304 48.6347Z" fill="white" />
    <path d="M113.535 70.4563C112.208 68.5061 111.47 65.8776 112.711 62.7827L125.702 49.6765C125.702 49.6765 118.871 65.4658 128.591 60.56C137.107 56.2599 155.325 36.4733 159.304 48.6347C163.925 68.7484 159.171 67.9913 155.161 86.7241C153.32 95.3122 160.551 108.727 161.163 115.662C154.87 118.527 124.915 120.307 119.773 115.541C116.714 110.841 106.37 93.3862 113.535 70.4563Z" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M116.672 31.8953C123.491 31.8892 126.883 29.6665 126.883 29.6665C126.883 29.6665 125.018 19.2251 132.885 15.3671C140.753 11.5091 150.825 14.5676 154.216 25.9902C155.876 31.5864 157.081 37.0372 157.85 46.0433" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M140.42 37.3258C138.87 48.5061 132.389 50.8137 129.452 49.8143C126.514 48.815 120.742 49.1421 118.592 50.0263C116.442 50.9106 114.922 48.7605 115.128 44.4059C115.34 40.0513 112.397 37.7922 113.971 33.704C115.546 29.6098 119.428 32.6018 124.31 32.3898C129.191 32.1778 132.444 29.1374 135.853 29.5008C139.263 29.8703 141.02 32.9591 140.42 37.3258Z" fill="#FFB49E" />
    <path d="M135.853 29.5076C132.443 29.1382 129.191 32.1846 124.31 32.3966C119.882 32.5843 116.273 30.1435 114.468 32.7478C116.981 30.8582 119.489 33.5533 123.05 33.4019C127.931 33.19 132.28 30.2102 135.69 30.5796C139.1 30.949 139.76 33.9773 139.16 38.338C138.433 43.5829 136.622 46.8716 134.6 48.7855C137.095 47.132 139.542 43.6495 140.42 37.3326C141.019 32.9598 139.263 29.871 135.853 29.5076Z" fill="white" />
    <path d="M140.42 37.3258C138.87 48.5061 132.389 50.8137 129.452 49.8143C126.514 48.815 120.742 49.1421 118.592 50.0263C116.442 50.9106 114.922 48.7605 115.128 44.4059C115.34 40.0513 112.397 37.7922 113.971 33.704C115.546 29.6098 119.428 32.6018 124.31 32.3898C129.191 32.1778 132.444 29.1374 135.853 29.5008C139.263 29.8703 141.02 32.9591 140.42 37.3258Z" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M131.195 34.8978C127.822 37.4294 126.429 36.7208 125.993 37.6716C125.557 38.6286 125.339 40.6393 129.379 39.5128C133.412 38.3924 133.594 34.4556 131.195 34.8978Z" fill="#282750" />
    <path d="M118.702 35.2089C120.658 37.7223 121.809 37.4801 121.936 38.2614C122.069 39.0426 121.827 40.5507 119.107 38.9336C116.394 37.3105 117.048 34.4094 118.702 35.2089Z" fill="#282750" />
    <path d="M144.865 16.0819C149.299 15.7488 148.251 10.522 151.921 12.0422C155.276 13.4291 154.428 18.1896 148.741 18.4076" fill="white" />
    <path d="M144.865 16.0819C149.299 15.7488 148.251 10.522 151.921 12.0422C155.276 13.4291 154.428 18.1896 148.741 18.4076" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M146.815 17.1103C148.844 16.3654 150.969 13.9004 150.969 13.9004C150.969 13.9004 148.686 16.8802 147.644 17.2375C146.597 17.6009 146.815 17.1103 146.815 17.1103Z" fill="#282750" />
    <path d="M144.568 17.1291C150.376 12.0477 146.972 6.02148 146.972 6.02148C147.027 10.0188 144.907 13.6345 141.764 14.9124C142.066 16.8747 144.568 17.1291 144.568 17.1291Z" fill="#282750" />
    <path d="M133.697 14.7368C131.868 13.471 131.25 10.6305 132.128 8.54102C132.128 8.54102 128.597 11.8055 131.613 15.9118C132.595 15.3728 133.697 14.7368 133.697 14.7368Z" fill="#282750" />
    <path d="M157.73 44.8185C155.731 43.3953 152.951 42.7654 153.369 42.0325C153.787 41.2997 155.495 40.7001 155.416 39.1618C155.338 37.6234 154.92 37.8354 154.944 37.0238C154.968 36.2122 156.476 35.752 156.476 35.752" fill="#282750" />
    <path d="M139.257 53.0017C129.833 58.289 124.285 56.8778 124.854 51.9902" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M126.968 58.6189C127.364 57.5887 127.207 56.5695 126.617 56.3425C126.027 56.1155 125.227 56.7666 124.831 57.7968C124.435 58.827 124.592 59.8461 125.182 60.0732C125.772 60.3002 126.571 59.6491 126.968 58.6189Z" fill="#282750" />
    <path d="M137.028 72.0254C134.466 75.3262 130.215 74.9689 128.664 74.2966" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M133.236 104.035C124.757 98.2688 122.377 91.0918 122.377 91.0918" stroke="#282750" stroke-miterlimit="10" stroke-linejoin="round" />
    <path d="M161.071 115.76C156.753 116.692 146.754 118.152 146.754 118.152L151.054 119.69L161.071 115.76Z" fill="#282750" />
    <path d="M133.449 120.012C124.473 120.454 121.045 116.457 121.045 116.457C121.045 116.457 127.525 119.189 133.449 118.607C133.346 119.261 133.449 120.012 133.449 120.012Z" fill="#282750" />
    <path d="M129.3 26.3036C129.45 26.2883 129.554 26.1087 129.533 25.9024C129.512 25.6961 129.374 25.5412 129.224 25.5564C129.075 25.5716 128.97 25.7512 128.991 25.9576C129.012 26.1639 129.151 26.3188 129.3 26.3036Z" fill="white" />
    <path d="M96.1699 73.5195C96.1699 73.5195 96.9028 76.4812 96.9028 76.5357C96.9028 76.5902 97.1874 73.5195 97.1874 73.5195L97.8597 74.7187C97.8597 74.7187 98.6955 72.8351 98.7379 72.8654C98.7742 72.8897 99.2769 73.7012 99.2769 73.7012L99.7554 73.035C99.7554 73.035 99.5676 73.3984 99.9128 73.144C100.258 72.8897 100.307 72.8594 100.307 72.8594" stroke="#282750" stroke-miterlimit="10" />
    <path d="M87.3951 75.1683L92.1555 72.4852L91.0048 71.5586L86.208 73.963L87.3951 75.1683Z" fill="#FFB49E" />
    <path d="M131.104 112.227C131.104 112.227 129.451 99.3331 124.315 95.5659C119.179 91.7988 110.815 92.8829 110.033 100.647C109.361 105.038 106.569 117.497 106.684 117.745C106.799 117.993 94.1594 125.612 94.1594 125.612C94.1594 125.612 87.0006 124.383 87.1096 128.126C89.1083 128.126 108.15 128.126 108.15 128.126L124.539 120.04" fill="#282750" />
    <path d="M121.347 116.568C125.053 112.631 125.344 110.784 124.768 106.629C124.193 102.475 118.664 94.3709 114.666 96.7693C112.268 95.7397 110.027 98.9618 110.027 103.716C110.027 108.47 104.964 109.724 103.71 113.092C102.456 116.459 102.971 121.141 106.448 124.223" fill="white" />
    <path d="M121.347 116.568C125.053 112.631 125.344 110.784 124.768 106.629C124.193 102.475 118.664 94.3709 114.666 96.7693C112.268 95.7397 110.027 98.9618 110.027 103.716C110.027 108.47 104.964 109.724 103.71 113.092C102.456 116.459 102.971 121.141 106.448 124.223" stroke="#282750" stroke-miterlimit="10" />
    <path d="M119.155 104.609C115.636 114.572 111.069 114.693 112.329 109.703C115.933 106.584 117.247 105.609 117.247 105.609L119.155 104.609Z" fill="#282750" />
    <path d="M90.3984 126.749C90.3984 124.266 94.7955 123.982 97.2181 124.381C105.122 121.529 130.456 110.688 130.571 110.972C130.686 111.257 134.132 113.159 133.805 113.746C133.551 114.206 123.842 122.05 119.67 125.411C118.452 126.392 116.938 126.919 115.382 126.907L90.3984 126.749Z" fill="#282750" />
    <path d="M143.745 128.907C148.772 128.907 152.847 125.434 152.847 121.149C152.847 116.864 148.772 113.391 143.745 113.391C138.717 113.391 134.642 116.864 134.642 121.149C134.642 125.434 138.717 128.907 143.745 128.907Z" fill="#282750" />
    <path d="M139.22 114.545C136.525 115.908 130.923 111.723 130.923 111.723L106.679 128.166H140.407L139.087 117.476" fill="#282750" />
    <path d="M111.978 102.01C103.632 103.306 103.384 108.291 105.176 109.963C106.969 111.634 110.009 111.931 112.281 110.047C114.552 108.164 113.746 101.738 111.978 102.01Z" fill="white" />
    <path d="M106.903 107.061C107.973 107.061 108.841 106.51 108.841 105.831C108.841 105.152 107.973 104.602 106.903 104.602C105.833 104.602 104.965 105.152 104.965 105.831C104.965 106.51 105.833 107.061 106.903 107.061Z" fill="#282750" />
    <path d="M106.582 105.572C108.357 119.454 113.674 105.784 119.918 104.379" stroke="#282750" stroke-miterlimit="10" />
    <path d="M116.835 102.422C117.307 102.422 117.689 101.725 117.689 100.865C117.689 100.005 117.307 99.3086 116.835 99.3086C116.364 99.3086 115.981 100.005 115.981 100.865C115.981 101.725 116.364 102.422 116.835 102.422Z" fill="#282750" />
    <path d="M112.844 102.003C113.279 102.003 113.631 101.363 113.631 100.574C113.631 99.7845 113.279 99.1445 112.844 99.1445C112.409 99.1445 112.057 99.7845 112.057 100.574C112.057 101.363 112.409 102.003 112.844 102.003Z" fill="#282750" />
    <path d="M107.787 110.422C106.479 110.846 105.897 110.331 105.897 110.331C105.897 110.331 102.524 108.671 105.334 104.898C108.144 101.125 114.043 102.754 114.043 102.754" stroke="#282750" stroke-miterlimit="10" />
    <path d="M116.575 100.725C116.762 100.725 116.914 100.497 116.914 100.216C116.914 99.9348 116.762 99.707 116.575 99.707C116.387 99.707 116.235 99.9348 116.235 100.216C116.235 100.497 116.387 100.725 116.575 100.725Z" fill="white" />
    <path d="M112.674 100.404C112.861 100.404 113.013 100.176 113.013 99.8955C113.013 99.6145 112.861 99.3867 112.674 99.3867C112.487 99.3867 112.335 99.6145 112.335 99.8955C112.335 100.176 112.487 100.404 112.674 100.404Z" fill="white" />
    <path d="M128.39 121.146L120.087 127.615C119.287 128.239 118.294 128.578 117.283 128.572L110.548 128.535" stroke="white" stroke-miterlimit="10" />
    <path d="M119.543 94.681C119.664 89.424 119.931 86.2201 120.736 86.0384C121.536 85.8628 126.659 90.1811 126.126 92.4098C125.593 94.6386 124.437 96.8614 124.437 96.8614" fill="#282750" />
    <path d="M116.914 93.3737C116.841 89.1705 116.684 86.6086 116.211 86.4693C115.739 86.3239 112.723 89.7822 113.038 91.5628C113.353 93.3434 113.371 95.6328 113.371 95.6328" fill="#282750" />
    <path d="M120.735 86.0443C121.534 85.8686 126.658 90.1869 126.125 92.4157C125.641 94.4446 123.66 95.7952 123.485 96.1405C123.406 96.1283 121.613 95.6135 121.613 95.6135C121.377 93.6209 120.262 91.2286 120.462 86.2502C120.547 86.1351 120.638 86.0624 120.735 86.0443Z" fill="white" stroke="#282750" stroke-miterlimit="10" />
    <path d="M120.839 95.3659C122.317 93.228 122.377 91.084 122.377 91.084" stroke="#282750" stroke-miterlimit="10" />
    <path d="M148.711 119.435C148.941 118.981 158.983 115.668 155.785 106.595C152.587 97.5225 141.921 99.7452 139.408 103.228C140.377 103.173 140.377 103.173 140.377 103.173C140.377 103.173 137.179 105.275 136.955 109.817C142.66 105.796 148.765 106.656 148.711 119.435Z" fill="#282750" stroke="#282750" stroke-miterlimit="10" />
    <path d="M145.482 100.35C147.239 101.149 147.366 101.361 147.196 101.361L145.482 100.35Z" fill="#282750" />
    <path d="M136.955 109.818C137.185 105.275 140.377 103.174 140.377 103.174C140.377 103.174 140.377 103.174 139.408 103.228C140.413 101.835 142.733 100.642 145.349 100.297C146.149 101.242 144.526 102.271 144.526 102.271C144.526 102.271 152.272 104.058 146.016 109.133C143.714 106.802 140.268 107.48 136.955 109.818Z" fill="white" stroke="#282750" stroke-miterlimit="10" />
    <path d="M115.637 86.8008C115.582 86.8674 115.401 92.0033 113.674 93.7112C113.553 93.8324 113.432 93.9172 113.305 93.9898C113.257 93.2449 113.178 92.3485 113.038 91.5551C112.79 90.144 114.625 87.6911 115.637 86.8008Z" fill="white" stroke="#282750" stroke-miterlimit="10" />
    <path d="M156.591 81.5871L156.688 81.3873L149.614 74.3496" fill="#282750" />
    <path d="M136.55 85.7471L134.073 90.3743C133.733 91.0041 133.976 91.7915 134.606 92.1307L142.751 96.4913C143.381 96.8305 144.169 96.5882 144.508 95.9584L146.985 91.3312C147.324 90.7013 147.082 89.914 146.452 89.5748L138.306 85.2141C137.67 84.875 136.889 85.1172 136.55 85.7471Z" fill="#282750" />
    <path d="M135.44 89.4719L134.746 90.7695L145.004 96.2587L145.699 94.9611L135.44 89.4719Z" fill="#282750" />
    <path d="M138.312 88.4853C138.834 88.4853 139.257 88.0623 139.257 87.5405C139.257 87.0187 138.834 86.5957 138.312 86.5957C137.79 86.5957 137.367 87.0187 137.367 87.5405C137.367 88.0623 137.79 88.4853 138.312 88.4853Z" fill="#282750" />
    <path d="M142.825 82.1607L141.668 83.5779L146.737 90.3067C146.737 90.3067 149.19 89.804 150.486 87.1089C151.782 84.4137 146.822 80.4043 146.822 80.4043L142.825 82.1607Z" fill="#FFB49E" stroke="#282750" stroke-miterlimit="10" />
    <path d="M137.847 85.2034L135.369 89.8305C135.03 90.4604 135.273 91.2478 135.902 91.5869L144.048 95.9476C144.678 96.2868 145.466 96.0445 145.805 95.4146L148.282 90.7875C148.621 90.1576 148.379 89.3702 147.749 89.0311L139.603 84.6704C138.973 84.3312 138.186 84.5674 137.847 85.2034Z" fill="white" stroke="#282750" stroke-miterlimit="10" />
    <path d="M145.133 90.6914L146.592 91.4727" stroke="#282750" stroke-miterlimit="10" />
    <path d="M147.288 90.1709L144.642 88.7598" stroke="#282750" stroke-miterlimit="10" />
    <path d="M136.31 88.6985L135.615 89.9961L145.12 95.0824L145.815 93.7847L136.31 88.6985Z" fill="#282750" />
    <path d="M139.179 87.7119C139.701 87.7119 140.124 87.2889 140.124 86.7671C140.124 86.2453 139.701 85.8223 139.179 85.8223C138.657 85.8223 138.234 86.2453 138.234 86.7671C138.234 87.2889 138.657 87.7119 139.179 87.7119Z" fill="#FFCF47" />
    <path d="M144.945 87.2118C144.945 87.2118 141.784 88.6291 141.003 87.9931C139.888 84.9285 142.305 82.8633 142.305 82.8633" fill="#FFB49E" />
    <path d="M144.945 87.2118C144.945 87.2118 141.784 88.6291 141.003 87.9931C139.888 84.9285 142.305 82.8633 142.305 82.8633" stroke="#282750" stroke-miterlimit="10" />
    <path d="M146.852 69.7246C147.373 75.8659 142.909 82.1222 142.909 82.1222L150.256 87.5246C150.256 87.5246 161.878 79.0334 162.211 66.9446C161.902 52.73 157.045 47.6304 152.951 48.4299C147.712 49.4534 138.748 58.6351 147.524 70.1546" fill="white" />
    <path d="M146.852 69.7246C147.373 75.8659 142.909 82.1222 142.909 82.1222L150.256 87.5246C150.256 87.5246 161.878 79.0334 162.211 66.9446C161.902 52.73 157.045 47.6304 152.951 48.4299C147.712 49.4534 138.748 58.6351 147.524 70.1546" stroke="#282750" stroke-miterlimit="10" />
    <path d="M146.805 73.4165C146.805 73.4165 151.971 72.6958 150.384 70.7214C148.797 68.7469 149.379 69.9885 149.264 67.7476C149.149 65.5067 148.155 64.9253 147.417 65.8943C146.678 66.8634 145.69 67.3176 145.69 67.3176C145.69 67.3176 147.138 70.0551 147.18 70.0854C147.217 70.1096 146.871 71.5814 146.871 71.5814L146.805 73.4165Z" fill="#282750" />
    <path d="M151.738 48.7793C150.533 51.8621 154.785 50.6992 155.893 53.146C157.002 55.5929 154.809 56.1622 156.384 57.7308C157.958 59.3055 161.126 58.4031 161.126 58.4031L160.654 55.1629L158.921 51.6925C158.921 51.6925 156.681 49.3607 156.608 49.3123C156.535 49.2578 153.574 48.1191 153.574 48.1191L152.156 48.3372" fill="#282750" />
    <path d="M145.514 66.9844C146.713 70.915 143.473 81.417 143.473 81.417L145.823 76.378L146.313 74.0402L146.725 70.0732L146.804 69.1405" fill="#282750" />
    <path d="M115.467 58.8213C115.455 58.8394 115.443 58.8637 115.419 58.8939C113.16 62.0615 111.712 65.7378 111.609 69.6261C111.561 71.3582 111.537 72.7209 111.573 72.733C111.676 72.7512 112.936 72.9814 112.936 72.9814L113.505 70.2559C113.505 70.2559 112.203 67.3367 112.179 67.1913C112.155 67.046 112.342 63.8663 112.354 63.83C112.361 63.7997 113.033 62.97 113.033 62.97C113.033 62.97 117.163 58.2822 117.121 58.3186C117.079 58.3549 116.364 58.4215 116.364 58.4215" fill="#282750" />
    <path d="M0 128.375H197.006" stroke="#282750" stroke-miterlimit="10" />
    <path d="M40.2568 74.0555C44.0664 80.6873 59.4136 89.8024 72.6288 84.4848C64.3254 85.0965 64.4102 84.933 64.4102 84.933C64.4102 84.933 51.8126 82.2257 51.5764 81.9774C51.3402 81.7291 47.1734 79.0824 47.0462 78.9794C46.919 78.8765 41.1169 73.3287 41.1835 73.044C41.2501 72.7594 40.7656 71.9842 40.7535 72.0689" fill="#282750" />
    <path d="M65.5068 108.86C65.5068 108.86 71.8601 106.317 73.7195 103.01C69.1044 106.274 65.5068 107.558 65.5068 107.558V108.109" fill="#282750" />
  </svg>
)

const ConnectModal = () => {
  const {
    matches,
    handleClose,
  } = useQuoteBoardLogic()

  const partnerInfo = useAppSelector(({ app }) => app.partnerInfo)

  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type='closed'
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>
      <StyledDialogContent>
        <Svg className='first-svg' />
        <div>
          <StyledModalTitle
            component="h3"
            variant="h1"
            label="You are now connected to the moving company you selected!"
            textAlign="center"
          />
          <Content>
            <span>Here’s your Moo’ver’s contact info:</span>
            <b>{partnerInfo?.companyName || ''}</b>
            <b>{partnerInfo?.email || ''}</b>
            <b>{formatPhoneNumber(partnerInfo?.phone || "") || ''}</b>
            <p>Please check your email. Someone on their team will be reaching out to you shortly.  If you have any questions or need to speak to someone on our team, please email us at <b>support@moomovesyou.com</b>.</p>
          </Content>
        </div>
        <Svg className='second-svg' />
      </StyledDialogContent>
    </StyledModal >
  );
};

export default ConnectModal;
