import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';

import { COLORS } from '@utils/colors';

export const StyledFooter = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '60px 70px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '42px 20px 22px 20px',
    background: COLORS.beige,
  },
}));

export const FooterDescription = styled(Typography)(({ theme }) => ({
  maxWidth: 732,
  fontWeight: 300,
  fontSize: 16,
  lineHeight: '125%',
  color: COLORS.grey['50'],

  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
    width: '100%',
    marginBottom: 42,
    fontSize: 14,
    lineHeight: '130%',
  },
}));

export const FooterLinksStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: 51,
}));

export const FooterLinks = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: 51,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 7,
  },
}));

export const FooterLink = styled(Typography)(() => ({
  color: COLORS.grey['50'],
  cursor: 'pointer',
}));
