import { UseFormSetError } from 'react-hook-form/dist/types/form';

export const errorHandler = (error: any, setError?: UseFormSetError<any>) => {
  console.log('Errors: ', error, Object.entries(error));

  try {
    // Handle nested errors object
    if (error?.data?.errors) {
      Object.entries(error.data.errors).forEach(([field, messageArray]) => {
        // Map the backend field name to the form field name
        const formFieldName =
          field === 'textMessagesPhone' ? 'phoneNumberForTexts' : field;

        // Check if there are any messages in the array
        if (Array.isArray(messageArray) && messageArray.length > 0) {
          // Customize the error message
          const errorMessage = messageArray
            .map(message =>
              message.replace(/textMessagesPhone/gi, 'phone number'),
            )
            .join(', '); // Concatenate multiple messages if needed

          // Set the error using the form field name
          setError?.(formFieldName, {
            type: 'server',
            message: errorMessage,
          });
        }
      });
      return '';
    }

    // Handle other specific error codes
    const specificErrorMessage = getSpecificErrorMessage(error?.data?.error);
    if (specificErrorMessage) {
      return specificErrorMessage;
    }

    return 'Something went wrong.';
  } catch {
    return 'Something went wrong.';
  }
};

// Helper function to handle specific error codes
const getSpecificErrorMessage = (errorCode: string | undefined) => {
  const errorMessages: { [key: string]: string } = {
    DOT_ALREADY_EXISTS: 'DOT Already Exists',
    RESOURCE_NOT_FOUND: 'Invalid Credential',
    EMAIL_ALREADY_EXISTS: 'User Email Already Exists',
    PHONE_ALREADY_EXISTS: 'User Phone Number Already Exists',
    INVALID_CREDENTIALS: 'Invalid Credentials',
    DISTANCE_CALCULATION_FAILED:
      'Moving is not available for the mentioned locations',
  };

  return errorCode ? errorMessages[errorCode] : undefined;
};
