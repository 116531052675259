import { StyledCheckbox, Wrapper } from './styled';
import { ReactNode } from 'react';

interface ICustomCheckbox {
  title?: ReactNode;
  active: boolean;
  onClick?: () => void;
  className?: string;
}

const CustomCheckbox = ({
  title,
  active,
  onClick,
  ...rest
}: ICustomCheckbox) => {
  if (!active) {
    return (
      <Wrapper onClick={onClick} {...rest}>
        <StyledCheckbox />
        {title}
      </Wrapper>
    );
  }

  return (
    <Wrapper onClick={onClick} {...rest}>
      <svg
        fill="none"
        width="16"
        height="16"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.5"
          y="0.5"
          width="17"
          height="17"
          rx="4.5"
          fill="#A6E6A9"
          stroke="#282750"
        />
        <path
          d="M4 9L7.75 13L14 5"
          stroke="#282750"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {title}
    </Wrapper>
  );
};

export default CustomCheckbox;
