import { Helmet } from 'react-helmet';

import ApplyNowSection from '../ApplyNowSection';
import PromotionSection from '../PromotionSection/index';
import JobSection from '../JobSection';
import PriceSection from '../PriceSection';
import FooterSection from '../FooterSection';
import { PromoAndJobBox, StyledTruckerLanding, DownBackground } from './styled';

const Content = () => {
  return (
    <StyledTruckerLanding>
      <Helmet>
        <title>Trucker Partner | The Easiest Way to Find Trucking Jobs</title>
        <meta name="keywords" content="Trucker landing, Trucking services, Truck jobs, Trucker Partner" />
        <meta
          name="description"
          content="Join the Moo Trucker Partner program and make money with premium CPM and CPCF rates.
              Access thousands of confirmed jobs and manage your progress and payouts."
        />
      </Helmet>
      <ApplyNowSection />
      <PromoAndJobBox>
        <PromotionSection />
        <JobSection />
      </PromoAndJobBox>
      <DownBackground>
        <PriceSection />
        <FooterSection />
      </DownBackground>
    </StyledTruckerLanding>
  );
};

export default Content;
