import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { PartnerRegistrationFormValues } from '@modules/truckerBecomePartner/types';
import { StyledTextField } from '@modules/truckerBecomePartner/styled';
import { CustomErrorMessage, AdditionalWrapper } from '../../styled';
import CustomCheckbox from '@components/CustomCheckbox';

interface FieldWithOptionalCheckboxProps {
  type: string;
  placeholder: string;
  fieldName: keyof PartnerRegistrationFormValues;
  checkboxLabel: string;
  checkboxName: keyof PartnerRegistrationFormValues;
  className?: string;
}

const ToggleableInputField = ({
  type,
  placeholder,
  fieldName,
  checkboxLabel,
  checkboxName,
  className = '',
}: FieldWithOptionalCheckboxProps) => {
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const isCheckboxActive = watch(checkboxName);

  const handleCheckboxChange = (checked: boolean) => {
    setValue(checkboxName, checked);
    if (!checked) {
      setValue(fieldName, '');
    }
  };

  return (
    <AdditionalWrapper className={className}>
      <StyledTextField
        type={type}
        autoComplete="off"
        placeholder={placeholder}
        {...register(fieldName)}
        inputProps={{
          onWheel: event => event.currentTarget.blur(),
        }}
      />
      <CustomCheckbox
        checked={isCheckboxActive}
        onCheck={handleCheckboxChange}
        label={checkboxLabel}
        alignment="top"
        containerStyles={{
          marginTop: '15px',
          gap: '12px',
        }}
        boxStyles={{
          marginTop: '4px',
        }}
      />
      {errors[fieldName] && (
        <CustomErrorMessage>
          <ErrorMessage name={fieldName} errors={errors} />
        </CustomErrorMessage>
      )}
    </AdditionalWrapper>
  );
};

export default ToggleableInputField;
