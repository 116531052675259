import { styled } from '@mui/material/styles';
import Modal from '@ui/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '671px',
    maxWidth: '100%',
    height: '500px',
    borderRadius: 20,

  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },

    '.MuiDialog-container': {
      '.MuiPaper-root': {
        'h2': {
          zIndex: '1000',
          position: 'relative'
        }
      }
    }
  },
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 100,
  height: '100%',
  padding: '0',
  borderRadius: 20,
  width: '100%',
  display: 'flex',
  gap: 0,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  'button': {
    width: '200px',
    height: '52px',
    padding: '12px 30px',
    marginTop: "32px",
    color: COLORS.darkBlue
  },

  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    zIndex: 0,
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    padding: '60px 0 0 0',
    'overflow-x': 'hidden',

    'button': {
      height: '36px',
    },
  },
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  fontWeight: 400,
  lineHeight: 'normal',
  marginTop: '62px',
  width: '100%',
  padding: '0px 100px',
  textAlign: 'left',

  [theme.breakpoints.down('sm')]: {
    marginTop: '40px',
    padding: '0 45px 0 45px',
    fontSize: 30,
    textAlign: 'left',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  maxWidth: '678px',
  width: '100%',
  padding: '20px 100px 0px 100px',
  marginBottom: 42,

  p: {
    color: COLORS.darkBlue,
    fontFamily: FontFamilyNames.PlainLight,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '130%', /* 20.8px */
    letterSpacing: '-0.32px',
    marginBottom: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 45px 0px 45px',
    p: {
      fontSize: '14px',
      letterSpacing: '-0.14px',
    }
  },
}));

export const Footer = styled(Box)(({ theme }) => ({
  background: '#FEF6EB',
  height: '183px',
  padding: '39px 100px',
  marginTop: '5px',

  p: {
    color: COLORS.darkBlue,
    fontFamily: FontFamilyNames.PlainLight,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '130%',
    letterSpacing: '-0.32px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '43px',
    height: '100%',

    p: {
      fontSize: '14px',
      letterSpacing: '-0.14px',
    },
  },
}));