import React, { FC } from 'react';
import OtpInput from 'react-otp-input';
import { StyledInput } from './styles';

interface OtpInputControllerProps {
  onFilledChange?: (isFilled: boolean, otpValue: string) => void;
  numInputs: number;
  onOtpChange: (otpValue: string) => void;
  otp: string;
  error?: boolean;
}

const OtpInputController: FC<OtpInputControllerProps> = ({
  onFilledChange,
  otp,
  onOtpChange,
  error,
  numInputs,
}) => {
  const handleOtpChange = (otpValue: string) => {
    onOtpChange(otpValue);
    onFilledChange?.(otpValue.length === numInputs, otpValue);
  };
  return (
    <OtpInput
      value={otp}
      inputType="tel"
      numInputs={numInputs}
      onChange={handleOtpChange}
      renderInput={props => {
        const isFilled = otp.length > 0;
        const { className = '', ...restProps } = props;

        return (
          <StyledInput
            error={!!error}
            className={`${className} ${isFilled ? 'filled' : ''}`}
            {...restProps}
          />
        );
      }}
    />
  );
};

export default OtpInputController;
