import { COLORS } from '@utils/colors';
import { StylesConfig } from 'react-select';

interface OptionType {
  label: string;
  value: string;
}

export const selectStyles: StylesConfig<OptionType, false> = {
  container: provided => ({
    ...provided,
    position: 'relative',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '50px',
    minHeight: '50px',
    cursor: 'pointer',
    borderColor: COLORS.grey[25],
    boxShadow: state.isFocused ? 'none' : 'default',
    '&:hover': {
      borderColor: COLORS.grey[25],
    },
    borderTopRightRadius: '25px',
    borderBottomRightRadius: '25px',
    borderBottomLeftRadius: '0',
    borderTopLeftRadius: '0',

    ...(state.selectProps.menuIsOpen && {
      transition: 'none',
      borderTopRightRadius: '25px',
      borderBottomRightRadius: '0',
    }),

    '@media (max-width: 768px)': {
      borderTopRightRadius: '25px',
      borderBottomRightRadius: '25px',
      borderBottomLeftRadius: '25px',
      borderTopLeftRadius: '25px',

      ...(state.selectProps.menuIsOpen && {
        transition: 'none',
        borderTopRightRadius: '25px',
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
      }),
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: COLORS.darkBlue,
    fontSize: '16px',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0px 17px',
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '16px',
    fontWeight: '300',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isDisabled
      ? 'none'
      : state.isSelected
        ? 'aliceblue'
        : 'default',
    color: state.isDisabled ? 'default' : 'default',
    '&:hover': {
      ...provided[':hover'],
      backgroundColor: 'aliceblue',
    },
    '&:active': {
      ...provided[':active'],
      backgroundColor: 'aliceblue',
    },
  }),
  menu: provided => ({
    ...provided,
    marginTop: '0px',
    borderRadius: '0px 0px 25px 25px',
    overflow: 'hidden',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    paddingRight: '17px',
    color: COLORS.grey[50],
    '&:hover': {
      color: COLORS.grey[50],
    },
  }),
};
