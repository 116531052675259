import { IMovesResponseData } from '../../../../../../types/moves-response.model';
import MoveDetailsSummary from '../MoveDetailsSummary';
import RenderLeads from '../RenderLeads';

import { StyledTextLikeFooter } from '@modules/profile/components/Content/components/Moves/styled';
import {
  MovesContainer,
  MoveDetailsContainer,
  PartnersSection,
  PreferredPartnersHeader,
  PartnersTitle,
  PartnersContent,
} from './styled';
import NoMove from '../NoMove';
import { useMovesData } from '@modules/profile/hooks/useMovesData';
import { MoveNavbarEnum } from '@modules/profile/types/move-navbar.enum';

interface IMoveListProps {
  activeTab: MoveNavbarEnum;
}

const MovesList = ({ activeTab }: IMoveListProps) => {
  const { moves } = useMovesData(activeTab);

  return (
    <MovesContainer>
      {moves.map(
        ({ destination, origin, mileage, house, leads, id: moveId }) => {
          if (leads.length === 0) {
            return (
              <NoMove
                origin={origin}
                destination={destination}
                house={house}
                mileage={mileage}
              />
            );
          }

          return (
            <MoveDetailsContainer key={moveId}>
              <MoveDetailsSummary
                mileage={mileage}
                house={house}
                destination={destination}
                origin={origin}
              />

              <PartnersSection>
                <PreferredPartnersHeader>
                  <PartnersTitle>
                    These are our Preferred Moo'ving Partners in your area
                  </PartnersTitle>
                  <PartnersContent>
                    Each of these moving companies has received your information
                    and job details. They will be in touch with you soon. If
                    need a quote right now, please call them using the contact
                    information below.
                  </PartnersContent>
                </PreferredPartnersHeader>

                <RenderLeads moveId={moveId} leads={leads} />

                <StyledTextLikeFooter>
                  If you requested a quote outside of normal business hours, you
                  should receive your quotes the next business day. <br />
                  If you requested a quote for an in-state move, it is your
                  responsibility to check your movers operating status within
                  your state.
                </StyledTextLikeFooter>
              </PartnersSection>
            </MoveDetailsContainer>
          );
        },
      )}
    </MovesContainer>
  );
};

export default MovesList;
