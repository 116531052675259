import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';

import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';

export const Footer = styled(Box)(({ theme }) => ({
  padding: '0 70px',
  margin: '90px auto 50px auto',

  [theme.breakpoints.down('lg')]: {
    padding: '0 20px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
    marginTop: '35px',
    marginBottom: '0',
  },
}));

export const FooterContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '14px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey['50'],
  fontSize: '16px',
  margin: '40px auto 0 auto',
  maxWidth: 723,
  textAlign: 'center',
  lineHeight: '130%',
  letterSpacing: '-0.32px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    maxWidth: '300px',
    width: '100%',
    padding: '0',
    margin: '40px auto',
    letterSpacing: '-0.14px',
  },
}));

export const FooterInfoBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 25,
    order: 2,
  },
}));

export const FooterLogo = styled('img')(({ theme }) => ({
  marginBottom: 20,
  maxWidth: '268px',
  height: '29px',

  [theme.breakpoints.down('sm')]: {
    width: '164px',
    height: '17px',
  },
}));

export const FooterInfoText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: 28,
  color: COLORS.grey['50'],

  '> div:last-child': {
    display: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    gap: 73,
    '> div:last-child': {
      display: 'block',
    },
  },
}));

export const FooterLinksBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 47,

  [theme.breakpoints.down('xl')]: {
    gap: 20,
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    gap: 32,
  },
}));

export const FooterLinks = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: 47,

  '> div': {
    gap: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },

  [theme.breakpoints.down('xl')]: {
    gap: 20,
  },

  [theme.breakpoints.down('sm')]: {
    gap: 10,
    width: 'max-content',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '30px 35px',

    ':nth-of-type(1)': {
      div: {
        ':nth-of-type(1)': {
          gridSpam: 1 / 2,
        },
        ':nth-of-type(2)': {
          gridSpam: 1 / 2,
          gridRow: 2,
        },
      },
    },
  },
}));

export const FooterBackground = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 0,
  },
}));

export const FooterLink = styled('a')(({ theme }) => ({
  color: COLORS.darkBlue,
  cursor: 'pointer',
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.28px',

  [theme.breakpoints.down('sm')]: {
    lineHeight: '125%',
  },
}));

export const FooterLine = styled('div')(() => ({
  height: 1,
  width: '100%',
  background: COLORS.grey['50'],
  marginTop: 20,
}));

export const FooterBottomText = styled('div')(({ theme }) => ({
  color: COLORS.grey['50'],
  margin: '13px 0 50px 0',
  textAlign: 'right',

  [theme.breakpoints.down('sm')]: {
    margin: '32px 0',
    fontSize: 14,
  },
}));

export const BannerBox = styled(Box)(({ theme }) => ({
  img: {
    width: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: 35,
  },
}));
