import { memo } from 'react';

import { useAppSelector } from '@app/store/hooks';
import {
  Container,
  StyledBox,
  MobileGridWrapper,
  StyledLabel,
  StyledContent,
} from './styled';
import { formatPhoneNumber } from '@utils/helpers';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ProfileView = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const userInfo = useAppSelector(({ auth }) => auth.userInfo);

  return (
    <Container>
      <MobileGridWrapper>
        <StyledBox>
          <StyledLabel>Name{matches ? '' : ':'}</StyledLabel>
          <StyledContent>{userInfo?.fullName}</StyledContent>
        </StyledBox>
        <StyledBox>
          <StyledLabel>Email Address{matches ? '' : ':'}</StyledLabel>
          <StyledContent>{userInfo?.email}</StyledContent>
        </StyledBox>
        <StyledBox>
          <StyledLabel>Phone Number{matches ? '' : ':'}</StyledLabel>
          <StyledContent>
            {formatPhoneNumber(userInfo?.phone || '')}
          </StyledContent>
        </StyledBox>
      </MobileGridWrapper>
    </Container>
  );
};

export default memo(ProfileView);
