import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledItemWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',

  '> div': {
    display: 'flex',
    gap: '16px',
    padding: '16px 0',
    maxHeight: 54,
    alignItems: 'center',
  },

  '> span': {
    display: 'block',
    height: '1px',
    width: '100%',
    opacity: '0.2',
    background: '#A09D95',
  }
}))
