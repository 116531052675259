import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const FilledStar = styled('svg')({
  fill: '#FFCF47',
});

const UnfilledStar = styled('svg')({
  fill: '#d3d3d3',
});

interface IStarRatingProps {
  rating: number;
}

const StarRating = ({ rating }: IStarRatingProps) => {
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const unfilledStars = totalStars - filledStars;

  return (
    <Box sx={{ display: 'flex', gap: '4px' }}>
      {Array(filledStars)
        .fill('')
        .map((_, index) => (
          <FilledStar key={index} width="19" height="18" viewBox="0 0 19 18">
            <path d="M9.54482 0.0898438L11.6676 6.62307H18.537L12.9795 10.6608L15.1023 17.1941L9.54482 13.1563L3.98732 17.1941L6.1101 10.6608L0.552603 6.62307H7.42205L9.54482 0.0898438Z" />
          </FilledStar>
        ))}
      {Array(unfilledStars)
        .fill('')
        .map((_, index) => (
          <UnfilledStar key={index} width="19" height="18" viewBox="0 0 19 18">
            <path d="M9.54482 0.0898438L11.6676 6.62307H18.537L12.9795 10.6608L15.1023 17.1941L9.54482 13.1563L3.98732 17.1941L6.1101 10.6608L0.552603 6.62307H7.42205L9.54482 0.0898438Z" />
          </UnfilledStar>
        ))}
    </Box>
  );
};

export default StarRating;
