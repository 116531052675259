import React, { useRef } from 'react';
import { FormProvider } from 'react-hook-form';

import { useMediaQuery } from '@mui/material';
import { useMoveRegistration } from './useMoveRegistration';
import TestimonialSwiper from './components/Swiper';
import {
  Footer,
  Process,
  Estimate,
  Services,
  Partner,
  HowMooWorksBlock,
  WhatMakesMooBlock,
} from './components';
import {
  TopTitle,
  HomePage,
  HeaderTitle,
  SubtitleText,
  HeaderTitleAndProcessContainer,
} from './styled';
import PhoneVerificationModal from '@modals/PhoneVerificationModal';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/store/hooks';
import { useTheme } from '@mui/material/styles';
import { usePhoneVerification } from '@modules/home/hooks/usePhoneVerification';
import { FooterBackground } from '@modules/home/components/Content/components/Footer/styled';
import Box from '@mui/material/Box';
import { isDebugMode } from '@utils/constants';

const Content = () => {
  const {
    phone,
    agree,
    isValid,
    levels,
    methods,
    message,
    isProcessLoading,
    setAgree,
    handleSubmit,
    createMoveSubmit,
  } = useMoveRegistration();
  const navigate = useNavigate();
  const userInfo = useAppSelector(({ auth }) => auth.userInfo);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const myRef = useRef<HTMLDivElement>(null);
  const formData = methods.getValues();

  const {
    isVerifyModalOpen,
    verificationErrorMessage,
    otp,
    isResendDisabled,
    isPhoneVerificationLoading,
    handleModalClose,
    submitMoveWithOtp,
    handleOtpChange,
    resendOtp,
    isSubmitting,
    handleGetMyQuotesClick,
  } = usePhoneVerification({
    phone,
    formData,
    navigate,
    userInfo,
    createMoveSubmit,
  });

  return (
    <HomePage ref={myRef}>
      <FormProvider {...methods}>
        <HeaderTitleAndProcessContainer>
          <HeaderTitle>
            <TopTitle
              component="h2"
              variant="h2"
              textAlign="center"
              label={
                matches
                  ? `Compare. Connect.\nBook Direct.`
                  : `Compare. Connect. Book Direct.`
              }
            />
            <SubtitleText>
              Free Local and Long-Distance Moving Quotes.
            </SubtitleText>
          </HeaderTitle>

          <Box component="form" onSubmit={handleSubmit(createMoveSubmit)}>
            {isDebugMode ? (
              <Process
                onGetMyQuotesClick={handleGetMyQuotesClick}
                agree={agree}
                setAgree={setAgree}
                isValid={isValid}
                levels={levels}
                message={message || verificationErrorMessage}
                isProcessLoading={isProcessLoading}
                isPhoneVerificationLoading={isPhoneVerificationLoading}
              />
            ) : (
              <TopTitle
                variant="h3"
                textAlign="center"
                sx={{ marginTop: '20px', fontSize: '48px' }}>
                The NEW Moo is Coming Soon! <br />
                Launching March 2024!
              </TopTitle>
            )}
          </Box>
        </HeaderTitleAndProcessContainer>

        <PhoneVerificationModal
          otp={otp}
          isSubmitting={isSubmitting}
          onOtpChange={handleOtpChange}
          errorMessage={verificationErrorMessage}
          isOpen={isVerifyModalOpen}
          onClose={handleModalClose}
          phoneNumber={phone}
          onContinueClick={submitMoveWithOtp}
          onResendOtp={resendOtp}
          isResendDisabled={isResendDisabled}
          // setIsResendDisabled={setIsResendDisabled}
        />

        <WhatMakesMooBlock />
        <HowMooWorksBlock />
        <Estimate />
        <Services />
        <TestimonialSwiper />
        <Partner />
        <FooterBackground>
          <Footer />
        </FooterBackground>
      </FormProvider>
    </HomePage>
  );
};

export default Content;
