import { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { useMediaQuery } from '@mui/material';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { errorHandler } from '@utils/errorHendler';
import { useSetNewPasswordForChangePasswordFormMutation } from '@modules/auth/api/auth.api';
import { IProfileChangePasswordState } from '../../../../types';

interface ISseChangePasswordLogic {
  setIsOpenChangePasswordView: (isOpen: boolean) => void;
}

export const useChangePasswordLogic = ({
  setIsOpenChangePasswordView,
}: ISseChangePasswordLogic) => {
  const theme = useTheme();
  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // State for controlling new password visibility
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [setNewPassword] = useSetNewPasswordForChangePasswordFormMutation();

  const {
    formState: { errors },
    reset,
    register,
    watch,
    handleSubmit,
    setError,
  } = useForm<IProfileChangePasswordState>({
    mode: 'onSubmit',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const handleRequest = async ({
    currentPassword,
    newPassword,
    confirmNewPassword,
  }: IProfileChangePasswordState) => {
    try {
      setIsLoading(true);

      await setNewPassword({
        currentPassword,
        newPassword,
        newPasswordConfirmation: confirmNewPassword,
      }).unwrap();

      setIsOpenChangePasswordView(false);

      setSuccessMessage('New password set!');
      setIsLoading(false);
      reset();
    } catch (error) {
      const handlerErrorMessage = errorHandler(error, setError);

      setErrorMessage(handlerErrorMessage);
      setIsLoading(false);
    }
  };

  const passwordInputProps = useMemo(
    () => ({
      // For new password field
      newPasswordEndAdornment: showNewPassword ? (
        <VisibilityIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowNewPassword(!showNewPassword)}
        />
      ) : (
        <VisibilityOffIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowNewPassword(!showNewPassword)}
        />
      ),
      // For confirm password field
      confirmNewPasswordEndAdornment: showConfirmPassword ? (
        <VisibilityIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
        />
      ) : (
        <VisibilityOffIcon
          cursor="pointer"
          color="action"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
        />
      ),
    }),
    [showNewPassword, showConfirmPassword],
  );

  return {
    showNewPassword,
    showConfirmPassword,
    matches,
    errors,
    isLoading,
    errorMessage,
    successMessage,
    passwordInputProps,
    setErrorMessage,
    reset,
    watch,
    register,
    handleRequest,
    handleSubmit,
  };
};
