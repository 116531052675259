import { CSSProperties, FC } from 'react';
import Typography from '@mui/material/Typography';
import { styled, Theme, SxProps } from '@mui/material/styles';
import { FontFamilyNames } from '@app/types/fonts';
import { COLORS } from '@utils/colors';
import Box from '@mui/material/Box';

type CheckboxProps = {
  checked: boolean;
  onCheck: (checked: boolean) => void;
  label: string;
  alignment?: 'center' | 'top' | 'bottom';
  containerStyles?: SxProps<Theme>;
  boxStyles?: SxProps<Theme>;
  typographyStyles?: SxProps<Theme>;
  checkIconStyles?: CSSProperties;
  onLabelClick?: () => void;
};

const CheckIcon = styled('svg')({});

const Label = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',
  color: COLORS.grey[50],
  whiteSpace: 'pre-wrap',
}));

const CheckboxContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'alignment',
})<{ alignment: 'center' | 'top' | 'bottom' }>(({ alignment }) => ({
  display: 'flex',
  alignItems:
    alignment === 'center'
      ? 'center'
      : alignment === 'top'
        ? 'flex-start'
        : 'flex-end',
  cursor: 'pointer',
}));

const CustomCheckbox: FC<CheckboxProps> = ({
  checked,
  onCheck,
  label,
  alignment = 'top',
  containerStyles,
  boxStyles,
  typographyStyles,
  checkIconStyles,
  onLabelClick,
}) => {
  const handleCheckboxChange = () => {
    onCheck(!checked);
  };

  const emptyCheckboxSVG = (
    <CheckIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      onClick={handleCheckboxChange}
      style={checkIconStyles}>
      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#A09D95" />
    </CheckIcon>
  );

  const filledCheckboxSVG = (
    <CheckIcon
      fill="none"
      width="16"
      height="16"
      viewBox="0 0 18 18"
      onClick={handleCheckboxChange}
      style={checkIconStyles}>
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="4.5"
        fill="#A6E6A9"
        stroke="#282750"
      />
      <path
        d="M4 9L7.75 13L14 5"
        stroke="#282750"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CheckIcon>
  );

  return (
    <CheckboxContainer alignment={alignment} sx={containerStyles}>
      <Box display="flex" onClick={handleCheckboxChange} sx={boxStyles}>
        {checked ? filledCheckboxSVG : emptyCheckboxSVG}
      </Box>
      <Label
        onClick={onLabelClick || handleCheckboxChange}
        variant="body1"
        sx={typographyStyles}>
        {label}
      </Label>
    </CheckboxContainer>
  );
};

export default CustomCheckbox;
