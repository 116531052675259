import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@components/Button';

import { COLORS } from '@utils/colors';
import { Box } from '@mui/material';
import { FontFamilyNames } from '@app/types/fonts';

export const Container = styled(Box)`
  //> p:nth-child(2) {
  //  padding-top: 0px
  //}
`;

export const StyledLink = styled(Typography)(() => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '300',
  lineHeight: '123.4%',
  letterSpacing: '-0.32px',
  width: '100%',
  cursor: 'pointer',
  padding: '20px 0',
  color: COLORS.darkBlue,
  borderBottom: `1px solid ${COLORS.grey['50']}`,

  svg: {
    marginRight: 5,
  },
}));

export const StyledButton = styled(Button)`
  width: 162px;
  height: 46px;
  margin-bottom: 37px;
  font-family: FontFamilyNames.MoretBook;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
