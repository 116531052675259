import { FC } from 'react';

import { DialogProps } from '@mui/material/Dialog';

import { StyledDialog } from './styled';

interface IDialogProps extends DialogProps {
}

const Modal: FC<IDialogProps> = ({
  children,
  ...restProps }) => {
  return (
    <StyledDialog {...restProps}>
      {children}
    </StyledDialog>
  );
};

export default Modal;
