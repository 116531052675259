import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';

export const AutocompleteContainer = styled(Box)({
  position: 'relative',
});

export const SuggestionsContainer = styled(Paper)({
  position: 'absolute',
  zIndex: 2,
  left: 0,
  right: 0,
  borderRight: `1px solid ${COLORS.darkBlue}`,
  borderLeft: `1px solid ${COLORS.darkBlue}`,
  borderBottom: '0',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  boxShadow: 'unset',
});

export const StyledSuggestionItem = styled(MenuItem)({
  backgroundColor: 'white',
  color: COLORS.darkBlue,
  margin: 0,
  borderBottom: `1px solid ${COLORS.darkBlue}`,
  borderRadius: '0',
  padding: '10px',
  borderRight: 0,
  borderLeft: 0,

  '&:hover': {
    backgroundColor: COLORS.beige,
    borderBottom: `1px solid ${COLORS.darkBlue}`,
    borderRight: 0,
    borderLeft: 0,
  },

  '&:first-child': {
    borderTop: '0',
  },

  '&:last-child': {
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
});
