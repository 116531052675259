import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '@utils/colors';


export const Wrapper = styled(Stack) <{ error: string }>`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 50;

  .MuiFormControl-root {
    > .MuiInputBase-root {
        height: 50px;
        padding: 11px 17px 11px 17px;
        background: ${COLORS.white};
        color: rgb(40, 39, 80);
        border: ${({ error }) => error ? 'none' : `1px solid ${COLORS.grey[25]}`};
        border-radius: 0;
        border-left: 0;

        > fieldset {
          border-radius: 0px;
          border: ${({ error }) => error ? `1px solid ${COLORS.error.main}` : 'none'};
        }

        &::after, &::before {
          display: none;
          border: none;
        }

        > svg {
          cursor: pointer;  
        }
        
        > .MuiInputBase-input {
          padding: 0;
          color: ${COLORS.black} !important;
          font-size: 16px;
          line-height: 130%;
          letter-spacing: -0.32px;


          ::placeholder {
            color: ${COLORS.darkBlue} !important;
            opacity: 1;
          }
        }
      }
  }
  

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .MuiFormControl-root {
      > .MuiInputBase-root {
        height: 46px;
        border-radius: 25px;
        border: 1px solid ${COLORS.grey[25]};
        margin-bottom: 10px;

         > .MuiInputBase-input {
          padding: 0;
          color: ${COLORS.black} !important;
          font-size: 14px;
          letter-spacing: -0.14px;


          ::placeholder {
            color: ${COLORS.darkBlue} !important;
            opacity: 1;
          }
        }
      }
    }
  }
`