import { MoveNavbarEnum } from '../../../../../../types/move-navbar.enum';
import {
  MovesNavbar,
  Panel,
  Left,
  NavbarUnderline,
  CurrentMoveTitle,
  PastMoveTitle,
  AccountMoveTitle,
} from './styled';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AddNewMoveAction } from '../../../Moves/components/AddNewMoveAction';

interface INavbarProps {
  activeTab: MoveNavbarEnum;
  onActiveTabChange: (inisActive: MoveNavbarEnum) => void;
}

const Navbar = ({ activeTab, onActiveTabChange }: INavbarProps) => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MovesNavbar>
      <Panel>
        <Left>
          <CurrentMoveTitle
            isActive={activeTab === MoveNavbarEnum.CURRENT}
            onClick={() => onActiveTabChange(MoveNavbarEnum.CURRENT)}>
            Current Moo’ves
            {/* TODO: remove QuoteBoardModal*/}
            {/*<CircleQuestion*/}
            {/*  onClick={() =>*/}
            {/*    dispatch(setPopUpName({ name: PopUpEnum.QuoteBoardModal }))*/}
            {/*  }*/}
            {/*/>*/}
          </CurrentMoveTitle>

          <PastMoveTitle
            isActive={activeTab === MoveNavbarEnum.PAST}
            onClick={() => onActiveTabChange(MoveNavbarEnum.PAST)}>
            Past Moo’ves
          </PastMoveTitle>

          <AccountMoveTitle
            isActive={activeTab === MoveNavbarEnum.ACCOUNT}
            onClick={() => onActiveTabChange(MoveNavbarEnum.ACCOUNT)}>
            My Account
          </AccountMoveTitle>
        </Left>

        {!matches && <AddNewMoveAction />}
      </Panel>

      <NavbarUnderline />
    </MovesNavbar>
  );
};

export default Navbar;
