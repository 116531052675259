import React, { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { StyledTextField } from '@modules/truckerBecomePartner/styled';
import {
  StyledFieldWrapper,
  CustomErrorMessage,
  AdditionalWrapper,
} from '../../styled';
import { TextFieldProps } from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';

interface SimpleFieldProps extends Omit<TextFieldProps, 'variant' | 'name'> {
  fieldName: string;
  loading?: boolean;
  externalOnChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const SimpleField = React.forwardRef<HTMLInputElement, SimpleFieldProps>(
  ({ fieldName, externalOnChange, loading, ...props }, ref) => {
    const { className } = props;
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const { onChange, ...restRegister } = register(fieldName);

    return (
      <StyledFieldWrapper className={className}>
        <AdditionalWrapper>
          <StyledTextField
            {...props}
            {...restRegister}
            onChange={event => {
              onChange(event);
              if (externalOnChange)
                externalOnChange(event as ChangeEvent<HTMLInputElement>);
            }}
            inputRef={ref}
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {props.InputProps?.endAdornment}
                </>
              ),
            }}
            type={props.type}
          />
          {errors[fieldName] && (
            <CustomErrorMessage>
              <ErrorMessage errors={errors} name={fieldName} />
            </CustomErrorMessage>
          )}
        </AdditionalWrapper>
      </StyledFieldWrapper>
    );
  },
);

export default SimpleField;
