import { PHONE_NUMBER_FORMAT } from '@utils/constants';

export const deletePlusFromPhone = (phone: string) => {
  return phone?.replace(PHONE_NUMBER_FORMAT, '') || phone;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '';
  }

  const newPhone = deletePlusFromPhone(phoneNumber);
  const cleaned = newPhone.replace(/\D/g, '');
  const formatted = cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

  return formatted;
};
