import { Components } from '@mui/material/styles';

export const MuiMenu: Components['MuiMenu'] = {
  styleOverrides: {
    paper: {
      marginTop: '-2px',
      padding: 0,
      maxHeight: '220px',
      background: 'transparent !important',
      boxShadow: 'none',
      transition: 'none !important',
      opacity: 1,

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
};

export const MuiList: Components['MuiList'] = {
  styleOverrides: {
    root: {
      margin: 0,
      padding: '2px 0',
      transition: 'none',
    },
  },
};

export const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      marginTop: '2px',
      color: '#fff',
      background: 'rgba(255, 255, 255, 0.05)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(32px)',
      borderRadius: '4px',

      '&:hover': {
        background: 'rgba(255, 255, 255, 0.2)',
        border: '1px solid rgba(255, 255, 255, 0.4)',
      },

      '&.Mui-selected, &.Mui-selected:hover': {
        background: 'rgba(255, 255, 255, 0.4)',
        border: '1px solid rgba(255, 255, 255, 0.8)',

        '&.Mui-disabled': {
          background: 'rgba(255, 255, 255, 0.05)',
          border: '1px solid rgba(255, 255, 255, 0.1)',

          '&.Mui-focusVisible': {
            background: 'rgba(255, 255, 255, 0.05)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          },
        },

        '&.Mui-focusVisible': {
          background: 'rgba(255, 255, 255, 0.6)',
        },
      },
    },
  },
};
