import React, { useEffect, useState } from 'react';

import { PASSWORD_REGEX } from '@utils/regex';
import { useChangePasswordLogic } from './useChangePasswordLogic';
import {
  Container,
  StyledSaveButton,
  StyledCancelButton,
  StyledPasswordContent,
  StyledPasswordActions,
} from './styled';
import PasswordInput from '@modules/profile/components/Account/components/PasswordForm/components/PasswordInput';
import ChangePasswordModal from '@modals/ChangePasswordModal';
import Typography from '@components/Typography';

interface IChangePasswordForm {
  isOpen: boolean;
  setIsOpenChangePasswordView: (isOpen: boolean) => void;
}

const ChangePasswordForm = ({
  isOpen,
  setIsOpenChangePasswordView,
}: IChangePasswordForm) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const {
    matches,
    errors,
    isLoading,
    showConfirmPassword,
    showNewPassword,
    successMessage,
    passwordInputProps,
    setErrorMessage,
    errorMessage,
    reset,
    watch,
    register,
    handleRequest,
    handleSubmit,
  } = useChangePasswordLogic({ setIsOpenChangePasswordView });

  useEffect(() => {
    if (successMessage) {
      setErrorMessage('');
      setIsSuccessModalOpen(true);
    }
  }, [successMessage]);

  if (!isOpen) {
    if (successMessage) {
      setIsOpenChangePasswordView(true);
      setErrorMessage('');
      return null;
    }

    return null;
  }

  console.log('sdafds3 passwordInputProps', {
    passwordInputProps,
  });

  return (
    <Container>
      <form onSubmit={handleSubmit(handleRequest)}>
        <StyledPasswordContent>
          <PasswordInput
            label="Current Password"
            type="password"
            placeholder="••••••••••••"
            error={errors?.currentPassword?.message}
            {...register('currentPassword', {
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters',
              },
              required: {
                value: true,
                message: 'Password is a required field',
              },
              // pattern: {
              //   value: PASSWORD_REGEX,
              //   message: 'Please enter a valid password.'
              // },
            })}
          />

          <PasswordInput
            label="New Password"
            type={showNewPassword ? 'text' : 'password'}
            placeholder="••••••••••••"
            InputProps={{
              endAdornment: passwordInputProps.newPasswordEndAdornment,
            }}
            error={errors?.newPassword?.message}
            {...register('newPassword', {
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters',
              },
              required: {
                value: true,
                message: 'Password is a required field',
              },
              pattern: {
                value: PASSWORD_REGEX,
                message:
                  'Password should contain minimum six characters, at least one uppercase letter, one lowercase letter, and one special character',
              },
            })}
          />

          <PasswordInput
            label="Confirm New Password"
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: passwordInputProps.confirmNewPasswordEndAdornment,
            }}
            placeholder="••••••••••••"
            error={errors?.confirmNewPassword?.message}
            {...register('confirmNewPassword', {
              validate: value => {
                return (
                  value === watch('newPassword') || 'The passwords do not match'
                );
              },
            })}
          />
        </StyledPasswordContent>

        <StyledPasswordActions>
          <StyledSaveButton type="submit" disabled={!!isLoading}>
            {isLoading
              ? 'Loading ...'
              : matches
                ? 'Save Changes'
                : 'Set New Password'}
          </StyledSaveButton>

          <StyledCancelButton
            type="button"
            onClick={() => {
              reset();
              setErrorMessage('');
              setIsOpenChangePasswordView(false);
            }}>
            Cancel
          </StyledCancelButton>
        </StyledPasswordActions>

        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
      </form>

      <ChangePasswordModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        title="New password set!"
        doneButtonLabel="Done"
      />
    </Container>
  );
};

export default ChangePasswordForm;
