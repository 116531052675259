import { styled } from '@mui/material/styles';
import Modal from '@ui/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';

export const StyledModal = styled(Modal)(({ theme }) => ({

  '.MuiPaper-root': {
    width: '771px',
    maxWidth: '100%',
    height: '552px',
    borderRadius: 20,

  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },

    '.MuiDialog-container': {
      '.MuiPaper-root': {
        'h2': {
          zIndex: '1000',
          position: 'relative'
        }
      }
    }
  },
}));

export const BackgroundWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',

  '.background': {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(254, 246, 235, 0.00) 0%, #FEF6EB 100%)',
  },

  [theme.breakpoints.down('sm')]: {
    '.background': {
      display: 'none'
    },
  },
}))

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 100,
  height: '100%',
  padding: '0',
  borderRadius: 20,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  'button': {
    width: '200px',
    height: '52px',
    padding: '12px 30px',
    marginTop: "32px",
    color: COLORS.darkBlue
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    zIndex: 0,
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    padding: '60px 45px 0 45px',
    'overflow-x': 'hidden',

    'button': {
      fontSize: 20,
      height: '36px',
      marginTop: "0px",
    },
  },
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 40,
  lineHeight: 'normal',
  marginBottom: 32,

  [theme.breakpoints.down('sm')]: {
    maxWidth: '240px',
    fontSize: 30,
    marginBottom: 18,
    textAlign: 'center',
  },
}));

export const StyledLeftLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, 25%)',

  [theme.breakpoints.down('sm')]: {
    top: 'unset',
    bottom: '0',
    left: '35%',
    transform: 'unset',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  maxWidth: '678px',
  margin: '0 auto',
  width: '100%',

  display: 'grid',
  gap: '32px',
  gridTemplateColumns: '1fr 38px 1fr 38px 1fr',

  '.arrows': {
    svg: {
      marginTop: '140px',
    }
  },
  '> div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',

    '.svg-wrapper': {
      height: '120px',
    },
    'p': {
      marginTop: '20px',
      color: "#282750",
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '130%',
      letterSpacing: '-0.32px',
      marginBottom: '12px',
    },
    'span': {
      color: "#282750",
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '130%',
      letterSpacing: '-0.28px',
    }
  },

  [theme.breakpoints.down('sm')]: {
    margin: '46px auto',
    gap: '36px',
    gridTemplateColumns: '1fr',
    maxWidth: '288px',

    '.arrows': {
      display: 'none',
    },
    '> div': {
      display: 'grid',
      gap: '0px 14px',
      justifyItems: 'self-start',
      gridTemplateColumns: '100px 1fr',
      textAlign: 'left',

      '.svg-wrapper': {
        height: 'max-content',
        svg: {
          width: '79px',
          height: '79px',
        }
      },
      'p': {
        marginTop: '0',
        marginBottom: '8px'
      },
      'span': {
        fontSize: '12px',
      }
    },
  },
}));