import React from 'react';
import { ButtonWrapper } from '@modules/truckerBecomePartner/styled';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '@assets/svg/close-icon.svg';

const PreviousButton = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ButtonWrapper onClick={() => navigate(PAGE_ROUTES_PUBLIC.TRUCKER_LANDING)}>
      {matches ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="11"
            viewBox="0 0 22 11"
            fill="none">
            <line
              x1="22"
              y1="5.86723"
              x2="1"
              y2="5.86723"
              stroke="#282750"
              strokeWidth="1.28916"
            />
            <path
              d="M1 5.50005C3.52778 5.59265 8.64167 6.72226 8.875 10.5"
              stroke="#282750"
              strokeWidth="1.28916"
            />
            <path
              d="M1 5.49994C3.52778 5.40735 8.64167 4.27774 8.875 0.5"
              stroke="#282750"
              strokeWidth="1.28916"
            />
          </svg>
          Previous
        </Box>
      ) : (
        <IconButton>
          <CloseIcon />
        </IconButton>
      )}
    </ButtonWrapper>
  );
};

export default PreviousButton;
