import { styled } from '@mui/material/styles';
import Modal from '@ui/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '670px',
    height: '500px',
    maxWidth: '100%',
    borderRadius: 20,
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },

    '.MuiDialog-container': {
      '.MuiPaper-root': {
        'h2': {
          zIndex: '1000',
          position: 'relative'
        }
      }
    }
  },
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 100,
  height: '100%',
  borderRadius: 20,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    zIndex: 0,
    width: '100%',
    height: '100%',
    padding: '0',
    justifyContent: 'flex-start',
  },
}));


export const TitleWrapper = styled(Box)(({ theme }) => ({
  height: '139px',
  width: '100%',
  background: '#FEF6EB',
  padding: '65px 70px 0 70px',

  [theme.breakpoints.down('sm')]: {
    padding: '65px 45px 0 45px',
  },
}))

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 40,
  lineHeight: 'normal',
  marginBottom: 24,
  textAlign: 'left',

  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
    marginBottom: 0,
    textAlign: 'left',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  width: '100%',
  padding: '30px 70px',

  '> p > b': {
    fontSize: '16px',
    lineHeight: '130%',
    letterSpacing: '-0.32px',
    fontWeight: 600,
  },
  '> p': {
    fontSize: '16px',
    lineHeight: '130%',
    letterSpacing: '-0.32px',
    fontWeight: 300,
    marginBottom: '15px'
  },

  [theme.breakpoints.down('sm')]: {
    padding: '35px 45px',

    '> p': {
      fontSize: '14px',
      letterSpacing: '-0.28px',
      '> b': {
        fontSize: '14px',
        letterSpacing: '-0.28px',
      },
    },
  },
}));