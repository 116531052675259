import contactUs1 from '@assets/svg/contactUs1.svg';
import contactUs2 from '@assets/svg/contactUs2.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';

import PhoneField from '@ui/PhoneField';
import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import { MESSAGE_REGEX, EMAIL_REGEX, NAME_REGEX } from '@utils/regex';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { StyledErrorMessage } from '@components/ErrorMessage/styled';
import { useContactUsLogic } from './useContactUsLogic';
import {
  AreaWrapper,
  ThanksWrapper,
  StyledModal,
  StyledForm,
  FieldWrapper,
  StyledTextArea,
  StyledLeftLogo,
  StyledRightLogo,
  StyledTextField,
  StyledIconButton,
  StyledModalTitle,
  StyledSubmitButton,
  StyledPhoneNumber,
  StyledDialogContent,
  StyledSuccessMessage,
  StyledLocalErrorMessage,
} from './styled';
import { Box } from '@mui/material';
import { IContactUsFormValues } from '@modals/ContactUsModal/types';

const ContactUsPopup = () => {
  const {
    isDone,
    error,
    errors,
    message,
    matches,
    isLoading,
    control,
    register,
    handleClose,
    handleSubmit,
    handleRequest,
    clearMessages,
  } = useContactUsLogic();

  const render = () => {
    if (isDone) {
      return (
        <ThanksWrapper>
          <Box>
            <StyledModalTitle
              component="h3"
              variant="h1"
              label="Thank you!"
              textAlign="center"
            />
            <p>Our support team will be in touch with you shortly. </p>
            <StyledSubmitButton
              onClick={() => {
                handleClose();
              }}>
              Done
            </StyledSubmitButton>
          </Box>
        </ThanksWrapper>
      );
    }

    return (
      <>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Contact Us"
          textAlign="center"
        />
        <StyledForm onSubmit={handleSubmit(handleRequest, clearMessages)}>
          <Stack alignItems="center">
            <FieldWrapper>
              <StyledTextField
                placeholder="Your Name"
                variant="outlined"
                type="text"
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Name is a required field',
                  },
                  pattern: {
                    value: NAME_REGEX,
                    message: 'Please enter a valid name.',
                  },
                })}
              />
              {errors && errors.name && (
                <StyledErrorMessage>{errors.name?.message}</StyledErrorMessage>
              )}
            </FieldWrapper>

            <FieldWrapper>
              <StyledTextField
                placeholder="Your Email Address"
                variant="outlined"
                type="text"
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Email is a required field',
                  },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Please enter a valid email address.',
                  },
                })}
              />
              {errors && errors.email && (
                <StyledErrorMessage>
                  {errors?.email?.message}
                </StyledErrorMessage>
              )}
            </FieldWrapper>

            <FieldWrapper>
              <PhoneField<IContactUsFormValues>
                control={control}
                name="phone"
                placeholder="Your Phone Number">
                <StyledPhoneNumber />
              </PhoneField>
              {errors?.phone?.message && (
                <StyledErrorMessage>
                  {errors?.phone?.message}
                </StyledErrorMessage>
              )}
            </FieldWrapper>

            <FieldWrapper>
              <AreaWrapper>
                <StyledTextArea
                  placeholder="Enter your message"
                  {...register('message', {
                    required: {
                      value: true,
                      message: 'Message is a required field',
                    },
                    pattern: {
                      value: MESSAGE_REGEX,
                      message: 'Please enter a valid message.',
                    },
                  })}
                />
              </AreaWrapper>
              {errors.message && (
                <StyledErrorMessage>
                  {errors?.message?.message}
                </StyledErrorMessage>
              )}
            </FieldWrapper>

            {error && (
              <StyledLocalErrorMessage>{error}</StyledLocalErrorMessage>
            )}
            {message && <StyledSuccessMessage>{message}</StyledSuccessMessage>}

            <StyledSubmitButton
              type="submit"
              disabled={isLoading}
              endIcon={<ArrowRightIcon />}>
              Submit
            </StyledSubmitButton>
          </Stack>
        </StyledForm>
      </>
    );
  };

  return (
    <StyledModal open={true} onClose={handleClose}>
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type="opened"
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}>
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}>
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>

      <StyledDialogContent>
        {render()}
        <StyledLeftLogo src={contactUs1} />
        <StyledRightLogo src={contactUs2} />
      </StyledDialogContent>
    </StyledModal>
  );
};

export default ContactUsPopup;
