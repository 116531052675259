import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, useMediaQuery } from '@mui/material';
import CustomCheckbox from '@components/CustomCheckbox';
import { PartnerRegistrationFormValues } from '@modules/truckerBecomePartner/types';
import { PhoneFieldWrapper } from '@modules/truckerBecomePartner';
import { useTheme } from '@mui/material/styles';

interface PhoneFieldWithConsentProps {
  phonePlaceholder: string;
  phoneFieldName: keyof PartnerRegistrationFormValues;
  consentCheckboxName: keyof PartnerRegistrationFormValues;
  consentCheckboxLabel: string;
}

const PhoneFieldWithConsent: React.FC<PhoneFieldWithConsentProps> = ({
  phonePlaceholder,
  phoneFieldName,
  consentCheckboxName,
  consentCheckboxLabel,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    watch,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useFormContext<PartnerRegistrationFormValues>();

  const isConsentChecked = watch(consentCheckboxName);

  const handleConsentChange = (checked: boolean) => {
    setValue(consentCheckboxName, checked);
    if (checked) {
      // If checkbox is checked and phone number is empty, trigger validation
      trigger(phoneFieldName);
    } else {
      // If checkbox is unchecked, clear any errors related to the phone field
      clearErrors(phoneFieldName);
    }
  };

  console.log('dsfasdf32 errors', { errors, phoneFieldName });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <PhoneFieldWrapper<PartnerRegistrationFormValues>
        placeholder={phonePlaceholder}
        registerName={phoneFieldName}
      />
      <CustomCheckbox
        checked={isConsentChecked as boolean}
        onCheck={handleConsentChange}
        label={consentCheckboxLabel.replace(/\\n/g, '\n')} // Ensuring that line breaks are correctly rendered
        alignment="top"
        containerStyles={{
          marginTop: matches && errors[phoneFieldName] ? '45px' : '15px',
          gap: '12px',
        }}
        boxStyles={{ marginTop: '4px' }}
      />
    </Box>
  );
};

export default PhoneFieldWithConsent;
