import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@components/Typography';
import { FontFamilyNames } from '@app/types/fonts';
import { COLORS } from '@utils/colors';

export const PartnerInfoWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1040px',
  maxHeight: '691px',
  margin: '0 auto',
  gap: 50,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  marginBottom: 60, // 177

  [theme.breakpoints.down('l')]: {
    gridTemplateColumns: '1fr',
    padding: '0 20px',
    maxHeight: 'unset',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    maxHeight: 'unset',
    marginBottom: 100,
  },
  [theme.breakpoints.down('sm')]: {},
}));

export const ItemWrapper = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  width: '100%',
  height: '691px',
  maxWidth: '495px',
  borderRadius: '29px',
  border: '1px solid #282750',
  background: 'linear-gradient(180deg, #FEF6E9 0 %, #FFF 100 %)',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('md')]: {
    height: 'max-content',
  },

  [theme.breakpoints.down('sm')]: {
    maxWidth: '335px',
    height: 'max-content',
  },
}));

export const ImgWrapper = styled(Box)(({ theme }) => ({
  maxHeight: '342px',
  height: '100%',

  img: {
    height: '100%',
    width: '99.9%',
  },

  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.down('sm')]: {
    maxHeight: '231px',

    img: {
      height: '100%',
    },
  },
}));

export const Info = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flex: '1',

  '&.section-1': {
    padding: '53px 82px 46px 52px',
  },
  '&.section-2': {
    padding: '53px 56px 46px 52px',
  },

  button: {
    color: COLORS.darkBlue,
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 'normal',
    padding: '12px 30px',
    height: '52px',

    [theme.breakpoints.down('l')]: {
      height: '46px',
    },

    svg: {
      width: '36px',
      height: '18px',
    },
  },

  [theme.breakpoints.down('l')]: {
    padding: '30px',
  },

  [theme.breakpoints.down('md')]: {
    padding: '30px',

    button: {
      width: '100%',
      fontSize: '16px',
      svg: {
        width: '21px',
        height: '10px',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&.section-1': {
      padding: '54px 81px 46px 62px',
      gap: '30px',

      [theme.breakpoints.down('sm')]: {
        padding: '15px 32px 33px 27px',
      },
    },

    '&.section-2': {
      padding: '54px 56px 46px 62px',
      gap: '68px',

      [theme.breakpoints.down('sm')]: {
        padding: '45px 32px 33px 27px',
      },
    },
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '-0.32px',
  display: 'flex',
  alignItems: 'center',

  '&.first': {
    marginBottom: '15px',
  },
  '&.partner': {
    // marginBottom: '30px',
  },
  '&.referral-partner': {
    marginBottom: '45px',
  },

  svg: {
    marginRight: 10,
  },

  [theme.breakpoints.down('md')]: {},

  [theme.breakpoints.down('sm')]: {
    '&.first': {
      marginBottom: '13px',
    },
    '&.partner': {},
    '&.referral-partner': {
      marginBottom: '30px',
    },

    svg: {
      marginRight: 10,
    },

    fontSize: '14px',
    letterSpacing: '-0.28px',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  textAlign: 'left',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  marginBottom: 15,

  [theme.breakpoints.down('md')]: {},

  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
    marginBottom: 5,
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  textAlign: 'left',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '130%',
  letterSpacing: '-0.32px',
  whiteSpace: 'pre-line',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    paddingRight: '5px',
  },
}));
