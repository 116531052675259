import marry from '@assets/images/home/users/marry.png';
import harold from '@assets/images/home/users/harold.png';
import kris from '@assets/images/home/users/kris.png';
// import peter from '@assets/images/home/users/marry.png'
// import steve from '@assets/images/home/users/harold.png'
// import john from '@assets/images/home/users/kris.png'

export const items = [
  {
    id: 0,
    name: 'Marry L.',
    desktopDescription:
      '““Getting Quotes from Moo was so easy. I entered\nmy move details into their site and instantly got\nconnected with 3 companies that could help me!\nSo Easy!””',
    mobileDescription:
      '““Getting Quotes from Moo was so easy. I entered my move details into their site and instantly got connected with 3 companies that could help me! So Easy!””',
    rating: 5,
    img: marry,
  },
  {
    id: 1,
    name: 'Harold K.',
    desktopDescription:
      '“I loved how easy it was to get quotes quickly\nso I could compare and choose who to work\nwith. I definitely recommend Moo Moves You\nfor your next move!”',
    mobileDescription:
      '“I loved how easy it was to get quotes\nquickly so I could compare and choose\nwho to work with. I definitely recommend\nMoo Moves You for your next move!”',
    rating: 5,
    img: harold,
  },
  {
    id: 2,
    name: 'Kris T.',
    desktopDescription:
      '“Moo let me book my move directly\nwith the company I chose. This helped\nme get the best price!”',
    mobileDescription:
      '“Moo let me book my move directly\nwith the company I chose. This helped\nme get the best price!”',
    rating: 5,
    img: kris,
  },
];

export const swiperParams = {
  pagination: { clickable: true },
  scrollbar: { draggable: true },
  breakpoints: {
    0: {
      slidesPerView: 1.2,
      spaceBetween: 10,
    },
    600: {
      slidesPerView: 1.2,
      spaceBetween: 40,
    },
    900: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1000: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  },
  effect: 'coverflow',
  coverflowEffect: {
    rotate: 0,
    slideShadows: false,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};
