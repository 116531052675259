import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { useMediaQuery } from '@mui/material';
import { useAppDispatch } from '@app/store/hooks';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { IContactUsFormValues } from './types';
import { useContactUsMutation } from '@modules/auth/api/auth.api';
import { errorHandler } from '@utils/errorHendler';

export const useContactUsLogic = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [sendRequest] = useContactUsMutation()

  const {
    control,
    formState: { errors },
    setError: setFormError,
    reset,
    watch,
    setValue,
    register,
    handleSubmit,
  } = useForm<IContactUsFormValues>();

  const clearMessages = () => {
    setMessage('');
    setError('');
  };

  const handleRequest: SubmitHandler<IContactUsFormValues> = async (data) => {
    try {
      clearMessages();
      setIsLoading(true);

      await sendRequest({
        email: data.email,
        message: data.message,
        name: data.name,
        phone: `+1${data.phone}`,
      }).unwrap();

      reset();
      setIsLoading(false);
      setMessage('Your message has been sent');
      setIsDone(true)
    } catch (e) {
      const errorMessage = errorHandler(e, setFormError)

      setIsLoading(false);
      setError(errorMessage) // 'Something went wrong, try again'
    }
  };

  const phone = watch('phone');

  const handleClose = () => {
    reset();
    clearMessages();
    dispatch(setPopUpName({ name: PopUpEnum.Empty }))
  };

  return {
    isDone,
    control,
    phone,
    error,
    errors,
    message,
    matches,
    isLoading,
    setIsLoading,
    setValue,
    register,
    handleClose,
    handleSubmit,
    handleRequest,
    clearMessages,
  }
}
