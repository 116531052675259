import trackerJobForm from '@assets/svg/tracker-job-form.svg';
import mooDashboardImage from '@assets/svg/moo-dashboard-image.svg';
import PlainImage from '@components/Image/PlainImage';
import Box from '@mui/material/Box';
import {
  ImageBox,
  StyledTypography,
  StyledJobContent,
  JobSectionContainer,
} from '@modules/truckerLanding/components/Content/styled';
import { FlexColumnBox, PrimaryText, SecondaryText } from './styled';

const JobSection = () => (
  <Box>
    <StyledTypography
      align="center"
      variant="h3"
      label="The Digital Lead Board"
    />
    <JobSectionContainer>
      <StyledJobContent>
        <FlexColumnBox>
          <PrimaryText>Get Connected Instantly!</PrimaryText>

          <SecondaryText>
            When one of our clients requests a moving quote in your selected
            Service Area, you instantly receive their name, email and verified
            cell phone number via email and text. They receive your company info
            as well!
          </SecondaryText>
          <SecondaryText>
            Hand it off to your Sales Team and book the move!
          </SecondaryText>
        </FlexColumnBox>

        <ImageBox>
          <PlainImage objectFit="contain" src={trackerJobForm} />
        </ImageBox>
      </StyledJobContent>

      <StyledJobContent>
        <FlexColumnBox>
          <PrimaryText>Nationwide Lead Board</PrimaryText>

          <SecondaryText>
            Looking to fill an empty leg, backhaul or need a few leads that
            aren't in your service area? We got you covered…
          </SecondaryText>
          <SecondaryText>
            All of our Moo’ving Partners get access to our Nationwide Lead
            Board. Sort through it and purchase additional leads a la carte at
            your convenience!
          </SecondaryText>
        </FlexColumnBox>

        <ImageBox>
          <PlainImage objectFit="contain" src={mooDashboardImage} />
        </ImageBox>
      </StyledJobContent>
    </JobSectionContainer>
  </Box>
);

export default JobSection;
