import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ISelectOption } from '../types';
import { states } from '../data';


export const becomePartnerSlice = createSlice({
  name: 'become',
  initialState: {
    agreement: false,
    states: states
  },
  reducers: {
    setStates: (state, { payload }: PayloadAction<ISelectOption[]>) => {
      state.states = payload
    },
    updateAgreement: (state, { payload }) => {
      state.agreement = payload
    },
  },
});

export const { setStates, updateAgreement } = becomePartnerSlice.actions;