import { css } from '@mui/material/styles';

import { FontFamilyNames, ThemeFont } from 'app/types/fonts';

import MoretBoldTtf from './Moret-Bold.ttf';
import MoretBoldWoff from './Moret-Bold.woff';
import MoretBoldWoff2 from './Moret-Bold.woff2';
import MoretBookTtf from './Moret-Book.ttf';
import MoretBookWoff from './Moret-Book.woff';
import MoretBookWoff2 from './Moret-Book.woff2';
import MoretRegularTtf from './Moret-Regular.ttf';
import MoretRegularWoff2 from './Moret-Regular.woff2';
import MoretRegularWoff from './Moret-Regular.woff';

const MoretFont: ThemeFont = {
  fontFamily: [
    FontFamilyNames.MoretBold,
    FontFamilyNames.MoretBook,
    FontFamilyNames.MoretRegular,
  ],
  fontFaces: css`
    @font-face {
      font-family: ${FontFamilyNames.MoretBold};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.MoretBold}),
        url(${MoretBoldWoff2}) format('woff2'),
        url(${MoretBoldWoff}) format('woff'),
        url(${MoretBoldTtf}) format("truetype");
    }

    @font-face {
      font-family: ${FontFamilyNames.MoretBook};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.MoretBook}),
        url(${MoretBookWoff2}) format('woff2'),
        url(${MoretBookWoff}) format('woff'),
        url(${MoretBookTtf}) format("truetype");
    }

    @font-face {
      font-family: ${FontFamilyNames.MoretRegular};
      font-style: normal;
      font-display: auto;
      src: local(${FontFamilyNames.MoretRegular}),
        url(${MoretRegularWoff2}) format('woff2'),
        url(${MoretRegularWoff}) format('woff'),
        url(${MoretRegularTtf}) format("truetype");
    }
  `,
};

export default MoretFont;
