import MuiPhoneNumber from 'react-phone-input-2';
import { styled } from '@mui/material/styles';
import { COLORS } from '@utils/colors';
import { Box } from '@mui/material';
import { FontFamilyNames } from '@app/types/fonts';
import Typography from '@components/Typography';

export const StyledPhoneNumber = styled(MuiPhoneNumber)(({ theme }) => ({
  width: '100%',
  borderBottom: `1px solid ${COLORS.darkBlue}`,

  input: {
    width: '100%',
    height: '100%',
    border: 'none',
    fontSize: '14px',
    color: COLORS.darkBlue,
    background: 'transparent',
    padding: '0',
    paddingBottom: '15px',

    '&:-webkit-autofill': {
      boxShadow: 'rgb(254, 246, 235) 0px 0px 0px 1000px inset !important',
    },

    ':-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0px 1000px white inset',
    },
  },
  '.form-control::placeholder': {
    color: COLORS.grey[50],
    fontFamily: FontFamilyNames.PlainLight,
    fontSize: '16px',
    letterSpacing: '-0.32px',
  },

  '.form-control:focus-visible': {
    outlineWidth: 0,
  },
  '.special-label': {
    display: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    height: '36px',
  },
}));

export const StyledFieldWrapper = styled(Box)<{ type?: string }>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      height: '36px',
    },
  }),
);
export const CompanyDetailsField = styled(Box)<{ type?: string }>(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
}));

export const DirectContactGuidelines = styled(Box)<{ type?: string }>(() => ({
  color: COLORS.grey[50],
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '12px',
  letterSpacing: '-0.12px',
}));

export const StyledGoogleReview = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '16px',
  margin: '53px 0',

  '.wrapper': {
    position: 'relative',
  },
  '.google-logo': {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  input: {
    paddingLeft: '36px !important',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  [theme.breakpoints.down('sm')]: {
    margin: '60px 0',
    '.google-logo': {
      left: 0,
      top: '11px',
    },
  },
}));

export const Description = styled(Box)(({ theme }) => ({
  color: '#A09D95',
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.12px',
  marginTop: '16px',

  ol: {
    margin: '5px 0px 0px 12px',

    li: {
      'span.underlined': {
        textDecoration: 'underline',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {},
}));

export const CustomErrorMessage = styled(Typography)(() => ({
  color: COLORS.error.main,
  marginTop: 7,
}));

export const ToggleableInputCheckboxText = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '345px',
  lineHeight: '130%',
  letterSpacing: '-0.32px',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '307px',
    fontSize: '14px',
    lineHeight: '130%',
    letterSpacing: '-0.14px',
  },
}));

export const AdditionalWrapper = styled(Box)(() => ({
  width: '100%',

  '&.dot, &.registerState': {
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
}));
