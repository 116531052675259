import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';
import Button from '@components/Button';
import { styled } from '@mui/material/styles';
import { Stack, TextField, Typography } from '@mui/material';
import { FontFamilyNames } from '@app/types/fonts';

export const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: '328px',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const StyledPasswordContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  marginBottom: 30,
}));

export const StyledTypography = styled(Typography)(() => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',

  width: '230px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  textOverflow: 'ellipsis',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '& .MuiOutlinedInput-root': {
    borderBottom: `1px solid ${COLORS.darkBlue}`,
    borderRadius: 0,
  },

  '& .MuiInputBase-input': {
    color: COLORS.darkBlue,
    fontSize: 16,
    letterSpacing: '-0.02em',
    padding: '0',
    paddingBottom: '12px',
  },

  '& ::placeholder': {
    color: COLORS.darkBlue,
    opacity: 0.5,
  },
  'input:-webkit-autofill': {
    boxShadow: '0 0 0 1000px rgb(254 246 235) inset !important',
  },

  [theme.breakpoints.down('sm')]: {
    maxHeight: 31,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      width: '100%',
    },

    '& .MuiInputBase-input': {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
}));

export const StyledPasswordActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
}));

export const StyledSaveButton = styled(Button)(({ theme }) => ({
  width: '100%',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '-0.9px',
  fontSize: '20px',
  padding: '7px 34px',
  height: 39,

  [theme.breakpoints.down('sm')]: {
    marginBottom: '0',
    height: '40px',
    fontSize: '20px',
    lineHeight: '123.4%',
  },
}));

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  background: 'transparent',
  padding: '12px 30px',
  fontWeight: '400',
  lineHeight: 'normal',
  width: '118px',
  color: COLORS.grey[50],
  fontSize: '20px',
  border: `1px solid ${COLORS.grey[50]}`,
  height: 39,

  [theme.breakpoints.down('sm')]: {
    height: '40px',
    fontSize: '20px',
    lineHeight: '123.4%',
  },
}));

export const StyledTypographyInfo = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',
  marginBottom: 40,

  [theme.breakpoints.down('sm')]: {
    marginBottom: 27,
  },
}));
