import React, { memo, useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useFormContext } from 'react-hook-form';
import SimpleFiled from '../SimpleFiled';
import { PartnerRegistrationFormValues } from '@modules/truckerBecomePartner/types';
import {
  AutocompleteContainer,
  StyledSuggestionItem,
  SuggestionsContainer,
} from './styled';

const AddressAutocompleteField = () => {
  const [address, setAddress] = useState('');
  const { setValue, trigger, watch } =
    useFormContext<PartnerRegistrationFormValues>();

  const handleSelect = async (value: string) => {
    setAddress(value);
    setValue('companyAddress', value);
    await trigger('companyAddress');
  };

  const companyAddress = watch('companyAddress');

  useEffect(() => {
    // This effect updates the internal state whenever the watched companyAddress changes
    setAddress(companyAddress || '');
  }, [companyAddress]);

  return (
    <PlacesAutocomplete
      value={address}
      searchOptions={{
        types: ['address'],
        componentRestrictions: { country: 'us' },
      }}
      onChange={setAddress}
      onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const inputProps = getInputProps({
          placeholder: 'Search Places ...',
          className: 'location-search-input companyAddress',
        });
        return (
          <AutocompleteContainer>
            <SimpleFiled
              {...inputProps}
              type="text"
              placeholder="Company Legal Address:*"
              fieldName={'companyAddress'}
              loading={loading}
              externalOnChange={inputProps.onChange}
            />

            <SuggestionsContainer square>
              {suggestions.map(suggestion => {
                return (
                  <StyledSuggestionItem
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}>
                    {suggestion.description}
                  </StyledSuggestionItem>
                );
              })}
            </SuggestionsContainer>
          </AutocompleteContainer>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default memo(AddressAutocompleteField);
