import icon1 from '@assets/svg/homeIcons/what-makes-moo-special/icon1.svg';
import icon2 from '@assets/svg/homeIcons/what-makes-moo-special/icon2.svg';
import icon3 from '@assets/svg/homeIcons/what-makes-moo-special/icon3.svg';
import icon4 from '@assets/svg/homeIcons/what-makes-moo-special/icon4.svg';
import icon5 from '@assets/svg/homeIcons/what-makes-moo-special/icon5.svg';

import howMooWorksIcon1 from '@assets/svg/homeIcons/how-moo-works/icon1.svg';
import howMooWorksIcon2 from '@assets/svg/homeIcons/how-moo-works/icon2.svg';
import howMooWorksIcon3 from '@assets/svg/homeIcons/how-moo-works/icon3.svg';
import howMooWorksIcon4 from '@assets/svg/homeIcons/how-moo-works/icon4.svg';

export const blocks = [
  {
    title: 'Free Quotes!',
    desktopDescription:
      'Moo Moves You allows you to compare free moving quotes from multiple companies, helping you find the best deal for your move!',
    mobileDescription:
      'Moo Moves You allows you to compare free moving quotes from multiple companies, helping you find the best deal for your move!',
    image: icon1,
  },
  {
    title: 'Reliability!',
    desktopDescription:
      'Only work with moving companies that can complete your job on the dates you need!',
    mobileDescription:
      'Only work with moving companies that can complete your job on the dates you need!',
    image: icon2,
  },
  {
    title: 'Full Service Moves!',
    desktopDescription: `All of our movers offer full service moves which includes services like furniture disassembly and reassembly and protective packaging.`,
    mobileDescription: `All of our movers offer full service moves which includes services like furniture disassembly and reassembly and protective packaging.`,
    image: icon3,
  },
  {
    title: 'Packing Services!',
    desktopDescription: `If you need help packing your\npersonal items, Moo’s Moving\nPartners offer Full Packing Services!`,
    mobileDescription: `If you need help packing your\npersonal items, Moo’s Moving\nPartners offer Full Packing Services!`,
    image: icon4,
  },
  {
    title: 'Instant Connections!',
    desktopDescription: `You are instantly connected\nto Moving Companies that can\nservice your move!`,
    mobileDescription: `You are instantly connected\nto Moving Companies that can\nservice your move!`,
    image: icon5,
  },
];

export const howMooWorksBlocks = [
  {
    title: 'Enter Your Move Details',
    desktopDescription:
      'Provide us with your \nto & from Addresses, Move\nDate and Job Size.',
    mobileDescription:
      'Provide us with your to & from\nAddresses, Move Date and Job\nSize.',
    image: howMooWorksIcon1,
  },
  {
    title: 'Connect Securely',
    desktopDescription: `Get connected with our\nMoo’ving Partners that can\nservice your move.`,
    mobileDescription: `Get connected with our\nMoo’ving Partners that can\nservice your move.`,
    image: howMooWorksIcon2,
  },
  {
    title: 'Compare Quotes',
    desktopDescription: `Review free quotes and decide\nwhich moving company is the\nbest fit for you.`,
    mobileDescription: `Review free quotes and decide\nwhich moving company is the\nbest fit for you.`,
    image: howMooWorksIcon3,
  },
  {
    title: 'Book Direct',
    desktopDescription: `Choose your moving\ncompany and book your\nmove direct!`,
    mobileDescription: `Choose your moving company\nand book your move direct!`,
    image: howMooWorksIcon4,
  },
];

export const FOOTER_DESCRIPTION =
  'Moo Moves You is the first Digital Moving Marketplace.' +
  ' Our Mission: To simplify and standardize the moving process by combining easy to use ' +
  'technology and our online marketplace to match you (the customer) to our pre-qualified ' +
  'market of nationwide Moving Companies.';

export const DOT = 'DOT #: 3891017';
export const MC = 'MC #: 1430500';
export const COMPANY_INFO = [
  'Moo Moves You, LLC',
  '3500 NW 2nd Ave.',
  'Suite 623',
  'Boca Raton FL 33431',
];
