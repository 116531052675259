import { lazy } from 'react';

import type { RouteObject } from 'react-router-dom';
import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from 'app/types/enums/pages';

import { Layout } from '@modules/home'
import { TruckerLayout } from '@modules/truckerLanding'
import { AffiliateLayout } from '@modules/affiliateLanding'

import { AuthLayout } from '@modules/profile'

const Profile = lazy(() => import('@pages/Profile'))
const Home = lazy(() => import('@pages/Home'))
const Faq = lazy(() => import('@pages/FAQ'));
const TruckerFAQ = lazy(() => import('@pages/TruckerFAQ'));
const WhyJoin = lazy(() => import('@pages/WhyJoin'));
const HowItWorks = lazy(() => import('@pages/HowItsWork'))
const PrivacyPolicy = lazy(() => import('@pages/PrivacyPolicy'));
const CookiesPolicy = lazy(() => import('@pages/CookiesPolicy'))
const TermsAndConditions = lazy(() => import('@pages/TermsAndConditions'));
const TruckerLanding = lazy(() => import('@pages/TruckerLanding'));
const TruckerBecomePartner = lazy(() => import('@pages/TruckerBecomePartner'));
const Affiliate = lazy(() => import('@pages/Affiliate'));
const AffiliateBecomePartner = lazy(() => import('@pages/AffiliateBecomePartner'));

export const ROUTES: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PAGE_ROUTES_PUBLIC.HOME,
        element: <Home />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.FAQ,
        element: <Faq />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.HOW_IT_WORKS,
        element: <HowItWorks />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.TERMS_AND_CONDITIONS,
        element: <TermsAndConditions />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.COOKIES_POLICY,
        element: <CookiesPolicy />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.COOKIES_POLICY,
        element: <CookiesPolicy />,
      },

    ],
  },
  {
    element: <TruckerLayout />,
    children: [
      {
        path: PAGE_ROUTES_PUBLIC.TRUCKER_LANDING,
        element: <TruckerLanding />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.TRUCKER_BECOME_PARTNER,
        element: <TruckerBecomePartner />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.TRUCKER_FAQ,
        element: <TruckerFAQ />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.WHY_JOIN,
        element: <WhyJoin />,
      },
    ],
  },
  {
    element: <AffiliateLayout />,
    children: [
      {
        path: PAGE_ROUTES_PUBLIC.AFFILIATE,
        element: <Affiliate />,
      },
      {
        path: PAGE_ROUTES_PUBLIC.AFFILIATE_BECOME_PARTNER,
        element: <AffiliateBecomePartner />,
      },
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: PAGE_ROUTES_PRIVATE.PROFILE,
        element: <Profile />,
      },
    ],
  },
];
