import { CSSProperties, ImgHTMLAttributes } from 'react';

import { StyledImage } from './styled';

interface IProgressiveImgProps extends ImgHTMLAttributes<HTMLImageElement> {
  customClass?: string;
  alt?: string;
  objectFit?: CSSProperties['objectFit'];
  objectPosition?: CSSProperties['objectPosition'];
}

const PlainImage = ({
  src,
  alt = '',
  objectFit = 'cover',
  objectPosition,
  customClass = '',
  ...restProps
}: IProgressiveImgProps) => (
  <StyledImage
    alt={alt}
    src={src}
    objectFit={objectFit}
    objectPosition={objectPosition}
    className={`image ${customClass}`}
    {...restProps}
  />
);

export default PlainImage;
