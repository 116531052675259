import { AuthStorageKeys } from '../constants';
import { IAuthStore, IUserResponse } from '../types';

const getUserDataStorageType = () => {
  const rememberMe = localStorage.getItem(AuthStorageKeys.REMEMBER_ME);

  return rememberMe ? localStorage : sessionStorage;
};

export const getAuthInitialState = (): IAuthStore => {
  const storage = getUserDataStorageType();

  return {
    accessToken: storage.getItem(AuthStorageKeys.ACCESS_TOKEN) || '',
    userInfo: JSON.parse(storage.getItem(AuthStorageKeys.USER) as string) || null,
  };
};

export const saveUserDataInStorage = (payload: IUserResponse) => {
  const storage = getUserDataStorageType();

  storage.setItem(AuthStorageKeys.USER, JSON.stringify({ ...payload }));
};

export const saveTokenInStorage = (accessToken: string, refreshToken: string) => {
  const storage = getUserDataStorageType();

  storage.setItem(AuthStorageKeys.ACCESS_TOKEN, accessToken);
  storage.setItem(AuthStorageKeys.REFRESH_TOKEN, refreshToken);
};

export const purgeAuthDataFromStorage = () => {
  const storage = getUserDataStorageType();

  storage.removeItem(AuthStorageKeys.ACCESS_TOKEN);
  storage.removeItem(AuthStorageKeys.REFRESH_TOKEN);
  localStorage.removeItem(AuthStorageKeys.REMEMBER_ME);
  storage.removeItem(AuthStorageKeys.USER);
};
