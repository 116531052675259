export const processError = (error: unknown) => {
    const customError = error as any;

    if (customError?.data?.error === 'PHONE_ALREADY_EXIST') {
        return 'Phone number already exists';
    }

    if (customError?.data?.error === 'EMAIL_ALREADY_EXISTS') {
        return 'User Email Already Exists';
    }

    if (customError?.data?.error === 'INVALID_CODE') {
        return 'Invalid code';
    }

    const codeError = customError?.data?.errors?.code?.[0];
    const validPhoneNumberError = customError?.data?.errors?.phone?.[0];

    return codeError || validPhoneNumberError || customError?.data?.message || 'An error occurred';
}
