import { useState } from 'react';
import {
  StyledEditProfileButton,
  StyledMyProfileContainer,
} from '../../styled';
import ChangePasswordModal from '@modals/ChangePasswordModal'; // Assuming you've imported ChangePasswordModal component
import { ProfileForm, ProfileView } from './components';

const MyProfile = () => {
  const [isMyProfileFormOpen, setIsMyProfileFormOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  return (
    <StyledMyProfileContainer>
      {isMyProfileFormOpen ? (
        <ProfileForm
          isSuccessModalOpen={isSuccessModalOpen}
          setIsMyProfileFormOpen={setIsMyProfileFormOpen}
          setIsSuccessModalOpen={setIsSuccessModalOpen}
        />
      ) : (
        <>
          <ProfileView />
          <StyledEditProfileButton onClick={() => setIsMyProfileFormOpen(true)}>
            Edit Profile
          </StyledEditProfileButton>
        </>
      )}

      <ChangePasswordModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        title="Changes have been saved!"
        doneButtonLabel="Done"
      />
    </StyledMyProfileContainer>
  );
};

export default MyProfile;
