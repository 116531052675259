import { useNavigate } from 'react-router-dom';

import { Stack } from '@mui/material';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import { StyledMenuItem, RightSide, StyledButton } from '../../styled';

const RightPart = () => {
  const navigate = useNavigate();

  return (
    <RightSide>
      <Stack spacing={{ lg: '30px', sm: 2, md: '25px' }} direction="row">
        <StyledMenuItem
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.WHY_JOIN)
          }}
        >
          About Us
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.HOME)
          }}
        >
          Main Site
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.FAQ)
          }}
        >
          FAQ
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_REDIRECT_TO_REFERRAL}`
          }}
        >
          Partners Login
        </StyledMenuItem>
        <StyledButton
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.AFFILIATE_BECOME_PARTNER)
          }}
        >
          Sign Up
        </StyledButton>
      </Stack>
    </RightSide>
  );
};

export default RightPart;
