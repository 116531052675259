export const breakpoints = {
  xs: 0,
  esm: 376,
  vsm: 450,
  sm: 790,
  md: 900,
  l: 1025, // large
  lMore: 1100, // large
  lg: 1200,
  xl: 1366,
  el: 1441, // Extra Large
  eel: 1921, // Extra-Extra Large
};
