import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';

export const MovesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '60px',

  [theme.breakpoints.down('sm')]: {
    gap: '27px',
  },
}));

export const MoveDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '60px',

  [theme.breakpoints.down('sm')]: {
    gap: '27px',
  },
}));

export const PartnersSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',

  [theme.breakpoints.down('sm')]: {
    gap: '20px',
  },
}));

export const PartnersTitle = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  fontFamily: FontFamilyNames.PlainLight,
  lineHeight: '130%',
  fontSize: '24px',

  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
    lineHeight: '135%',
    letterSpacing: '-0.4px',
  },
}));

export const PreferredPartnersHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
}));

export const PartnersContent = styled(Typography)(({ theme }) => ({
  color: COLORS.grey[50],
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: 16,
  fontWeight: 300,
  lineHeight: '130%',
  maxWidth: '714px',

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    letterSpacing: '-0.14px',
  },
}));
