import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@components/Typography';

import { FontFamilyNames } from 'app/types/fonts';

import { COLORS } from '@utils/colors';

export const EstimateBox = styled(Box)(({ theme }) => ({
  maxWidth: '1014px',
  display: 'flex',
  gap: 72,
  margin: '0 auto 110px auto',

  [theme.breakpoints.down('lg')]: {
    padding: '0 20px',
    marginBottom: 89,
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: 100,
    padding: '0 20px',
  },
}));

export const EstimateTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: 40,
  fontWeight: 400,
  textAlign: 'left',
  marginBottom: 15,
  lineHeight: '46.52px',

  [theme.breakpoints.down('sm')]: {
    fontSize: 26,
    lineHeight: '30.24px',
    marginBottom: 15,
  },
}));

export const EstimateContentBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: 60,
  gridTemplateColumns: '1fr 1fr',

  [theme.breakpoints.down('md')]: {
    gap: 38,
    gridTemplateColumns: '1fr',
  },

  [theme.breakpoints.down('sm')]: {
    gap: 38,
  },
}));

export const EstimateImageBox = styled(Box)(({ theme }) => ({
  img: {
    width: '480px',
  },

  [theme.breakpoints.down('sm')]: {
    img: {
      width: '100%',
    },
  },
}));

export const EstimateTextBox = styled(Box)(({ theme }) => ({
  maxWidth: 445,
  marginTop: 45,
  paddingRight: '56px',

  button: {
    fontSize: '24px',
    width: '204px',
    height: '52px',
    padding: '12px 30px',
    margin: '30px 0px 15px 0',

    svg: {
      width: '36px',
      height: '18px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    paddingRight: 0,

    button: {
      fontSize: '16px',
      width: '100%',
      height: '46px',
      padding: '12px 30px',
      margin: '40px 0px 0px 0px',

      svg: {
        width: '36px',
        height: '18px',
      },
    },
    marginTop: 0,
  },
}));

export const EstimateText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: COLORS.darkBlue,
  marginBottom: 15,

  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

export const EstimateDescription = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: 16,
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',
  color: COLORS.darkBlue,

  '&.first': {
    marginBottom: 10,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    letterSpacing: '-0.14px',
  },
}));
