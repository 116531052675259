import { Box } from '@mui/material';
import styled from 'styled-components';
import { FontFamilyNames } from '@app/types/fonts';
import { COLORS } from '@utils/colors';

export const Wrapper = styled(Box)(() => ({
  cursor: 'pointer',
  width: 'max-content',
  color: 'rgb(160, 157, 149)',
  gap: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
}));

export const CheckoutWrapper = styled(Box)`
  font-family: ${FontFamilyNames.PlainLight};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: -0.32px;
  color: ${COLORS.darkBlue};
  margin-bottom: 26px;

  b {
    margin: 0 5px;
    cursor: pointer;
    text-decoration: underline;
  }

  display: flex;
  gap: 10px;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 16px;
  }
`;
