import { Mutex } from 'async-mutex'
import { buildBaseQuery } from './baseQuery'
import { AuthStorageKeys } from '@modules/auth/constants'
import { logout } from '@modules/auth'
import { setToken } from '@modules/auth/store/auth.slice'

const mutex = new Mutex()

export const baseQueryWithReAuth = (prefix = '') => {
  return async (args: any, api: any, extraOptions: any) => {
    // wait until the mutex is available without locking it
    const baseQuery = buildBaseQuery(prefix)

    await mutex.waitForUnlock()
    let response = await baseQuery(args, api, extraOptions)

    if (response.error && response.error.status === 401) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          const refreshToken = localStorage.getItem(AuthStorageKeys.REFRESH_TOKEN)
          const authQuery = buildBaseQuery('auth');

          const refreshResult = await authQuery(
            {
              url: 'refresh-token',
              method: 'POST',
              body: { refreshToken },
            },
            api,
            extraOptions,
          );

          if (refreshResult.data) {
            // @ts-ignore
            api.dispatch(setToken(refreshResult.data))
            response = await baseQuery(args, api, extraOptions)
          } else {
            api.dispatch(logout())
          }
        } finally {
          // release must be called once the mutex should be released again.
          release()
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock()
        response = await baseQuery(args, api, extraOptions)
      }
    }

    return response
  }
}
