import { Box } from '@mui/material';
import { blocks } from '../../data';
import {
  Container,
  StyledBlock,
  Title,
  StyledBlocks,
  StyledBlockTitle,
  StyledBlockImage,
  StyledBlockDescription,
} from './styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const WhatMakesMooBlock = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <Title>What makes Moo special?</Title>
      <StyledBlocks>
        {blocks.map((block, idx) => (
          <StyledBlock key={block.title} className={`block-${idx} block`}>
            <StyledBlockImage>
              <img src={block.image} alt={block.title} />
            </StyledBlockImage>
            <Box>
              <StyledBlockTitle className={`block-title-${idx} block-title`}>
                {block.title}
              </StyledBlockTitle>
              <StyledBlockDescription>
                {matches ? block.mobileDescription : block.desktopDescription}
              </StyledBlockDescription>
            </Box>
          </StyledBlock>
        ))}
      </StyledBlocks>
    </Container>
  );
};

export default WhatMakesMooBlock;
