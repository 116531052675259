import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';

export const StyledStack = styled(Stack)<{ zIndex: 'up' | '' }>(
  ({ theme, zIndex }) => ({
    position: 'relative',

    svg: {
      position: 'absolute',
      top: '15px',
      right: '16px',
      zIndex: '56',

      [theme.breakpoints.down('sm')]: {
        zIndex: zIndex === 'up' ? '60' : '56',
        right: '25px',
      },
    },
  }),
);

export const StyledLocationInput = styled('input')<{
  error: string;
  borderType: 'first' | 'second';
  zIndex: 'up' | '';
}>(({ error, theme, zIndex, borderType }) => {
  let styles = {};

  const borderSecond = {
    border: error
      ? `1px solid ${COLORS.error.main}`
      : `1px solid ${COLORS.grey[25]}`,
    borderLeft: error ? `1px solid ${COLORS.error.main}` : 'none',
    borderRadius: 0,
  };

  const borderFirst = {
    border: error
      ? `1px solid ${COLORS.error.main}`
      : `1px solid ${COLORS.grey[25]}`,
    borderRadius: '25px 0 0 25px',
  };

  if (borderType === 'second') {
    styles = { ...borderSecond };
  } else {
    styles = { ...borderFirst };
  }
  return {
    position: 'relative',
    zIndex: 50,
    padding: '14px 30px 12px 17px',
    width: '100%',
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '130%',
    letterSpacing: '-0.32px',
    color: COLORS.darkBlue,
    height: '50px',
    fontFamily: FontFamilyNames.PlainLight,
    ...styles,

    '::placeholder': {
      color: COLORS.darkBlue,
      fontSize: '14px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '46px',
      fontSize: 14,
      letterSpacing: '-0.14px',
      borderRadius: '25px',
      marginBottom: '10px',
      zIndex: zIndex === 'up' ? 60 : 56,
      border: error
        ? `1px solid ${COLORS.error.main}`
        : `1px solid ${COLORS.grey[25]}`,
    },
  };
});

export const StyledDropdownBox = styled(Box)<{ zIndex: 'up' | '' }>(
  ({ theme, zIndex }) => ({
    position: 'absolute',
    zIndex: 30,
    padding: '0',
    width: '120%',
    borderRadius: '30px 20px 20px 20px',
    background: theme.palette.common.white,
    border: `1px solid ${COLORS.grey[25]}`,

    'div:last-of-type': {
      borderTop: '0',
    },

    [theme.breakpoints.down('sm')]: {
      zIndex: zIndex === 'up' ? 59 : 55,
      width: '100%',
    },
  }),
);

export const SuggestionsList = styled(Stack)(({ theme }) => ({
  borderRadius: '30px 30px 20px 20px',
  overflowY: 'scroll',
  maxHeight: '212px',
  background: theme.palette.common.white,
  paddingTop: '50px',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const StyledDropdownBoxItem = styled(Stack)`
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid ${COLORS.grey[25]};
`;
