import { PartnerInfoAfterLockInRate } from '@modules/profile/types/moves-response.model';

export enum PopUpEnum {
  Empty = 'Empty',
  LoginModal = 'LoginModal',
  MainMenuModal = 'MainMenuModal',
  TruckerMenuModal = 'TruckerMenuModal',
  ContactUsModal = 'ContactUsModal',
  ResetPasswordModal = 'ResetPasswordModal',
  ForgotPasswordModal = 'ForgotPasswordModal',
  FullPackingServicesModal = 'FullPackingServicesModal',
  QuoteBoardModal = 'QuoteBoardModal',
  ConnectModal = 'ConnectModal',
  PriceModal = 'PriceModal',
  AdditionalServices = 'AdditionalServices',
  CreateNewMove = 'CreateNewMove',
  DeleteMove = 'DeleteMove',
  AgreementPopup = 'AgreementPopup',
}
export interface IPopUpParams {
  moveId?: number;
  token?: string;
}
export interface PartnerInfo {
  companyAddress: string;
  companyName: string;
  email: string;
  phone: string;
}

export interface IAppState {
  isOpenLogin: boolean;
  modal: PopUpEnum;
  isUpdateAfterDeleteMove: boolean;
  params?: IPopUpParams | null;
  partnerInfo?: PartnerInfoAfterLockInRate['partner'] | null;
}
