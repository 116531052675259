import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import {
  StyledFooter,
  FooterDescription,
  FooterLink,
  FooterLinks,
  FooterLinksStack,
} from './styled';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <StyledFooter>
      {/* <FooterDescription>
        Moo MovesList You is the first Digital Moving Marketplace. Our Mission:
        To simplify and standardize the moving process by combining easy to use
        technology and our online marketplace to match you (the customer) to our
        pre-qualified market of nationwide Moving Companies.
      </FooterDescription> */}

      <FooterLinksStack>
        <FooterLinks>
          <FooterLink
            label="Terms of Use"
            onClick={() => navigate(PAGE_ROUTES_PUBLIC.TERMS_AND_CONDITIONS)}
          />
          <FooterLink
            label="Privacy Policy"
            onClick={() => navigate(PAGE_ROUTES_PUBLIC.PRIVACY_POLICY)}
          />
        </FooterLinks>

        <FooterLink label="(C) 2023 Moo Moves You" />
      </FooterLinksStack>
    </StyledFooter>
  );
};

export default Footer;
