import React, { useState } from 'react';
import { Navbar } from './components';
import { MoveNavbarEnum } from '../../../../types/move-navbar.enum';
import { MovesContainer } from './styled';
import { useMovesData } from '../../../../hooks/useMovesData';
import MovesContent from '../../components/Moves/components/MovesContent';
import { FooterBackground } from '@modules/home/components/Content/components/Footer/styled';
import { Footer } from '@modules/home/components/Content/components';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

const Moves = () => {
  const [activeTab, setActiveTab] = useState<MoveNavbarEnum>(
    MoveNavbarEnum.CURRENT,
  );

  const handleActiveTabChange = (newActiveTab: MoveNavbarEnum) => {
    setActiveTab(newActiveTab);
  };

  return (
    <>
      <MovesContainer>
        <Navbar
          activeTab={activeTab}
          onActiveTabChange={handleActiveTabChange}
        />
        {/*  TODO: isLoading logic will need in future */}
        {/*{isLoading ? (*/}
        {/*  <Box*/}
        {/*    sx={{*/}
        {/*      height: '100vh',*/}
        {/*      display: 'flex',*/}
        {/*      justifyContent: 'center',*/}
        {/*      marginTop: '40px',*/}
        {/*    }}>*/}
        {/*    <CircularProgress />*/}
        {/*  </Box>*/}
        {/*) : (*/}
        {/*  <MovesContent activeTab={activeTab} moves={moves} />*/}
        {/*)}*/}
        <MovesContent activeTab={activeTab} />
      </MovesContainer>

      <FooterBackground>
        <Footer />
      </FooterBackground>
    </>
  );
};

export default Moves;
