import { configureStore } from '@reduxjs/toolkit';

import appReducer from '@app/store/features/app/app.slice';
import { homeLandingApi } from '@modules/home';
import { authApi, authSlice } from '@modules/auth';
import { profileApi } from '@modules/profile';
import { becomePartnerApi } from '@modules/truckerBecomePartner';
import { becomePartnerSlice } from '@modules/truckerBecomePartner';


export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    homeLandingApi.middleware,
    authApi.middleware,
    profileApi.middleware,
    becomePartnerApi.middleware,
  ]),
  reducer: {
    app: appReducer,
    auth: authSlice.reducer,
    become: becomePartnerSlice.reducer,
    [becomePartnerApi.reducerPath]: becomePartnerApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [homeLandingApi.reducerPath]: homeLandingApi.reducer,
  },
});
