import { Dayjs } from 'dayjs';

export enum PackingServicesEnum {
  PACKING_SERVICES_INCLUDED = 'Packing Services Included',
  PACKING_SERVICES_NOT_INCLUDED = 'Packing Services Not Included',
}

type Location = { address: string; placeId: string };
type Option = { value: string; label: string; isDisabled: boolean };

export interface IMoveRequestFormValues {
  name: string;
  email: string;
  phone: string;
  date: Dayjs | null;
  toPlace: Option;
  fromPlace: Option;
  packingServices: Option;
  currentHouseSize: {
    value: string;
    label: string;
    isDisabled: boolean;
    id: string | number;
  } | null;
  to: Location;
  from: Location;
  code?: string;
  noDropOffAddressOrStorageNeeded: boolean | null;
}

export interface ICreateMovePayload {
  houseId: string | number;
  pickupDate: string;
  originPlaceId: string;
  destinationPlaceId: string;
}

export interface IPhoneVerificationPayload {
  phone: string;
}
