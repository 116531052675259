import {
  InformationWrapper,
  InformationLeft,
  InformationRight,
  InformationLabel,
  InformationValue,
  InformationGroup,
} from './styled';

interface MoveInformationProps {
  originAddress: string;
  destinationAddress: string;
  mileage: number;
  houseTitle: string;
  isInstate: boolean;
}

export const MoveInformationSection = ({
  originAddress,
  destinationAddress,
  mileage,
  houseTitle,
  isInstate,
}: MoveInformationProps) => {
  return (
    <InformationWrapper>
      <InformationLeft>
        <InformationGroup>
          <InformationLabel variant="body2">
            Origin (only visible to you):
          </InformationLabel>
          <InformationValue variant="body2">
            {originAddress ?? '...'}
          </InformationValue>
        </InformationGroup>

        <InformationGroup>
          <InformationLabel variant="body2">
            Destination (only visible to you):
          </InformationLabel>
          <InformationValue variant="body2">
            {destinationAddress ?? '...'}
          </InformationValue>
        </InformationGroup>

        <InformationGroup>
          <InformationLabel variant="body2">Total Mileage:</InformationLabel>
          <InformationValue variant="body2">
            {`${mileage} mi` ?? '...'}
          </InformationValue>
        </InformationGroup>
      </InformationLeft>

      <InformationRight>
        <InformationGroup>
          <InformationLabel variant="body2">House Size:</InformationLabel>
          <InformationValue variant="body2">
            {houseTitle ?? '...'}
          </InformationValue>
        </InformationGroup>

        <InformationGroup>
          <InformationLabel variant="body2">Instate:</InformationLabel>
          <InformationValue variant="body2">
            {isInstate ? 'Yes' : 'No'}
          </InformationValue>
        </InformationGroup>
      </InformationRight>
    </InformationWrapper>
  );
};

export default MoveInformationSection;
