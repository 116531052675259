import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';

import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';

export const Container = styled(Stack)(({ theme }) => ({
  maxWidth: '1078px',
  width: '100%',
  margin: '110px auto',

  [theme.breakpoints.down('sm')]: {
    margin: '100px auto',
  },
}));

export const StyledBlocks = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',

  [theme.breakpoints.down('eel')]: {
    margin: '0 auto',
  },

  [theme.breakpoints.down('el')]: {
    // margin: '0px auto 120px',
    // padding: '0px 20px'
  },

  [theme.breakpoints.down('xl')]: {},

  [theme.breakpoints.down('md')]: {
    display: 'grid',
    gap: 40,
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
    margin: '0 auto',
    gap: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  textAlign: 'center',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'capitalize',
  marginBottom: '60px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
    marginBottom: '40px',
  },
}));

export const StyledBlock = styled(Stack)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  maxWidth: '240px',
  maxHeight: '330px',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '&:nth-of-type(1)': {
    img: {
      width: '195px',
      height: '172.241px',
    },
  },
  '&:nth-of-type(2)': {
    img: {
      width: '153.059px',
      height: '172.241px',
    },
  },
  '&:nth-of-type(3)': {
    img: {
      width: '183.677px',
      height: '172.241px',
    },
  },
  '&:nth-of-type(4)': {
    img: {
      width: '167.846px',
      height: '172.241px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    maxWidth: '335px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'unset',
    gap: '22px',
    width: '100%',
  },
}));

export const StyledBlockTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '130%',
  margin: '26px 0 6px 0',
  color: COLORS.darkBlue,
  letterSpacing: '-0.32px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    margin: '0 0 9px 0',
  },
}));

export const StyledBlockImage = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  width: 'min-content',

  '.number': {
    position: 'absolute',
    width: '58px',
    height: '58px',
    background: '#F8E6EB',
    borderRadius: '50%',
    border: '1px solid #282750',

    fontFamily: FontFamilyNames.MoretRegular,
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',

    '&:nth-of-type(4)': {
      img: {
        width: '167.846px',
        height: '172.241px',
      },
    },
  },

  img: {
    maxWidth: '190px',
    height: '140px',
  },

  '&:nth-of-type(0)': {
    img: {
      width: '200px',
      height: '200px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    minHeight: 'unset',
    paddingTop: 0,

    img: {
      width: '105px !important',
      height: '105px !important',
    },
  },
}));

export const StyledBlockDescription = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  whiteSpace: 'pre-wrap',
  fontWeight: 300,
  fontSize: '14px',
  lineHeight: '130%',
  letterSpacing: '-0.28px',
  color: COLORS.grey['50'],

  [theme.breakpoints.down('sm')]: {
    lineHeight: '130%',
    letterSpacing: '-0.14px',
  },
}));
