
import { ReactComponent as First } from '@assets/svg/quoteBoard/first.svg';
import { ReactComponent as Second } from '@assets/svg/quoteBoard/second.svg';
import { ReactComponent as Third } from '@assets/svg/quoteBoard/third.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';

import { Box } from '@mui/material';
import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import Button from '@components/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { useQuoteBoardLogic } from './useQuoteBoardLogic';
import {
  StyledModal,
  StyledIconButton,
  StyledModalTitle,
  StyledDialogContent,
  Content,
  BackgroundWrapper
} from './styled';


const QuoteBoardModal = () => {
  const {
    matches,
    handleClose,
  } = useQuoteBoardLogic()

  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <BackgroundWrapper>
        <div className='background'></div>
        <DialogTitle sx={{ padding: 0 }}>
          {matches ? (
            <Stack alignItems="flex-end">
              <BurgerMenuButton
                type='closed'
                onClick={handleClose}
                sx={{
                  background: COLORS.beige,
                }}
              >
                <MobileCloseIcon />
              </BurgerMenuButton>
            </Stack>
          ) : (
            <StyledIconButton
              aria-label="close"
              onTouchMove={handleClose}
              onClick={handleClose}
            >
              <CloseIcon />
            </StyledIconButton>
          )}
        </DialogTitle>
        <StyledDialogContent>
          <StyledModalTitle
            component="h3"
            variant="h1"
            label="Welcome to your Quote Board!"
            textAlign="center"
          />
          <Content>
            <Box>
              <div className='svg-wrapper'>
                <First />
              </div>
              <Box>
                <p>
                  <b>Review Your Quotes</b>
                </p>
                <span>
                  Moving Companies will start submitting quotes shortly. Review them here on your Quote Board! Every time a quote is submitted, you will recieve an e-mail.
                </span>
              </Box>
            </Box>
            <Box className="arrows">
              <svg xmlns="http://www.w3.org/2000/svg" width="37" height="19" viewBox="0 0 37 19" fill="none">
                <line y1="9.5" x2="36" y2="9.5" stroke="#282750" />
                <path d="M36 9.5C31.6667 9.33333 22.9 7.3 22.5 0.5" stroke="#282750" />
                <path d="M36 9.5C31.6667 9.66667 22.9 11.7 22.5 18.5" stroke="#282750" />
              </svg>
            </Box>
            <Box>
              <div className='svg-wrapper'>
                <Second />
              </div>
              <Box>
                <p>
                  <b>Compare and choose</b>
                </p>
                <span>
                  Pick the moving companies that best fit your needs and connect directly with them to book your move.
                </span>
              </Box>
            </Box>
            <Box className="arrows">
              <svg xmlns="http://www.w3.org/2000/svg" width="37" height="19" viewBox="0 0 37 19" fill="none">
                <line y1="9.5" x2="36" y2="9.5" stroke="#282750" />
                <path d="M36 9.5C31.6667 9.33333 22.9 7.3 22.5 0.5" stroke="#282750" />
                <path d="M36 9.5C31.6667 9.66667 22.9 11.7 22.5 18.5" stroke="#282750" />
              </svg>
            </Box>
            <Box>
              <div className='svg-wrapper'>
                <Third />
              </div>
              <Box>
                <p>
                  <b>Lock in your Rate</b>
                </p>
                <span>
                  Simply click “Lock In My Rate” and you will be securely connected to those moving companies with guaranteed pricing!
                </span>
              </Box>
            </Box>
          </Content>
          <Button
            onClick={() => {
              handleClose()
            }}
          >
            Got it
          </Button>
        </StyledDialogContent>
      </BackgroundWrapper>
    </StyledModal >
  );
};

export default QuoteBoardModal;
