import googlePng from '@assets/images/google.png';
import { SimpleField, GoogleReview, PhoneFieldWrapper } from './components';
import ToggleableInputField from '@modules/truckerBecomePartner/components/Content/components/Fields/components/ToggleableInputField';
import {
  CompanyDetailsField,
  DirectContactGuidelines,
} from '@modules/truckerBecomePartner/components/Content/components/Fields/styled';
import Box from '@mui/material/Box';
import AddressAutocompleteField from './components/AddressAutocompleteField';
import { PartnerRegistrationFormValues } from '@modules/truckerBecomePartner/types';
import PhoneFieldWithConsent from '@modules/truckerBecomePartner/components/Content/components/Fields/components/PhoneFieldWithConsent';

const BecomePartnerFields = () => {
  return (
    <>
      <CompanyDetailsField>
        <SimpleField
          type="text"
          placeholder="Company Legal Name:*"
          className="companyName"
          fieldName="companyName"
        />

        <AddressAutocompleteField />

        <SimpleField
          type="text"
          placeholder="Company Website:*"
          className="companyWebsite"
          fieldName="companyWebsite"
        />

        <SimpleField
          type="text"
          placeholder="Contact Name:*"
          className="constactName"
          fieldName="contactName"
        />

        <SimpleField
          type="text"
          placeholder="Email:*"
          className="email"
          fieldName="email"
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}>
          <PhoneFieldWrapper<PartnerRegistrationFormValues>
            placeholder="Phone Number:*"
            registerName="phone"
          />

          <DirectContactGuidelines>
            This contact information you enter will be provided to customers you
            are connected with. Please provide the best phone # and emails for
            them to reach you directly.
          </DirectContactGuidelines>
        </Box>

        <ToggleableInputField
          type="string"
          placeholder="DOT #:*"
          fieldName="dot"
          checkboxLabel="I don’t have a DOT #, I only do in-state moves."
          checkboxName="isDotActive"
          className="dot"
        />

        {/*<ToggleableInputField*/}
        {/*  type="number"*/}
        {/*  placeholder="State Registration  #:*"*/}
        {/*  fieldName="registerState"*/}
        {/*  checkboxLabel="I don’t have a State Registration "*/}
        {/*  checkboxName="isRegisterStateActive"*/}
        {/*  className="registerState"*/}
        {/*/>*/}
      </CompanyDetailsField>

      <GoogleReview
        icon={googlePng}
        placeholder="Google Reviews Link"
        fieldName="googlePlaceId"
      />

      <PhoneFieldWithConsent
        phonePlaceholder="Cell Phone # for Text Notifications (Optional)"
        phoneFieldName="phoneNumberForTexts"
        consentCheckboxLabel="I agree to receive text messages to the cell phone number provided when being connected to prospective customers. Standard messaging rates apply."
        consentCheckboxName="isPhoneNumberForTextsActive"
      />
    </>
  );
};
export default BecomePartnerFields;
