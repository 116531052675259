import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@components/Button';
import Typography from '@components/Typography';
import Stack from '@mui/material/Stack';

import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 33,

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
  },
}));

export const RootBox = styled(Box)(({ theme }) => ({
  width: 634,
  backgroundColor: COLORS.white,
  border: `1px solid ${COLORS.darkBlue}`,
  borderRadius: '26px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    minHeight: 'max-content',
    maxWidth: '100%',
  },
}));

export const Title = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  fontFamily: FontFamilyNames.PlainLight,
  margin: '8.5px 27px 30.41px',
  color: COLORS.darkBlue,
  fontSize: '16px',
  lineHeight: '130%',
  letterSpacing: '-0.32px',

  [theme.breakpoints.down('sm')]: {
    fontWeight: 300,
    fontSize: 14,
    lineHeight: 'normal',
    margin: '8.19px 31px 29px 32px'
  },
}));

export const PriceBox = styled(Stack)(({ theme }) => ({
  width: 634,
  alignItems: 'center',
  backgroundColor: COLORS.amour,
  padding: '32px 0',
  border: `1px solid ${COLORS.darkBlue}`,
  borderBottom: 'none',
  borderRadius: '26px',
  gap: '32px',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    padding: '32px 0',
  },
}));

export const PriceNumberStack = styled(Stack)(() => ({
  justifyContent: 'center',
  paddingBottom: '32px',
  borderBottom: `1px solid ${COLORS.darkBlue}`,
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px'
}));

export const PriceNumber = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.MoretRegular,
  fontWeight: 400,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'end',
  fontSize: '48px',
  letterSpacing: 'normal',
  lineHeight: '125%',

  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',
    alignItems: 'center',
    lineHeight: '40px',
  },
}));

export const Plus = styled(PriceNumber)(({ theme }) => ({
  margin: '0 18px',
  fontSize: '45px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',
  },
}));

export const PricePeriod = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '14px',
  lineHeight: 'normal',
  letterSpacing: '-0.28px',
  marginBottom: '9.06px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    margin: '0 0 0 6px',
    letterSpacing: 'normal',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: 20,
  lineHeight: '135%',
  textAlign: 'center',
  fontFamily: FontFamilyNames.PlainLight,
  color: COLORS.darkBlue,
  margin: '0 auto',
  marginTop: 32,
  maxWidth: 431,
  letterSpacing: '-0.01em',

  '& span': {
    textDecoration: 'underline',
    margin: '0 5px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0 32px',
  },
}));

export const ApplyNowButton = styled(Button)(({ theme }) => ({
  fontSize: '24px',
  padding: '15px 62px 20px 62px',
  lineHeight: 'unset',
  maxHeight: 52,

  [theme.breakpoints.down('sm')]: {
    alignSelf: 'center',
    maxWidth: '156px',
    fontSize: '20px',
    padding: '0',
    width: '100%',
    height: '44px',

    '&.footerApplyNow': {
      width: '224px',
      height: '36px',
      maxWidth: '100%',
    },
  },
}));

export const StyledTypography = styled(Typography)(() => ({
  fontFamily: FontFamilyNames.MoretRegular,
  paddingTop: '24px',
  letterSpacing: 'normal'
}));
