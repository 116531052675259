import fullPackingServicesRightSvg from '@assets/svg/fullPackingServicesRight.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';

import Button from '@components/Button';
import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { useDeleteMoveLogic } from './useDeleteMoveLogic';
import {
  StyledModal,
  StyledLeftLogo,
  StyledIconButton,
  StyledModalTitle,
  StyledDialogContent,
  Description
} from './styled';

const DeleteMove = () => {
  const {
    matches,
    handleClose,
    handleDelete
  } = useDeleteMoveLogic()


  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type='closed'
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>

      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label={`Are You Sure You Want\n To Delete This Moo’ve?`}
          textAlign="center"
        />
        <Description alignItems="center">
          If you delete your Moo’ve, current Moo’ve will be moved to Inactive and truckers won’t be able to place bids on it anymore.
        </Description>
        <Button
          onClick={() => handleDelete()}
        >
          Delete Move
        </Button>
        <StyledLeftLogo src={fullPackingServicesRightSvg} />
      </StyledDialogContent>
    </StyledModal >
  );
};

export default DeleteMove;
