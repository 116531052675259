import mooLogo from '@assets/svg/moo-login.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';

import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import { EMAIL_REGEX } from '@utils/regex';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { StyledErrorMessage } from '@components/ErrorMessage/styled';
import { useForgotPasswordLogic } from './useForgotPasswordLogic';
import {
  StyledModal,
  FieldWrapper,
  StyledLeftLogo,
  SubTitle,
  StyledTextField,
  StyledIconButton,
  StyledModalTitle,
  StyledLoginButton,
  MainErrorMessage,
  MainSuccessMessage,
  StyledDialogContent,
  ButtonAndMessageWrapper
} from './styled';

const ForgotPasswordModal = () => {
  const {
    errors,
    matches,
    isLoading,
    errorMessage,
    successMessage,
    register,
    handleClose,
    handleSubmit,
    handleLogin,
    clearMessages,
  } = useForgotPasswordLogic()


  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type='opened'
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>

      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Reset Password"
          textAlign="center"
        />
        <SubTitle>
          Enter your Email below and we will send link to reset your password.
        </SubTitle>
        <form onSubmit={handleSubmit(handleLogin, clearMessages)}>
          <Stack alignItems="center">
            <FieldWrapper>
              <StyledTextField
                placeholder='Your Email Address'
                variant="outlined"
                type="text"
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Email is a required field'
                  },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Please enter a valid email address.'
                  },
                })}
              />
              {errors && errors.email && (<StyledErrorMessage>{errors.email?.message}</StyledErrorMessage>)}
            </FieldWrapper>

            <ButtonAndMessageWrapper>
              <StyledLoginButton
                type="submit"
                disabled={!!isLoading}
              >
                {isLoading ? 'Loading ... ' : 'Reset Password'}
              </StyledLoginButton>
              {errorMessage && (<MainErrorMessage> {errorMessage}</MainErrorMessage>)}
              {successMessage && (<MainSuccessMessage> {successMessage}</MainSuccessMessage>)}
            </ButtonAndMessageWrapper>
          </Stack>
        </form>
        <StyledLeftLogo src={mooLogo} />
      </StyledDialogContent>
    </StyledModal >
  );
};

export default ForgotPasswordModal;
