import {styled, Theme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';
import {FontFamilyNames} from '@app/types/fonts';

export const StyledPromotionSection = styled(Stack)(({theme}) => ({
    margin: '110px 0 110px',

    [theme.breakpoints.down('md')]: {
        margin: '0',
        marginTop: 100,
    },

    [theme.breakpoints.down('sm')]: {
        margin: '60px 0 60px',
    },
}));

export const ImageBox = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('md')]: {},
}));


export const StyledMovingTrackBox = styled(Box)(({theme}) => ({
    position: 'absolute',
    width: '249.328px',
    height: '115.24px',
    left: '238px',
    top: '18px',

    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

export const StyledRow1 = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    height: 281,
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        height: 'auto',
        marginLeft: '0',
        gap: 40,
    }
}));

export const StyledMoveTrackerContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    width: '708px',
    justifyContent: 'space-between',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%',
        gap: 40,
    }
}));


export const StyledRow2 = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0',
        gap: 40,
    }
}));

export const StyledCardBox = styled(Box)(({theme}) => ({
    textAlign: 'center',

    '&.card-box-1': {
        width: '240px',
        height: '281px',
        display: 'flex',
        flexDirection: 'column',
        WebkitBoxAlign: 'center',
        alignItems: 'center',


        ".top": {
            width: '167.846px',
            height: '154.446px',
            img: {
                height: 'auto',
            },

        },

        ".bottom": {
            marginTop: '28.21px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',

            ".description": {
                fontSize: '16px',
                color: '#A09D95',
                fontStyle: 'normal',
                lineHeight: '130%'
            }
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '96px',
            display: 'flex',
            flexDirection: 'row',
            gap: '22px',
            alignItems: 'stretch',

            ".top": {
                marginBottom: '0',
                width: '104.999px',
                height: '96.617px'
            },

            ".bottom": {
                flex: '1',
                display: 'flex',
                gap: '9px',
                flexDirection: 'column',
                justifyContent: 'end',
                marginBottom: '11.62px',

                '.title': {
                    textAlign: 'left',
                    letterSpacing: '-0.32px',
                    lineHeight: '135%',
                },

                ".description": {
                    fontSize: '14px',
                    textAlign: 'left',
                }
            },
        },


    },

    '&.card-box-2': {
        width: "285px",
        height: '261.345px',

        ".top": {
            width: '204.841px',
            height: '149.961px',
            marginLeft: '46px',

            img: {
                height: 'auto',
            },
        },

        ".bottom": {
            marginTop: '30.38px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',

            ".description": {
                fontSize: '16px',
                color: '#A09D95',
                fontStyle: 'normal',
                lineHeight: '130%'
            }
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '89px',
            display: 'flex',
            flexDirection: 'row',
            gap: '22px',
            alignItems: 'stretch',

            ".top": {
                marginLeft: '0',
                width: '105px',
                height: '76.869px'
            },

            ".bottom": {
                flex: '1',
                display: 'flex',
                gap: '9px',
                flexDirection: 'column',
                justifyContent: 'end',
                marginTop: 0,

                '.title': {
                    textAlign: 'left',
                    letterSpacing: '-0.32px',
                    lineHeight: '135%'
                },

                ".description": {
                    fontSize: '14px',
                    textAlign: 'left',
                }
            },
        },


    },

    '&.card-box-3': {
        width: '285px',
        height: '223.115px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',

        ".top": {
            width: '204.84px',
            height: '132.732px',
            marginTop: '17.23px',
            marginRight: '43px',
            img: {
                height: 'auto',
            },
        },

        ".bottom": {
            marginTop: '30.38px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',

            ".description": {
                fontSize: '16px',
                color: '#A09D95',
                fontStyle: 'normal',
                lineHeight: '130%'
            }
        },


        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '89px',
            display: 'flex',
            flexDirection: 'row',
            gap: '22px',
            alignItems: 'stretch',

            ".top": {
                width: '105px',
                height: '68.037px',
                margin: '0',
            },

            ".bottom": {
                flex: '1',
                display: 'flex',
                gap: '9px',
                flexDirection: 'column',
                justifyContent: 'end',
                marginTop: 0,

                '.title': {
                    textAlign: 'left',
                    letterSpacing: '-0.32px',
                    lineHeight: '135%'
                },

                ".description": {
                    fontSize: '14px',
                    textAlign: 'left',
                }
            },
        },
    },

    '&.card-box-4': {
        width: '285px',
        height: '266px',

        ".top": {
            width: '160px',
            height: '160px',
            marginLeft: '55px',
            img: {
                height: 'auto',
            },
        },

        ".bottom": {
            marginTop: '25.08px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',

            '.title': {
                letterSpacing: 'normal',
            },

            ".description": {
                fontSize: '16px',
                color: "#A09D95",
                fontStyle: 'normal',
                lineHeight: '130%'
            }
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '105px',
            display: 'flex',
            flexDirection: 'row',
            gap: '22px',
            alignItems: 'stretch',

            ".top": {
                width: '105px',
                height: '100%',
                margin: '0',
            },

            ".bottom": {
                flex: '1',
                display: 'flex',
                gap: '9px',
                flexDirection: 'column',
                marginTop: 0,
                justifyContent: 'start',

                '.title': {
                    textAlign: 'left',
                    letterSpacing: '-0.32px',
                    lineHeight: '135%'
                },

                ".description": {
                    fontSize: '14px',
                    textAlign: 'left',
                }
            },
        },

    },

    '&.card-box-5': {
        width: '285px',
        height: '258.945px',

        ".top": {
            width: '143.916px',
            height: '150px',
            marginLeft: '75px',

            img: {
                height: 'auto',
                marginTop: '6.2px',
            },
        },

        ".bottom": {
            marginTop: '27.94px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',

            ".description": {
                fontSize: '16px',
                color: '#A09D95',
                fontStyle: 'normal',
                lineHeight: '130%'
            }
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '109px',
            display: 'flex',
            flexDirection: 'row',
            gap: '22px',
            alignItems: 'stretch',

            ".top": {
                marginLeft: '0',
                width: '105px',
                height: '100%',

                img: {
                    marginTop: '0',
                }
            },

            ".bottom": {
                flex: '1',
                display: 'flex',
                gap: '9px',
                flexDirection: 'column',
                marginTop: 0,
                justifyContent: 'start',

                '.title': {
                    textAlign: 'left',
                    letterSpacing: '-0.32px',
                    lineHeight: '135%'
                },

                ".description": {
                    fontSize: '14px',
                    textAlign: 'left',
                    letterSpacing: '-0.14px',
                }
            },
        },

    },

    '&.card-box-6': {
        width: '285px',
        height: '259px',

        ".top": {
            width: '135.072px',
            height: '152px',
            marginLeft: '74px',
            marginTop: '11px',

            img: {
                height: 'auto',
            },
        },

        ".bottom": {
            marginTop: '21.88px',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',

            ".description": {
                fontSize: '16px',
                color: '#A09D95',
                fontStyle: 'normal',
                lineHeight: '130%'
            }
        },

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '118px',
            display: 'flex',
            flexDirection: 'row',
            gap: '22px',
            alignItems: 'stretch',

            ".top": {
                marginLeft: '0',
                width: '105px',
                height: '100%',
                marginTop: '0px',

            },

            ".bottom": {
                flex: '1',
                display: 'flex',
                gap: '9px',
                flexDirection: 'column',
                marginTop: 0,
                justifyContent: 'start',

                '.title': {
                    textAlign: 'left',
                    letterSpacing: '-0.32px',
                    lineHeight: '135%'
                },

                ".description": {
                    fontSize: '14px',
                    textAlign: 'left',
                    letterSpacing: '-0.14px',
                }
            },
        },


    },

    [theme.breakpoints.down('md')]: {
        minHeight: 'unset',
    },
}));

export const StyledCardContainer = styled(Box)(({theme}: { theme: Theme }) => ({
    maxWidth: '1100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '49px',
    marginTop: 50,

    [theme.breakpoints.down('sm')]: {
        marginTop: 40,
    },
}));


export const Title = styled(Typography)(({theme}) => ({
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '135%',
    letterSpacing: '-0.4px',

    [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '75%',
        letterSpacing: 'normal'
    },
}));

export const DescriptionText = styled(Typography)(() => ({}));

export const StyledTitle = styled(Typography)(({theme}) => ({
    fontFamily: FontFamilyNames.MoretRegular,
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {
        lineHeight: '85%',
    },
}));

