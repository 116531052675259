import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Box, Stack, useMediaQuery } from '@mui/material';

import appLogo from '@assets/svg/app-logo.svg';
import homeBanner from '@assets/svg/homeBanner.svg';
import homeBannerMobile from '@assets/svg/homeBannerMobile.svg';

import { PAGE_ROUTES_PRIVATE, PAGE_ROUTES_PUBLIC } from 'app/types/enums/pages';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { PopUpEnum } from '@app/store/features/app/app.types';

import {
  Footer as StyledFooter,
  FooterBottomText,
  FooterContent,
  FooterInfoBox,
  FooterInfoText,
  FooterLine,
  FooterLink,
  FooterLinks,
  FooterLinksBox,
  FooterLogo,
  BannerBox,
} from './styled';
import { LINKS } from '@constants/links';
import { isDebugMode } from '@utils/constants';

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(({ auth }) => auth);

  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <BannerBox>
        <img src={matches ? homeBannerMobile : homeBanner} alt="" />
      </BannerBox>
      <StyledFooter>
        <FooterLogo src={appLogo} alt="appLogo" />
        <FooterContent>
          <FooterInfoBox>
            <FooterInfoText>
              <Box>
                Moo Moves You, LLC
                <br />
                3500 NW 2nd Ave.
                <br />
                Suite 623
                <br />
                Boca Raton FL 33431
              </Box>
              <Box />
            </FooterInfoText>
          </FooterInfoBox>

          <FooterLinksBox>
            <FooterLinks>
              <Box>
                {/* <FooterLink onClick={() => navigate(PAGE_ROUTES_PUBLIC.ABOUT)}>About Moo</FooterLink> */}
                <FooterLink
                  onClick={() => navigate(PAGE_ROUTES_PUBLIC.HOW_IT_WORKS)}>
                  How Moo works
                </FooterLink>
                <FooterLink onClick={() => navigate(PAGE_ROUTES_PUBLIC.FAQ)}>
                  F.A.Q.
                </FooterLink>
              </Box>

              <Box>
                {/*{isDebugMode && (*/}
                {/*  <FooterLink*/}
                {/*    onClick={() => {*/}
                {/*      window.location.href = LINKS.MOVINGTIPS;*/}
                {/*    }}>*/}
                {/*    Moo'ving Tips*/}
                {/*  </FooterLink>*/}
                {/*)}*/}
                <FooterLink
                  onClick={() => {
                    navigate(PAGE_ROUTES_PUBLIC.TRUCKER_LANDING);
                  }}>
                  Moo'ving Partner
                </FooterLink>
                <FooterLink
                  onClick={() => {
                    navigate(PAGE_ROUTES_PUBLIC.AFFILIATE);
                  }}>
                  Referral Partner
                </FooterLink>
              </Box>

              <Box>
                {isDebugMode && (
                  <FooterLink
                    onClick={() => {
                      if (!auth.userInfo) {
                        dispatch(setPopUpName({ name: PopUpEnum.LoginModal }));
                        return;
                      }

                      navigate(PAGE_ROUTES_PRIVATE.PROFILE);
                    }}>
                    {auth.userInfo ? 'My Profile' : 'Login'}
                  </FooterLink>
                )}

                <FooterLink
                  onClick={() =>
                    dispatch(setPopUpName({ name: PopUpEnum.ContactUsModal }))
                  }>
                  Contact Us
                </FooterLink>
              </Box>

              <Box>
                <FooterLink
                  onClick={() =>
                    navigate(PAGE_ROUTES_PUBLIC.TERMS_AND_CONDITIONS)
                  }>
                  Terms of Use
                </FooterLink>
                <FooterLink
                  onClick={() => navigate(PAGE_ROUTES_PUBLIC.PRIVACY_POLICY)}>
                  Privacy Policy
                </FooterLink>
                <FooterLink
                  onClick={() => navigate(PAGE_ROUTES_PUBLIC.COOKIES_POLICY)}>
                  Cookie Policy
                </FooterLink>
              </Box>
            </FooterLinks>
            <Stack>
              <FooterLink href="tel:786-535-5979">(786) 535-5979</FooterLink>
              <FooterLink href="mailto:support@moomovesyou.com">
                support@moomovesyou.com
              </FooterLink>
            </Stack>
          </FooterLinksBox>
        </FooterContent>
        <FooterLine />
        <FooterBottomText>(C) 2023 Moo Moves You</FooterBottomText>
      </StyledFooter>
    </>
  );
};

export default Footer;
