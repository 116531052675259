import { memo } from 'react';
import { useNavigate } from 'react-router-dom';


import { ReactComponent as MobileCloseMenuIcon } from '@assets/svg/mobile-close-icon.svg';
import Stack from '@mui/material/Stack';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';

import { useAppDispatch } from '@app/store/hooks';
import MobileDrawerMenu from './components/MobileDrawerMenu';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { StyledMuiDrawer, StyledBottomLink, StyledBottomLinksStack, StyledDrawerContent, StyledMenuBox } from './styled';

const MainMenuModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setPopUpName({ name: PopUpEnum.Empty }))
  }
  return (
    <StyledMuiDrawer
      open={true}
      anchor="right"
      variant="temporary"
    >
      <Stack alignItems="flex-end">
        <BurgerMenuButton
          className='menu-icon black'
          type='opened'
          onClick={() => {
            handleClose()
          }}
        >
          <MobileCloseMenuIcon />
        </BurgerMenuButton>
      </Stack>
      <StyledDrawerContent>

        <StyledMenuBox>
          <MobileDrawerMenu />
        </StyledMenuBox>

        <StyledBottomLinksStack>
          <StyledBottomLink
            label="Terms of Use"
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.TERMS_AND_CONDITIONS);
              handleClose()
            }}
          />
          <StyledBottomLink
            label="Privacy Policy"
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.PRIVACY_POLICY);
              handleClose()
            }}
          />
          {/* <StyledBottomLink
            label="Cookie policy"
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.COOKIES_POLICY);
              handleClose()
            }}
          /> */}
          <StyledBottomLink label="(C) 2023 Moo Moves You" />
        </StyledBottomLinksStack>

      </StyledDrawerContent>
    </StyledMuiDrawer>
  );
};

export default memo(MainMenuModal);
