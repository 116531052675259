import { useNavigate } from 'react-router-dom';

import trackerLanding from '@assets/svg/tracker-landing.svg';
import mobileTrackerLanding from '@assets/svg/tracker-landing-mobile.svg';
import Box from '@mui/material/Box';
import Typography from '@components/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import {
  ImageBox,
  ApplyNowContent,
  StyledStackWrapper,
  ApplyNowButton,
  ImagePlaceholder,
  Image,
  StyledTitleAndSubTitleStack,
} from './styled';

const ApplyNowSection = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {matches ? (
        <>
          <ApplyNow />
          <Image
            imagePlaceholder={<ImagePlaceholder />}
            objectFit="cover"
            objectPosition="bottom"
            src={mobileTrackerLanding}
          />
        </>
      ) : (
        <>
          <ImageBox>
            <Image
              imagePlaceholder={<ImagePlaceholder />}
              objectFit="cover"
              objectPosition="bottom"
              src={trackerLanding}
            />
            <ApplyNow />
          </ImageBox>
        </>
      )}
    </Box>
  );
};

const ApplyNow = () => {
  const navigate = useNavigate();

  return (
    <ApplyNowContent>
      <StyledStackWrapper>
        <StyledTitleAndSubTitleStack spacing="22px">
          <Typography className="title" variant="h1" component="h2">
            {`Become a Moo  Moo'ving Partner Today!`}
          </Typography>
          <Typography className="subTitle" variant="h5" component="p">
            {`The easiest way to get more moving jobs!`}
          </Typography>
        </StyledTitleAndSubTitleStack>

        <ApplyNowButton
          label="Sign Up Now!"
          onClick={() => {
            navigate(PAGE_ROUTES_PUBLIC.TRUCKER_BECOME_PARTNER);
          }}
        />
      </StyledStackWrapper>
    </ApplyNowContent>
  );
};

export default ApplyNowSection;
