import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    maxWidth: '670px',
    height: '500px',
    borderRadius: '20px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      margin: 0,
      borderRadius: 0,

      '.MuiDialogContent-root': {
        padding: 0,
      },
    },
  },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));
