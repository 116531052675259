import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

export const MoveContentStyled = styled(Stack)(({ theme }) => ({
  padding: '61px 71px 60px 70px',

  '@media(max-width:1439px)': {
    padding: '61px 31px 60px 30px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '32px 20px 32px 20px',
  },
}));
