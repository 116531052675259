import { Location } from '@app/types/model/allMoveInfo.model';

export const isMoveInstate = (
  origin?: Location,
  destination?: Location,
): boolean => {
  const originState = origin?.state;
  const destinationState = destination?.state;

  return originState === destinationState;
};
