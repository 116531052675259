import {
  BidCardContainer,
  CompanyName,
  TitleText,
  InfoSection,
  StyledLink,
  ContentText,
  ContactInfoSection,
} from './styled';
import { formatPhoneNumber } from '@utils/helpers';
import GoogleReviewsRating from '../GoogleReviewsRating/GoogleReviewsRating';

interface ILeadProps {
  companyName: string;
  companyWebsite: string;
  contactName: string;
  dot: number;
  email: string;
  phone: string;
  rating: number | null;
  googlePlaceId: string | null;
}

const Lead = ({
  companyName,
  companyWebsite,
  contactName,
  dot,
  email,
  phone,
  rating,
  googlePlaceId,
}: ILeadProps) => {
  return (
    <BidCardContainer>
      <CompanyName>{companyName}</CompanyName>

      <InfoSection>
        <TitleText variant="subtitle2">Website</TitleText>
        <StyledLink href={companyWebsite} target="_blank">
          {companyWebsite}
        </StyledLink>
      </InfoSection>

      {dot && (
        <InfoSection>
          <TitleText variant="subtitle2">
            Department of Transportation #
          </TitleText>
          <ContentText>{dot}</ContentText>
        </InfoSection>
      )}

      <ContactInfoSection>
        <TitleText variant="subtitle2">Moving Company's Contact</TitleText>
        <ContentText>{contactName}</ContentText>
        <ContentText>{email}</ContentText>
        <ContentText>{formatPhoneNumber(phone)}</ContentText>
      </ContactInfoSection>

      {rating && (
        <GoogleReviewsRating googlePlaceId={googlePlaceId} rating={rating} />
      )}
    </BidCardContainer>
  );
};

export default Lead;
