import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  UseFormGetFieldState,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { useAppSelector } from '@app/store/hooks';
import { IMoveRequestFormValues } from '@modules/home/types/move.types';
import { LevelsType } from './useMoveRegistration';
import { deletePlusFromPhone } from '@utils/helpers';
import { looseFurnitureOption } from '../Content/components/Process/data';

interface IUseListener {
  levels: LevelsType;
  values: IMoveRequestFormValues;
  watch: UseFormWatch<IMoveRequestFormValues>;
  setValue: UseFormSetValue<IMoveRequestFormValues>;
  setLevels: Dispatch<SetStateAction<LevelsType>>;
  getFieldState: UseFormGetFieldState<IMoveRequestFormValues>;
}

export const useFormValidation = ({
  values,
  levels,
  setLevels,
  setValue,
  getFieldState,
}: IUseListener) => {
  const [isValid, setIsValid] = useState(false);

  const auth = useAppSelector(({ auth }) => auth);

  useEffect(() => {
    const now = dayjs().startOf('day');
    const {
      from: sourceAddress,
      to: destinationAddress,
      date,
      currentHouseSize,
      fromPlace,
      toPlace,
      packingServices,
    } = values;

    const isDateValid = date && (date.isSame(now) || date.isAfter(now));
    const isAddressValid =
      sourceAddress?.address && destinationAddress?.address;
    const isHouseSizeValid = Boolean(currentHouseSize?.value);
    const isFromToPlaceValid = Boolean(
      fromPlace.value && toPlace.value && packingServices.value,
    );

    const shouldEnableThirdLevel =
      levels.second &&
      !levels.third &&
      isAddressValid &&
      isDateValid &&
      isHouseSizeValid &&
      isFromToPlaceValid;

    if (shouldEnableThirdLevel) {
      setLevels(prevLevels => ({ ...prevLevels, third: true }));
    }
  }, [values, levels, setLevels]);

  useEffect(() => {
    if (auth.userInfo) {
      const { fullName, phone, email } = auth.userInfo;

      const fixedPhone = deletePlusFromPhone(phone);

      setValue('name', fullName);
      setValue('email', email);
      setValue('phone', fixedPhone);
    }
  }, [auth?.userInfo]);

  useEffect(() => {
    const isDateValid = !!values.date && dayjs(values.date).isValid();
    const isFromAddressValid = !!values.from?.address && !!values.from?.placeId;
    const isToAddressValid = !!values.to?.address && !!values.to?.placeId;
    const isCurrentHouseSizeValid = !!values.currentHouseSize?.value;
    const isLooseFurnitureSelected =
      values.currentHouseSize?.value === looseFurnitureOption.value;

    // Check if userInfo exists and contains necessary fields
    const userInfoExists =
      !!auth.userInfo &&
      auth.userInfo.fullName &&
      auth.userInfo.phone &&
      auth.userInfo.email;

    const validateField = (fieldName: keyof typeof values) => {
      // If userInfo exists, skip validation for name, phone, and email
      if (
        userInfoExists &&
        (fieldName === 'name' || fieldName === 'phone' || fieldName === 'email')
      ) {
        return true; // Assume these fields are valid
      }

      const fieldState = getFieldState(fieldName);
      const isValid =
        (fieldState.isTouched || fieldState.isDirty) &&
        !fieldState.error &&
        values[fieldName];
      return isValid;
    };

    const isContactInfoValid =
      userInfoExists ||
      (validateField('name') &&
        validateField('phone') &&
        validateField('email'));

    const isEverythingValid =
      isDateValid &&
      isFromAddressValid &&
      isToAddressValid &&
      isCurrentHouseSizeValid &&
      !isLooseFurnitureSelected &&
      isContactInfoValid;

    setIsValid(!!isEverythingValid);
  }, [values, levels, auth.userInfo]);

  return { isValid };
};
