import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Button from '@components/Button';
import { FontFamilyNames } from '@app/types/fonts';

export const BackgroundWrapper = styled(Toolbar)<{ background: string }>(
  ({ background }) => ({
    background,
    width: '100%',
    padding: 0,
  }),
);

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  ...theme.mixins.toolbar,
  width: '100%',
  maxWidth: 1440,
  zIndex: 9,
  margin: '0 auto',
  padding: '0 70px',
  display: 'flex',
  justifyContent: 'space-between',

  '&.isCheckout': {
    background: 'transparent',
  },
  '.cloud': {
    display: 'none',
  },

  [theme.breakpoints.down('lg')]: {
    padding: '0 35px',
    gap: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    '.cloud': {
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
}));

export const StyledMenuItem = styled(Typography)<{ disabled?: boolean }>(
  ({ disabled }) => ({
    cursor: 'pointer',

    ...(disabled && {
      opacity: 0.5,
    }),
  }),
);

export const LeftSide = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const RightSide = styled(Stack)(({ theme }) => ({
  '> div': {
    alignItems: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    '> .MuiStack-root': {
      display: 'none',
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.28px',
  width: '173px',
  height: '42px',

  [theme.breakpoints.down('sm')]: {},
}));
