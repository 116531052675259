import { PaletteOptions } from '@mui/material';

import { COLORS } from '@utils/colors';

export const palette: PaletteOptions = {
  common: {
    black: COLORS.darkBlue,
  },
  background: {
    default: COLORS.white,
    paper: COLORS.white,
  },
  grey: COLORS.grey,
  primary: {
    contrastText: COLORS.black,
    main: COLORS.lightGreen,
  },
  secondary: {
    contrastText: COLORS.black,
    main: COLORS.beige,
  },
  info: {
    main: COLORS.amour,
    contrastText: COLORS.black,
  },
  error: {
    contrastText: COLORS.white,
    main: COLORS.error.main,
  },
  text: {
    primary: COLORS.darkBlue,
  },
};
