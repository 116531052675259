import { useTheme } from 'styled-components';

import { useMediaQuery } from '@mui/material';
import { useAppDispatch } from '@app/store/hooks';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { setPopUpName } from '@app/store/features/app/app.slice';

export const useCreateNewMoveLogic = () => {
  const theme = useTheme();
  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setPopUpName({ name: PopUpEnum.Empty }))
  };

  return {
    matches,
    handleClose
  }
}
