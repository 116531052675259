// @ts-nocheck
import { useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '@assets/svg/arrow-left.svg';

import { items, swiperParams } from './data';
import { ReactComponent as FilledStar } from '@assets/svg/filled-star.svg';
import { ReactComponent as UnfilledStar } from '@assets/svg/unfilled-star.svg';
import 'swiper/css';
import {
  Container,
  Title,
  StyledSwiper,
  StyledSwiperSlideItem,
  StarsWrapper,
  Quote,
  Avatar,
  Name,
  PaginationBox,
  NavigationButton,
  SlideInfo,
} from './styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const TestimonialSwiper = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const result = activeSlide + 1;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const renderRating = (rating: number) => {
    const filled = Array.from({ length: rating }, (_, index) => index + 1);
    const unfilled = Array.from(
      { length: 5 - rating },
      (_, index) => index + 1,
    );

    return (
      <>
        {filled.map(item => (
          <FilledStar key={item} />
        ))}
        {unfilled.map(item => (
          <UnfilledStar className="special-size-for-svg" key={item} />
        ))}
      </>
    );
  };

  return (
    <Container>
      <Title>What Do Our Customers Say?</Title>
      <StyledSwiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        {...swiperParams}
        initialSlide={matches ? 0 : 1}
        centeredSlides={true}
        onSwiper={swiper => {
          setActiveSlide(swiper.activeIndex);
        }}
        onSlideChange={swiper => {
          setActiveSlide(swiper.activeIndex);
        }}>
        {items.map(
          ({
            id,
            name,
            mobileDescription,
            desktopDescription,
            rating,
            img,
          }) => (
            <StyledSwiperSlideItem key={id}>
              <Avatar src={img} isActive={activeSlide === id} />
              <Name>{name}</Name>
              <Quote>{matches ? mobileDescription : desktopDescription}</Quote>
              <StarsWrapper>
                {activeSlide !== id
                  ? [1, 2, 3, 4, 5].map(item => <UnfilledStar key={item} />)
                  : renderRating(rating)}
              </StarsWrapper>
            </StyledSwiperSlideItem>
          ),
        )}
      </StyledSwiper>

      <PaginationBox>
        <NavigationButton>
          <ArrowLeftIcon />
        </NavigationButton>
        <SlideInfo>
          {result} / {items.length}
        </SlideInfo>
        <NavigationButton>
          <ArrowRightIcon />
        </NavigationButton>
      </PaginationBox>
    </Container>
  );
};

export default TestimonialSwiper;
