import { useNavigate } from 'react-router-dom';

import { FooterLink, FooterLinks, FooterLinksStack } from '@modules/truckerBecomePartner/styled';
import { StyledFooter } from './styled';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';

const FooterSection = () => {
  const navigate = useNavigate();

  return (
    <StyledFooter>
      <FooterLinksStack>
        <FooterLinks>
          <FooterLink label="Terms of Use" onClick={() => navigate(PAGE_ROUTES_PUBLIC.TERMS_AND_CONDITIONS)} />
          <FooterLink label="Privacy Policy" onClick={() => navigate(PAGE_ROUTES_PUBLIC.PRIVACY_POLICY)} />
        </FooterLinks>
        <FooterLink label="(C) 2023 Moo Moves You" />
      </FooterLinksStack>
    </StyledFooter>
  );
};

export default FooterSection;
