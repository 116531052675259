import { FontFamilyNames } from '@app/types/fonts';
import { Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { COLORS } from '@utils/colors';

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 37,
  padding: '0 70px',

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
  },
}));

export const StyledMenuItem = styled(Typography)<{ cursor: string }>(
  ({ cursor }) => ({
    cursor: cursor,
    textAlign: 'center',
    fontFamily: FontFamilyNames.PlainLight,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '125%',
    letterSpacing: '-0.28px',
    color: COLORS.darkBlue,
  }),
);
