import { MoveNavbarEnum } from '@modules/profile/types/move-navbar.enum';
import MovesList from '@modules/profile/components/Content/components/Moves/components/MovesList';
import { MoveContentStyled } from '@modules/profile/components/Content/components/Moves/components/MovesContent/styled';
import { CurrentMoves } from '@modules/profile/components/Content/components/Moves/components';
import Account from '@modules/profile/components/Account';

interface IMovesContentProps {
  activeTab: MoveNavbarEnum;
}

const MovesContent = ({ activeTab }: IMovesContentProps) => {
  const renderMoves = () => {
    switch (activeTab) {
      case MoveNavbarEnum.CURRENT:
        return (
          <MoveContentStyled>
            <CurrentMoves activeTab={activeTab} />
          </MoveContentStyled>
        );
      case MoveNavbarEnum.PAST:
        return (
          <MoveContentStyled>
            <MovesList activeTab={activeTab} />
          </MoveContentStyled>
        );
      // TODO: here should be account component
      case MoveNavbarEnum.ACCOUNT:
        return <Account />;
      default:
        // Potentially throw an error or return null for an unsupported type
        return null;
    }
  };

  return renderMoves();
};

export default MovesContent;
