import { styled } from '@mui/material/styles';
import Typography from '@components/Typography';
import Box from "@mui/material/Box";
import {COLORS} from "@utils/colors";

export const FlexColumnBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const PrimaryText = styled(Typography)(({ theme }) => ({
  fontSize: '24.117px',
  lineHeight: '135%',
  [theme.breakpoints.down('sm')]: {
    color: COLORS.darkBlue,
    fontSize: '18px',
  },
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  color: '#A09D95',
  fontSize: '16.078px',
  [theme.breakpoints.down('sm')]: {
    letterSpacing: '-0.14px',
    fontSize: '14px',
    lineHeight: '130%',
  },
}));
