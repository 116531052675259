import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@components/Button';
import star from '@assets/svg/littleStar.svg';
import aboutFAQ from '@assets/svg/inHomeAboutFAQ.svg';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import aboutFAQMobile from '@assets/svg/inHomeAboutFAQMobile.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';
import {
  Title,
  MooImg,
  Wrapper,
  FlexBox,
  Container,
  StyledTypography,
} from './styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';

const desktopOrder1 = [
  'Local Moving',
  'Long Distance Moving',
  'Cross Country Moving',
  'Senior Moving & Nursing Homes',
  'White Glove & Full Service Moves',
];
const desktopOrder2 = [
  'Intrastate Moving',
  'Student Moving',
  'Estate Moving',
  'Packing Services',
  'Storage Services',
];

const mobileOrder1 = [
  'Intrastate Moving',
  'Long Distance Moving',
  'Student Moving',
  'Local Moves',
  'Estate Moving',
];
const mobileOrder2 = [
  'Packing Services',
  'Storage Services',
  'White Glove & Full Service Moves',
  'Senior Moving & Nursing Homes',
];

const renderDesktopView = () => {
  return (
    <>
      <Box>
        {desktopOrder1.map(item => (
          <StyledTypography key={item}>
            <img src={star} alt="star 1" /> {item}{' '}
          </StyledTypography>
        ))}
      </Box>
      <Box>
        {desktopOrder2.map(item => (
          <StyledTypography key={item}>
            <img src={star} alt="star 2" /> {item}{' '}
          </StyledTypography>
        ))}
      </Box>
    </>
  );
};

const renderMobileView = () => {
  return (
    <>
      <Box>
        {mobileOrder1.map(item => (
          <StyledTypography key={item}>
            <img src={star} alt="order 1" /> {item}{' '}
          </StyledTypography>
        ))}
      </Box>
      <Box>
        {mobileOrder2.map(item => (
          <StyledTypography key={item}>
            <img src={star} alt="order 2" /> {item}{' '}
          </StyledTypography>
        ))}
      </Box>
    </>
  );
};

const Services = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container>
      <Wrapper>
        <Stack flex={1}>
          <Title>Moving Services We Help You With {matches ? ':' : ''}</Title>
          <FlexBox>
            {matches ? renderMobileView() : renderDesktopView()}
          </FlexBox>
          <Button
            endIcon={<ArrowRightIcon />}
            onClick={() => {
              navigate(PAGE_ROUTES_PUBLIC.FAQ);
            }}>
            Read our FAQ’s
          </Button>
        </Stack>
        <Box>
          {matches ? (
            <MooImg src={aboutFAQMobile} />
          ) : (
            <MooImg src={aboutFAQ} />
          )}
        </Box>
      </Wrapper>
    </Container>
  );
};

export default Services;
