import Select from 'react-select';

import { useFormContext } from 'react-hook-form';
import { selectStyles } from './styled';
import Stack from '@mui/material/Stack';

interface ISelectProps {
  name: string;
  options: { value: string; label: string; isDisabled: boolean }[];
}

const StyledSelect = ({ name, options }: ISelectProps) => {
  const { watch, setValue, getValues } = useFormContext();

  return (
    <Stack>
      <Select
        placeholder="Home Size"
        value={watch(name)}
        onChange={data => {
          setValue(name, data);
        }}
        options={options}
        isOptionDisabled={option => option.isDisabled}
        styles={selectStyles}
      />
    </Stack>
  );
};

export default StyledSelect;
