import { useNavigate } from 'react-router-dom';
import {
  ApplyNowButton,
  Container,
  PriceBox,
  PriceNumber,
  PriceNumberStack,
  PricePeriod,
  RootBox,
  Title,
  StyledTypography,
} from './styled';

const PriceSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <RootBox>
          <StyledTypography
            align="center"
            variant="h3"
            label="No Subscription Fee!"
          />
          <Title>
            Only pay for Instant Connections in your selected Service Areas!
          </Title>

          <PriceBox>
            <PriceNumberStack>
              <PriceNumber>
                $ 9.99
                <PricePeriod>/ Per Connection - Local Leads</PricePeriod>
              </PriceNumber>

              {/*<Plus>+</Plus>*/}

              <PriceNumber>
                $ 19.99
                <PricePeriod>/ Per Connection - Inter-State Leads</PricePeriod>
              </PriceNumber>
            </PriceNumberStack>

            <ApplyNowButton
              sx={{ width: '362px', padding: '12px 30px' }}
              onClick={() => navigate('/trucker-become-partner')}
              className="footerApplyNow"
              label="Sign Up Now!"
            />
          </PriceBox>
        </RootBox>
      </Container>
    </>
  );
};

export default PriceSection;
