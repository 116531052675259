import { styled, Theme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import { COLORS } from '@utils/colors';
import Box from '@mui/material/Box';

export const NavbarUnderline = styled(Box)(() => ({
  content: '',
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  height: '2px',
  backgroundColor: COLORS.grey[50],
  opacity: '0.2',
  transition: 'width 0.3s ease-in-out',
  willChange: 'width',
}));

export const MovesNavbar = styled(Stack)(({ theme }) => ({
  position: 'relative',
  background: COLORS.beige,
  padding: '0 70px',

  [theme.breakpoints.down('sm')]: {
    padding: 0,
    whiteSpace: 'nowrap',
  },
}));

export const Panel = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'unset',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 10,

  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
    overflowX: 'scroll',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '135%',
  letterSpacing: '-0.4px',
  cursor: 'pointer',
  paddingBottom: '13px',

  '&.currentMoveTitle': {
    display: 'flex',
    gap: '13px',
    alignItems: 'flex-end',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px 12px',
    fontSize: '14px',
    letterSpacing: '-0.14px',

    '&.pastMoveTitle': {
      display: 'flex',
      gap: '13px',
      alignItems: 'flex-end',
      padding: '0 15.5px 12px',
    },

    '&.accountMoveTitle': {
      display: 'flex',
      gap: '13px',
      alignItems: 'flex-end',
      padding: '0 19.5px 12px',
    },
  },
}));

const commonTitleStyles = ({ isActive }: { isActive: boolean }) => ({
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '135%',
  letterSpacing: '-0.4px',
  cursor: 'pointer',
  paddingBottom: '13px',
  boxShadow: isActive ? `inset 0 -2px 0 0 ${COLORS.lightGreen}` : 'none',
  display: 'flex',
  gap: '13px',
  alignItems: 'flex-end',
});

export const CurrentMoveTitle = styled(Typography)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    ...commonTitleStyles({ isActive }),

    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 12px',
      fontSize: '14px',
      letterSpacing: '-0.14px',
    },
  }),
);

export const PastMoveTitle = styled(Typography)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    ...commonTitleStyles({ isActive }),
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      letterSpacing: '-0.14px',
      padding: '0 15.5px 12px',
    },
  }),
);

export const AccountMoveTitle = styled(Typography)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    ...commonTitleStyles({ isActive }),
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      letterSpacing: '-0.14px',
      padding: '0 19.5px 12px',
    },
  }),
);

export const Left = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'unset',
  gap: '40px',

  [theme.breakpoints.down('sm')]: {
    gap: 0,
    paddingTop: 30,

    svg: {
      width: '19px',
      height: '19px',
      marginLeft: '6px',
      marginBottom: '-4px',
      display: 'none',
    },
  },
}));
