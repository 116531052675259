import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import {
  PartnerRegistrationFormValues,
  FormValuesPayload,
} from '@modules/truckerBecomePartner/types';
import { getFormValidationSchema } from '@modules/truckerBecomePartner/helper';
import {
  useLazyGetStatesQuery,
  useRegisterPartnerMutation,
} from '@modules/truckerBecomePartner/api/requests.api';
import { becomePartnerInitialState } from '@modules/truckerBecomePartner/data';
import {
  setStates,
  updateAgreement,
} from '@modules/truckerBecomePartner/store/becomePartner.slice';
import { errorHandler } from '@utils/errorHendler';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { PHONE_NUMBER_FORMAT } from '@utils/constants';

export const useBecomePartnerLogic = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.userInfo);
  const agreement = useAppSelector(({ become }) => become.agreement);
  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [getStates] = useLazyGetStatesQuery();
  const [signUpPartner] = useRegisterPartnerMutation();

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = getFormValidationSchema();

  const methods = useForm<PartnerRegistrationFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: becomePartnerInitialState,
  });

  const { handleSubmit, setError: setFormError, reset, getValues } = methods;

  const onSubmit = async (values: PartnerRegistrationFormValues) => {
    setIsLoading(true);
    try {
      const {
        dot,
        email,
        phone,
        companyName,
        contactName,
        isDotActive,
        companyWebsite,
        companyAddress,
        googlePlaceId,
        isGooglePlaceId,
        // registerState,
        // isRegisterStateActive,
        phoneNumberForTexts,
        isPhoneNumberForTextsActive,
      } = values;

      const formattedPhoneNumberForTexts =
        phoneNumberForTexts && isPhoneNumberForTextsActive
          ? `${PHONE_NUMBER_FORMAT}${phoneNumberForTexts}`
          : undefined;

      const payload: FormValuesPayload = {
        email,
        phone: `${PHONE_NUMBER_FORMAT}${phone}`,
        companyName,
        contactName,
        companyAddress,
        companyWebsite,
        dot: isDotActive ? undefined : dot,
        googlePlaceId:
          isGooglePlaceId && !googlePlaceId?.placeId
            ? undefined
            : googlePlaceId?.placeId,
        textMessagesPhone: formattedPhoneNumberForTexts,
      };

      const response = await signUpPartner(payload).unwrap();

      if (!response?.accessToken) {
        throw Error('Something went wrong.');
      }

      setIsLoading(false);
      setError('');

      dispatch(
        setPopUpName({
          name: PopUpEnum.AgreementPopup,
          params: {
            token: response?.accessToken || '',
          },
        }),
      );

      reset(becomePartnerInitialState);
    } catch (error) {
      const errorMessage = errorHandler(error, setFormError);
      console.log('Become partner errorMessage:', errorMessage);

      setError(errorMessage);
      setIsLoading(false);
      dispatch(updateAgreement(false));
    }
  };

  const handleGetStates = async () => {
    try {
      const response = await getStates().unwrap();

      dispatch(setStates(response));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetStates();
  }, []);

  useEffect(() => {
    if (agreement) {
      onSubmit(getValues());
    }
  }, [agreement]);

  return {
    isLoading,
    user,
    error,
    methods,
    matches,
    navigate,
    onSubmit,
    handleSubmit,
  };
};
