import { Components, Theme } from '@mui/material/styles';

const emptyOptionStyles = {
  marginTop: '2px',
  padding: '6px 16px',
  minHeight: '48px !important',
  display: 'flex',
  alignItems: 'center',
  color: '#fff',
  background: 'rgba(255, 255, 255, 0.05)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(32px)',
  borderRadius: '4px',
  cursor: 'default',
};

export const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    root: {},
    input: {
      // '::placeholder': {
      //   color: 'color: #8E8E93;',
      // },
    },
    popper: {},
    paper: {
      padding: 0,
      color: '#fff',
      background: 'transparent !important',
      boxShadow: 'none',
      transition: 'none !important',
      borderRadius: 0,
      opacity: 1,
    },
    listbox: {
      padding: '2px 0',
      maxHeight: '220px',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    option: {
      marginBottom: '2px',
      padding: '6px 16px',
      minHeight: '48px !important',
      color: '#fff',
      background: 'rgba(255, 255, 255, 0.05)',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(32px)',
      borderRadius: '4px',

      '&:hover, &.Mui-focused': {
        background: 'rgba(255, 255, 255, 0.2)',
        border: '1px solid rgba(255, 255, 255, 0.4)',
      },

      '&[aria-selected="true"], &[aria-selected="true"]:hover': {
        background: 'rgba(255, 255, 255, 0.4) !important',
        border: '1px solid rgba(255, 255, 255, 0.8)',
      },
    },
    noOptions: emptyOptionStyles,
    loading: emptyOptionStyles,
  },
};

export const setAutocompleteMedias = (theme: Theme) => {
  const emptyOptionMediaStyles = {
    marginTop: '-1px',
    padding: '6px 16px',
    minHeight: '40px !important',
  };

  theme.components = {
    ...theme.components,
    MuiAutocomplete: {
      ...theme.components?.MuiAutocomplete,
      styleOverrides: {
        ...theme.components?.MuiAutocomplete?.styleOverrides,
        input: {
          ...(theme.components?.MuiAutocomplete?.styleOverrides?.input as Object),
          [theme.breakpoints.down('md')]: {
            // @ts-ignore
            ...theme.components?.MuiAutocomplete?.styleOverrides?.input?.[theme.breakpoints.down('md')],
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            height: '40px',
          },
        },
        listbox: {
          ...(theme.components?.MuiAutocomplete?.styleOverrides?.listbox as Object),
          [theme.breakpoints.down('md')]: {
            // @ts-ignore
            ...theme.components?.MuiAutocomplete?.styleOverrides?.listbox?.[theme.breakpoints.down('md')],
            marginTop: '-1px',
            padding: 0,
            maxHeight: '220px',
          },
        },
        option: {
          ...(theme.components?.MuiAutocomplete?.styleOverrides?.option as Object),
          [theme.breakpoints.down('md')]: {
            // @ts-ignore
            ...theme.components?.MuiAutocomplete?.styleOverrides?.option?.[theme.breakpoints.down('md')],
            marginBottom: 0,
            minHeight: '40px !important',
            color: '#fff',
            backdropFilter: 'blur(32px)',
            borderRadius: 0,

            ':first-child': {
              borderRadius: '4px 4px 0 0',
            },

            ':last-child': {
              borderRadius: '0 0 4px 4px',
            },
          },
        },
        noOptions: {
          ...(theme.components?.MuiAutocomplete?.styleOverrides?.noOptions as Object),
          [theme.breakpoints.down('md')]: {
            // @ts-ignore
            ...theme.components?.MuiAutocomplete?.styleOverrides?.noOptions?.[theme.breakpoints.down('md')],
            ...emptyOptionMediaStyles,
          },
        },
        loading: {
          ...(theme.components?.MuiAutocomplete?.styleOverrides?.loading as Object),
          [theme.breakpoints.down('md')]: {
            // @ts-ignore
            ...theme.components?.MuiAutocomplete?.styleOverrides?.loading?.[theme.breakpoints.down('md')],
            ...emptyOptionMediaStyles,
          },
        },
      },
    },
  };
};
