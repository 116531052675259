import { Title, ImageBox, StyledCardBox, DescriptionText } from './styled';
import PlainImage from '@components/Image/PlainImage';
import Box from '@mui/material/Box';

interface IPromotionCardProps {
  imageSrc: string;
  title: string;
  description: string;
  id: number;
}

const PromotionCard = ({
  imageSrc,
  id,
  title,
  description,
}: IPromotionCardProps) => {
  return (
    <StyledCardBox className={`card-box-${id}`} key="promotion_2">
      <ImageBox className="top">
        <PlainImage
          className={`image-wrapper-${id}`}
          objectFit="cover"
          objectPosition="bottom"
          src={imageSrc}
        />
      </ImageBox>
      <Box className="bottom">
        <Title className="title" variant="h5" label={title} />
        <DescriptionText
          className="description"
          variant="body1"
          label={description}
        />
      </Box>
    </StyledCardBox>
  );
};

export default PromotionCard;
