import React, { FC } from 'react';
import PhoneVerificationForm from '@modals/PhoneVerificationModal/components/PhoneVerificationForm';
import CloseButton from '@modals/PhoneVerificationModal/CloseButton';
import SuccessModal from '@modals/SuccessModal';

interface PhoneVerificationModalProps {
  isOpen: boolean;
  isSubmitting: boolean;
  errorMessage: string;
  isResendDisabled: boolean;
  // setIsResendDisabled: (isResendDisabled: boolean) => void;
  onClose: () => void;
  phoneNumber: string;
  onOtpChange: (otp: string) => void;
  otp: string;
  onResendOtp: () => void;
  onContinueClick: (isValid: boolean) => void;
}

export const PhoneVerificationModal: FC<PhoneVerificationModalProps> = ({
  isOpen,
  onClose,
  phoneNumber,
  onContinueClick,
  isSubmitting,
  errorMessage,
  otp,
  onOtpChange,
  onResendOtp,
  isResendDisabled,
}) => {
  return (
    <SuccessModal
      isOpen={isOpen}
      onClose={onClose}
      closeIcon={<CloseButton onClose={onClose} />}>
      <PhoneVerificationForm
        otp={otp}
        isSubmitting={isSubmitting}
        errorMessage={errorMessage}
        phoneNumber={phoneNumber}
        onOtpChange={onOtpChange}
        onResendOtp={onResendOtp}
        onContinueClick={onContinueClick}
        isResendDisabled={isResendDisabled}
      />
    </SuccessModal>
  );
};

export default PhoneVerificationModal;
