import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@app/store/hooks';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import { PopUpEnum } from '@app/store/features/app/app.types';

import {
  Container,
  StyledLink,
  StyledButton,
} from './styled';

const MainMenuModal = () => {
  let time: any;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    time = setTimeout(() => {
      dispatch(setPopUpName({ name: PopUpEnum.Empty }))
    }, 200)
  }

  useEffect(() => {
    return () => {
      clearTimeout(time)
    }
  }, [time])

  return (
    <Container>
      <StyledButton
        onClick={() => {
          navigate(PAGE_ROUTES_PUBLIC.TRUCKER_BECOME_PARTNER)
          handleClose()
        }}
      >
        Sign Up Now!
      </StyledButton>
      <StyledLink
        onClick={() => {
          navigate(PAGE_ROUTES_PUBLIC.WHY_JOIN);
          handleClose()
        }}
      >
        Why Join?
      </StyledLink>
      <StyledLink
        onClick={() => {
          navigate(PAGE_ROUTES_PUBLIC.HOME);
          handleClose();
        }}
      >
        Main Site
      </StyledLink>
      <StyledLink
        onClick={() => {
          navigate(PAGE_ROUTES_PUBLIC.TRUCKER_FAQ);
          handleClose();
        }}
      >
        F.A.Q
      </StyledLink>
      <StyledLink
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_REDIRECT_TO_PARTNER}/login`
          handleClose();
        }}
      >
        Partners Login
      </StyledLink>
    </Container>
  );
};

export default memo(MainMenuModal);
