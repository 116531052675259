import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useAppDispatch } from '@app/store/hooks';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { ReactComponent as MobileBurgerMenuIcon } from '@assets/svg/mobile-burger-menu-icon.svg';
import Center from './components/Center';
import { useMediaQuery } from '@mui/material';
import RightPart from './components/NavbarSections/RightPart';
import { StyledToolbar, BackgroundWrapper } from './styled';
import PreviousButton from '@modules/truckerLanding/components/Header/components/PreviousButton';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Header = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const [background, setBackground] = useState('transparent');
  const location = useLocation();
  const [isCloudActive, setIsCloudActive] = useState(false);

  useEffect(() => {
    const currentPage = window.location.pathname === '/trucker-become-partner';
    const result = matches
      ? 'transparent'
      : currentPage
        ? 'white'
        : 'transparent';

    setBackground(result);
    setIsCloudActive(['/trucker-landing'].includes(location.pathname));
  }, [location]);

  return (
    <BackgroundWrapper background={background}>
      <StyledToolbar>
        {isCloudActive ? (
          <svg
            className="cloud"
            xmlns="http://www.w3.org/2000/svg"
            width="111"
            height="35"
            viewBox="0 0 111 35"
            fill="none">
            <path
              d="M-16.537 20.8194C-12.6461 20.8194 -8.95218 20.0628 -5.65232 18.7204C-0.283897 28.3612 11.167 35 24.4157 35C35.645 35 45.5937 30.2162 51.627 22.8696C59.5565 24.3828 65.8116 22.7231 70.3182 20.3556C74.603 23.2845 80.0206 25.0418 85.9062 25.0418C99.7705 25.0418 111 15.3766 111 3.44141C111 2.26987 110.877 1.12273 110.68 0H-42C-41.3105 11.5934 -30.1797 20.8194 -16.537 20.8194Z"
              fill="#D1D7F1"
            />
          </svg>
        ) : null}

        {matches && location.pathname === '/trucker-become-partner' && (
          <Box sx={{ alignSelf: 'flex-start', marginTop: '25px' }}>
            <PreviousButton />
          </Box>
        )}

        <Center />
        {/*{matches && <Center />}*/}
        <RightPart />
        <BurgerMenuButton
          type="closed"
          onClick={() => {
            dispatch(setPopUpName({ name: PopUpEnum.TruckerMenuModal }));
          }}>
          <MobileBurgerMenuIcon />
        </BurgerMenuButton>
      </StyledToolbar>
    </BackgroundWrapper>
  );
};

export default Header;
