import * as React from 'react';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { useAppDispatch } from '@app/store/hooks';
import { StyledAddNewMoveAction } from '../AddNewMoveAction/styles';

export const AddNewMoveAction = () => {
  const dispatch = useAppDispatch();

  return (
    <StyledAddNewMoveAction
      onClick={() => dispatch(setPopUpName({ name: PopUpEnum.CreateNewMove }))}>
      Add New Moo’ve +
    </StyledAddNewMoveAction>
  );
};
