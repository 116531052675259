import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';
import Button from '@components/Button';

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));

export const StyledTitleBox = styled(Box)({
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',

  '.phoneText': {
    width: '320px',
    margin: '0 auto',
  },
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  lineHeight: '130%',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    margin: '0 auto',
  },
}));

export const StyledResend = styled('button')(({ theme }) => ({
  fontStyle: 'normal',
  lineHeight: '130%',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  textDecorationColor: COLORS.darkBlue,
  textDecoration: 'underline',
  color: COLORS.darkBlue,
  fontFamily: FontFamilyNames.PlainLight,
  fontWeight: 700,
  letterSpacing: '-0.28px',
  textDecorationLine: 'underline',
  fontSize: '16px',

  '&:disabled': {
    opacity: 0.6,
    cursor: 'not-allowed',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const StyledBoldTypography = styled(StyledTypography)({
  color: COLORS.darkBlue,
  fontWeight: '700',
  cursor: 'pointer',
  display: 'inline',
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    maxWidth: '670px',
    height: '500px',
    borderRadius: '20px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      margin: 0,
      borderRadius: 0,

      '.MuiDialogContent-root': {
        padding: 0,
      },
    },
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '40px',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',

  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',
    fontFamily: FontFamilyNames.MoretRegular,
  },
}));

export const ContinueButton = styled(Button)(({ theme }) => ({
  width: '248px',
  height: '48px',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '20px',
  textTransform: 'capitalize',
  lineHeight: '123.4%',
  position: 'absolute',
  bottom: '0',
  right: '185px',

  '&.Mui-disabled': {
    color: COLORS.grey[50],
    border: `1px solid ${COLORS.grey[50]}`,
  },

  [theme.breakpoints.down('sm')]: {
    width: '180px',
    height: '36px',
    position: 'initial',
  },
}));

export const StyledOuterBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '25px',

  position: 'relative',
  paddingBottom: '80px',
  [theme.breakpoints.down('sm')]: {
    gap: '50px',
    paddingBottom: '40px',
    height: '100%',
  },
}));

export const StyledInnerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  padding: '80px 141px 0 141px',
  [theme.breakpoints.down('sm')]: {
    padding: '140px 27.5px 0 27.5px',
  },
}));

export const StyledCodeBox = styled(Box)(({ theme }) => ({
  gap: '41px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    gap: '25px',
  },
}));

export const StyledRequestBox = styled(Box)({
  display: 'flex',
  gap: '5px',
});

export const StyledLeftLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: 216,
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 0,
  },
  '@media (max-height: 800px)': {
    width: 160,
  },
  '@media (max-height: 743px)': {
    width: 130,
  },
  '@media (max-height: 700px)': {
    width: 105,
  },
}));
