import { House } from '../../../../../../types/moves-response.model';
import {
  ViewContainer,
  Title,
  StyledExpandIcon,
  DetailsContainer,
  ToggleDetailsButton,
  AddNewMoveAndViewDetailsSection,
} from './styled';
import { isMoveInstate } from '../../../../../../helper/profile.helper';
import { Location } from '@app/types/model/allMoveInfo.model';
import { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { AddNewMoveAction } from '@modules/profile/components/Content/components/Moves/components/AddNewMoveAction';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MoveInformationSection from '@modules/profile/components/Content/components/Moves/components/MoveDetailsSummary/MoveInformationSection';

interface IMoveDetailsSummaryProps {
  origin?: Location;
  destination?: Location;
  mileage: number;
  house: House;
}

const MoveDetailsSummary = ({
  destination,
  origin,
  mileage,
  house,
}: IMoveDetailsSummaryProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <ViewContainer>
      <Title>
        {origin?.state}, {origin?.city} to {destination?.state}{' '}
        {destination?.city},{' '}
      </Title>

      <DetailsContainer>
        {matches ? (
          <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
            <MoveInformationSection
              originAddress={origin?.fullAddress ? origin.fullAddress : ''}
              isInstate={isMoveInstate(origin, destination)}
              mileage={mileage}
              houseTitle={house.title}
              destinationAddress={
                destination?.fullAddress ? destination.fullAddress : ''
              }
            />
          </Collapse>
        ) : (
          <MoveInformationSection
            originAddress={origin?.fullAddress ? origin.fullAddress : ''}
            isInstate={isMoveInstate(origin, destination)}
            mileage={mileage}
            houseTitle={house.title}
            destinationAddress={
              destination?.fullAddress ? destination.fullAddress : ''
            }
          />
        )}

        {matches && (
          <AddNewMoveAndViewDetailsSection>
            <ToggleDetailsButton onClick={toggleCollapse}>
              <StyledExpandIcon
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                expanded={!isCollapsed}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.71876 12.7754L8.47188 8.21011C8.76355 7.92996 9.23645 7.92996 9.52812 8.21011L14.2812 12.7754C14.5729 13.0555 14.5729 13.5097 14.2812 13.7899C13.9896 14.07 13.5167 14.07 13.225 13.7899L9 9.73187L4.77501 13.7899C4.48333 14.07 4.01043 14.07 3.71876 13.7899C3.42708 13.5097 3.42708 13.0555 3.71876 12.7754Z"
                  fill="#282750"
                />
              </StyledExpandIcon>

              <Typography variant="body2">
                {isCollapsed ? 'View Details' : 'Hide Details'}
              </Typography>
            </ToggleDetailsButton>

            <AddNewMoveAction />
          </AddNewMoveAndViewDetailsSection>
        )}
      </DetailsContainer>
    </ViewContainer>
  );
};

export default MoveDetailsSummary;
