import { ChangePasswordForm } from './components';
import { useState } from 'react';
import { StyledChangePasswordButton } from '@modules/profile/components/Account/styled';
import Box from '@mui/material/Box';
import ChangePasswordModal from '@modals/ChangePasswordModal';

const PasswordForm = () => {
  const [isOpenChangePasswordView, setIsOpenChangePasswordView] =
    useState(true);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleChangePasswordClick = () => setIsOpenChangePasswordView(true);

  return (
    <Box>
      <ChangePasswordForm
        isOpen={isOpenChangePasswordView}
        setIsOpenChangePasswordView={setIsOpenChangePasswordView}
      />

      {!isOpenChangePasswordView && (
        <StyledChangePasswordButton onClick={handleChangePasswordClick}>
          Change Password
        </StyledChangePasswordButton>
      )}

      {/*<ChangePasswordModal*/}
      {/*  isOpen={isChangePasswordModalOpen}*/}
      {/*  onClose={() => setIsChangePasswordModalOpen(false)}*/}
      {/*  title="Changes have been saved!"*/}
      {/*  doneButtonLabel="Done"*/}
      {/*/>*/}
    </Box>
  );
};

export default PasswordForm;
