import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';
import { styled } from '@mui/material/styles';
import { FontFamilyNames } from '@app/types/fonts';
import { StyledSaveButton } from '@modules/profile/components/Account/components/PasswordForm/components/ChangePasswordForm/styled';

export const StyledAccountContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '60px',
  paddingBottom: '120px',

  [theme.breakpoints.down('sm')]: {
    paddingBottom: '80px',
  },
}));

export const StyledAccount = styled(Box)(({ theme }) => ({
  padding: '41px 70px 0 71px',

  [theme.breakpoints.down('sm')]: {
    padding: '32px 20px 0 20px',
  },
}));

export const StyledEditProfileButton = styled(StyledSaveButton)(
  ({ theme }) => ({
    width: '148px',
    height: '39px',
    fontFamily: FontFamilyNames.MoretRegular,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    marginBottom: 0,

    [theme.breakpoints.down('sm')]: {
      width: '150px',
      height: '40px',
      fontSize: '16px',
      lineHeight: '123.4%',
      letterSpacing: '-0.14px',
    },
  }),
);

export const StyledChangePasswordButton = styled(StyledEditProfileButton)(
  ({ theme }) => ({
    width: '203px',

    [theme.breakpoints.down('sm')]: {
      width: '184px',
      height: '40px',
      fontSize: '16px',
      lineHeight: '123.4%',
      letterSpacing: '-0.14px',
    },
  }),
);

export const SuccessMessage = styled(Box)(({ theme }) => ({
  color: COLORS.orange,
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  padding: '0px 70px 0px 70px',
  marginTop: '38px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '0',
    padding: '0 28px',
  },
}));

export const StyledMyProfileContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  gap: '30px',
  flexDirection: 'column',
}));

export const ErrorMessage = styled(Box)(() => ({
  color: COLORS.error.main,
}));

export const MobileProfileHeader = styled(Box)(({ theme }) => ({
  display: 'none',

  img: {
    cursor: 'pointer',
  },

  [theme.breakpoints.down('sm')]: {
    display: 'block',

    img: {
      display: 'block',
      width: '127px',
      height: '15px',
      marginTop: '22px',
    },
  },
}));
