import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { COLORS } from '@utils/colors';

export const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${COLORS.darkBlue}`,
  color: COLORS.darkBlue,
  fontFamily: 'Moret-Regular',
  textTransform: 'initial',
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: '400',
  lineHeight: 'normal',

  '&.MuiButton': {
    cursor: 'pointer !important',

    '&-containedPrimary': {
      borderRadius: '53.2609px',

      '&.Mui-disabled': {
        color: 'rgba(160, 157, 149, 1)',
        borderColor: 'rgba(160, 157, 149, 1)',
        background: 'transparent',
        '> span > svg > line, > span > svg > path': {
          stroke: 'rgba(160, 157, 149, 1)',
        },
      },
    },

    '&-containedSecondary': {
      borderRadius: '53.2609px',
      background: COLORS.amour,
    },

    '&-outlinedInfo': {
      background: 'unset',
      border: 'none',

      '&:hover': {},

      '&.Mui-disabled': {},
    },

    '&-sizeLarge': {
      minWidth: '290px',
      padding: '22px 0',
      fontSize: '24.3478px',
    },
  },

  '&:hover': {
    background: COLORS.orange,
    border: `1px solid ${COLORS.darkBlue}`,
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}));
