import Moves from './components/Moves';
import { ContentWrapper } from './styled';

const Content = () => (
  <ContentWrapper className="currentMoves">
    <Moves />
  </ContentWrapper>
);

export default Content;
