import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { Box } from '@mui/material';
import PhoneField from '@ui/PhoneField';
import { EMAIL_REGEX, NAME_REGEX } from '@utils/regex';
import { useAppSelector } from '@app/store/hooks';
import { ErrorMessage } from '@modules/profile/components/Account/styled';
import { StyledInput, ThirdLevel as StyledThirdLevel } from '../../../styled';
import { StyledPhoneNumber } from './styled';
import { IMoveRequestFormValues } from '@modules/home/types/move.types';

const ThirdLevel = () => {
  const auth = useAppSelector(({ auth }) => auth);
  const {
    control,
    formState: { errors },
  } = useFormContext<IMoveRequestFormValues>();

  const phoneErrorMessage = errors?.phone?.message as string;
  const emailErrorMessage = errors?.email?.message as string;
  const nameErrorMessage = errors?.name?.message as string;

  return (
    <Box>
      <StyledThirdLevel>
        <Box>
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Please provide your name.',
              },
              pattern: {
                value: NAME_REGEX,
                message: 'Please enter a valid name.',
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <>
                <StyledInput
                  type="text"
                  position="left"
                  placeholder="Your Name"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  disabled={!!auth?.userInfo?.fullName}
                />
                {nameErrorMessage ? (
                  <ErrorMessage>{nameErrorMessage}</ErrorMessage>
                ) : null}
              </>
            )}
          />
        </Box>
        <Box>
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Please provide your email address.',
              },
              pattern: {
                value: EMAIL_REGEX,
                message: 'Please enter a valid email address.',
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <>
                <StyledInput
                  position="center"
                  placeholder="Email"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputRef={ref}
                  disabled={!!auth.userInfo?.email}
                />
                {emailErrorMessage ? (
                  <ErrorMessage>{emailErrorMessage}</ErrorMessage>
                ) : null}
              </>
            )}
          />
        </Box>
        <Box>
          <PhoneField<IMoveRequestFormValues> name="phone" control={control}>
            <StyledPhoneNumber
              autocompleteSearch
              disabled={!!auth?.userInfo?.phone}
              inputProps={{}}
            />
          </PhoneField>
          {phoneErrorMessage ? (
            <ErrorMessage>{phoneErrorMessage}</ErrorMessage>
          ) : null}
        </Box>
      </StyledThirdLevel>
    </Box>
  );
};

export default ThirdLevel;
