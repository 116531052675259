import { CSSProperties } from 'react';

import { styled } from '@mui/material/styles';

export const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'objectFit' && prop !== 'objectPosition',
})<{
  objectFit?: CSSProperties['objectFit'];
  objectPosition?: CSSProperties['objectPosition'];
}>(({ objectFit, objectPosition }) => ({
  width: '100%',
  height: '100%',
  objectFit,
  objectPosition,
}));
