import { styled } from '@mui/material/styles';
import Modal from '@ui/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@components/Typography';
import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '670px',
    maxWidth: '100%',
    height: '552px',
    borderRadius: 20,
  },

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,

    '.MuiPaper-root': {
      maxWidth: 'unset',
      margin: 0,
      borderRadius: 0,
      height: '100vh',
      maxHeight: '100%',
    },

    '.MuiDialog-container': {
      '.MuiPaper-root': {
        'h2': {
          zIndex: '1000',
          position: 'relative'
        }
      }
    }
  },
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 100,
  height: '100%',
  borderRadius: 20,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '41px 81px',

  '.first-svg': {
    marginBottom: '28px',
  },
  '.second-svg': {
    display: 'none'
  },
  'button': {
    width: '200px',
    height: '52px',
    padding: '12px 30px',
    marginTop: "32px",
    color: COLORS.darkBlue
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    zIndex: 0,
    width: '100%',
    height: '100%',
    padding: '60px 45px 0 45px',
    justifyContent: 'space-between',

    'overflow-x': 'hidden',

    '.first-svg': {
      display: 'none'
    },
    '.second-svg': {
      display: 'block',
      width: '100%',
      height: 'max-content',
    },
    'button': {
      height: '36px',
    },
  },
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 40,
  lineHeight: 'normal',
  marginBottom: 24,
  textAlign: 'left',

  [theme.breakpoints.down('sm')]: {
    maxWidth: 270,
    fontSize: 30,
    textAlign: 'left',
  },
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  zIndex: 999,
  right: 41,
  top: 41,
  color: COLORS.darkBlue,

  svg: {
    width: 18,
    height: 18,
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  width: '100%',

  display: 'grid',
  gridTemplateColumns: '1fr',

  '> span': {
    color: '#A09D95',
    fontSize: '16px',
    letterSpacing: '-0.14px',
  },

  '> b': {
    fontSize: '16px',
    letterSpacing: '-0.28px',
    marginTop: '8px'
  },
  '> p': {
    fontSize: '16px',
    letterSpacing: '-0.28px',
    marginTop: '24px',
    '> b': {
      textDecoration: 'underline'
    },
  },

  [theme.breakpoints.down('sm')]: {
    margin: '0px auto',
    gridTemplateColumns: '1fr',

    '> span': {
      color: COLORS.grey[50],
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '130%',
      letterSpacing: '-0.32px',
    },
    '> b': {
      color: "#282750",
      fontSize: '14px',
      letterSpacing: '-0.28px',
      marginTop: '8px'
    },
    '> p': {
      fontSize: '14px',
      letterSpacing: '-0.14px',
      marginTop: '32px',
      maxWidth: 270,

      '> b': {
        fontSize: '14px',
      },
    },
  },
}));