import {styled} from '@mui/material/styles';
import {FontFamilyNames} from "@app/types/fonts";
import {COLORS} from "@utils/colors";

export const StyledInput = styled('input')<{ error: boolean }>(({theme, error}) => ({
    width: '48px',
    textAlign: 'center',
    marginRight: '20px',
    backgroundColor: 'transparent',
    border: 'none',
    fontFamily: FontFamilyNames.MoretBook,
    fontSize: '48px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    justifyContent: 'center',
    borderBottom: error ? `2px solid ${COLORS.coralPink}` : `2px solid ${COLORS.grey[50]}`,
    color: error ? COLORS.coralPink : COLORS.darkBlue,

    '&:focus': {
        borderBottom: `2px solid ${error ? COLORS.coralPink : COLORS.darkBlue}`,
    },
    '&.filled': {
        borderBottom: `2px solid ${error ? COLORS.coralPink : COLORS.darkBlue}`,
    },

    [theme.breakpoints.down('sm')]: {
        width: '40px',
        marginRight: '16px',
        fontSize: '40px',
    }
}));
