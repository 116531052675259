import React, { forwardRef } from 'react';
import { TextFieldProps } from '@mui/material/TextField'; // If you're using MUI
import {
  StyledBox,
  StyledTypography,
  StyledTextField,
} from '../ChangePasswordForm/styled';
import { ErrorMessage } from '@modules/profile/components/Account/styled';

interface PasswordInputProps extends Omit<TextFieldProps<'standard'>, 'error'> {
  label: string;
  error?: string;
}
export const PasswordInput = forwardRef(
  ({ label, error, ...rest }: PasswordInputProps, ref) => {
    return (
      <StyledBox>
        <StyledTypography>{label}</StyledTypography>
        <StyledTextField inputRef={ref} {...rest} />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </StyledBox>
    );
  },
);

export default PasswordInput;
