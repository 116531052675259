import { useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { PopUpEnum } from '@app/store/features/app/app.types';
import {
  setPopUpName,
  setUpdateAfterDeleteMove,
} from '@app/store/features/app/app.slice';
import { useDeleteMoveMutation } from '@modules/profile/api/profile.api';
import { useTheme } from '@mui/material/styles';

export const useDeleteMoveLogic = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const params = useAppSelector(({ app }) => app.params);

  const handleClose = () => {
    dispatch(setPopUpName({ name: PopUpEnum.Empty }));
  };

  const [deleteRequest] = useDeleteMoveMutation();

  const handleDelete = async () => {
    if (!params?.moveId) {
      handleClose();
      return;
    }

    await deleteRequest({ id: params.moveId });
    dispatch(setUpdateAfterDeleteMove({ isUpdateAfterDeleteMove: true }));
    handleClose();
  };

  return {
    matches,
    handleClose,
    handleDelete,
  };
};
