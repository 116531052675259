import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { setUser } from '@modules/auth';
import { useMediaQuery } from '@mui/material';
import { deletePlusFromPhone } from '@utils/helpers';
import { errorHandler } from '@utils/errorHendler';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { setPopUpName } from '@app/store/features/app/app.slice';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { useLazyGetProfileQuery } from '@modules/auth/api/auth.api';
import { useSetProfileInformationMutation } from '@modules/profile/api/profile.api';
import { IProfileInfoFormValues } from '../../../../types';
import { PHONE_NUMBER_FORMAT } from '@utils/constants';

export const useUpdateProfileLogic = ({
  setIsMyProfileFormOpen,
  setIsSuccessModalOpen,
}: {
  setIsSuccessModalOpen: (isSuccessModalOpen: boolean) => void;
  setIsMyProfileFormOpen: (isMyProfileFormOpen: boolean) => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(({ auth }) => auth);
  const phone = deletePlusFromPhone(auth.userInfo?.phone || '');

  const [updateProfileInfo] = useSetProfileInformationMutation();
  const [getProfile] = useLazyGetProfileQuery();

  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const clearMessages = () => {
    setMessage('');
    setError('');
  };

  const {
    formState: { errors, isValid, isDirty },
    control,
    reset,
    setValue,
    register,
    setError: setFormError,
    watch,
    handleSubmit,
  } = useForm<IProfileInfoFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      fullName: auth.userInfo?.fullName,
      phone: phone,
    },
  });

  const handleRequest = async (data: IProfileInfoFormValues) => {
    try {
      setIsLoading(true);
      if (!isValid) {
        setError('Please fill all fields.');
        setIsLoading(false);
        return;
      }

      // Sending phone to backend with +
      let phone = data.phone.replace('+1', '');
      const phoneWithPlus = `${PHONE_NUMBER_FORMAT}${phone}`;

      if (auth?.userInfo?.phone === data.phone) {
        await updateProfileInfo({
          fullName: data.fullName,
        }).unwrap();
      } else {
        await updateProfileInfo({
          fullName: data.fullName,
          phone: phoneWithPlus,
        }).unwrap();
      }

      // Save phone in storage without +
      const { ...rest } = await getProfile().unwrap();

      const profileResponseData = {
        ...rest,
        phone: deletePlusFromPhone(rest.phone),
      };

      dispatch(setUser(profileResponseData));
      setIsSuccessModalOpen(true);
      setIsLoading(false);
      setIsMyProfileFormOpen(false);
    } catch (error) {
      const handlerErrorMessage = errorHandler(error, setFormError);
      console.log('sdf234 handlerErrorMessage', { handlerErrorMessage, error });
      setError(handlerErrorMessage);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    clearMessages();
    dispatch(setPopUpName({ name: PopUpEnum.Empty }));
    setIsSuccessModalOpen(false);
  };

  return {
    error,
    errors,
    message,
    matches,
    isLoading,
    control,
    isDirty,
    email: auth.userInfo?.email,
    watch,
    setIsLoading,
    setValue,
    register,
    handleClose,
    handleSubmit,
    handleRequest,
    clearMessages,
    setIsSuccessModalOpen,
  };
};
