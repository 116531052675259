import { styled } from '@mui/material/styles';
import { COLORS } from '@utils/colors';
import { Box } from '@mui/material';

export const BurgerMenuButton = styled(Box)<{ type: 'closed' | 'opened' }>(({
  theme,
  type,
}) => {
  const list = [
    '/profile',
    '/trucker-landing',
    '/trucker-become-partner',
    '/trucker-faq',
    '/why-join',
    '/affiliate',
    '/affiliate-become-partner',
  ];

  return {
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      cursor: 'pointer',
      zIndex: '999',
      right: '0',
      top: list.includes(window.location.pathname) ? '0' : '52px',
      width: '49px',
      height: '49px',
      borderRadius: 'unset',
      border: `1px solid ${theme.palette.common.black}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.common.white,

      '&:hover': {
        background: COLORS.beige,
      },
    },
  };
});
