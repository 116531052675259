import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import PlacesAutocomplete from 'react-places-autocomplete';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocalLocation } from './useLocalLocation';
import {
  StyledStack,
  StyledDropdownBox,
  SuggestionsList,
  StyledLocationInput,
  StyledDropdownBoxItem,
} from './styled';

interface ILocationField {
  name: 'from' | 'to';
  placeholder: string;
  locationValue?: string;
  disabled: boolean;
  onSelect?: (address: string, placeId: string) => void;
}

const LocationField = (props: ILocationField) => {
  const { setError, setValue } = useFormContext();
  const { name, placeholder, locationValue, onSelect, ...restProps } = props;

  const { address, setAddress, handleSelect, makeTextWithDots } =
    useLocalLocation({ name, locationValue });

  const onSelectAddress = async (address: string, placeId: string) => {
    await handleSelect(address);
    if (onSelect) {
      onSelect(address, placeId);
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <PlacesAutocomplete
        value={address}
        searchOptions={{
          types: ['establishment'],
          componentRestrictions: { country: 'us' },
        }}
        onSelect={onSelectAddress}
        onChange={value => {
          setError(name, { message: 'Select from the list' });
          setValue(name, { address: '', placeId: '' });
          setAddress(value);
        }}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          const { value, ...inputProps } = getInputProps();
          const valueWithDots = makeTextWithDots(value);

          return (
            <StyledStack zIndex={'up'} direction="column">
              <StyledLocationInput
                {...inputProps}
                {...restProps}
                value={valueWithDots}
                placeholder={placeholder || ''}
              />

              {!!suggestions.length && (
                <StyledDropdownBox zIndex="up">
                  <SuggestionsList>
                    {suggestions.map(suggestion => {
                      return (
                        <StyledDropdownBoxItem
                          direction="row"
                          spacing="10px"
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.placeId}
                          sx={{ cursor: 'pointer' }}>
                          <Typography variant="body2">
                            {suggestion.description}
                          </Typography>
                        </StyledDropdownBoxItem>
                      );
                    })}
                  </SuggestionsList>
                </StyledDropdownBox>
              )}
            </StyledStack>
          );
        }}
      </PlacesAutocomplete>
    </Box>
  );
};

export default memo(LocationField);
