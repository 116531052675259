import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';

export const RatingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const RatingValue = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const RatingTitle = styled(Typography)(({ theme }) => ({
  color: COLORS.grey[50],
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const ReviewsLink = styled(Link)(({ theme }) => ({
  textDecoration: 'underline',
  color: COLORS.darkBlue,
  fontSize: '16px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));
