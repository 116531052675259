import React from 'react';
import { ReactComponent as NoMoveMobile } from '@assets/svg/no-move-mobile.svg';
import {
  LottieContainer,
  LottieContent,
  MoveDetailsSummaryContainer,
  NoMoveDesktopContainer,
  NoMoveMobileContainer,
  NoMoveMobileTitle,
  StyledNoMoveDesktop,
} from './styled';
import MoveDetailsSummary from '../MoveDetailsSummary';
import { House } from '../../../../../../types/moves-response.model';
import { Location } from '@app/types/model/allMoveInfo.model';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Lottie from 'lottie-react';
import animationData from './animationData.json';
import Box from '@mui/material/Box';

interface INoMoveProps {
  mileage: number;
  house: House;
  origin?: Location;
  destination?: Location;
}

const NoMove = ({ mileage, house, destination, origin }: INoMoveProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {matches ? (
        <NoMoveMobileContainer sx={{ position: 'relative' }}>
          <Lottie
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              transform: 'translate(-24%, -54%)',
              width: '260px',
              paddingTop: '27px',
            }}
            animationData={animationData}
            loop={true}
            autoplay={true}
          />
          <NoMoveMobileTitle>
            Hold Tight! You will be connected to our Moo'ving Partners that
            service your area shortly!
          </NoMoveMobileTitle>
          <NoMoveMobile />
        </NoMoveMobileContainer>
      ) : (
        <NoMoveDesktopContainer>
          <MoveDetailsSummaryContainer>
            <MoveDetailsSummary
              house={house}
              mileage={mileage}
              destination={destination}
              origin={origin}
            />
          </MoveDetailsSummaryContainer>
          <LottieContainer>
            <LottieContent>
              <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
              />
            </LottieContent>
            <StyledNoMoveDesktop />
          </LottieContainer>
        </NoMoveDesktopContainer>
      )}
    </>
  );
};

export default NoMove;
