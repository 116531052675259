import { ISelectOption } from './types';

export const becomePartnerInitialState = {
  companyName: '',
  companyAddress: '',
  companyWebsite: '',
  googlePlaceId: undefined,
  email: '',
  phone: '',
  dot: undefined,
  registerName: undefined,
  isDotActive: false,
  isGooglePlaceId: false,
  // registerState: undefined,
  // isRegisterStateActive: false,
  isPhoneNumberForTextsActive: false,
  contactName: '',
  phoneNumberForTexts: '',
};

export const states = [] as ISelectOption[];
