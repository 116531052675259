import { Dispatch, SetStateAction, memo } from 'react';

import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';
import Button from '@components/Button';
import FirstLevel from './components/Levels/First';
import ThirdLevel from './components/Levels/Third';
import {
  Container,
  Wrapper,
  ButtonWrapper,
  StyledErrorMessage,
} from './styled';
import { useAppSelector } from '@app/store/hooks';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES_PUBLIC } from 'app/types/enums/pages';
import Box from '@mui/material/Box';
import { CheckoutWrapper } from '@components/CustomCheckbox/styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomCheckbox from '@modules/truckerBecomePartner/components/Content/components/Fields/components/Select/components/CustomCheckbox';

type ProcessStateType = {
  third: boolean;
  second: boolean;
};

interface IProcessProps {
  agree: boolean;
  isValid: boolean;
  message: string;
  levels: ProcessStateType;
  isProcessLoading: boolean;
  setAgree: Dispatch<SetStateAction<boolean>>;
  onGetMyQuotesClick: () => void;
  isPhoneVerificationLoading: boolean;
}

const Process = ({
  agree,
  setAgree,
  isValid,
  message,
  levels,
  isProcessLoading,
  onGetMyQuotesClick,
  isPhoneVerificationLoading,
}: IProcessProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const user = useAppSelector(state => state.auth.userInfo);
  let isDisableButton = isProcessLoading || isPhoneVerificationLoading;

  if (user === null) {
    if (!agree || !isValid) {
      isDisableButton = true;
    }
  } else {
    if (!isValid) {
      isDisableButton = true;
    }
  }

  const toggleAgree = () => {
    setAgree(prev => !prev);
  };

  return (
    <Container>
      <Wrapper>
        <FirstLevel />
        {levels.third && <ThirdLevel />}
        <ButtonWrapper>
          {user === null && levels.second && levels.third && (
            <>
              <CheckoutWrapper>
                <CustomCheckbox active={agree} onClick={toggleAgree} />
                <Box sx={{ cursor: 'pointer' }} onClick={() => toggleAgree()}>
                  I agree with the
                  <b
                    onClick={() =>
                      navigate(PAGE_ROUTES_PUBLIC.TERMS_AND_CONDITIONS)
                    }>
                    terms of use
                  </b>
                  {matches ? <br /> : ''}
                  of Moo Moves You, LLC
                </Box>
              </CheckoutWrapper>
            </>
          )}

          <Button
            type="button"
            onClick={onGetMyQuotesClick}
            size="medium"
            endIcon={isProcessLoading ? null : <ArrowRightIcon />}
            disabled={isDisableButton}>
            {isProcessLoading ? 'Loading ... ' : 'Get My Quotes'}
          </Button>

          {message && <StyledErrorMessage>{message}</StyledErrorMessage>}
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default memo(Process);
