import { styled } from '@mui/material/styles';

import { Footer } from '@modules/truckerBecomePartner/styled';

export const StyledFooter = styled(Footer)(({ theme }) => ({
  padding: '0',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 87,
  gap: 50,

  [theme.breakpoints.down('sm')]: {
    background: 'none',
    padding: '46.78px 20px 0 20px',
    marginTop: 0,
    gap: 0,
    alignItems: 'unset',
  },
}));

