import Routes from "@app/routes";
import ModalsController from "./ModalsController";

function App() {
  return (
    <>
      <Routes />
      <ModalsController />
    </>
  );
}

export default App;
