import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  ICreateMovePayload,
  IPhoneVerificationPayload,
} from '../types/move.types';
import { baseQueryWithReAuth } from '@app/api/baseQueryWithReauth';

export const homeLandingApi = createApi({
  reducerPath: 'houses',
  baseQuery: baseQueryWithReAuth(),
  endpoints: builder => ({
    getHouses: builder.query<{ id: string; title: string }[], void>({
      query: () => '/houses',
    }),
    createMove: builder.mutation<any, ICreateMovePayload>({
      query: body => ({
        url: '/moves',
        method: 'POST',
        body,
      }),
    }),
    verifyPhone: builder.mutation<any, IPhoneVerificationPayload>({
      query: body => ({
        url: '/phone-verification',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetHousesQuery,
  useCreateMoveMutation,
  useVerifyPhoneMutation,
} = homeLandingApi;
