import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTES_PRIVATE } from '@app/types/enums/pages';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { setPopUpName } from '@app/store/features/app/app.slice';
import {
  StyledMenuItem,
  RightSide,
  StyledGetMyQuotesButton,
  StyledStack,
} from '../../styled';
import React from 'react';
import { isDebugMode } from '@utils/constants';

const RightPart = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(({ auth }) => auth);

  return (
    <RightSide>
      <StyledStack direction="row">
        {isDebugMode && (
          <StyledMenuItem
            onClick={() => {
              if (!auth.userInfo) {
                dispatch(setPopUpName({ name: PopUpEnum.LoginModal }));
                return;
              }

              navigate(PAGE_ROUTES_PRIVATE.PROFILE);
            }}>
            {auth.userInfo ? 'My Profile' : 'Login'}
          </StyledMenuItem>
        )}

        {/*<StyledGetMyQuotesButton*/}
        {/*  type="button"*/}
        {/*  fullWidth*/}
        {/*  variant="contained"*/}
        {/*  onClick={() => navigate(PAGE_ROUTES_PRIVATE.PROFILE)}>*/}
        {/*  Get My Quotes*/}
        {/*</StyledGetMyQuotesButton>*/}
      </StyledStack>
    </RightSide>
  );
};

export default RightPart;
