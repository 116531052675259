import React from 'react';
import Stack from "@mui/material/Stack";
import { BurgerMenuButton } from "@components/BurgerMenuButton/styled";
import { COLORS } from "@utils/colors";
import { StyledIconButton } from "@modals/Profile/PriceModal/styled";
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface CloseButtonConditionalProps {
    onClose: () => void;
}

const CloseButton: React.FC<CloseButtonConditionalProps> = ({ onClose }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return matches ? (
        <Stack alignItems="flex-end">
            <BurgerMenuButton
                type='closed'
                onClick={onClose}
                sx={{
                    background: COLORS.beige,
                }}
            >
                <MobileCloseIcon />
            </BurgerMenuButton>
        </Stack>
    ) : (
        <StyledIconButton
            aria-label="close"
            onTouchMove={onClose}
            onClick={onClose}
            sx={{
                top: 35,
                right: 35
            }}
        >
            <CloseIcon />
        </StyledIconButton>
    );
};

export default CloseButton;
