import { Box } from '@mui/material';
import StarRating from './StarRating';
import {
  RatingContainer,
  RatingTitle,
  RatingValue,
  ReviewsLink,
} from './styled';

interface IGoogleReviewsRatingProps {
  rating: number;
  googlePlaceId: string | null;
}

const GoogleReviewsRating = ({
  rating,
  googlePlaceId,
}: IGoogleReviewsRatingProps) => {
  return (
    <RatingContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}>
        <RatingTitle>Google Reviews Rating:</RatingTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '14px',
          }}>
          <RatingValue>{rating}</RatingValue>
          <StarRating rating={rating} />
          <ReviewsLink
            href={`https://www.google.com/maps/place/?q=place_id:${googlePlaceId}&hl=en`}
            target="_blank">
            View Reviews
          </ReviewsLink>
        </Box>
      </Box>
    </RatingContainer>
  );
};

export default GoogleReviewsRating;
