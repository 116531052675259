import { createTheme } from '@mui/material/styles';

import { MuiCssBaseline } from '@theme/cssBaseline';
import { palette } from '@theme/palette';
import { breakpoints } from '@theme/breakpoints';
import {
  typography,
  MuiTypography,
  setTypographyMedias,
} from '@theme/typography';
import { MuiButton, MuiButtonBase } from '@theme/buttons';
import {
  MuiInputLabel,
  MuiOutlinedInput,
  MuiTextField,
  MuiFormHelperText,
  MuiInput,
} from '@theme/inputs';
import { MuiLink } from '@theme/links';
import { MuiList, MuiMenu, MuiMenuItem } from '@theme/menu';
import { MuiAutocomplete, setAutocompleteMedias } from '@theme/autocomplete';
import { TOOLBAR_MIN_HEIGHT } from '@utils/constants';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    l: true; // large
    el: true; // Extra Large
    eel: true; // Extra-Extra Large
  }
}

const theme = createTheme({
  palette,
  typography,
  spacing: [0, 4, 8, 16, 20, 24, 32, 40, 64, 84],
  breakpoints: {
    values: breakpoints,
  },
  components: {
    MuiLink,
    MuiList,
    MuiMenu,
    MuiInput,
    MuiButton,
    MuiMenuItem,
    MuiTextField,
    MuiInputLabel,
    MuiButtonBase,
    MuiTypography,
    MuiCssBaseline,
    MuiAutocomplete,
    MuiOutlinedInput,
    MuiFormHelperText,
  },
  mixins: {
    toolbar: {
      minHeight: TOOLBAR_MIN_HEIGHT,
      // # 900 === md
      '@media(max-width:900px)': {
        minHeight: '90px',
      },

      // # 600 === sm
      '@media(max-width:600px)': {
        minHeight: '51px',
      },
    },
  },
});

setTypographyMedias(theme);
setAutocompleteMedias(theme);

export default theme;
