import { memo, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import StyledSelect from '../../Select';
import DatePickerVia from '../../DatePicker';
import LocationField from '../../LocationField';
import { looseFurnitureOption } from '../../../data';

import {
  FirstLevel as StyledFirstLevel,
  MinimumSizeMessage,
} from '../../../styled';
import { useGetHousesQuery } from '@modules/home/api/requests.api';

const FirstLevel = () => {
  const { watch } = useFormContext();
  const { data: housesData } = useGetHousesQuery();
  const selectedHomeSize = watch('currentHouseSize');
  const [showMinimumSizeMessage, setShowMinimumSizeMessage] = useState(false);

  const sortedHomeSizeOptions = useMemo(() => {
    const options =
      housesData?.map(item => ({
        id: item.id,
        value: item.title,
        label: item.title,
        isDisabled: false,
      })) || [];

    options.push(looseFurnitureOption);

    return options;
  }, [housesData]);

  useEffect(() => {
    setShowMinimumSizeMessage(
      selectedHomeSize?.value === looseFurnitureOption.value,
    );
  }, [selectedHomeSize]);

  return (
    <>
      <StyledFirstLevel>
        <Box>
          <LocationField
            zIndex="up"
            name="from"
            types="address"
            borderType="first"
            placeholder="I’m Moving From (Address or Zip)"
            locationValue={watch('from').address}
          />
        </Box>
        <Box>
          <LocationField
            zIndex=""
            name="to"
            types="address"
            placeholder="I’m Moving To (Address or Zip)"
            borderType="second"
            locationValue={watch('to').address}
          />

          {/*TODO: hide AddressOrStorageSelection. maybe can need it */}
          {/*{matches && (*/}
          {/*  <AddressOrStorageSelection*/}
          {/*    styles={{*/}
          {/*      wrapper: {*/}
          {/*        gap: '6px',*/}
          {/*        marginBottom: '10px',*/}
          {/*      },*/}
          {/*      container: {*/}
          {/*        marginTop: '6px',*/}
          {/*      },*/}
          {/*    }}*/}
          {/*  />*/}
          {/*)}*/}
        </Box>
        <Box>
          <DatePickerVia />
        </Box>
        <Box>
          <StyledSelect
            name="currentHouseSize"
            options={sortedHomeSizeOptions}
          />
        </Box>
      </StyledFirstLevel>
      {/*TODO: hide AddressOrStorageSelection. maybe can need it */}
      {/*{!matches && <AddressOrStorageSelection />}*/}
      {showMinimumSizeMessage && (
        <MinimumSizeMessage>
          Unfortunately our minimum job size is studio / 1 bedroom moves.
        </MinimumSizeMessage>
      )}
    </>
  );
};

export default memo(FirstLevel);
