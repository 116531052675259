import fullPackingServicesSvg from '@assets/svg/fullPackingServices.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';

import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { useFullPackingServicesLogic } from './useFullPackingServicesLogic';
import {
  StyledModal,
  StyledLeftLogo,
  StyledIconButton,
  StyledModalTitle,
  StyledDialogContent,
  Description
} from './styled';

const FullPackingServicesModal = () => {
  const {
    matches,
    handleClose,
  } = useFullPackingServicesLogic()

  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type='closed'
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>

      <StyledDialogContent>
        <StyledModalTitle
          component="h3"
          variant="h1"
          label="Full Packing Services"
          textAlign="center"
        />
        <Description alignItems="center">
          Full Packing Services involve professional movers packing all of your personal belongings, including items such as clothing, accessories, and kitchen items, into boxes either on the day of or prior to moving day. While this service provides convenience and saves you time, it is usually more costly compared to a standard move.
        </Description>
        <StyledLeftLogo src={fullPackingServicesSvg} />
      </StyledDialogContent>
    </StyledModal >
  );
};

export default FullPackingServicesModal;
