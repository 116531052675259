import { buildBaseQuery } from '@app/api';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { FormValuesPayload } from '../types';

export const becomePartnerApi = createApi({
  reducerPath: 'becomePartnerApi',
  baseQuery: buildBaseQuery(),
  endpoints: (builder) => ({
    getStates: builder.query<{ id: string, code: string, name: string }[], void>({
      query: () => '/states',
    }),
    registerPartner: builder.mutation<{ accessToken: string }, FormValuesPayload>({
      query: (body) => ({
        url: `/partners`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLazyGetStatesQuery, useRegisterPartnerMutation } = becomePartnerApi;