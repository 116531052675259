import MuiPhoneNumber from 'react-phone-input-2';

import Box from '@mui/material/Box';
import { COLORS } from '@utils/colors';
import { styled } from '@mui/material/styles';
import { FontFamilyNames } from '@app/types/fonts';
import { Stack, TextField, Typography } from '@mui/material';
import Button from '@components/Button';

export const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: '328px',
}));

export const Title = styled('h2')(({ theme }) => ({
  marginBottom: '27px',
  marginLeft: '23px',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',

  [theme.breakpoints.down('sm')]: {
    marginLeft: '0',
    fontSize: '30px',
  },
}));

export const FieldsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  marginBottom: 30,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',
  color: COLORS.grey[50],

  width: '230px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  textOverflow: 'ellipsis',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '.MuiOutlinedInput-root': {
    borderBottom: `1px solid ${COLORS.darkBlue}`,
    borderRadius: 0,
  },
  '.MuiInputBase-input': {
    color: COLORS.darkBlue,
    fontSize: 16,
    padding: '0px 0px 15px 0',
    letterSpacing: '-0.02em',
    height: 20,
  },
  '::placeholder': {
    color: COLORS.darkBlue,
    opacity: 0.5,
  },
  'input:-webkit-autofill': {
    boxShadow: '0 0 0 1000px white inset !important',
  },
  '> .Mui-disabled': {
    fieldset: {
      border: 'none',
    },
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: 27,
    maxHeight: 31,
    width: '100%',

    '.MuiOutlinedInput-root': {
      width: '100%',
    },

    '.MuiInputBase-input': {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
}));

export const PhoneNumber = styled(MuiPhoneNumber)(({ theme }) => ({
  width: '100%',
  borderRadius: 0,
  borderBottom: `1px solid ${COLORS.darkBlue}`,

  input: {
    width: '100%',
    border: 'none',
    background: 'transparent',
    padding: '0 0 14px 0',
    fontSize: '16px',
    color: COLORS.darkBlue,
  },
  '.form-control::placeholder': {
    color: COLORS.darkBlue,
    opacity: 0.5,
  },
  '.form-control:focus-visible': {
    outlineWidth: 0,
  },
  '& .special-label': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    ':last-child': {
      marginBottom: 25,
    },

    marginBottom: 30,
    width: '100%',
    input: {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: '182px',
  padding: '12px 30px',
  height: '39px',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '125%',

  [theme.breakpoints.down('sm')]: {
    height: 40,
  },
}));

export const LocalErrorMessage = styled(Typography)(({ theme }) => ({
  color: COLORS.error.main,
  textAlign: 'center',
}));
