import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';

import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';
import Box from '@mui/material/Box';

export const TopTitle = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',

  '&.MuiTypography-root': {
    letterSpacing: 0,
    marginTop: '0',
    fontSize: 48,
    fontWright: 400,
    lineHeight: 'normal',
    fontFamily: FontFamilyNames.MoretRegular,
    fontWeight: 400,
  },

  [theme.breakpoints.down('sm')]: {
    '&.MuiTypography-root': {
      fontSize: 30,
      textAlign: 'center',
    },
  },
}));

export const HeaderTitle = styled(Stack)(() => ({
  gap: 10,
  alignItems: 'center',
}));

export const HeaderTitleAndProcessContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '60px',

  [theme.breakpoints.down('sm')]: {
    gap: '36px',
  },
}));

export const SubtitleText = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '135%',

  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '135%',
  },
}));

export const HomePage = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingBottom: 0,
  paddingTop: 53,
  backgroundColor: COLORS.beige,

  h2: {
    padding: '0 20px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
    marginTop: 36,
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    color: COLORS.darkBlue,
    fontSize: 20,
    marginTop: 10,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      padding: '0 20px',
    },
  },
}));
