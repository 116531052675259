import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';

import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';

export const Container = styled(Stack)(({ theme }) => ({
  maxWidth: '1300px',
  width: '100%',
  margin: '110px auto 0 auto',

  [theme.breakpoints.down('sm')]: {
    margin: '100px auto 0 auto',
  },
}));

export const StyledBlocks = styled(Stack)(({ theme }) => ({
  padding: '0 20px',
  width: '100%',
  gap: 0,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',

  [theme.breakpoints.down('eel')]: {
    margin: '0 auto',
  },

  [theme.breakpoints.down('el')]: {
    padding: '0',
  },

  [theme.breakpoints.down('xl')]: {
    display: 'flex',
    justifyContent: 'center',
    gap: 40,
  },

  [theme.breakpoints.down('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    justifyItems: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
    margin: '0 auto',
    gap: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  textAlign: 'center',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'capitalize',
  marginBottom: '60px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
    marginBottom: '40px',
  },
}));

export const StyledBlock = styled(Stack)(({ theme }) => ({
  maxWidth: '240px',
  textAlign: 'center',
  justifyContent: 'flex-start',

  '&.block-0': {
    img: {
      width: '116.661px',
      height: '131.449px',
      marginTop: '19px',
    },
  },
  '&.block-1': {
    img: {
      width: '109.666px',
      height: '119.901px',
      marginTop: '18px',
    },
  },
  '&.block-2': {
    img: {
      width: '140.903px',
      height: '143.887px',
    },
  },
  '&.block-3': {
    img: {
      width: '145.003px',
      height: '135.002px',
      marginTop: '9px',
    },
  },
  '&.block-4': {
    img: {
      width: '154.32px',
      height: '142',
      marginTop: '2.2px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    maxWidth: '335px',
    flexDirection: 'unset',
    gap: '15px',
    width: '100%',
    justifyContent: 'flex-start',

    '&.block img': {
      width: '90px',
      height: '90px',
      marginTop: 0,
    },
  },
}));

export const StyledBlockTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '130%',
  color: COLORS.darkBlue,
  letterSpacing: '-0.32px',

  '&.block-title-0': {
    margin: '26px 0 6px 0',
  },

  '&.block-title-1': {
    margin: '39px 0 6px 0',
  },

  '&.block-title-2': {
    margin: '33px 0 6px 0',
  },

  '&.block-title-3': {
    margin: '33px 0 6px 0',
  },

  '&.block-title-4': {
    margin: '32px 0 6px 0',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    margin: '0 0 9px 0',

    '&.block-title': {
      marginTop: '0',
    },
  },
}));

export const StyledBlockImage = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  minHeight: '135px',

  [theme.breakpoints.down('sm')]: {
    minHeight: 'unset',

    img: {
      marginTop: 0,
    },
  },
}));

export const StyledBlockDescription = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  whiteSpace: 'pre-wrap',
  fontWeight: 300,
  fontSize: '14px',
  lineHeight: '130%',
  letterSpacing: '-0.28px',
  color: COLORS.grey['50'],

  [theme.breakpoints.down('sm')]: {
    lineHeight: '130%',
    letterSpacing: '-0.14px',
  },
}));
