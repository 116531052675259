import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { FontFamilyNames } from '@app/types/fonts';
import { COLORS } from '@utils/colors';

export const Container = styled(Stack)(({ theme }) => ({
  // margin: '0 70px',
  // marginTop: '37px',
  height: 'min-content',
  // background: 'cyan',
  [theme.breakpoints.down('sm')]: {
    // margin: '26px 31px 0px 31px',
    // background: 'yellow',
  },
}));

export const Title = styled('h2')(({ theme }) => ({
  marginBottom: '27px',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',

  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',

    marginBottom: '19px',
  },
}));

export const MobileGridWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '6px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const StyledContent = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: '130%',
  letterSpacing: '-0.32px',

  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  textOverflow: 'ellipsis',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyledLabel = styled(StyledContent)(({ theme }) => ({
  color: COLORS.grey[50],
}));
