import React, { FC } from 'react';
import SuccessModal, { SuccessModalProps } from '@modals/SuccessModal';
import CloseButton from '@modals/PhoneVerificationModal/CloseButton';
import { ButtonProps } from '@mui/material/Button';
import Button from '@components/Button';
import { StyledLeftLogo, StyledRightLogo } from './styled';
import Box from '@mui/material/Box';
import contactUs1 from '@assets/svg/contactUs1.svg';
import contactUs2 from '@assets/svg/contactUs2.svg';
import Typography from '@components/Typography';
import deliveryCowMobile from '@assets/svg/delivery-cow-mobile.svg';
import deliveryCow from '@assets/svg/delivery-cow.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ChangePasswordModalProps extends SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  doneButtonLabel?: string;
  doneButtonProps?: ButtonProps;
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  isOpen,
  onClose,
  title,
  doneButtonLabel = 'Done',
  doneButtonProps,
  ...restProps
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SuccessModal
      isOpen={isOpen}
      onClose={onClose}
      closeIcon={<CloseButton onClose={onClose} />}
      styles={{
        dialog: {
          width: '100%',
          '.MuiPaper-root': {
            width: '100%',
            maxWidth: '670px',
            height: '500px',

            [theme.breakpoints.down('sm')]: {
              minHeight: '100%',
              maxWidth: '100%',
            },
          },
        },
        dialogContent: {
          '.MuiPaper-root': {
            minHeight: '100%',
            backgroundColor: 'cyan',
          },
        },
      }}
      {...restProps}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: matches ? 'center' : 'flex-end',
        }}>
        {matches ? (
          <StyledLeftLogo
            src={deliveryCowMobile}
            sx={{
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
          />
        ) : (
          <>
            <StyledLeftLogo src={contactUs1} />
            <StyledRightLogo src={contactUs2} />
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            alignItems: 'center',
          }}>
          <Typography variant="h3">{title}</Typography>
          <Button
            label={doneButtonLabel}
            onClick={onClose}
            sx={
              matches
                ? {
                    width: '256px',
                    height: '36px',
                  }
                : {
                    width: '107px',
                    height: '48px',
                    marginBottom: '147px',
                  }
            }
            {...doneButtonProps}
          />
        </Box>
      </Box>
    </SuccessModal>
  );
};

export default ChangePasswordModal;
