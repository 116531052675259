import { FormProvider } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import becomeTruckerPartner from '@assets/svg/full-illustration.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/svg/arrow-right.svg';

import Box from '@mui/material/Box';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import { useBecomePartnerLogic } from './useBecomePartnerLogic';
import { Footer, BecomePartnerFields } from './components';
import {
  Title,
  Container,
  ContentBox,
  ContentStack,
  Image,
  NextButtonBox,
  StyledErrorMessage,
  TitleStack,
  FieldsWrapper,
  StyledNextButton,
} from '../../styled';

const Content = () => {
  const { user, error, methods, isLoading, handleSubmit, onSubmit } =
    useBecomePartnerLogic();

  if (user) {
    return <Navigate to={PAGE_ROUTES_PUBLIC.HOME} />;
  }

  return (
    <FormProvider {...methods}>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <ContentStack>
          <Box>
            <Image src={becomeTruckerPartner} alt="becomeTruckerPartner" />
          </Box>
          <ContentBox>
            <TitleStack>
              <Title>Become a Moo Moves You Moving Partner Today!</Title>
            </TitleStack>

            <FieldsWrapper>
              <BecomePartnerFields />
              {error && <StyledErrorMessage>{error}</StyledErrorMessage>}

              <NextButtonBox>
                <StyledNextButton
                  variant="contained"
                  type="submit"
                  endIcon={<ArrowRightIcon />}
                  disabled={isLoading}>
                  Register Now
                </StyledNextButton>
              </NextButtonBox>
            </FieldsWrapper>
          </ContentBox>
        </ContentStack>
        <Footer />
      </Container>
    </FormProvider>
  );
};

export default Content;
