import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';
import TextField from '@mui/material/TextField';
import { COLORS } from '@utils/colors';
import { FontFamilyNames } from '@app/types/fonts';
import Button from '@components/Button';

export const Container = styled('form')(() => ({
  background: COLORS.white,
}));

export const ContentStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: '1440px',
  margin: '0 auto',
  display: 'grid',
  paddingRight: '70px',
  gridTemplateColumns: '1fr 732px',

  '> .MuiBox-root': {
    position: 'relative',

    '> img': {
      position: 'absolute',
      top: 0,
      right: '-180px',
    },
  },
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '300px 1fr',
  },
  [theme.breakpoints.down('md')]: {
    paddingRight: '0px',
    justifyItems: 'center',
    gridTemplateColumns: '0px 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const Image = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  background: COLORS.beige,
  borderRadius: '49px',
  padding: '60px',
  marginTop: 56,
  maxWidth: 732,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: 20,
    paddingTop: 27,
    marginTop: 0,
    borderRadius: 0,
  },
}));

export const TitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '40px',
  textAlign: 'center',

  [theme.breakpoints.down('sm')]: {
    marginBottom: '60px',
    textAlign: 'left',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '64px',
  lineHeight: '74px',
  fontFamily: FontFamilyNames.MoretRegular,
  fontWeight: 400,

  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',
    lineHeight: 'normal',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  lineHeight: '28px',
  fontFamily: FontFamilyNames.MoretRegular,
  fontWeight: 400,
  marginBottom: '18px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

export const NextButtonBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '60px',

  button: {
    width: '100%',
    height: '48px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    button: {
      width: '100%',
      height: '36px',
      padding: '12px 30px',
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '& .MuiOutlinedInput-root': {
    borderBottom: `1px solid ${COLORS.darkBlue}`,
    borderRadius: 0,

    [theme.breakpoints.down('sm')]: {
      height: 36,
    },
  },
  '& .MuiInputBase-input': {
    color: COLORS.darkBlue,
    fontSize: 16,
    padding: 0,
    paddingBottom: '12px',
    letterSpacing: '-0.02em',

    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },

  '& ::placeholder': {
    color: COLORS.grey[50],
    opacity: 1,

    display: 'block !important',
    fontSize: '16px',
    letterSpacing: '-0.32px',

    [theme.breakpoints.down('sm')]: {
      height: 36,
      letterSpacing: '-0.14px',
      fontSize: '14px',
    },
  },
  'input:-webkit-autofill': {
    boxShadow: `0 0 0 1000px  ${COLORS.beige} inset !important`,
  },

  '& .Mui-error': {
    border: 'none',
  },
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
  },
  fieldset: {
    border: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,

    '& .MuiInputBase-input': {
      padding: 0,
      // height: '18px',
      paddingTop: '0',
      paddingBottom: '15px',
    },
  },
}));

export const StyledNextButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: '0 auto',
    fontSize: 20,
  },
}));

export const Footer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '60px 70px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '100px 20px 90px 20px',
    background: COLORS.beige,
  },
}));

export const FooterLinksStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'flex-end',
  gap: 51,
}));

export const FooterLinks = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: 51,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 7,
  },
}));

export const FooterLink = styled(Typography)(({ theme }) => ({
  color: COLORS.grey['50'],
  cursor: 'pointer',
  fontSize: '16px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const StyledErrorMessage = styled(Typography)(() => ({
  color: COLORS.error.main,
  fontSize: '12px',
  marginBottom: 20,
}));

export const ButtonWrapper = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.MoretRegular,

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    top: '0',
    right: 'unset',
    left: '25px',
    fontSize: '16px',
    svg: {
      marginRight: '5px',
      width: 21,
      height: 16,
    },
  },
}));

export const FieldsWrapper = styled(Box)(() => ({
  maxWidth: 400,
  margin: '0 auto',
}));
