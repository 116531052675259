import { FieldValues, useFormContext } from 'react-hook-form';

import PhoneField from '@ui/PhoneField';
import { ErrorMessage } from '@hookform/error-message';
import {
  StyledPhoneNumber,
  StyledFieldWrapper,
  CustomErrorMessage,
} from '../../styled';

// Extend the props to include a generic type for the form values
interface PhoneFieldWrapperProps<TFieldValues extends FieldValues> {
  placeholder: string;
  registerName: keyof TFieldValues;
}

// Make the PhoneFieldWrapper generic and apply the generic type T to useFormContext
function PhoneFieldWrapper<TFieldValues extends FieldValues>({
  placeholder,
  registerName,
}: PhoneFieldWrapperProps<TFieldValues>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>(); // Use the generic type T here

  return (
    <StyledFieldWrapper>
      <PhoneField<TFieldValues>
        name={registerName} // Ensure name prop in PhoneField is typed to accept keyof T
        control={control}
        placeholder={placeholder}>
        <StyledPhoneNumber
          autocompleteSearch
          inputProps={{
            autoComplete: 'off', // Changed to 'off' to match typical usage
          }}
        />
      </PhoneField>
      {errors[registerName] && (
        <CustomErrorMessage>
          {/* Ensure ErrorMessage component is properly typed to handle generic form values */}
          <ErrorMessage name={registerName as any} errors={errors} />
        </CustomErrorMessage>
      )}
    </StyledFieldWrapper>
  );
}

export default PhoneFieldWrapper;
