import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

export const ContentWrapper = styled(Stack)(({ theme }) => ({
  '.currentMoves': {
    padding: '61px 71px 0 70px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0',
    height: 'min-content',

    '.currentMoves': {
      padding: '32px 20px 0 20px',
    },
  },
}));
