import { css } from '@mui/material/styles';

export const resetCSS = css`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    outline: none;
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }

  'ol, ul': {
    listStyle: 'none',
  },

  'blockquote, q': {
    quotes: 'none',
  },

  'blockquote:before, blockquote:after, q:before, q:after': {
    content: 'none',
    fallbacks: [
      {
        content: "''",
      },
    ],
  },

  table: {
    borderCollapse: 'collapse',
    borderSpacing: '0',
  },
 `.styles as any;
