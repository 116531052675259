import Box from '@mui/material/Box';
import { howMooWorksBlocks } from '../../data';
import {
  Container,
  StyledBlock,
  Title,
  StyledBlocks,
  StyledBlockTitle,
  StyledBlockImage,
  StyledBlockDescription,
} from './styled';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const HowMooWorksBlock = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      <Title>How Moo Works?</Title>
      <StyledBlocks>
        {howMooWorksBlocks.map((block, idx) => (
          <StyledBlock key={block.title}>
            <StyledBlockImage className={`block-image-${idx}`}>
              <img src={block.image} alt={block.title} />
            </StyledBlockImage>
            <Box>
              <StyledBlockTitle>{block.title}</StyledBlockTitle>
              <StyledBlockDescription>
                {matches ? block.mobileDescription : block.desktopDescription}
              </StyledBlockDescription>
            </Box>
          </StyledBlock>
        ))}
      </StyledBlocks>
    </Container>
  );
};

export default HowMooWorksBlock;
