import React, { Attributes, ReactElement, ReactNode } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { PHONE_REGEX } from '@utils/regex';

function addPropsToReactElement<T extends Attributes & { [key: string]: any }>(
  element: ReactNode,
  props: T,
): ReactElement {
  if (React.isValidElement(element)) {
    // Now TypeScript knows props has a shape that matches what React.cloneElement expects.
    return React.cloneElement(element, props);
  }
  // Return an empty fragment if the element is not a valid React element.
  return <></>;
}

function addPropsToChildren<T extends Attributes & { [key: string]: any }>(
  children: ReactNode,
  props: T,
): ReactElement {
  const validChildren = React.Children.toArray(children).map(child =>
    addPropsToReactElement(child, props),
  );
  // Wrap the children in a Fragment to ensure the return type is ReactElement
  return <>{validChildren}</>;
}

interface PhoneFieldProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: keyof TFieldValues;
  children: React.ReactNode;
  placeholder?: string;
}
const PhoneField = <TFieldValues extends FieldValues>({
  control,
  children,
  name,
  placeholder = 'Cell Phone Number',
}: PhoneFieldProps<TFieldValues>) => {
  return (
    <Controller
      name={name as any} // Casting to `any` to satisfy Controller's name prop type
      control={control}
      rules={{
        required: {
          value: true,
          message: 'Phone is a required field',
        },
        pattern: {
          value: PHONE_REGEX,
          message: 'Please enter a valid phone.',
        },
      }}
      render={({ field: { onChange, onBlur, value, ref } }) =>
        // Directly return the result of addPropsToChildren
        addPropsToChildren(children, {
          name: name.toString(),
          placeholder,
          country: 'us',
          onlyCountries: ['us'],
          disableCountryCode: true,
          masks: { us: '(...) ...-....' },
          value: value,
          onBlur: onBlur,
          onChange: onChange,
          inputProps: {
            ref,
          },
        })
      }
    />
  );
};

export default PhoneField;
