import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';

import {
  IRegisterValues,
  ILoginFormValues,
  ILoginResponse,
  IUserResponse,
  ILoginPayload,
  IContactUsPayload,
  IResetPasswordFormValues,
  ISetNewPasswordResetFormValues,
  ISetNewPasswordChangePasswordFormValues,
  IReferralRegisterValues,
} from '../types/index';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: buildBaseQuery(''),
  endpoints: builder => ({
    refreshToken: builder.mutation<ILoginResponse, ILoginFormValues>({
      query: body => ({
        url: 'auth/refresh-token',
        method: 'POST',
        body,
      }),
    }),
    login: builder.mutation<ILoginResponse, ILoginPayload>({
      query: body => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation<ILoginResponse, IRegisterValues>({
      query: body => ({
        url: 'auth/register',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<void, IResetPasswordFormValues>({
      query: body => ({
        url: 'auth/reset-password',
        method: 'POST',
        body: {
          ...body,
          role: 'user',
        },
      }),
    }),
    setNewPasswordForResetForm: builder.mutation<
      void,
      ISetNewPasswordResetFormValues & { token: string }
    >({
      query: body => ({
        url: 'auth/password',
        method: 'PUT',
        body,
      }),
    }),
    setNewPasswordForChangePasswordForm: builder.mutation<
      void,
      ISetNewPasswordChangePasswordFormValues
    >({
      query: body => ({
        url: 'auth/password',
        method: 'PATCH',
        body,
      }),
    }),
    getProfile: builder.query<IUserResponse, void>({
      query: () => '/profile',
    }),
    contactUs: builder.mutation<void, IContactUsPayload>({
      query: body => ({
        url: '/questions',
        method: 'POST',
        body,
      }),
    }),
    referralRegister: builder.mutation<ILoginResponse, IReferralRegisterValues>(
      {
        query: body => ({
          url: '/referral-partners',
          method: 'POST',
          body,
        }),
      },
    ),
    checkEmailAvailability: builder.query<number, { email: string }>({
      query: ({ email }) => ({
        url: `auth/email-availability?email=${encodeURIComponent(email)}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useResetPasswordMutation,
  useRegisterMutation,
  useSetNewPasswordForResetFormMutation,
  useSetNewPasswordForChangePasswordFormMutation,
  useContactUsMutation,
  useReferralRegisterMutation,
  useLazyCheckEmailAvailabilityQuery,
} = authApi;
