
import { ReactComponent as CloseIcon } from '@assets/svg/popup-close-icon.svg';
import { ReactComponent as MobileCloseIcon } from '@assets/svg/mobile-close-icon.svg';

import { COLORS } from '@utils/colors';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import { useQuoteBoardLogic } from './useAdditionalServicesModalLogic';
import {
  StyledModal,
  StyledIconButton,
  TitleWrapper,
  StyledModalTitle,
  StyledDialogContent,
  Content,
} from './styled';

const AdditionalServices = () => {
  const {
    matches,
    handleClose,
  } = useQuoteBoardLogic()

  return (
    <StyledModal
      open={true}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: 0 }}>
        {matches ? (
          <Stack alignItems="flex-end">
            <BurgerMenuButton
              type='closed'
              onClick={handleClose}
              sx={{
                background: COLORS.beige,
              }}
            >
              <MobileCloseIcon />
            </BurgerMenuButton>
          </Stack>
        ) : (
          <StyledIconButton
            aria-label="close"
            onTouchMove={handleClose}
            onClick={handleClose}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
      </DialogTitle>
      <StyledDialogContent>
        <TitleWrapper>
          <StyledModalTitle
            component="h3"
            variant="h1"
            label="Additional Services, If Required"
            textAlign="center"
          />
        </TitleWrapper>
        <Content>
          <p>
            <b>Long Carry:</b> The first 75 feet of carry from where the moving truck can legally park to your front door are included in your Total Price. This fee represents the up-charge for every additional 75 feet of long carry, if applicable.
          </p>
          <p>
            <b>Extra Stairs:</b> The first flight of stairs is included in your Total Price. This fee represents the up-charge for each additional continuous flight of stairs that movers would need to carry your furniture to complete your move, if applicable.
          </p>
          <p>
            <b>Shuttle Service:</b> In some cases, a large moving truck cannot get reasonably close to your home to unload. This fee represents the cost if this service is required.
          </p>
        </Content>
      </StyledDialogContent>
    </StyledModal >
  );
};

export default AdditionalServices;
