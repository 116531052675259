import { memo, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { StyledMenuItem, LeftSide } from '../../styled';

import { PAGE_ROUTES_PUBLIC } from 'app/types/enums/pages';
import { LINKS } from '@constants/links';
import { isDebugMode } from '@utils/constants';

const LeftPart = () => {
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    if (redirect) {
      window.location.href = redirect;
    }
  }, [redirect]);

  return (
    <LeftSide>
      <Stack spacing={{ lg: '30px', sm: 2, md: '25px' }} direction="row">
        <StyledMenuItem
          onClick={() => navigate(PAGE_ROUTES_PUBLIC.HOW_IT_WORKS)}>
          How Moo Works
        </StyledMenuItem>
        {/*{isDebugMode && (*/}
        {/*  <StyledMenuItem*/}
        {/*    onClick={() => {*/}
        {/*      // if (process.env.NODE_ENV === 'development') {*/}
        {/*      //   setRedirect('https://www.moomovesyou.com/movingtips')*/}
        {/*      //   return*/}
        {/*      // }*/}

        {/*      window.location.href = LINKS.MOVINGTIPS;*/}
        {/*    }}>*/}
        {/*    Moo’ving Tips*/}
        {/*  </StyledMenuItem>*/}
        {/*)}*/}

        <StyledMenuItem onClick={() => navigate(PAGE_ROUTES_PUBLIC.FAQ)}>
          F.A.Q
        </StyledMenuItem>
      </Stack>
    </LeftSide>
  );
};

export default memo(LeftPart);
