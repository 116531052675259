import { MoveNavbarEnum } from '../types/move-navbar.enum';
import { useGetMovesQuery } from '@modules/profile/api/profile.api';

export const useMovesData = (activeTab: MoveNavbarEnum) => {
  const {
    data: movesData,
    isFetching,
    error,
  } = useGetMovesQuery(
    { status: [activeTab] },
    {
      pollingInterval: 5000,
    },
  );

  const moves = movesData?.data ?? [];

  return { moves, isLoading: isFetching, error };
};
