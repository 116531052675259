import {
  IMoveRequestFormValues,
  PackingServicesEnum,
} from '@modules/home/types/move.types';

export const looseFurnitureOption = {
  value: 'Loose furniture Only (small move)',
  label: 'Loose furniture Only (small move)',
  isDisabled: false,
  id: 'looseFurniture',
};

export const fromHouseOrApartmentOptions = [
  {
    value: '',
    label: 'From House, Apartment or Storage Unit',
    isDisabled: true,
  },
  { value: 'House', label: 'House', isDisabled: false },
  { value: 'Apartment', label: 'Apartment', isDisabled: false },
  { value: 'Storage', label: 'Storage Unit', isDisabled: false },
];

export const toHouseOrApartmentOptions = [
  { value: '', label: 'To House, Apartment or Storage Unit', isDisabled: true },
  { value: 'House', label: 'House', isDisabled: false },
  { value: 'Apartment', label: 'Apartment', isDisabled: false },
  { value: 'Storage', label: 'Storage Unit', isDisabled: false },
];

export const packingServicesOptions = [
  { value: '', label: 'Full Packing Services', isDisabled: true },
  {
    value: PackingServicesEnum.PACKING_SERVICES_INCLUDED,
    label: 'Packing Services Included',
    isDisabled: false,
  },
  {
    value: PackingServicesEnum.PACKING_SERVICES_NOT_INCLUDED,
    label: 'Packing Services Not Included',
    isDisabled: false,
  },
];

export const initialFormState: IMoveRequestFormValues = {
  from: { address: '', placeId: '' },
  to: { address: '', placeId: '' },
  date: null,
  currentHouseSize: null,
  // after backend changes will delete this part
  fromPlace: fromHouseOrApartmentOptions[1],
  toPlace: toHouseOrApartmentOptions[1],
  packingServices: packingServicesOptions[1],
  name: '',
  email: '',
  phone: '',
  noDropOffAddressOrStorageNeeded: null,
};
