import { Components, css } from '@mui/material/styles';

import { FontFamilyNames } from 'app/types/fonts';

import { resetCSS } from '@styles/reset';
import MoretFont from '@assets/fonts/Moret';
import PlainFont from '@assets/fonts/Plain';
import { COLORS } from '@utils/colors';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: css`
    ${resetCSS}

    ${MoretFont.fontFaces}
    ${PlainFont.fontFaces}


    :root {
      --toastify-font-family: ${FontFamilyNames.MoretRegular};
    }

    img, svg {
      user-select: none;
    }
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      outline: none;
      
      ::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }

    body {
      webkit-font-smoothing: antialiased;
      moz-osx-font-smoothing: grayscale;
      line-height: unset;
      background:  ${COLORS.beige};
      overflow-x: hidden;
    }

    body,
    html,
    #root {
      min-height: var(--100vh);
      display: flex;
      flex-direction: column;
      scroll-behavior: smooth;
    }

    #root {
      color:  ${COLORS.darkBlue};
      
    }

    main {
      flex: 1;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    footer {
      z-index: 1;
    }

    .Toastify__toast-body {
      font-size: 14px;
    }

    .MuiTooltip-popper[data-popper-placement*="bottom"],
    .MuiTooltip-popper[data-popper-placement*="top"] {
      .MuiTooltip-tooltip {
        &.MuiTooltip-tooltip {  
          color: ${COLORS.black};
          background: none;
          margin-top: 0;
          margin-bottom: 9px;
          font-size: 13px;
        }
      }
    }

    .MuiPickersPopper-root {
      max-width: 270px;
      .MuiPaper-elevation {
        max-width: 270px;
        border-radius: 0px 0px 20px 20px;
        .MuiPickersLayout-root {
          max-width: 270px;
          .MuiPickersLayout-contentWrapper {
            max-width: 270px;
            .MuiDateCalendar-root {
              max-width: 270px;
              max-height: 254px;
              
              .MuiPickersCalendarHeader-root {
                position: relative;
                max-width: 270px;

                padding-left: 12px;

                .MuiPickersCalendarHeader-labelContainer {
                  position: absolute;
                  left: 29%;
                }
                .MuiPickersArrowSwitcher-root {
                  width: 100%;
                  justify-content: space-between;
                }
              }
              .MuiDateCalendar-viewTransitionContainer {
                max-width: 270px;

                > div {
                  > div {
                    .MuiDayCalendar-header {
                      .MuiDayCalendar-weekDayLabel {
                        width: 28px;
                        height: 28px;
                        color: ${COLORS.orange};
                      }
                    }
                    .MuiDayCalendar-slideTransition {
                      .MuiDayCalendar-monthContainer {
                        .MuiDayCalendar-weekContainer {
                          .MuiButtonBase-root, .MuiPickersDay-hiddenDaySpacingFiller {
                            width: 28px;
                            height: 28px;
                          }
                          .MuiPickersDay-today {
                            border-radius: 5px;
                          }
                          .Mui-selected {
                            border-radius: 5px;
                            background: ${COLORS.orange};
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      @media(max-width: 600px) {
        width: calc(100% - 48px);
        max-width: unset;

        .MuiPaper-elevation {
          max-width: unset;
          .MuiPickersLayout-root {
            max-width: unset;
            .MuiPickersLayout-contentWrapper {
              max-width: unset;
              .MuiDateCalendar-root {
                max-width: unset;
                .MuiDateCalendar-viewTransitionContainer {
                  max-width: unset;
                }
              }
            }
          }
        }
      }
    }

  ` as any,
};
