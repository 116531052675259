import { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";

import { setPopUpName } from "@app/store/features/app/app.slice";
import { PopUpEnum } from "@app/store/features/app/app.types";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { useMediaQuery } from "@mui/material";
// import { updateAgreement } from "@modules/truckerBecomePartner/store/becomePartner.slice";

const useAgreement = () => {
  const [redirect, setRedirect] = useState('')
  const elemRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerMobileRef = useRef<HTMLDivElement>(null)
  const theme = useTheme();
  const dispatch = useAppDispatch()
  // @ts-ignore
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const agreement = useAppSelector(({ become }) => become.agreement)
  const params = useAppSelector((state) => state.app.params)
  const [isDisable, setIsDisable] = useState(matches ? false : true)

  const handleAgreement = () => {
    if (params?.token) {
      let link = `${process.env.REACT_APP_REDIRECT_TO_PARTNER}/login-with-token?token=${params.token || ''}`

      setRedirect(link)
    }
    // dispatch(updateAgreement(true))
    // dispatch(setPopUpName({ name: PopUpEnum.Empty }))
  };

  const handleClose = () => {
    dispatch(setPopUpName({ name: PopUpEnum.Empty }))
  };

  const handleScroll = () => {
    const container = containerRef.current
    const elem = elemRef.current

    if (elem && container) {
      const { offsetHeight, scrollTop, scrollHeight } = container;
      const result = offsetHeight + scrollTop >= (scrollHeight - 5);

      elem.style.top = `${scrollTop / 5.8}px`

      setIsDisable((prev) => {
        if (prev && result) {
          return false
        }

        return prev
      })
    }
  }

  useEffect(() => {
    if (redirect) {
      window.location.href = redirect;
    }
  }, [redirect])

  return { isDisable, elemRef, containerRef, agreement, matches, containerMobileRef, handleScroll, handleAgreement, handleClose }
}

export default useAgreement;
