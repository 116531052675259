import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IAppState, IPopUpParams, PartnerInfo, PopUpEnum } from '@app/store/features/app/app.types';

const initialState: IAppState = {
  modal: PopUpEnum.Empty,
  params: null,
  isUpdateAfterDeleteMove: false,
  isOpenLogin: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUpdateAfterDeleteMove: (state, { payload }: PayloadAction<{ isUpdateAfterDeleteMove: boolean }>) => {
      state.isUpdateAfterDeleteMove = payload.isUpdateAfterDeleteMove
    },
    setPopUpName: (state, { payload }: PayloadAction<{ name: PopUpEnum, params?: IPopUpParams }>) => {
      state.modal = payload.name;

      if (
        payload.name === PopUpEnum.DeleteMove && payload?.params ||
        payload.name === PopUpEnum.AgreementPopup && payload?.params
      ) {
        state.params = payload?.params;
      }

      if (
        payload.name !== PopUpEnum.DeleteMove && payload.name !== PopUpEnum.AgreementPopup
      ) {
        state.params = null
      }
    },
    setPartnerInfo: (state, { payload }: PayloadAction<{ info: PartnerInfo }>) => {
      state.partnerInfo = payload.info;
    },
    setLoginPopUpStatus: (state, { payload }) => {
      state.isOpenLogin = payload;
    }
  },
});

export const {
  setLoginPopUpStatus,
  setPartnerInfo,
  setPopUpName,
  setUpdateAfterDeleteMove
} = appSlice.actions;

export default appSlice.reducer;
