export enum FontFamilyNames {
  // moret
  MoretBold = 'Moret-Bold',
  MoretBook = 'Moret-Book',
  MoretRegular = 'Moret-Regular',
  // plain
  PlainBold = 'Plain-Bold',
  PlainBoldItalic = 'Plain-BoldItalic',
  PlainLight = 'Plain-Light',
  PlainLightItalic = 'Plain-LightItalic',
}

type FontFamily = typeof FontFamilyNames;

type Values = FontFamily[keyof FontFamily];

export interface ThemeFont {
  fontFamily: Values[];
  fontFaces: any;
}
