import { Navigate, Outlet, useNavigate } from 'react-router';

import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';
import { PopUpEnum } from '@app/store/features/app/app.types';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import {
  setLoginPopUpStatus,
  setPopUpName,
} from '@app/store/features/app/app.slice';
import { ReactComponent as MobileBurgerMenuIcon } from '@assets/svg/mobile-burger-menu-icon.svg';
import { BurgerMenuButton } from '@components/BurgerMenuButton/styled';
import Header from '../Header';
import { LayoutContainer, Container, ProfileHeader } from './styled';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import appLogoMobile from '@assets/svg/app-logo-mobile.svg';
import PlainImage from '@ui/Image/PlainImage';
import appLogo from '@assets/svg/app-logo.svg';
import Box from '@mui/material/Box';

const AuthLayout = () => {
  const dispatch = useAppDispatch();
  const { accessToken, userInfo } = useAppSelector(({ auth }) => auth);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // TODO: remove QuoteBoardModal
  // useEffect(() => {
  //   if (userInfo) {
  //     dispatch(setPopUpName({ name: PopUpEnum.QuoteBoardModal }));
  //   }
  // }, []);

  if (!accessToken) {
    dispatch(setLoginPopUpStatus(true));
    return <Navigate to={PAGE_ROUTES_PUBLIC.HOME} />;
  }

  if (!userInfo) {
    return null;
  }

  return (
    <LayoutContainer>
      {/*<Account />*/}
      <Container className="container">
        <ProfileHeader className="profile-header">
          {matches ? (
            <Box
              sx={{
                width: '127px',
                height: 'auto',
              }}>
              <PlainImage
                src={appLogoMobile}
                objectFit="contain"
                onClick={() => {
                  navigate(PAGE_ROUTES_PUBLIC.HOME);
                }}
              />
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  width: '207px',
                  height: 'auto',
                  position: 'absolute',
                  top: '40px',
                  left: '71px',
                  zIndex: 9999,
                  cursor: 'pointer',
                }}>
                <PlainImage
                  src={appLogo}
                  objectFit="contain"
                  onClick={() => {
                    navigate(PAGE_ROUTES_PUBLIC.HOME);
                  }}
                />
              </Box>
            </Box>
          )}

          {!matches && <Header />}
          <BurgerMenuButton
            type="closed"
            onClick={() => {
              dispatch(setPopUpName({ name: PopUpEnum.MainMenuModal }));
            }}>
            <MobileBurgerMenuIcon />
          </BurgerMenuButton>
        </ProfileHeader>
        <div className="content">
          <Outlet />
        </div>
      </Container>
    </LayoutContainer>
  );
};

export default AuthLayout;
