import { useNavigate } from 'react-router-dom';

import PlainImage from '@ui/Image/PlainImage';
import appLogo from '@assets/svg/app-logo.svg';
import { PAGE_ROUTES_PUBLIC } from 'app/types/enums/pages';
import { Container, MobileLogo } from './styled';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const Center = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleLogoImageClick = () => {
    navigate(PAGE_ROUTES_PUBLIC.HOME);
  };

  return (
    <Container>
      {/*<MobileLogo onClick={handleLogoImageClick} src={appLogo} alt="Moo've" />*/}
      {/*<Box sx={{ padding: '0 32px 0 37px' }}>*/}
      <Box sx={{ width: '207px', height: '22px', marginTop: '34px' }}>
        <PlainImage
          className="desktop-version"
          onClick={handleLogoImageClick}
          objectFit="contain"
          src={appLogo}
        />
      </Box>
      {/*</Box>*/}
    </Container>
  );
};

export default Center;
