import { Navigate, Outlet } from 'react-router-dom';
import Header from '../Header';

import { Main, StyledOutletContainer } from './styled';
import { useAppSelector } from '@app/store/hooks';
import { PAGE_ROUTES_PUBLIC } from '@app/types/enums/pages';

const Layout = () => {
  const user = useAppSelector(({ auth }) => auth.userInfo);

  if (user && Object.keys(user).length) {
    return <Navigate to={PAGE_ROUTES_PUBLIC.HOME} replace={true} />
  }

  return (
    <Main>
      <StyledOutletContainer>
        <Header />
        <Outlet />
      </StyledOutletContainer>
    </Main>
  );
};

export default Layout;
