import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';

import { setUser } from '@modules/auth';
import { errorHandler } from '@utils/errorHendler';
import { IMoveRequestFormValues } from '@modules/home/types/move.types';
import { setToken } from '@modules/auth/store/auth.slice';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { useCreateMoveMutation } from '@modules/home/api/requests.api';
import {
  useLazyGetProfileQuery,
  useRegisterMutation,
} from '@modules/auth/api/auth.api';

import { useFormValidation } from './useFormValidation';
import { payloadCollector } from './helper';
import { initialFormState } from './components/Process/data';
import { deletePlusFromPhone } from '@utils/helpers';
import { PAGE_ROUTES_PRIVATE } from '@app/types/enums/pages';
import { useNavigate } from 'react-router-dom';

export type LevelsType = {
  third: boolean;
  second: boolean;
};

export const useMoveRegistration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [agree, setAgree] = useState(false);
  const auth = useAppSelector(({ auth }) => auth);

  const [message, setMessage] = useState('');
  const [isProcessLoading, setIsProcessLoading] = useState(false);

  const [levels, setLevels] = useState<LevelsType>({
    second: true,
    third: false,
  });

  const [makeRegister] = useRegisterMutation();
  const [getProfile] = useLazyGetProfileQuery();
  const [createMove] = useCreateMoveMutation();

  const methods = useForm<IMoveRequestFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialFormState,
  });

  // isValid does not work for all fields
  const {
    getValues,
    handleSubmit,
    setValue,
    reset,
    setError,
    getFieldState,
    watch,
  } = methods;
  const values = getValues();

  const createMoveSubmit = async (data: IMoveRequestFormValues) => {
    try {
      setIsProcessLoading(true);
      setMessage('');

      const normalizedPhone = data.phone ? `+1${data.phone}` : '';
      const dateValue = (data?.date as Dayjs).format('YYYY-MM-DD') as string;

      if (!dayjs(dateValue).isValid()) {
        throw new Error('Invalid data provided');
      }

      if (!auth?.userInfo) {
        const userRegisterResponse = await makeRegister({
          fullName: data.name,
          email: data.email,
          phone: normalizedPhone,
          code: data.code,
        }).unwrap();

        const { accessToken, refreshToken } = userRegisterResponse;

        dispatch(setToken({ accessToken, refreshToken, rememberMe: true }));

        const { phone, ...rest } = await getProfile().unwrap();

        const profileResponseData = {
          ...rest,
          phone: deletePlusFromPhone(phone),
        };

        dispatch(setUser(profileResponseData));
      }

      if (
        !data.currentHouseSize ||
        !data.toPlace ||
        !data.fromPlace ||
        !data.packingServices ||
        !data.from ||
        !data.to
      ) {
        throw new Error(
          'Required data fields missing for creating move payload',
        );
      }

      const payload = payloadCollector(data);

      await createMove({
        pickupDate: dateValue,
        houseId: payload.houseId,
        originPlaceId: payload.origin.placeId,
        destinationPlaceId: payload.destination.placeId,
      }).unwrap();

      setIsProcessLoading(false);
      reset();

      navigate(PAGE_ROUTES_PRIVATE.PROFILE);
    } catch (error) {
      const handledMessage = errorHandler(error, setError);

      setMessage(handledMessage);
      setIsProcessLoading(false);
      throw error;
    }
  };

  const { isValid } = useFormValidation({
    values,
    levels,
    watch,
    setLevels,
    setValue,
    getFieldState,
  });

  return {
    agree,
    phone: values.phone,
    setAgree,
    isValid,
    message,
    levels,
    methods,
    isProcessLoading,
    handleSubmit,
    createMoveSubmit,
  };
};
