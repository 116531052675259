import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@components/Typography';

import { FontFamilyNames } from '@app/types/fonts';
import { COLORS } from '@utils/colors';

export const Container = styled(Stack)(({ theme }) => ({
  maxWidth: '1006px',
  width: '100%',
  margin: '0px auto 110px',

  [theme.breakpoints.down('l')]: {
    padding: '0px 20px',
  },

  [theme.breakpoints.down('sm')]: {
    margin: '0 auto 100px auto',
  },
}));

export const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  maxHeight: '372px',
  display: 'flex',
  flexDirection: 'unset',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  '> div:first-child': {
    button: {
      fontSize: '24px',
      padding: '12px 30px',
      width: '235px',
      height: '52px',
      marginTop: '50px',

      svg: {
        width: '36px',
        height: '18px',
      },
    },
  },

  [theme.breakpoints.down('md')]: {
    '> div:first-child': {
      button: {
        fontSize: '16px',
        width: '100%',
        height: '46px',
        marginTop: '40px',
      },
    },

    '> div:last-child': {
      position: 'absolute',
      top: '130px',
      right: 0,
    },
  },

  [theme.breakpoints.down('sm')]: {
    maxHeight: 'unset',
  },
}));

export const FlexBox = styled(Stack)(({ theme }) => ({
  maxWidth: 500,
  gap: 25,
  display: 'flex',
  flexDirection: 'unset',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    gap: 0,
    flexDirection: 'column',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: COLORS.darkBlue,
  textAlign: 'center',
  fontFamily: FontFamilyNames.MoretRegular,
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'capitalize',
  marginTop: '20px',
  marginBottom: '50px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
    marginTop: '0px',
    marginBottom: '40px',
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: FontFamilyNames.PlainLight,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '130%',
  letterSpacing: '-0.32px',
  marginBottom: 12,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  img: {
    marginRight: 20,
    height: 21,
    width: 18,
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    marginBottom: '4px',
    letterSpacing: '-0.28px',
    height: 25,
  },
}));

export const MooImg = styled('img')(({ theme }) => ({
  height: 404,
  width: 385,
  position: 'relative',
  top: '-35px',

  [theme.breakpoints.down('md')]: {
    width: '141px',
    height: '203px',
    top: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
    marginBottom: '40px',
    top: '-100px',
  },
}));
