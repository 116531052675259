import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import { COLORS } from '@utils/colors';

export const StyledStack = styled(Stack)<{ zIndex: 'up' | '' }>(
  ({ theme, zIndex }) => ({
    position: 'relative',

    svg: {
      position: 'absolute',
      top: '15px',
      right: '16px',
      zIndex: '56',

      [theme.breakpoints.down('sm')]: {
        zIndex: zIndex === 'up' ? '60' : '56',
        right: '25px',
      },
    },
  }),
);

export const StyledLocationInput = styled('input')(({ theme }) => {
  return {
    position: 'relative',
    zIndex: 50,
    padding: '14px 30px 25px 0px',
    width: '100%',
    fontWeight: 300,
    fontSize: 16,
    lineHeight: '130%',
    letterSpacing: '-0.32px',
    height: '40px',
    background: 'none',
    border: 'none',
    borderBottom: `1px solid ${COLORS.darkBlue}`,
    color: COLORS.grey[50],

    '::placeholder': {
      color: COLORS.grey[50],
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '46px',
      fontSize: 14,
      letterSpacing: '-0.14px',
    },
  };
});

export const StyledDropdownBox = styled(Box)<{ zIndex: 'up' | '' }>(
  ({ theme, zIndex }) => ({
    position: 'absolute',
    zIndex: 30,
    padding: '0',
    width: '100%',
    top: '50px',
    borderRadius: '0 0 20px 20px',
    background: 'theme.palette.common.white',
    border: `1px solid ${COLORS.grey[25]}`,

    'div:last-of-type': {
      borderTop: '0',
    },

    [theme.breakpoints.down('sm')]: {
      zIndex: zIndex === 'up' ? 59 : 55,
      width: '100%',
    },
  }),
);

export const SuggestionsList = styled(Stack)(({ theme }) => ({
  borderRadius: '0 0 20px 20px',
  overflowY: 'scroll',
  maxHeight: '212px',
  background: theme.palette.common.white,
  paddingTop: '0',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const StyledDropdownBoxItem = styled(Stack)`
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid ${COLORS.grey[25]};
`;
